//
//  mainList.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 1..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// common
import ConstantValue from 'constants/value';

// string
import { useTranslation } from 'react-i18next';

// util
import DataUtil from 'util/dataUtil';


/**
 * @protocol IdentityList
 * @date 2022/11/01
 * @brief 컨텐츠 identity 목록 반환
 */
export function IdentityList() {
    const { t } = useTranslation();
    return [{
        icon: 'icofont-checked',
        title: t('main_identity01_title'),
        description: t('main_identity01_description'),
    },{
        icon: 'icofont-play-alt-1',
        title: t('main_identity02_title'),
        description: t('main_identity02_description'),
    },{
        icon: 'icofont-clock-time',
        title: t('main_identity03_title'),
        description: t('main_identity03_description'),
    }];
}

/**
 * @protocol CounterList
 * @date 2022/11/01
 * @brief 컨텐츠 identity 목록 반환
 */
export function CounterList() {
    const { t } = useTranslation();
    return [{
        icon: 'icofont-air-ticket',
        value: ConstantValue.MAIN_COUNTER_01,
        title: t('main_counter01'),
    },{
        icon: 'icofont-network',
        value: ConstantValue.MAIN_COUNTER_02,
        title: t('main_counter02'),
    },{
        icon: 'icofont-network-tower',
        value: ConstantValue.MAIN_COUNTER_03,
        title: t('main_counter03'),
    },{
        icon: 'icofont-microphone-alt',
        value: ConstantValue.MAIN_COUNTER_04,
        title: t('main_counter04'),
    }];
}

/**
 * @protocol WorryList
 * @date 2022/11/01
 * @brief 고민 목록 반환
 */
export function WorryList() {
    const { t } = useTranslation();

    /**
     * 인덱스별 태그 목록 반환
     * @param {Number} index 인덱스
     * @returns {Array} 태그 목록
     */
    const getTagList = (index) => {
        var list = [];
        for( var j = 1; j <= 12; j++ ) {
            var stringKey = 'main_worry0' + String(index) + '_tag' + (j >= 10 ? String(j) : '0' + String(j));
            if( DataUtil.validateString(t(stringKey)) ) {
                list.push(t(stringKey));
            }
        }
        return list;
    }
    
    var worryList = [];

    for( var i = 1; i <= 8; i++ ) {
        var title = t('main_worry' + (i >= 10 ? String(i) : '0' + String(i)) + '_title');
        if( DataUtil.validateString(title) ) {
            worryList.push({
                imageUrl: 'assets/images/image/img_step' + String(i) + '.png',
                title: title,
                tagList: getTagList(i),
            });
        }
    }
    
    return worryList;
}

/**
 * @protocol WelcomePhraseList
 * @date 2022/11/01
 * @brief 고민 목록 반환
 */
export function WelcomePhraseList() {
    const { t } = useTranslation();
    var list = [];
    for( var i = 1; i <= 6; i++ ) {
        var stringKey = 'main_welcome_phrase' + String(i);
        if( DataUtil.validateString(t(stringKey)) ) {
            list.push(t(stringKey));
        }
    }
    return list;
}

/**
 * @protocol ReviewList
 * @date 2022/11/01
 * @brief 후기 목록 반환
 */
export function ReviewList() {
    const { t } = useTranslation();
    var reviewList = [];

    for( var i = 1; i <= 5; i++ ) {
        var review = t('main_review0' + String(i) + '_review');
        if( DataUtil.validateString(review) ) {
            reviewList.push({
                imageUrl: 'assets/images/image/img_commenter' + String(i) + '.jpg',
                nickname: t('main_review0' + String(i) + '_nickname'),
                company: t('main_review0' + String(i) + '_company'),
                review: review,
            });
        }
    }
    return reviewList;
}
