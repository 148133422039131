//
//  m2mInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 2..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// info
import UserInfo from "info/user/userInfo";

export const M2MINFO_LIST           = 'm2m_list';
export const M2MINFO_CONTAINER      = 'm2m_info';
export const M2MINFO_ID             = 'project_m2m_idx';
export const M2MINFO_PROJECT_ID     = 'project_id';
export const M2MINFO_ORDINAL        = 'ordinal';
export const M2MINFO_MEMBER_ID      = 'member_id';
export const M2MINFO_CONTENTS       = 'contents';
export const M2MINFO_ANSWER_COUNT   = 'answer_count';
export const M2MINFO_REGDATE        = 'regdate';


/**
 * @protocol M2mInfo
 * @date 2022/12/02
 * @brief 1:1 문의 정보
 */
export default class M2mInfo {
    constructor() {
        this.m2mId = 0;
        this.projectId = '';
        this.ordinal = 0;
        this.memberId = '';
        this.contents = '';
        this.answerCount = 0;
        this.regdate = null;
        
        this.userInfo = new UserInfo();
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(M2MINFO_ID) ) {
            this.m2mId = dict[M2MINFO_ID];
        }

        if( dict.hasOwnProperty(M2MINFO_PROJECT_ID) ) {
            this.projectId = dict[M2MINFO_PROJECT_ID];
        }

        if( dict.hasOwnProperty(M2MINFO_ORDINAL) ) {
            this.ordinal = dict[M2MINFO_ORDINAL];
        }

        if( dict.hasOwnProperty(M2MINFO_MEMBER_ID) ) {
            this.memberId = dict[M2MINFO_MEMBER_ID];
        }

        if( dict.hasOwnProperty(M2MINFO_CONTENTS) ) {
            this.contents = dict[M2MINFO_CONTENTS];
        }

        if( dict.hasOwnProperty(M2MINFO_ANSWER_COUNT) ) {
            this.answerCount = dict[M2MINFO_ANSWER_COUNT];
        }

        if( dict.hasOwnProperty(M2MINFO_REGDATE) ) {
            this.regdate = new Date(dict[M2MINFO_REGDATE]);
        }

        this.userInfo.parseFromDictionary(dict);
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [M2MINFO_ID]: this.noticeId,
            [M2MINFO_PROJECT_ID]: this.projectId,
            [M2MINFO_ORDINAL]: this.ordinal,
            [M2MINFO_MEMBER_ID]: this.memberId,
            [M2MINFO_CONTENTS]: this.contents,
            [M2MINFO_ANSWER_COUNT]: this.answerCount,
            [M2MINFO_REGDATE]: this.regdate,
        };
        return dict;
    }

    /**
     * 생성 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForPost() {
        var dict = {
            [M2MINFO_PROJECT_ID]: this.projectId,
            [M2MINFO_ORDINAL]: this.ordinal,
            [M2MINFO_MEMBER_ID]: this.memberId,
            [M2MINFO_CONTENTS]: this.contents,
        };
        return dict;
    }
}
