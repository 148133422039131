//
//  projectReviewInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 29..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// info
import UserInfo from "info/user/userInfo";

export const PROJECTREVIEWINFO_REVIEW_LIST          = 'project_review_list';
export const PROJECTREVIEWINFO_PROJECT_CONTAINER    = 'project_review_info';
export const PROJECTREVIEWINFO_PROJECT_REVIEW_ID    = 'project_review_idx';
export const PROJECTREVIEWINFO_PROJECT_ID           = 'project_id';
export const PROJECTREVIEWINFO_ORDINAL              = 'ordinal';
export const PROJECTREVIEWINFO_REVIEWER_ID          = 'reviewer_id';
export const PROJECTREVIEWINFO_STAR_POINT           = 'star_point';
export const PROJECTREVIEWINFO_REVIEW               = 'review';
export const PROJECTREVIEWINFO_REGDATE              = 'regdate';


/**
 * @protocol ProjectReviewInfo
 * @date 2022/11/29
 * @brief 팀프로젝트 리뷰 정보
 */
export default class ProjectReviewInfo {
    constructor() {
        this.reviewId = '';
        this.projectId = '';
        this.ordinal = '';
        this.reviewerId ='';
        this.starPoint = 0;
        this.review = '';
        this.regdate = null;

        this.reviewer = new UserInfo();
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(PROJECTREVIEWINFO_PROJECT_REVIEW_ID) ) {
            this.reviewId = dict[PROJECTREVIEWINFO_PROJECT_REVIEW_ID];
        }

        if( dict.hasOwnProperty(PROJECTREVIEWINFO_PROJECT_ID) ) {
            this.projectId = dict[PROJECTREVIEWINFO_PROJECT_ID];
        }

        if( dict.hasOwnProperty(PROJECTREVIEWINFO_ORDINAL) ) {
            this.ordinal = dict[PROJECTREVIEWINFO_ORDINAL];
        }

        if( dict.hasOwnProperty(PROJECTREVIEWINFO_REVIEWER_ID) ) {
            this.reviewerId = dict[PROJECTREVIEWINFO_REVIEWER_ID];
        }

        if( dict.hasOwnProperty(PROJECTREVIEWINFO_STAR_POINT) ) {
            this.starPoint = Number(dict[PROJECTREVIEWINFO_STAR_POINT]);
        }

        if( dict.hasOwnProperty(PROJECTREVIEWINFO_REVIEW) ) {
            this.review = dict[PROJECTREVIEWINFO_REVIEW];
        }

        if( dict.hasOwnProperty(PROJECTREVIEWINFO_REGDATE) ) {
            this.regdate = new Date(dict[PROJECTREVIEWINFO_REGDATE]);
        }

        this.reviewer.parseFromDictionary(dict);
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [PROJECTREVIEWINFO_PROJECT_REVIEW_ID]: this.reviewId,
            [PROJECTREVIEWINFO_PROJECT_ID]: this.projectId,
            [PROJECTREVIEWINFO_ORDINAL]: this.ordinal,
            [PROJECTREVIEWINFO_REVIEWER_ID]: this.reviewerId,
            [PROJECTREVIEWINFO_STAR_POINT]: String(this.starPoint),
            [PROJECTREVIEWINFO_REVIEW]: this.review,
            [PROJECTREVIEWINFO_REGDATE]: this.regdate,
        };
        return dict;
    }

    /**
     * 등록에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForRegister() {
        var dict = {
            [PROJECTREVIEWINFO_PROJECT_ID]: this.projectId,
            [PROJECTREVIEWINFO_ORDINAL]: this.ordinal,
            [PROJECTREVIEWINFO_REVIEWER_ID]: this.reviewerId,
            [PROJECTREVIEWINFO_REVIEW]: this.review,
        };
        return dict;
    }

    /**
     * 삭제에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForDelete() {
        var dict = {
            [PROJECTREVIEWINFO_PROJECT_REVIEW_ID]: this.reviewId,
            [PROJECTREVIEWINFO_REVIEWER_ID]: this.reviewerId,
        };
        return dict;
    }
}
