//
//  networkUser.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 9..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// util
import DataUtil from 'util/dataUtil';

// network
import CommonNetwork from 'network/common/commonNetwork';

// info
import ResponseInfo, {
    RESPONSEINFO_CONTAINER,
} from 'info/common/responseInfo';
import UserInfo, {
    USERINFO_CONTAINER,
} from 'info/user/userInfo';
import ApplyInfo, {
    APPLYINFO_CONTAINER,
} from 'info/user/applyInfo';
// package
import axios from 'axios';
axios.defaults.withCredentials = true;

const NETWORK_PATH_LOGIN_KAKAO                      = '/login_kakao';
const NETWORK_PATH_SIGNUP                           = '/signup';
const NETWORK_PATH_LOGOUT                           = '/logout';
const NETWORK_PATH_GET_MEMBER_INFO                  = '/get_member_info';
const NETWORK_PATH_UPDATE_MEMBER_INFO               = '/update_member_info';
const NETWORK_PATH_UPDATE_MENTOR_BASIC_INFO         = '/update_mentor_basic_info';
const NETWORK_PATH_UPDATE_MENTOR_ADJUSTMENT_INFO    = '/update_mentor_adjustment_info';
const NETWORK_PATH_APPLY_MENTOR                     = '/apply_mentor';
const NETWORK_PATH_CHECK_APPLY_MENTOR               = '/check_apply_mentor';

const NETWORK_PARAM_CATEGORY_LIST   = 'category_list';
const NETWORK_PARAM_TIMETABLE_LIST  = 'timetable_list';
const NETWORK_PARAM_TENDENCY_LIST   = 'tendency_list';
const NETWORK_PARAM_MEMBER_ID       = 'member_id';


/**
 * @protocol NetworkUser
 * @date 2022/11/09
 * @brief 유저 관련 통신
 */
export default class NetworkUser {
    /**
     * 카카오 로그인 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} authCode 카카오 인증 코드
     */
    static requestKakaoLogin(resSuccess, resFail, authCode) {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_LOGIN_KAKAO]);
        
        try {
            axios.post(url, {
                auth_code: authCode,
            }, {
                headers: CommonNetwork.getHeader(),
            }).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                // 로그인 사용자 정보 parsing 및 설정
                var userInfo = new UserInfo();
                userInfo.parseFromDictionary(responseInfo.data);

                resSuccess({
                    [RESPONSEINFO_CONTAINER]: responseInfo,
                    [USERINFO_CONTAINER]: userInfo,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);

                // 카카오 uid 존재 시에 카카오 계정 정보 포함해서 전달
                if( DataUtil.validateString(responseInfo.data.member_id) ) {
                    var userInfo = new UserInfo();
                    userInfo.parseFromDictionary(responseInfo.data);
                    
                    resFail({
                        [RESPONSEINFO_CONTAINER]: responseInfo,
                        [USERINFO_CONTAINER]: userInfo,
                    });
                }
                else {
                    resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
                }
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 회원가입 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {UserInfo} userInfo 입력한 사용자 정보
     */
    static requestSignUp = async (resSuccess, resFail, userInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_SIGNUP]);
        
        try {
            await axios.post(url, userInfo.formDataForSignUp()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                // 가입된 사용자 정보 parsing 및 설정
                var userInfo = new UserInfo();
                userInfo.parseFromDictionary(responseInfo.data);

                resSuccess({
                    [RESPONSEINFO_CONTAINER]: responseInfo,
                    [USERINFO_CONTAINER]: userInfo,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 로그아웃 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {UserInfo} userInfo 사용자 정보
     */
    static requestLogout = async (resSuccess, resFail, userInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_LOGOUT]);
        
        try {
            await axios.post(url, userInfo.dictionaryForLogout()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 세션 사용자 정보 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     */
    static getUserInfo = async (resSuccess, resFail) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_MEMBER_INFO]);
        
        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                if( responseInfo.data ) {
                    // 로그인 사용자 정보 parsing 및 설정
                    var userInfo = new UserInfo();
                    userInfo.parseFromDictionary(responseInfo.data);

                    resSuccess({
                        [RESPONSEINFO_CONTAINER]: responseInfo,
                        [USERINFO_CONTAINER]: userInfo,
                    });
                }
                else {
                    resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
                }
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 회원정보 수정
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {UserInfo} userInfo 입력한 사용자 정보
     */
    static updateMemberInfo = async (resSuccess, resFail, userInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_UPDATE_MEMBER_INFO]);
        
        try {
            await axios.post(url, userInfo.formDataForUpdate()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                // 가입된 사용자 정보 parsing 및 설정
                var userInfo = new UserInfo();
                userInfo.parseFromDictionary(responseInfo.data);

                resSuccess({
                    [RESPONSEINFO_CONTAINER]: responseInfo,
                    [USERINFO_CONTAINER]: userInfo,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 사수 신청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {UserInfo} userInfo 입력한 사용자 정보
     * @param {Array} categoryList 상담 가능 카테고리 목록
     * @param {Array} timetabelList 상담 가능 시간 목록
     * @param {Array} dispositionList 사수 성향 목록
     */
    static applyMemberInfo = async (resSuccess, resFail, userInfo, categoryList, timetabelList, dispositionList) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_APPLY_MENTOR]);
        
        var formData = userInfo.formDataForApply();
        
        var categoryDictList = [];
        categoryList.forEach(element => {
            categoryDictList.push(element.dictionaryForApply());
        });

        var timetableDictList = [];
        timetabelList.forEach(element => {
            timetableDictList.push(element.dictionaryForApply());
        });

        var dispositionDictList = [];
        dispositionList.forEach(element => {
            dispositionDictList.push(element.dictionaryForApply());
        });
        
        formData.append([NETWORK_PARAM_CATEGORY_LIST], JSON.stringify(categoryDictList));
        formData.append([NETWORK_PARAM_TIMETABLE_LIST], JSON.stringify(timetableDictList));
        formData.append([NETWORK_PARAM_TENDENCY_LIST], JSON.stringify(dispositionDictList));

        try {
            await axios.post(url, formData).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                // 가입된 사용자 정보 parsing 및 설정
                var userInfo = new UserInfo();
                userInfo.parseFromDictionary(responseInfo.data);

                resSuccess({
                    [RESPONSEINFO_CONTAINER]: responseInfo,
                    [USERINFO_CONTAINER]: userInfo,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 사수 신청 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {Number} memberId 사용자 id
     */
    static checkApplyMentor = async (resSuccess, resFail, memberId) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_CHECK_APPLY_MENTOR])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_MEMBER_ID]: memberId,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                if( responseInfo.data ) {
                    var applyInfo = new ApplyInfo();
                    applyInfo.parseFromDictionary(responseInfo.data);
                    resSuccess({[APPLYINFO_CONTAINER]: applyInfo});
                }
                else {
                    resSuccess({[APPLYINFO_CONTAINER]: null});
                }
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 사수 기본정보 수정
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {UserInfo} userInfo 입력한 사용자 정보
     */
    static updateMentorBasicInfo = async (resSuccess, resFail, userInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_UPDATE_MENTOR_BASIC_INFO]);
        
        try {
            await axios.post(url, userInfo.dictionaryForUpdateMentorBasic()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                // 가입된 사용자 정보 parsing 및 설정
                var userInfo = new UserInfo();
                userInfo.parseFromDictionary(responseInfo.data);

                resSuccess({
                    [RESPONSEINFO_CONTAINER]: responseInfo,
                    [USERINFO_CONTAINER]: userInfo,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 사수 정산정보 수정
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {UserInfo} userInfo 입력한 사용자 정보
     */
    static updateMentorAdjustmentInfo = async (resSuccess, resFail, userInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_UPDATE_MENTOR_ADJUSTMENT_INFO]);
        
        try {
            await axios.post(url, userInfo.dictionaryForUpdateMentorAdjustment()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                // 가입된 사용자 정보 parsing 및 설정
                var userInfo = new UserInfo();
                userInfo.parseFromDictionary(responseInfo.data);

                resSuccess({
                    [RESPONSEINFO_CONTAINER]: responseInfo,
                    [USERINFO_CONTAINER]: userInfo,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }
}
