//
//  attendanceInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 1..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// info
import UserInfo from "info/user/userInfo";

export const ATTENDANCEINFO_LIST        = 'attendance_list';
export const ATTENDANCEINFO_CONTAINER   = 'attendance_info';
export const ATTENDANCEINFO_ID          = 'project_attendance_idx';
export const ATTENDANCEINFO_PROJECT_ID  = 'project_id';
export const ATTENDANCEINFO_ORDINAL     = 'ordinal';
export const ATTENDANCEINFO_WEEKS       = 'weeks';
export const ATTENDANCEINFO_MEMBER_ID   = 'member_id';


/**
 * @protocol AttendanceInfo
 * @date 2022/12/01
 * @brief 프로젝트 출석 정보
 */
export default class AttendanceInfo {
    constructor() {
        this.attendanceId = '';
        this.projectId = '';
        this.ordinal = '';
        this.weeks = '';
        this.memberId = '';

        this.userInfo = new UserInfo();
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(ATTENDANCEINFO_ID) ) {
            this.attendanceId = dict[ATTENDANCEINFO_ID];
        }

        if( dict.hasOwnProperty(ATTENDANCEINFO_PROJECT_ID) ) {
            this.projectId = dict[ATTENDANCEINFO_PROJECT_ID];
        }

        if( dict.hasOwnProperty(ATTENDANCEINFO_ORDINAL) ) {
            this.ordinal = dict[ATTENDANCEINFO_ORDINAL];
        }

        if( dict.hasOwnProperty(ATTENDANCEINFO_WEEKS) ) {
            this.weeks = dict[ATTENDANCEINFO_WEEKS];
        }

        if( dict.hasOwnProperty(ATTENDANCEINFO_MEMBER_ID) ) {
            this.memberId = dict[ATTENDANCEINFO_MEMBER_ID];
        }
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [ATTENDANCEINFO_ID]: this.attendanceId,
            [ATTENDANCEINFO_PROJECT_ID]: this.projectId,
            [ATTENDANCEINFO_ORDINAL]: this.ordinal,
            [ATTENDANCEINFO_WEEKS]: this.weeks,
            [ATTENDANCEINFO_MEMBER_ID]: this.memberId,
        };
        return dict;
    }

    /**
     * 출석에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAttendance() {
        var dict = {
            [ATTENDANCEINFO_PROJECT_ID]: this.projectId,
            [ATTENDANCEINFO_ORDINAL]: this.ordinal,
            [ATTENDANCEINFO_WEEKS]: this.weeks,
            [ATTENDANCEINFO_MEMBER_ID]: this.memberId,
        };
        return dict;
    }

    /**
     * 결석에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAbsense() {
        var dict = {
            [ATTENDANCEINFO_ID]: this.attendanceId,
        };
        return dict;
    }
}
