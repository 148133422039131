//
//  memberReviewInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 12..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// info
import UserInfo from "info/user/userInfo";

export const MEMBERREVIEWINFO_REVIEW_LIST       = 'mentor_review_list';
export const MEMBERREVIEWINFO_PROJECT_CONTAINER = 'member_review_info';
export const MEMBERREVIEWINFO_MEMBER_REVIEW_ID  = 'member_review_idx';
export const MEMBERREVIEWINFO_MEMBER_ID         = 'member_id';
export const MEMBERREVIEWINFO_MEMBER_NO         = 'member_no';
export const MEMBERREVIEWINFO_REVIEWER_ID       = 'reviewer_id';
export const MEMBERREVIEWINFO_STAR_POINT        = 'star_point';
export const MEMBERREVIEWINFO_REVIEW            = 'review';
export const MEMBERREVIEWINFO_REGDATE           = 'regdate';


/**
 * @protocol MemberReviewInfo
 * @date 2022/12/12
 * @brief 멤버 리뷰 정보
 */
export default class MemberReviewInfo {
    constructor() {
        this.reviewId = '';
        this.memberId = '';
        this.memberNo = '';
        this.reviewerId ='';
        this.starPoint = 0;
        this.review = '';
        this.regdate = null;

        this.reviewer = new UserInfo();
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(MEMBERREVIEWINFO_MEMBER_REVIEW_ID) ) {
            this.reviewId = dict[MEMBERREVIEWINFO_MEMBER_REVIEW_ID];
        }

        if( dict.hasOwnProperty(MEMBERREVIEWINFO_MEMBER_ID) ) {
            this.memberId = dict[MEMBERREVIEWINFO_MEMBER_ID];
        }

        if( dict.hasOwnProperty(MEMBERREVIEWINFO_MEMBER_NO) ) {
            this.memberNo = dict[MEMBERREVIEWINFO_MEMBER_NO];
        }

        if( dict.hasOwnProperty(MEMBERREVIEWINFO_REVIEWER_ID) ) {
            this.reviewerId = dict[MEMBERREVIEWINFO_REVIEWER_ID];
        }

        if( dict.hasOwnProperty(MEMBERREVIEWINFO_STAR_POINT) ) {
            this.starPoint = Number(dict[MEMBERREVIEWINFO_STAR_POINT]);
        }

        if( dict.hasOwnProperty(MEMBERREVIEWINFO_REVIEW) ) {
            this.review = dict[MEMBERREVIEWINFO_REVIEW];
        }

        if( dict.hasOwnProperty(MEMBERREVIEWINFO_REGDATE) ) {
            this.regdate = new Date(dict[MEMBERREVIEWINFO_REGDATE]);
        }

        this.reviewer.parseFromDictionary(dict);
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [MEMBERREVIEWINFO_MEMBER_REVIEW_ID]: this.reviewId,
            [MEMBERREVIEWINFO_MEMBER_ID]: this.memberId,
            [MEMBERREVIEWINFO_MEMBER_NO]: this.memberNo,
            [MEMBERREVIEWINFO_REVIEWER_ID]: this.reviewerId,
            [MEMBERREVIEWINFO_STAR_POINT]: String(this.starPoint),
            [MEMBERREVIEWINFO_REVIEW]: this.review,
            [MEMBERREVIEWINFO_REGDATE]: this.regdate,
        };
        return dict;
    }

    /**
     * 등록에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForRegister() {
        var dict = {
            [MEMBERREVIEWINFO_MEMBER_ID]: this.memberId,
            [MEMBERREVIEWINFO_REVIEWER_ID]: this.reviewerId,
            [MEMBERREVIEWINFO_REVIEW]: this.review,
        };
        return dict;
    }

    /**
     * 삭제에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForDelete() {
        var dict = {
            [MEMBERREVIEWINFO_MEMBER_REVIEW_ID]: this.reviewId,
            [MEMBERREVIEWINFO_REVIEWER_ID]: this.reviewerId,
        };
        return dict;
    }
}
