//
//  projectApplyInfo.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 31..
//  Copyright © 2022년 Melephant. All rights reserved.
//


export const PROJECTAPPLYINFO_CONTAINER     = 'counsel_apply_info';
export const PROJECTAPPLYINFO_PROJECT_ID    = 'project_id';
export const PROJECTAPPLYINFO_ORDINAL       = 'ordinal';
export const PROJECTAPPLYINFO_MEMBER_ID     = 'member_id';
export const PROJECTAPPLYINFO_MEMBER_NAME   = 'member_name';
export const PROJECTAPPLYINFO_CONTACT       = 'contact';
export const PROJECTAPPLYINFO_ORDER_NAME    = 'order_name';
export const PROJECTAPPLYINFO_PRICE         = 'price';


/**
 * @protocol ProjectApplyInfo
 * @date 2023/01/31
 * @brief 팀프로젝트 신청 정보
 */
export default class ProjectApplyInfo {
    constructor() {
        this.projectId = '';
        this.ordinal = '';
        this.memberId = '';
        this.memberName = '';
        this.contact = '';
        this.orderName = '';
        this.price = '';
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(PROJECTAPPLYINFO_PROJECT_ID) ) {
            this.projectId = dict[PROJECTAPPLYINFO_PROJECT_ID];
        }

        if( dict.hasOwnProperty(PROJECTAPPLYINFO_ORDINAL) ) {
            this.ordinal = dict[PROJECTAPPLYINFO_ORDINAL];
        }

        if( dict.hasOwnProperty(PROJECTAPPLYINFO_MEMBER_ID) ) {
            this.memberId = dict[PROJECTAPPLYINFO_MEMBER_ID];
        }

        if( dict.hasOwnProperty(PROJECTAPPLYINFO_MEMBER_NAME) ) {
            this.memberName = dict[PROJECTAPPLYINFO_MEMBER_NAME];
        }

        if( dict.hasOwnProperty(PROJECTAPPLYINFO_CONTACT) ) {
            this.contact = dict[PROJECTAPPLYINFO_CONTACT];
        }

        if( dict.hasOwnProperty(PROJECTAPPLYINFO_ORDER_NAME) ) {
            this.orderName = dict[PROJECTAPPLYINFO_ORDER_NAME];
        }

        if( dict.hasOwnProperty(PROJECTAPPLYINFO_PRICE) ) {
            this.price = dict[PROJECTAPPLYINFO_PRICE];
        }
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [PROJECTAPPLYINFO_PROJECT_ID]: this.projectId,
            [PROJECTAPPLYINFO_ORDINAL]: this.ordinal,
            [PROJECTAPPLYINFO_MEMBER_ID]: this.memberId,
            [PROJECTAPPLYINFO_MEMBER_NAME]: this.memberName,
            [PROJECTAPPLYINFO_CONTACT]: this.contact,
            [PROJECTAPPLYINFO_ORDER_NAME]: this.orderName,
            [PROJECTAPPLYINFO_PRICE]: this.price,
        };
        return dict;
    }

    /**
     * 결제시에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForPayment() {
        var dict = {
            [PROJECTAPPLYINFO_PROJECT_ID]: this.projectId,
            [PROJECTAPPLYINFO_ORDINAL]: this.ordinal,
            [PROJECTAPPLYINFO_MEMBER_ID]: this.memberId,
            [PROJECTAPPLYINFO_MEMBER_NAME]: this.memberName,
            [PROJECTAPPLYINFO_CONTACT]: this.contact,
            [PROJECTAPPLYINFO_ORDER_NAME]: this.orderName,
            [PROJECTAPPLYINFO_PRICE]: this.price,
        };
        return dict;
    }
}
