//
//  imagePicker.js
//  datatown
//
//  Created by Mumakil on 2022. 10. 26..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
    useRef,
} from 'react';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// package
import { Image } from 'react-bootstrap';


/**
 * @protocol ImagePicker
 * @date 2022/10/26
 * @brief 이미지 선택창
 */
function ImagePicker(props) {
    const { t } = useTranslation();

    const fileRef = useRef();
    const [fileUrl, setSelectFileUrl] = useState(props.defaultImageUrl ? props.defaultImageUrl : null);

    /**
     * 이미지 선택시 호출
     * @param {*} e 클릭 이벤트
     */
    const onImageChanged = (e) => {
        const img = e.target.files[0];

        if( img !== undefined ){
            setSelectFileUrl(URL.createObjectURL(img));
            if( props.onImageChanged ) {
                props.onImageChanged(img);
            }
        }
        else {
            setSelectFileUrl('');
            if( props.onImageChanged ) {
                props.onImageChanged('');
            }
        }
    };

    /**
     * 삭제 버튼 클릭시 호출
     */
    const onDeletePressed = () => {
        fileRef.current.value = null;
        setSelectFileUrl('');
        if( props.onImageChanged ) {
            props.onImageChanged('');
        }
    };

    return (
        <div style={{}}>
            {
                DataUtil.validateString(fileUrl) ?
                <div style={{ width: '100%', display: 'flex', alignItems: 'flex-end', marginBottom: '10px'}}>
                    <Image style={{height: '100px'}} src={fileUrl}/>
                    <button className="button soft-danger"
                        style={{ marginLeft: '20px', height: '100%', width: '100px'}}
                        onClick={onDeletePressed}>
                        {t('button_delete')}
                    </button>
                </div> :
                null
            }
            <div className="fallback">
                <input type="file"
                    ref={fileRef}
                    accept='image/jpg,image/png,image/jpeg,image/gif'
                    style={{backgroundColor: '#fff', width: '100%', maxWidth: '400px'}}
                    onChange={onImageChanged}/>
            </div>
        </div>
    );
}

export default ImagePicker;