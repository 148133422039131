//
//  scheduleModifyPopupView.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 7..
//  Copyright © 2022년 Melephant. All rights reserved.
//


import React, {
    useState,
    useEffect,
} from 'react';

// common
import { ReplaceKey } from 'constants/key';
import ConstantValue from 'constants/value';

// string
import { useTranslation } from 'react-i18next';

// package
import { Button, Modal } from 'react-bootstrap';


/**
 * @protocol ScheduleModifyPopupView
 * @date 2022/12/07
 * @brief 스케쥴 수정 화면 객체
 */
export default function ScheduleModifyPopupView(props) {
    const { t } = useTranslation();

    const [content, setContent] = useState('');

    useEffect(() => {
    }, []);

    const onContentChanged = ({ target: { value}}) => setContent(value);

    /**
     * 초기화
     */
    const initialize = () => {
        setContent('');
    }

    /**
     * 취소 버튼 클릭시 호출
     */
    const onCancelPressed = () => {
        if( props.onCancelPressed ) {
            props.onCancelPressed();
        }
        
        // 재사용 위해 초기화
        initialize();
    };

    /**
     * 취소 버튼 클릭시 호출
     */
    const onNextPressed = () => {
        if( props.onConfirmPressed ) {
            props.onConfirmPressed(props.data.scheduleId, content);
        }

        // 재사용 위해 초기화
        initialize();
    };

    return <Modal
                show={props.visible}
                onHide={onCancelPressed}
                backdrop="static"
                size='lg'
                keyboard={false}>
                <Modal.Header>
                    {t('project_manage_modify_title').replace(ReplaceKey.REPLACE_WEEKS, props.data ? props.data.weeks : '1')}
                </Modal.Header>
                <Modal.Body>
                    <div className="row col-xs-4">
                        <div className="col-lg-12">
                            <input className="uk-input"
                                type="text"
                                value={content}
                                placeholder={t('input_schedule')}
                                maxLength={ConstantValue.INPUT_MAX_LENGTH_SCHEDULE}
                                onChange={onContentChanged}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="canceled button outline-primary" variant="secondary"
                        onClick={onCancelPressed}>
                        {t('button_cancel')}
                    </Button>
                    <Button className="primary button"
                        disabled={content.length > 0 ? false : true}
                        onClick={onNextPressed}>
                        {t('button_next')}
                    </Button>
                </Modal.Footer>
            </Modal>;
}
