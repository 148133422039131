//
//  categoryInput.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 22..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
} from 'react';

// common
import { CategoryTypeKey } from 'constants/key';
import ConstantValue from 'constants/value';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// component
import CategoryPopupView from 'components/modal/categoryPopupView';


/**
 * @protocol CategoryInput
 * @date 2022/11/22
 * @brief 상담 카테고리 입력 화면
 */
function CategoryInput(props) {
    const { t } = useTranslation();

    const [insertVisible, setInsertVisible] = useState(false);

    /**
     * 취소 버튼 클릭시 호출
     */
    const onInsertCancelPressed = () => {
        setInsertVisible(false);
    }

    /**
     * 추가 버튼 클릭시 호출
     * @param {Array} list 선택한 카테고리 목록
     */
    const onInsertConfirmPressed = (list) => {
        setInsertVisible(false);
        
        if( props.insertData ) {
            props.insertData(list);
        }
    }

    /**
     * 추가 버튼 렌더링
     * @returns {*} 추가 버튼
     */
    const renderInsert = () => {
        if( props.data ) {
            if( props.data.length < ConstantValue.CATEGORY_MENTOR_LIMIT ) {
                return <button className="main-btn2"
                            type="button"
                            style={{marginTop: '10px'}}
                            onClick={() => {
                                setInsertVisible(true);
                            }}>{t('button_insert')}</button>;
            }
        }
    }

    /**
     * 추가한 항목들 표시
     * @returns {Array} 항목 ui 목록
     */
    const renderData = () => {
        var itemList = [];
        if( DataUtil.validateArray(props.data) ) {
            var index = 0;
            props.data.forEach(element => {
                var categoryStr = '';
                var jobStr = '';
                var keywordStr = '';
                
                if( DataUtil.validateArray(element) ) {
                    element.forEach(category => {
                        switch( category.type ) {
                            case CategoryTypeKey.CATEGORY_TYPE_1: {
                                if( categoryStr.length !== 0 ) {
                                    categoryStr += ', ';
                                }
                                categoryStr += category.name;
                                break;
                            }
                            case CategoryTypeKey.CATEGORY_TYPE_2: {
                                if( jobStr.length !== 0 ) {
                                    jobStr += ', ';
                                }
                                jobStr += category.name;
                                break;
                            }
                            case CategoryTypeKey.CATEGORY_TYPE_3: {
                                if( keywordStr.length !== 0 ) {
                                    keywordStr += ', ';
                                }
                                keywordStr += category.name;
                                break;
                            }
                            default: {
                                break;
                            }
                        }
                    });
                }
                
                itemList.push(<tr key={'category' + String(index)}>
                    <td>
                        <label>{categoryStr}</label>
                    </td>
                    <td>
                        <label>{jobStr}</label>
                    </td>
                    <td>
                        <label>{keywordStr}</label>
                    </td>
                    <td>
                        <button className="actions-btn"
                            value={index}
                            style={{border: 'none', backgroundColor: 'transparent'}}
                            type="button"
                            onClick={(e) => {
                                if( props.deleteData ) {
                                    props.deleteData(e.currentTarget.value);
                                }
                            }}>
                            <span className="iconbox button soft-danger"><i className="icofont-trash" value={index}></i></span>
                        </button>
                    </td>
                </tr>);
                
                index++;
            });
        }

        return itemList;
    }

    return (
        <div style={{width: '100%', marginBottom: '30px'}}>
            <table className="table table-default table-responsive-md">
                <thead>
                    <tr>
                        <th>{t('apply_header_category')}</th>
                        <th>{t('apply_header_job')}</th>
                        <th>{t('apply_header_keyword')}</th>
                        <th style={{width: '100px'}}>{t('apply_header_manage')}</th>
                    </tr>
                </thead>
                <tbody>
                    {renderData()}
                </tbody>
            </table>
            {renderInsert()}
            <CategoryPopupView
                visible={insertVisible}
                onCancelPressed={onInsertCancelPressed}
                onConfirmPressed={onInsertConfirmPressed}/>
        </div>
        
    );
}

export default CategoryInput;