//
//  networkBlog.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 18..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// common
import { CommonKey } from 'constants/key';

// network
import CommonNetwork from 'network/common/commonNetwork';

// info
import ResponseInfo, {
    RESPONSEINFO_CONTAINER,
} from 'info/common/responseInfo';
import BlogInfo, {
    BLOGINFO_LIST,
    BLOGINFO_CONTAINER,
} from 'info/blog/blogInfo';
import { listParseFromDictionary } from 'info/listParse';

// package
import axios from 'axios';
axios.defaults.withCredentials = true;

const NETWORK_PATH_GET_BLOG_LIST    = '/get_blog_list';
const NETWORK_PATH_GET_BLOG_INFO    = '/get_blog_info';

const NETWORK_PARAM_BLOG_IDX    = 'blog_idx';
const NETWORK_PARAM_PAGE_NUM    = 'page_num';
const NETWORK_PARAM_PAGE_SIZE   = 'page_size';


/**
 * @protocol NetworkBlog
 * @date 2023/01/18
 * @brief 블로그 관련 통신
 */
export default class NetworkBlog {
    /**
     * 블로그 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {Number} pageNum 페이지 번호
     * @param {Number} pageSize 페이지 크기
     */
    static getBlogList = async (resSuccess, resFail, pageNum, pageSize) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_BLOG_LIST])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_PAGE_NUM]: pageNum,
            [NETWORK_PARAM_PAGE_SIZE]: pageSize,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                var blogList = [];
                var totalCount = 0;

                if( responseInfo.data.hasOwnProperty(BLOGINFO_LIST) ) {
                    blogList = listParseFromDictionary(BlogInfo, responseInfo.data[BLOGINFO_LIST], '');
                }

                if( responseInfo.data.hasOwnProperty(CommonKey.PAGE_TOTAL_COUNT) ) {
                    totalCount = responseInfo.data[CommonKey.PAGE_TOTAL_COUNT];
                }

                resSuccess({
                    [BLOGINFO_LIST]: blogList,
                    [CommonKey.PAGE_TOTAL_COUNT]: totalCount,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 블로그 정보 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} blogIdx 블로그 id
     */
    static getBlogInfo = async (resSuccess, resFail, blogIdx) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_BLOG_INFO])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_BLOG_IDX]: blogIdx,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var blog = new BlogInfo();
                blog.parseFromDictionary(responseInfo.data);
                
                resSuccess({
                    [RESPONSEINFO_CONTAINER]: responseInfo,
                    [BLOGINFO_CONTAINER]: blog,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }
}
