//
//  projectImageView.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 27..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React from 'react';


/**
 * @protocol ProjectImageView
 * @date 2022/12/27
 * @brief 팀프로젝트 이미지 창
 */
function ProjectImageView(props) {
    return (
        <div style={props.style}>
            <figure >
                <img src={props.imageUrl}
                    style={{
                        maxWidth: props.maxWidth ? props.maxWidth : '400px',
                        aspectRatio: 870/470,
                        objectFit: 'contain',
                        backgroundColor: props.backgroundColor ? props.backgroundColor : '#EDEDED',
                    }}
                    alt=""/>
            </figure>
        </div>
    );
}

export default ProjectImageView;