//
//  networkM2m.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 2..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// common
import { CommonKey } from 'constants/key';

// network
import CommonNetwork from 'network/common/commonNetwork';
import { listParseFromDictionary } from 'info/listParse';

// info
import ResponseInfo, {
    RESPONSEINFO_CONTAINER,
} from 'info/common/responseInfo';
import M2mInfo, {
    M2MINFO_LIST,
    M2MINFO_CONTAINER,
} from 'info/solution/m2mInfo';
import ServiceM2mInfo, {
    SERVICEM2MINFO_LIST,
    SERVICEM2MINFO_CONTAINER,
} from 'info/service/serviceM2mInfo';
import M2mAnswerInfo, {
    M2MANSWERINFO_LIST,
} from 'info/solution/m2mAnswerInfo';

// package
import axios from 'axios';
axios.defaults.withCredentials = true;

const NETWORK_PATH_POST_PROJECT_M2M             = '/post_project_m2m';
const NETWORK_PATH_POST_SERVICE_M2M             = '/post_service_m2m';
const NETWORK_PATH_POST_PROJECT_M2M_ANSWER      = '/post_project_m2m_answer';
const NETWORK_PATH_GET_PROJECT_M2M_RECEIVE_LIST = '/get_project_m2m_receive_list';
const NETWORK_PATH_GET_PROJECT_M2M_SEND_LIST    = '/get_project_m2m_send_list';
const NETWORK_PATH_GET_SERVICE_M2M_SEND_LIST    = '/get_service_m2m_send_list';
const NETWORK_PATH_GET_PROJECT_M2M_INFO         = '/get_project_m2m_info';
const NETWORK_PATH_GET_SERVICE_M2M_INFO         = '/get_service_m2m_info';

const NETWORK_PARAM_MEMBER_ID       = 'member_id';
const NETWORK_PARAM_PROJECT_M2M_ID  = 'project_m2m_idx';
const NETWORK_PARAM_M2M_ID          = 'm2m_idx';
const NETWORK_PARAM_PAGE_NUM        = 'page_num';
const NETWORK_PARAM_PAGE_SIZE       = 'page_size';


/**
 * @protocol NetworkM2m
 * @date 2022/12/02
 * @brief 1:1 문의 관련 통신
 */
export default class NetworkM2m {
    /**
     * 1:1 문의 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {M2mInfo} m2mInfo 1:1 문의 정보
     */
    static requestPostProjectM2m = async (resSuccess, resFail, m2mInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_POST_PROJECT_M2M]);
        
        try {
            await axios.post(url, m2mInfo.dictionaryForPost()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 1:1 문의 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {ServiceM2mInfo} m2mInfo 1:1 문의 정보
     */
    static requestPostServiceM2m = async (resSuccess, resFail, m2mInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_POST_SERVICE_M2M]);
        
        try {
            await axios.post(url, m2mInfo.formDataForPost()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 1:1 문의 답변 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {M2mAnswerInfo} answerInfo 1:1 문의 답변 정보
     */
    static requestPostProjectM2mAnswer = async (resSuccess, resFail, answerInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_POST_PROJECT_M2M_ANSWER]);
        
        try {
            await axios.post(url, answerInfo.dictionaryForPost()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 1:1 문의 수신 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} memberId 멤버 id
     * @param {Number} pageNum 페이지 번호
     * @param {Number} pageSize 페이지 크기
     */
    static getProjectM2mReceiveList = async (resSuccess, resFail, memberId, pageNum, pageSize) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_PROJECT_M2M_RECEIVE_LIST])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_MEMBER_ID]: memberId,
            [NETWORK_PARAM_PAGE_NUM]: pageNum,
            [NETWORK_PARAM_PAGE_SIZE]: pageSize,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var m2mList = [];
                var totalCount = 0;
                
                if( responseInfo.data.hasOwnProperty(M2MINFO_LIST) ) {
                    m2mList = listParseFromDictionary(M2mInfo, responseInfo.data, M2MINFO_LIST);
                }

                if( responseInfo.data.hasOwnProperty(CommonKey.PAGE_TOTAL_COUNT) ) {
                    totalCount = responseInfo.data[CommonKey.PAGE_TOTAL_COUNT];
                }

                resSuccess({
                    [M2MINFO_LIST]: m2mList,
                    [CommonKey.PAGE_TOTAL_COUNT]: totalCount,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 1:1 문의 발송 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} memberId 멤버 id
     * @param {Number} pageNum 페이지 번호
     * @param {Number} pageSize 페이지 크기
     */
    static getProjectM2mSendList = async (resSuccess, resFail, memberId, pageNum, pageSize) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_PROJECT_M2M_SEND_LIST])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_MEMBER_ID]: memberId,
            [NETWORK_PARAM_PAGE_NUM]: pageNum,
            [NETWORK_PARAM_PAGE_SIZE]: pageSize,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var m2mList = [];
                var totalCount = 0;

                if( responseInfo.data.hasOwnProperty(M2MINFO_LIST) ) {
                    m2mList = listParseFromDictionary(M2mInfo, responseInfo.data[M2MINFO_LIST], '');
                }

                if( responseInfo.data.hasOwnProperty(CommonKey.PAGE_TOTAL_COUNT) ) {
                    totalCount = responseInfo.data[CommonKey.PAGE_TOTAL_COUNT];
                }

                resSuccess({
                    [M2MINFO_LIST]: m2mList,
                    [CommonKey.PAGE_TOTAL_COUNT]: totalCount,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 1:1 문의 발송 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} memberId 멤버 id
     * @param {Number} pageNum 페이지 번호
     * @param {Number} pageSize 페이지 크기
     */
    static getServiceM2mSendList = async (resSuccess, resFail, memberId, pageNum, pageSize) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_SERVICE_M2M_SEND_LIST])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_MEMBER_ID]: memberId,
            [NETWORK_PARAM_PAGE_NUM]: pageNum,
            [NETWORK_PARAM_PAGE_SIZE]: pageSize,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var m2mList = [];
                var totalCount = 0;

                if( responseInfo.data.hasOwnProperty(SERVICEM2MINFO_LIST) ) {
                    m2mList = listParseFromDictionary(ServiceM2mInfo, responseInfo.data[SERVICEM2MINFO_LIST], '');
                }

                if( responseInfo.data.hasOwnProperty(CommonKey.PAGE_TOTAL_COUNT) ) {
                    totalCount = responseInfo.data[CommonKey.PAGE_TOTAL_COUNT];
                }

                resSuccess({
                    [SERVICEM2MINFO_LIST]: m2mList,
                    [CommonKey.PAGE_TOTAL_COUNT]: totalCount,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 1:1 문의 상세 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} projectM2mId 팀프로젝트 1:1 문의 id
     */
    static getProjectM2mInfo = async (resSuccess, resFail, projectM2mId) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_PROJECT_M2M_INFO])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_PROJECT_M2M_ID]: projectM2mId,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var m2mAnswerList = [];
                var m2mInfo = new M2mInfo();

                if( responseInfo.data.hasOwnProperty(M2MINFO_CONTAINER) ) {
                    m2mInfo.parseFromDictionary(responseInfo.data[M2MINFO_CONTAINER]);
                }

                if( responseInfo.data.hasOwnProperty(M2MANSWERINFO_LIST) ) {
                    m2mAnswerList = listParseFromDictionary(M2mAnswerInfo, responseInfo.data[M2MANSWERINFO_LIST], '');
                }

                resSuccess({
                    [M2MINFO_CONTAINER]: m2mInfo,
                    [M2MANSWERINFO_LIST]: m2mAnswerList,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 1:1 문의 상세 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} m2mId 1:1 문의 id
     */
    static getServiceM2mInfo = async (resSuccess, resFail, m2mId) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_SERVICE_M2M_INFO])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_M2M_ID]: m2mId,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var m2mInfo = new ServiceM2mInfo();
                m2mInfo.parseFromDictionary(responseInfo.data);

                resSuccess({
                    [SERVICEM2MINFO_CONTAINER]: m2mInfo,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }
}
