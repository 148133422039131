//
//  listParse.js
//  datatown
//
//  Created by Mumakil on 2022. 8. 18..
//  Copyright © 2022년 Melephant. All rights reserved.
//


/**
 * dictionary로 부터 정보 객체 목록 반환
 * @param {class} classInfo 정보 객체
 * @param {json} dict json 데이터
 * @param {string} containerKey 키
 * @returns {array} 정보 객체 목록
 */
function listParseFromDictionary(classInfo, dict, containerKey) {
    var results = [];
    if( containerKey !== '' && !dict.hasOwnProperty(containerKey) ){
        return null;
    }

    var items;
    if( containerKey === '' ) {
        items = dict;
    }
    else {
        items = dict[containerKey];
    }

    for( var i = 0; i < items.length; i++ ) {
        var itemDict = items[i];
        var item = new classInfo();
        item.parseFromDictionary(itemDict);
        results.push(item);
    }

    return results;
}

export { listParseFromDictionary };
