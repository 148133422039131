//
//  categoryPopupView.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 22..
//  Copyright © 2022년 Melephant. All rights reserved.
//


import React, {
    useState,
    useEffect,
} from 'react';

// common
import ConstantValue from 'constants/value';
import { CategoryTypeKey } from 'constants/key';

// util
import DataUtil from 'util/dataUtil';

// info
import { CATEGORYINFO_LIST } from 'info/counsel/categoryInfo';

// network
import NetworkCounsel from 'network/networkCounsel';

// package
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';

/**
 * 이메일 인증 상태
 */
 const CATEGORY_SELECT_STATE = {
	CATEGORY_SELECT_STATE_CATEGORY: 'category_select_state_category',   // 카테고리 입력
    CATEGORY_SELECT_STATE_JOB:      'category_select_state_job',        // 직무 입력
    CATEGORY_SELECT_STATE_KEYWORD1: 'category_select_state_keyword1',   // 키워드 입력
    CATEGORY_SELECT_STATE_KEYWORD2: 'category_select_state_keyword2',   // 키워드 입력
    CATEGORY_SELECT_STATE_KEYWORD3: 'category_select_state_keyword3',   // 키워드 입력
    CATEGORY_SELECT_STATE_KEYWORD4: 'category_select_state_keyword4',   // 키워드 입력
};


/**
 * @protocol CategoryPopupView
 * @date 2022/11/22
 * @brief 카테고리 선택 화면 객체
 */
export default function CategoryPopupView(props) {
    const { t } = useTranslation();
    const [step, setStep] = useState(CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_CATEGORY);
    const [categoryList, setCategoryList] = useState(null);
    const [jobList, setJobList] = useState(null);
    const [keywordList1, setKeywordList1] = useState(null);
    const [keywordList2, setKeywordList2] = useState(null);
    const [keywordList3, setKeywordList3] = useState(null);
    const [keywordList4, setKeywordList4] = useState(null);

    useEffect(() => {
        // 카테고리 정보 갱신
        if( categoryList === null ) {
            NetworkCounsel.getCategoryList((res) => {
                setCategoryList(res[CATEGORYINFO_LIST]);
            }, () => {
            }, CategoryTypeKey.CATEGORY_TYPE_1);
        }

        if( jobList === null ) {
            NetworkCounsel.getCategoryList((res) => {
                setJobList(res[CATEGORYINFO_LIST]);
            }, () => {
            }, CategoryTypeKey.CATEGORY_TYPE_2);
        }

        if( keywordList1 === null ) {
            NetworkCounsel.getCategoryList((res) => {
                var list = res[CATEGORYINFO_LIST];

                for( var i = 0; i < 4; i++ ) {
                    var groupList = [];

                    for( var j = 0; j < 4; j++ ) {
                        var index = i * 4 + j;
                        if( index < list.length ) {
                            groupList.push(list[index]);
                        }
                    }

                    switch(i) {
                        case 0: {
                            setKeywordList1(groupList);
                            break;
                        }
                        case 1: {
                            setKeywordList2(groupList);
                            break;
                        }
                        case 2: {
                            setKeywordList3(groupList);
                            break;
                        }
                        case 3: {
                            setKeywordList4(groupList);
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                }
            }, () => {
            }, CategoryTypeKey.CATEGORY_TYPE_3);
        }
    }, [categoryList, jobList, keywordList1, keywordList2, keywordList3, keywordList4]);

    /**
     * 초기화
     */
    const initialize = () => {
        // 화면 단계 초기화
        setStep(CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_CATEGORY);

        // 데이터 초기화
        var updateCategoryList = categoryList.splice(0, categoryList.length);
        updateCategoryList.forEach(element => {
            element.isSelect = false;
        });
		setCategoryList(updateCategoryList);
        var updateJobList = jobList.splice(0, jobList.length);
        updateJobList.forEach(element => {
            element.isSelect = false;
        });
		setJobList(updateJobList);
        var updateKeywordList1 = keywordList1.splice(0, keywordList1.length);
        updateKeywordList1.forEach(element => {
            element.isSelect = false;
        });
		setKeywordList1(updateKeywordList1);
        var updateKeywordList2 = keywordList2.splice(0, keywordList2.length);
        updateKeywordList2.forEach(element => {
            element.isSelect = false;
        });
		setKeywordList2(updateKeywordList2);
        var updateKeywordList3 = keywordList3.splice(0, keywordList3.length);
        updateKeywordList3.forEach(element => {
            element.isSelect = false;
        });
		setKeywordList3(updateKeywordList3);
        var updateKeywordList4 = keywordList4.splice(0, keywordList4.length);
        updateKeywordList4.forEach(element => {
            element.isSelect = false;
        });
		setKeywordList4(updateKeywordList4);
    }

    /**
     * 단계별 화면 제목 반환
     * @returns {String} 화면 제목
     */
    const getTitleAtStep = () => {
        switch( step ) {
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_JOB: {
                return t('apply_keyword_title');
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD1: {
                return t('apply_keyword_title1');
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD2: {
                return t('apply_keyword_title2');
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD3: {
                return t('apply_keyword_title3');
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD4: {
                return t('apply_keyword_title4');
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_CATEGORY:
            default: {
                return t('apply_category_title');
            }
        }
    }

    /**
     * 카테고리 클릭시 호출 함수 반환
     * @returns {Function} 클릭시 호출 함수
     */
    const getCategoryPressed = () => {
        switch( step ) {
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_JOB: {
                return onJobPressed;
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD1: {
                return onKeyword1Pressed;
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD2: {
                return onKeyword2Pressed;
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD3: {
                return onKeyword3Pressed;
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD4: {
                return onKeyword4Pressed;
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_CATEGORY:
            default: {
                return onCategoryPressed;
            }
        }
    }

    /**
     * 취소 버튼 클릭시 호출
     */
    const onCancelPressed = () => {
        if( props.onCancelPressed ) {
            props.onCancelPressed();
        }
        
        // 재사용 위해 초기화
        initialize();
    };

    /**
     * 취소 버튼 클릭시 호출
     */
    const onNextPressed = () => {
        switch( step ) {
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_JOB: {
                setStep(CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD1);
                break;
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD1: {
                setStep(CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD2);
                break;
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD2: {
                setStep(CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD3);
                break;
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD3: {
                setStep(CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD4);
                break;
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD4: {
                // 선택한 데이터만 설정후, 전달
                var list = [];
                categoryList.forEach(element => {
                    if( element.isSelect ) {
                        list.push(element);
                    }
                });
                jobList.forEach(element => {
                    if( element.isSelect ) {
                        list.push(element);
                    }
                });
                keywordList1.forEach(element => {
                    if( element.isSelect ) {
                        list.push(element);
                    }
                });
                keywordList2.forEach(element => {
                    if( element.isSelect ) {
                        list.push(element);
                    }
                });
                keywordList3.forEach(element => {
                    if( element.isSelect ) {
                        list.push(element);
                    }
                });
                keywordList4.forEach(element => {
                    if( element.isSelect ) {
                        list.push(element);
                    }
                });

                if( props.onConfirmPressed ) {
                    props.onConfirmPressed(list);
                }

                // 재사용 위해 초기화
                initialize();
                break;
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_CATEGORY:
            default: {
                setStep(CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_JOB);
                break;
            }
        }
    };

    /**
     * 항목 클릭시 호출
     * @param {*} event 이벤트
     */
    const onCategoryPressed = (event) => {
        var index = categoryList.findIndex(element => element.categoryId === Number(event.target.id));
        
        if( index >= 0 ) {
            var updateDataList = categoryList.splice(0, categoryList.length);

            // 선택 해제
            if( !event.target.checked ) {
                updateDataList[index].isSelect = false;
                setCategoryList(updateDataList);
            }
            else {
                // 사수 상담 가능 카테고리 개수는 제한없음
                updateDataList[index].isSelect = true;
                setCategoryList(updateDataList);
            }
        }
    };

    /**
     * 항목 클릭시 호출
     * @param {*} event 이벤트
     */
    const onJobPressed = (event) => {
        var index = jobList.findIndex(element => element.categoryId === Number(event.target.id));
        
        if( index >= 0 ) {
            var updateDataList = jobList.splice(0, jobList.length);

            // 선택 해제
            if( !event.target.checked ) {
                updateDataList[index].isSelect = false;
                setJobList(updateDataList);
            }
            else {
                // 선택 개수 체크 후, 선택 설정
                var count = updateDataList.filter(element => element.isSelect === true).length;
                if( count < ConstantValue.CATEGORY_JOB_SELECT_LIMIT ) {
                    updateDataList[index].isSelect = true;
                    setJobList(updateDataList);
                }
                else {
                    setJobList(updateDataList);
                }
            }
        }
    };

    /**
     * 항목 클릭시 호출
     * @param {*} event 이벤트
     */
    const onKeyword1Pressed = (event) => {
        var index = keywordList1.findIndex(element => element.categoryId === Number(event.target.id));
        
        if( index >= 0 ) {
            var updateDataList = keywordList1.splice(0, keywordList1.length);

            // 선택 해제
            if( !event.target.checked ) {
                updateDataList[index].isSelect = false;
                setKeywordList1(updateDataList);
            }
            else {
                // 선택 개수 체크 후, 선택 설정
                var count = updateDataList.filter(element => element.isSelect === true).length;
                if( count < ConstantValue.CATEGORY_KEYWORD_SELECT_LIMIT ) {
                    updateDataList[index].isSelect = true;
                    setKeywordList1(updateDataList);
                }
                else {
                    setKeywordList1(updateDataList);
                }
            }
        }
    };

    /**
     * 항목 클릭시 호출
     * @param {*} event 이벤트
     */
    const onKeyword2Pressed = (event) => {
        var index = keywordList2.findIndex(element => element.categoryId === Number(event.target.id));
        
        if( index >= 0 ) {
            var updateDataList = keywordList2.splice(0, keywordList2.length);

            // 선택 해제
            if( !event.target.checked ) {
                updateDataList[index].isSelect = false;
                setKeywordList2(updateDataList);
            }
            else {
                // 선택 개수 체크 후, 선택 설정
                var count = updateDataList.filter(element => element.isSelect === true).length;
                if( count < ConstantValue.CATEGORY_KEYWORD_SELECT_LIMIT ) {
                    updateDataList[index].isSelect = true;
                    setKeywordList2(updateDataList);
                }
                else {
                    setKeywordList2(updateDataList);
                }
            }
        }
    };

    /**
     * 항목 클릭시 호출
     * @param {*} event 이벤트
     */
    const onKeyword3Pressed = (event) => {
        var index = keywordList3.findIndex(element => element.categoryId === Number(event.target.id));
        
        if( index >= 0 ) {
            var updateDataList = keywordList3.splice(0, keywordList3.length);

            // 선택 해제
            if( !event.target.checked ) {
                updateDataList[index].isSelect = false;
                setKeywordList3(updateDataList);
            }
            else {
                // 선택 개수 체크 후, 선택 설정
                var count = updateDataList.filter(element => element.isSelect === true).length;
                if( count < ConstantValue.CATEGORY_KEYWORD_SELECT_LIMIT ) {
                    updateDataList[index].isSelect = true;
                    setKeywordList3(updateDataList);
                }
                else {
                    setKeywordList3(updateDataList);
                }
            }
        }
    };

    /**
     * 항목 클릭시 호출
     * @param {*} event 이벤트
     */
    const onKeyword4Pressed = (event) => {
        var index = keywordList4.findIndex(element => element.categoryId === Number(event.target.id));
        
        if( index >= 0 ) {
            var updateDataList = keywordList4.splice(0, keywordList4.length);

            // 선택 해제
            if( !event.target.checked ) {
                updateDataList[index].isSelect = false;
                setKeywordList4(updateDataList);
            }
            else {
                // 선택 개수 체크 후, 선택 설정
                var count = updateDataList.filter(element => element.isSelect === true).length;
                if( count < ConstantValue.CATEGORY_KEYWORD_SELECT_LIMIT ) {
                    updateDataList[index].isSelect = true;
                    setKeywordList4(updateDataList);
                }
                else {
                    setKeywordList4(updateDataList);
                }
            }
        }
    };

    /**
     * 카테고리 목록 반환
     * @returns {Array} 카테고리 목록
     */
    const renderCategoryList = (dataList) => {
        if( DataUtil.validateArray(dataList) ) {
            var itemList = [];
            
            for( var i = 0; i < dataList.length; i++ ) {
                itemList.push(
                    <div key={"category" + String(i)}
                        className="col-lg-4 col-md-4 col-sm-4">
                        <div className='main-wraper'>
                            <div className="group-box" style={{ marginBottom: '0px', paddingBottom: '0px'}}>
                                <span style={{fontWeight: '500', fontSize: '15px'}}>{dataList[i].name}</span>
                                <ins>{dataList[i].desc}</ins>
                                <input type="checkbox"
                                    id={dataList[i].categoryId}
                                    multiple
                                    checked={dataList[i].isSelect ? true : false}
                                    onChange={getCategoryPressed()}/>
                                <label htmlFor={dataList[i].categoryId} style={{width: '100px'}}>{t('button_select')}</label> 
                            </div>
                        </div>
                    </div>
                );
            }

            return itemList;
        }

        return null;
    }

    /**
     * 카테고리 목록 반환
     * @returns {Array} 카테고리 목록
     */
    const renderCategoryBList = (dataList) => {
        if( DataUtil.validateArray(dataList) ) {
            var itemList = [];
            
            for( var i = 0; i < dataList.length; i++ ) {
                itemList.push(
                    <div key={"job" + String(i)}
                        className="col-lg-4 col-md-4 col-sm-4">
                        <div className='main-wraper'>
                            <div className="group-box" style={{ marginBottom: '0px', paddingBottom: '0px'}}>
                                <img src={dataList[i].icon} alt=""/>
                                <input type="checkbox"
                                    id={dataList[i].categoryId}
                                    multiple
                                    checked={dataList[i].isSelect ? true : false}
                                    onChange={getCategoryPressed()}/>
                                <label htmlFor={dataList[i].categoryId} style={{width: '100px'}}>{t('button_select')}</label> 
                            </div>
                        </div>
                    </div>
                );
            }

            return itemList;
        }

        return null;
    }
    
    /**
     * 카테고리 항목 렌더링
     * @returns {Array} 카테고리 목록
     */
    const renderCategory = () => {
        switch( step ) {
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_JOB: {
                return renderCategoryBList(jobList);
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD1: {
                return renderCategoryList(keywordList1);
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD2: {
                return renderCategoryList(keywordList2);
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD3: {
                return renderCategoryList(keywordList3);
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD4: {
                return renderCategoryList(keywordList4);
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_CATEGORY:
            default: {
                return renderCategoryList(categoryList);
            }
        }
    }

    /**
     * 다음 버튼 렌더링
     * @returns {*} 버튼
     */
    const renderNextButton = () => {
        switch( step ) {
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_JOB: {
                return <Button className="primary button"
                            disabled={jobList && jobList.filter(element => element.isSelect === true).length > 0 ? false : true}
                            onClick={onNextPressed}>
                        {t('button_next')}
                        </Button>;
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD1: {
                return <Button className="primary button"
                            disabled={keywordList1 && keywordList1.filter(element => element.isSelect === true).length > 0 ? false : true}
                            onClick={onNextPressed}>
                        {t('button_next')}
                        </Button>;
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD2: {
                return <Button className="primary button"
                            disabled={keywordList2 && keywordList2.filter(element => element.isSelect === true).length > 0 ? false : true}
                            onClick={onNextPressed}>
                        {t('button_next')}
                        </Button>;
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD3: {
                return <Button className="primary button"
                            disabled={keywordList3 && keywordList3.filter(element => element.isSelect === true).length > 0 ? false : true}
                            onClick={onNextPressed}>
                        {t('button_next')}
                        </Button>;
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_KEYWORD4: {
                return <Button className="primary button"
                            disabled={keywordList4 && keywordList4.filter(element => element.isSelect === true).length > 0 ? false : true}
                            onClick={onNextPressed}>
                        {t('button_insert')}
                        </Button>;
            }
            case CATEGORY_SELECT_STATE.CATEGORY_SELECT_STATE_CATEGORY:
            default: {
                return <Button className="primary button"
                            disabled={categoryList && categoryList.filter(element => element.isSelect === true).length > 0 ? false : true}
                            onClick={onNextPressed}>
                        {t('button_next')}
                        </Button>;
            }
        }
    }

    return <Modal
                show={props.visible}
                onHide={onCancelPressed}
                backdrop="static"
                size='xl'
                style={{zIndex: '9999999'}}
                keyboard={false}>
                <Modal.Header>
                    {getTitleAtStep()}
                </Modal.Header>
                <Modal.Body>
                    <div className="row col-xs-4">
                        {renderCategory()}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button className="canceled button outline-primary" variant="secondary"
                    onClick={onCancelPressed}>
                    {t('button_cancel')}
                </Button>
                {renderNextButton()}
                </Modal.Footer>
            </Modal>;
}
