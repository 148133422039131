//
//  answerCell.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 16..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
} from 'react';

// util
import DataUtil from 'util/dataUtil';

// style
import styles from 'css/component/answerCell.module.css';


/**
 * @protocol AnswerCell
 * @date 2022/12/16
 * @brief 답변 셀
 */
function AnswerCell(props) {
    return (
        props.disableList ?
        <div className={styles.container} style={props.style}>
            <div className={styles.header}>
                <div className={styles.user}>
                    <img src={props.data.userInfo && props.data.userInfo.deskImgUrl ? props.data.userInfo.deskImgUrl : 'assets/images/image/img_user.jpg'} alt=""/>
                    <h4 className={styles.nickname}>{props.data.userInfo.nickname}</h4>
                    <h6 className={styles.date}>{DataUtil.convertDateTimeToString(props.data.regdate)}</h6>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.answer}>
                    <span>{props.data.answer}</span>
                </div>
            </div>
        </div> :
        <li>
            <div className={styles.container} style={props.style}>
                <div className={styles.header}>
                    <div className={styles.user}>
                        <img src={props.data.userInfo && props.data.userInfo.deskImgUrl ? props.data.userInfo.deskImgUrl : 'assets/images/image/img_user.jpg'} alt=""/>
                        <h4 className={styles.nickname}>{props.data.userInfo.nickname}</h4>
                        <h6 className={styles.date}>{DataUtil.convertDateTimeToString(props.data.regdate)}</h6>
                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={styles.answer}>
                        <span>{props.data.answer}</span>
                    </div>
                </div>
            </div>
        </li>
    );
}

export default AnswerCell;