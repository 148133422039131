//
//  editMentorView.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 24..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
    useEffect,
} from 'react';

// common
import ConstantValue from 'constants/value';

// util
import ListUtil from 'util/listUtil';

// string
import { useTranslation } from 'react-i18next';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';
import { sessionUpdate } from 'redux/actions/sessionAction';

// network
import NetworkUser from 'network/networkUser';

// info
import UserInfo, {
    USERINFO_CONTAINER,
} from 'info/user/userInfo';

// component
import { Form, Button } from 'react-bootstrap';

// package
import { useNavigate } from 'react-router';


/**
 * @protocol EditMentorView
 * @date 2022/11/17
 * @brief 프로필 수정 화면
 */
function EditMentorView(props) {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    var user = useSelector(state => state.session.user);
    const isLogin = useSelector(state => state.session.isLogin);
    const isMentor = useSelector(state => state.session.isMentor);

    if( !user ) {
		user = new UserInfo();
	}

    useEffect(() => {
		// 로그인 상태 체크
        if( !isLogin || !user.memberId ) {
            dispatch(popupShowDefault(t('alert_not_login'), () => {
                navigate('/');
            }));
        }
        if( !isMentor ) {
            dispatch(popupShowDefault(t('alert_not_mentor'), () => {
                navigate('/');
            }));
        }
	}, [dispatch, navigate, t, user.memberId, isLogin, isMentor]);

    // 사수정보
	const [termOfService, setTermOfService] = useState(user.empPeriod ? user.empPeriod : '');
    const [career, setCareer] = useState(user.career ? user.career : '');
	const [actionType, setActionType] = useState(user.curriculum ? user.curriculum : '');

    // 정산정보
    const [bankName, setBankName] = useState(user.bankName ? user.bankName : '');
    const [bankAccount, setBankAccount] = useState(user.bankAccount ? user.bankAccount : '');
    const [bankCode, setBankCode] = useState(user.bankCode ? user.bankCode : '');

    const onTermsOfServicePressed = ({ target: { value}}) => setTermOfService(value);
	const onCareerChanged = ({ target: { value}}) => setCareer(value);
	const onActionTypePressed = ({ target: { value}}) => setActionType(value);

    const onBankNameChanged = ({ target: { value}}) => setBankName(value);
    const onBankAccountChanged = ({ target: { value}}) => setBankAccount(value);
    const onBankCodePressed = ({ target: { value}}) => setBankCode(value);

    const onBasicSubmitPressed = (event) => {
        event.preventDefault();

        var newUserInfo = new UserInfo();
        newUserInfo.memberId = user.memberId;
		newUserInfo.empPeriod = termOfService;
		newUserInfo.curriculum = actionType;
		newUserInfo.career = career;

        NetworkUser.updateMentorBasicInfo((res) => {
            // 세션 정보 설정
            dispatch(sessionUpdate(res[USERINFO_CONTAINER]));
            dispatch(popupShowDefault(t('alert_complete_modify')));
        }, (res) => {
            dispatch(popupShowDefault(t('alert_network_error')));
        }, newUserInfo);
    };

    const onAdjustmentSubmitPressed = (event) => {
        event.preventDefault();

        var newUserInfo = new UserInfo();
        newUserInfo.memberId = user.memberId;
		newUserInfo.bankName = bankName;
		newUserInfo.bankAccount = bankAccount;
		newUserInfo.bankCode = bankCode;
        
        NetworkUser.updateMentorAdjustmentInfo((res) => {
            // 세션 정보 설정
            dispatch(sessionUpdate(res[USERINFO_CONTAINER]));
            dispatch(popupShowDefault(t('alert_complete_modify')));
        }, (res) => {
            dispatch(popupShowDefault(t('alert_network_error')));
        }, newUserInfo);
    };

    return (
        <div className={"tab-pane fade show" + (props.defaultActive ? " active" : "")}
            id={props.tabId} >
            <div className="container">
                <div className="signup-form">
                        <h4><i className="icofont-lock"></i> {t('edit_mentor_title')}</h4>
                        <Form className="c-form"
                            onSubmit={onBasicSubmitPressed}
                            style={{marginBottom: '40px'}}>
                            <h4>
								<b>{t('edit_mentor_basic')}</b>
							</h4>
                            <div className="row merged-10">
                                <div className="col-lg-6">
                                    <select aria-required
                                        defaultValue={user.empPeriod}
										required
                                        onChange={onTermsOfServicePressed}>
                                        {ListUtil.generateTermOfServiceList()}
                                    </select>
                                </div>
								<div className="col-lg-6">
                                    <select aria-required
                                        defaultValue={user.curriculum}
										required
                                        onChange={onActionTypePressed}>
                                        {ListUtil.generateActionTypeList()}
                                    </select>
                                </div>
								<div className="col-lg-12">
									<textarea placeholder={t('input_career')}
										rows="4"
										defaultValue={user.career}
										className="uk-textarea"
										required
										onChange={onCareerChanged}></textarea>
								</div>
                                <div className="col-lg-12">
                                    <Button className="main-btn" type="submit">
                                        <i className="icofont-key"></i> {t('button_modify')}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                        <Form className="c-form" onSubmit={onAdjustmentSubmitPressed}>
                            <h4>
								<b>{t('edit_mentor_adjustment')}</b>
							</h4>
                            <div className="row merged-10">
                                <div className="col-lg-12">
									<input type="text"
										value={bankName}
										placeholder={t('input_bank_name')}
										required
										maxLength={ConstantValue.INPUT_MAX_LENGTH_BANKNAME}
										onChange={onBankNameChanged}/>
								</div>
                                <div className="col-lg-6">
                                    <select aria-required
                                        defaultValue={user.bankCode}
										required
                                        onChange={onBankCodePressed}>
                                        {ListUtil.generateBankList()}
                                    </select>
                                </div>
                                <div className="col-lg-6">
									<input type="text"
										value={bankAccount}
										placeholder={t('input_bank_account')}
										required
										maxLength={ConstantValue.INPUT_MAX_LENGTH_BANKACCOUNT}
										onChange={onBankAccountChanged}/>
								</div>
                                <div className="col-lg-12">
                                    <Button className="main-btn" type="submit">
                                        <i className="icofont-key"></i> {t('button_modify')}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
            </div>
        </div>
    );
}

export default EditMentorView;