//
//  projectConfirmScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 29..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
} from 'react';

// common
import ConstantValue from 'constants/value';
import ConstantPath from 'constants/path';
import { CommonKey } from 'constants/key';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkPayment from 'network/networkPayment';
import NetworkSolution from 'network/networkSolution';
import NetworkStatus from 'network/common/networkStatus';

// info
import { PROJECTINFO_CONTAINER } from 'info/solution/projectInfo';
import { ORDINALINFO_ORDINAL } from 'info/solution/ordinalInfo';
import { RESPONSEINFO_CONTAINER } from 'info/common/responseInfo';
import PaymentInfo, {
    PAYMENTINFO_CONTAINER,
    PAYMENTINFO_ORDER_ID,
} from 'info/payment/paymentInfo';
import ProjectApplyInfo from 'info/solution/projectApplyInfo';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault, popupShowDefaultCancel } from 'redux/actions/popupAction';
import { dataPayment } from 'redux/actions/dataAction';

// component
import Layout from 'components/layout/layout';
import ProjectImageView from 'components/image/projectImageView';

// package
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from 'react-bootstrap';


/**
 * @protocol ProjectConfirmScreen
 * @date 2022/11/29
 * @brief 팀프로젝트 결제 확인 화면
 */
function ProjectConfirmScreen(props) {
    const { t } = useTranslation();
    
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isLogin = useSelector(state => state.session.isLogin);
    const user = useSelector(state => state.session.user);

    const [project, setProject] = useState(null);
    const [requestOrdinal, setRequestOrdinal] = useState(null);
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [contactCofirm, setContactConfirm] = useState('');
    const [agreement, setAgreement] = useState(false);

    useEffect(() => {
        if( location.state ) {
            setProject(location.state[PROJECTINFO_CONTAINER]);
            setRequestOrdinal(location.state[ORDINALINFO_ORDINAL]);
        }
    }, [location.state]);

    const onNameChanged = ({ target: { value}}) => setName(value);
    const onContactChanged = ({ target: { value}}) => setContact(value);
    const onContactConfirmChanged = ({ target: { value}}) => setContactConfirm(value);
    const onAgreementChanged = ({ target: { value }}) => setAgreement(value);

    const onCancelPressed = () => {
        dispatch(popupShowDefault(t('alert_cancel_payment'), () => {
            navigate(-1);
        }));
    }

    const onSubmitPressed = (event) => {
        event.preventDefault();

        if( contact !== contactCofirm ) {
            dispatch(popupShowDefault(t('alert_not_match_contact')));
            return;
        }

        // 로그인 체크 후, 화면 전환 처리
        if( isLogin ) {
            // 팀플 신청 가능한지 체크
            NetworkSolution.checkProjectApply((res) => {
                var applyInfo = new ProjectApplyInfo();
                applyInfo.projectId = project.projectId;
                applyInfo.ordinal = requestOrdinal;
                applyInfo.memberId = user.memberId;
                applyInfo.memberName = name;
                applyInfo.contact = contact;
                applyInfo.orderName = project.name;
                applyInfo.price = project.price;
                
                // 무료일때와 구분
                if( Number(project.price) === 0 ) {
                    NetworkPayment.requestPaymentProject((res) => {
                        var payment = res[PAYMENTINFO_CONTAINER];

                        var compPayment = new PaymentInfo();
                        compPayment.orderId = payment.orderId;
                        compPayment.receipt = '';
                        compPayment.payType = '무료';
                        compPayment.paymentKey = '';
                        compPayment.approveddate = DataUtil.convertDateToISOString(new Date());

                        // 결제 완료 요청
                        NetworkPayment.completePaymentProject((res) => {
                            dispatch(dataPayment(null));
                            navigate(ConstantPath.PATH_PROJECT_INVOICE, {
                                state: { [PAYMENTINFO_ORDER_ID]: compPayment.orderId }
                            });
                        }, (res) => {
                            dispatch(popupShowDefault(t('alert_network_error'), () => {
                                dispatch(dataPayment(null));
                                navigate(ConstantPath.PATH_SOLUTION_PROJECT);
                            }));
                        }, compPayment);
                    }, (res) => {
                        dispatch(popupShowDefault(t('alert_network_error')));
                    }, applyInfo);
                }
                else {
                    NetworkPayment.requestPaymentProject((res) => {
                        var paymentInfo = res[PAYMENTINFO_CONTAINER];
                        
                        // 검증용 데이터 보관
                        dispatch(dataPayment(paymentInfo));

                        navigate(ConstantPath.PATH_PAYMENT_PROJECT, {
                            state: { [PAYMENTINFO_CONTAINER]: paymentInfo }
                        });
                    }, (res) => {
                        dispatch(popupShowDefault(t('alert_network_error')));
                    }, applyInfo);
                }
            }, (res) => {
                var response = res[RESPONSEINFO_CONTAINER];
                
                if( response.code === NetworkStatus.ALREADY_EXIST ) {
                    dispatch(popupShowDefault(t('alert_already_apply_project')));
                }
                else if( response.code === NetworkStatus.ALREADY_FULL ) {
                    dispatch(popupShowDefault(t('alert_already_full_project')));
                }
                else {
                    dispatch(popupShowDefault(t('alert_network_error')));
                }
            }, user.memberId, project.projectId, requestOrdinal);
        }
        else {
            dispatch(popupShowDefaultCancel(t('alert_not_login'), () => {
                setTimeout(() => {
                    navigate(ConstantPath.PATH_LOGIN, {
                        state: { [CommonKey.REDIRECT]: location.pathname }
                    });
                }, 100);
            }), null);
        }
    }

    /**
     * 일정 문구 반환
     * @returns {String} 일정 문구
     */
    const getPeriod = () => {
        var index = project.ordinalList.findIndex(element => element.ordinal === Number(requestOrdinal));
        
        if( index >= 0 ) {
            return DataUtil.convertDateToString(project.ordinalList[index].startdate) +
            '~' + DataUtil.convertDateToString(project.ordinalList[index].enddate);
        }
        return '';
    }

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}
            visibleSolutionHeader={true}
            location={ConstantPath.PATH_SOLUTION_PROJECT}>
            <div className="gap" style={{textAlign: 'left'}}>
                <div className="container">
                    <div className="row">
                    <Form className="c-form" onSubmit={onSubmitPressed}>
                        <div className="col-lg-12">
                            <div id="page-contents" className="row merged20">
                                <div className="col-lg-12">
                                    <div className="main-wraper">
                                    {
                                        project ?
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <h4 className="main-title">{t('project_confirm_section_project')}</h4>
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-5">
                                                <div className="full-book">
                                                    <ProjectImageView
                                                        maxWidth='100%'
                                                        imageUrl={project.gateImgUrl}/>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-7">
                                                <div className="prod-detail">
                                                    <h4>{project.name + ' - ' + requestOrdinal + t('word_ordinary_short')}</h4>
                                                    <span>{t('project_confirm_price')}
                                                        <i>{
                                                            Number(project.price) === 0 ?
                                                            t('word_free') :
                                                            t('word_currency') + DataUtil.numberWithCommas(Number(project.price))
                                                        }</i>
                                                    </span>
                                                    <ul className="item-info">
                                                        <li><span>{t('project_confirm_mentor')}</span>{project.mentor.nickname}</li>
                                                        <li><span>{t('project_confirm_period')}</span>{getPeriod()}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12"
                                                style={{marginTop: '20px'}}>
                                                <h4 className="main-title">{t('project_confirm_section_apply')}</h4>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <input type="text"
                                                            style={{backgroundColor: '#fff'}}
                                                            value={name}
                                                            placeholder={t('input_participant')}
                                                            required
                                                            maxLength={ConstantValue.INPUT_MAX_LENGTH_NAME}
                                                            onChange={onNameChanged}/>
                                                    </div>
                                                    <div className="col-lg-6"/>
                                                    <div className="col-lg-6">
                                                        <input type="text"
                                                            style={{backgroundColor: '#fff'}}
                                                            pattern="[0-9]+"
                                                            value={contact}
                                                            placeholder={t('input_contact')}
                                                            required
                                                            maxLength={ConstantValue.INPUT_MAX_LENGTH_PHONE}
                                                            onChange={onContactChanged}/>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <input type="text"
                                                            style={{backgroundColor: '#fff'}}
                                                            pattern="[0-9]+"
                                                            value={contactCofirm}
                                                            placeholder={t('input_contact_confirm')}
                                                            required
                                                            maxLength={ConstantValue.INPUT_MAX_LENGTH_PHONE}
                                                            onChange={onContactConfirmChanged}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12"
                                                style={{marginTop: '20px'}}>
                                                <h4 className="main-title">{t('project_confirm_section_note')}</h4>
                                                <span style={{whiteSpace: 'pre-wrap'}}>{t('project_confirm_note')}</span>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12"
                                                style={{marginTop: '20px'}}>
                                                <h4 className="main-title">{t('project_confirm_section_refund')}</h4>
                                                <span style={{whiteSpace: 'pre-wrap'}}>{t('project_confirm_refund')}</span>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12"
                                                style={{marginTop: '20px'}}>
                                                <h4 className="main-title">{t('project_confirm_section_noshow')}</h4>
                                                <span style={{whiteSpace: 'pre-wrap'}}>{t('project_confirm_noshow')}</span>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 row"
                                                style={{marginTop: '40px'}}>
                                                <div className="col-lg-6">
                                                    <input type="checkbox"
                                                        id="agreement"
                                                        value={agreement}
                                                        required
                                                        onClick={onAgreementChanged}/>
                                                    <label htmlFor="agreement">{t('project_confirm_agreement')}</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 row">
                                                <button className="cancel-btn"
                                                    style={{marginRight: '10px'}}
                                                    onClick={onCancelPressed}>{t('button_cancel')}</button>
                                                <button className="main-btn">{t('button_apply')}</button>
                                            </div>
                                        </div> :
                                    null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ProjectConfirmScreen;