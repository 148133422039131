//
//  helpScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 19..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
} from 'react';

// common
import { MenuList } from 'constants/list';
import { HelpMenuKey } from 'constants/key';

// util
import DataUtil from 'util/dataUtil';

// redux
import { useSelector } from 'react-redux';

// component
import Layout from 'components/layout/layout';
import SideTab from 'components/tab/sideTab';
import HelpFaqView from 'components/subview/helpFaqView';
import HelpM2mView from 'components/subview/helpM2mView';
import HelpNoticeListView from 'components/subview/helpNoticeListView';

// package
import { useLocation } from "react-router-dom";


/**
 * @protocol HelpScreen
 * @date 2022/12/19
 * @brief 고객센터 화면
 */
function HelpScreen(props) {
    const location = useLocation();

    const isLogin = useSelector(state => state.session.isLogin);
    const isMentor = useSelector(state => state.session.isMentor);

    const menuList = MenuList.HelpMenuList();
    var currentTab = HelpMenuKey.HELP_MENU_FAQ;
    
    let index = menuList.findIndex(element => element.url === location.pathname);
    if( index >= 0 ) {
        currentTab = menuList[index].id;
    }

    useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin, isMentor, location.pathname]);

    /**
     * 메뉴별 컨텐츠 ui 렌더링
     * @returns {Array} 메뉴별 컨텐츠 목록
     */
    const renderContent = () => {
        switch( currentTab ) {
            case HelpMenuKey.HELP_MENU_FAQ: {
                return <HelpFaqView key={HelpMenuKey.HELP_MENU_FAQ}/>
            }
            case HelpMenuKey.HELP_MENU_M2M: {
                return <HelpM2mView key={HelpMenuKey.HELP_MENU_M2M}/>
            }
            case HelpMenuKey.HELP_MENU_NOTICE: {
                return <HelpNoticeListView key={HelpMenuKey.HELP_MENU_NOTICE}/>
            }
            default: {
                break;
            }
        }
    }

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}>
			<div className="gap" style={{textAlign: 'left'}}>
                {
                    DataUtil.validateArray(menuList) && DataUtil.validateString(currentTab) ?
                    <div className="container">
                        <SideTab
                            defaultTab={currentTab}
                            menuList={menuList}/>
                        {/* <div className="col-lg-12"> */}
                            <div className="tab-content" id="components-nav">
                                {renderContent()}
                            </div>
                        {/* </div> */}
                    </div> :
                    null
                }
            </div>
        </Layout>
    );
}

export default HelpScreen;