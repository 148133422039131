//
//  projectRequestBar.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 29..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
    useEffect,
} from 'react';

// common
import { OrdinalStatusKey } from 'constants/key';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// style
import styles from 'css/component/projectRequestBar.module.css';


/**
 * @protocol ProjectRequestBar
 * @date 2022/11/29
 * @brief 프로젝트 신청 바
 */
function ProjectRequestBar(props) {
    const { t } = useTranslation();

    const [ordinalList, setOrdinalList] = useState(null);

    useEffect(() => {
        if( DataUtil.validateArray(props.data.ordinalList) ) {
            var list = [];
            props.data.ordinalList.forEach(element => {
                if( element.ordinalStatus === OrdinalStatusKey.ORDINAL_STATUS_RECRUITMENT ) {
                    list.push(element);
                }
            });
            list.sort(function(a, b) {return Number(a.ordinal) - Number(b.ordinal);})
            setOrdinalList(list);
        }
    }, [props.data.ordinalList]);

    /**
     * 기수 선택 목록 생성
     * @returns {Array} 기수 선택 목록
     */
    const generateOrdinalList = () => {
        var itemList = [];
        if( DataUtil.validateArray(ordinalList) ) {
            itemList.push(  <option key={'ordinal'}
                                    value={''}>
                                    {t('input_ordinal')}
                                </option>);
            for( var i = 0; i < ordinalList.length; i++ ) {
                itemList.push(  <option key={'ordinal' + String(i)}
                                    value={ordinalList[i].ordinal}>
                                    {ordinalList[i].ordinal + t('word_ordinary_short')}
                                </option>);
            }
        }
        return itemList;
    }

    /**
     * 기수 관련 메시지 반환
     * @returns {String} 기수 관련 메시지
     */
    const getOrdinalMessage = () => {
        if( DataUtil.validateArray(ordinalList) ) {
            var ordinalStr = '';
            ordinalList.forEach(element => {
                if( ordinalStr.length > 0 ) {
                    ordinalStr += ', ';
                }
                ordinalStr += String(element.ordinal) + t('word_ordinary_short');
            });
            return ordinalStr + ' ' + t('word_recruitment'); 
        }
        else {
            return t('notexist_recruitment_message');
        }
    }

    return (
        <>
            <div className={styles.bar}>
                <form style={{backgroundColor: '#f00'}} onSubmit={props.onRequestPressed}>
                    <div className={styles.container}>
                        <h4 className={styles.name}>{props.data.name}</h4>
                        <h4 className={styles.recruit}>{getOrdinalMessage()}</h4>
                        {
                            DataUtil.validateArray(ordinalList) ?
                            <div className={styles.requestContainer}>
                                <div className={styles.ordinalContainer}>
                                    <h6 className={styles.ordnial}>{t('word_request_ordinal')}</h6>
                                    <div className={styles.select}>
                                        <select required
                                            onChange={props.onOrdinalChanged}>
                                            {generateOrdinalList()}
                                        </select>
                                    </div>
                                </div>
                                <button className="main-btn">{t('button_apply_project')}</button>
                            </div> :
                            null
                        }
                    </div>
                </form>
            </div>
            <div className={styles.responsive}>
                <form style={{backgroundColor: '#f00'}} onSubmit={props.onRequestPressed}>
                    <div className={styles.container}>
                        <div className={styles.top}>
                            <h4 className={styles.name}>{props.data.name}</h4>
                            <h4 className={styles.recruit}>{getOrdinalMessage()}</h4>
                        </div>
                        {
                            DataUtil.validateArray(ordinalList) ?
                            <div className={styles.requestContainer}>
                                <div className={styles.ordinalContainer}>
                                    <h6 className={styles.ordnial}>{t('word_request_ordinal')}</h6>
                                    <div className={styles.select}>
                                        <select required
                                            onChange={props.onOrdinalChanged}>
                                            {generateOrdinalList()}
                                        </select>
                                    </div>
                                </div>
                                <button className="main-btn">{t('button_apply_project')}</button>
                            </div> :
                            null
                        }
                    </div>
                </form>
            </div>
        </>
    );
}

export default ProjectRequestBar;
