//
//  list.js
//  datatown
//
//  Created by Mumakil on 2022. 8. 30..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import * as CommonList from "constants/list/commonList";
import * as TableList from "constants/list/tableList";
import * as CarouselList from "constants/list/carouselList";
import * as MainList from 'constants/list/mainList';
import * as MenuList from 'constants/list/menuList';
import * as NftList from 'constants/list/nftList';
import * as FaqList from 'constants/list/faqList';
import * as ApplyList from 'constants/list/applyList';

export const LIST_TOTAL = 'list_total';

export {
    CommonList,
    TableList,
    CarouselList,
    MainList,
    MenuList,
    NftList,
    FaqList,
    ApplyList,
};