//
//  pageTable.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 15..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
    useEffect,
} from "react";

// component
import DefaultTable from 'components/table/defaultTable';

// package
import Pagination from "react-js-pagination";


/**
 * @protocol PageTable
 * @date 2022/12/15
 * @brief 페이징 처리 테이블 화면
 */
function PageTable(props) {
    const [pageNum, setPageNum] = useState(1);
    const pageSize = props.pageSize ? props.pageSize : 10;

    useEffect(() => {
        if( props.dataList === null ) {
            props.fetchData(pageNum, pageSize);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNum, pageSize, props.dataList]);

    /**
     * 페이지 클릭시 호출
     * @param {Number} page 페이지 번호
     */
    const onPageChanged = (page) => {
        setPageNum(page);
        
        props.fetchData(page, pageSize);
    };

    return (
        <div style={{width: '100%'}}>
            <div>
                <DefaultTable
                    dataList={props.dataList ? props.dataList : []}
                    columnList={props.columnList}
                    renderCell={props.renderCell}/> 
                <Pagination
                    activePage={pageNum}
                    itemsCountPerPage={pageSize}
                    totalItemsCount={props.totalCount}
                    pageRangeDisplayed={props.pageRangeDisplayed ? props.pageRangeDisplayed : 5}
                    prevPageText={"‹"}
                    nextPageText={"›"}
                    onChange={onPageChanged}/>
            </div>
        </div>
        
    );
};

export default PageTable;
