//
//  counselPaymentScreen.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 25..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// common
import ConstantPath from 'constants/path';

// string
import { useTranslation } from 'react-i18next';

// info
import { PAYMENTINFO_CONTAINER } from 'info/payment/paymentInfo';

// redux
import { useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';

// package
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { useLocation, useNavigate } from "react-router-dom";


/**
 * @protocol CounselPaymentScreen
 * @date 2023/01/25
 * @brief 결제 화면
 */
function CounselPaymentScreen(props) {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const paymentInfo = location.state[PAYMENTINFO_CONTAINER];

    const renderTossPayment = () => {
        const clientKey = process.env.REACT_APP_TOSS_PAYMENT_CLIENT_KEY;

        loadTossPayments(clientKey).then(tossPayments => {
            tossPayments.requestPayment('카드', { // 결제 수단 파라미터
                // 결제 정보 파라미터
                amount: paymentInfo.price,
                orderId: paymentInfo.orderId,
                orderName: paymentInfo.orderName,
                successUrl: window.location.origin + ConstantPath.PATH_PAYMENT_COUNSEL_SUCCESS,
                failUrl: window.location.origin + ConstantPath.PATH_PAYMENT_COUNSEL_FAIL,
            })
            .catch(function (error) {
                if (error.code === 'USER_CANCEL') {
                    // 결제 고객이 결제창을 닫았을 때 에러 처리
                    navigate(ConstantPath.PATH_SOLUTION_REQUEST);
                } else if (error.code === 'INVALID_CARD_COMPANY') {
                    // 유효하지 않은 카드 코드에 대한 에러 처리
                    dispatch(popupShowDefault(t('alert_invalid_payment_company'), () => {
                        navigate(ConstantPath.PATH_SOLUTION_REQUEST);
                    }));
                }
                else {
                    dispatch(popupShowDefault('[' + error.code + ']\n' + t('alert_network_error'), () => {
                        navigate(ConstantPath.PATH_SOLUTION_REQUEST);
                    }));
                }
            });
        });
    }

    return (
        renderTossPayment()
    );
}

export default CounselPaymentScreen;