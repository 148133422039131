//
//  commonKey.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 11..
//  Copyright © 2022년 Melephant. All rights reserved.
//


/**
 * @protocol CommonKey
 * @date 2022/11/11
 * @brief 기본 키값
 */
export const CommonKey = {
    USERINFO_MBTI_DEFAIULT:         'unknown',
    PAGE_TOTAL_COUNT:               'total_count',

    TAB_ID:         'tab_id',
    USER_EXIST:     'user_exist',
    REDIRECT:       'redirect_to'
};
