//
//  noticeInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 1..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// info
import UserInfo from "info/user/userInfo";

export const NOTICEINFO_LIST        = 'notice_list';
export const NOTICEINFO_CONTAINER   = 'notice_info';
export const NOTICEINFO_ID          = 'project_notice_idx';
export const NOTICEINFO_PROJECT_ID  = 'project_id';
export const NOTICEINFO_ORDINAL     = 'ordinal';
export const NOTICEINFO_MEMBER_ID   = 'member_id';
export const NOTICEINFO_SUBJECT     = 'subject';
export const NOTICEINFO_CONTENTS    = 'contents';
export const NOTICEINFO_VIEW_COUNT  = 'view_count';
export const NOTICEINFO_REGDATE     = 'regdate';


/**
 * @protocol NoticeInfo
 * @date 2022/12/01
 * @brief 프로젝트 공지사항 정보
 */
export default class NoticeInfo {
    constructor() {
        this.noticeId = 0;
        this.projectId = '';
        this.ordinal = 0;
        this.memberId = '';
        this.subject = '';
        this.contents = '';
        this.viewCount = 0;
        this.regdate = null;

        this.userInfo = new UserInfo();
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(NOTICEINFO_ID) ) {
            this.noticeId = dict[NOTICEINFO_ID];
        }

        if( dict.hasOwnProperty(NOTICEINFO_PROJECT_ID) ) {
            this.projectId = dict[NOTICEINFO_PROJECT_ID];
        }

        if( dict.hasOwnProperty(NOTICEINFO_ORDINAL) ) {
            this.ordinal = dict[NOTICEINFO_ORDINAL];
        }

        if( dict.hasOwnProperty(NOTICEINFO_MEMBER_ID) ) {
            this.memberId = dict[NOTICEINFO_MEMBER_ID];
        }

        if( dict.hasOwnProperty(NOTICEINFO_SUBJECT) ) {
            this.subject = dict[NOTICEINFO_SUBJECT];
        }

        if( dict.hasOwnProperty(NOTICEINFO_CONTENTS) ) {
            this.contents = dict[NOTICEINFO_CONTENTS].replaceAll('\\n', '\n');;
        }

        if( dict.hasOwnProperty(NOTICEINFO_VIEW_COUNT) ) {
            this.viewCount = dict[NOTICEINFO_VIEW_COUNT];
        }

        if( dict.hasOwnProperty(NOTICEINFO_REGDATE) ) {
            this.regdate = new Date(dict[NOTICEINFO_REGDATE]);
        }

        this.userInfo.parseFromDictionary(dict);
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [NOTICEINFO_ID]: this.noticeId,
            [NOTICEINFO_PROJECT_ID]: this.projectId,
            [NOTICEINFO_ORDINAL]: this.ordinal,
            [NOTICEINFO_MEMBER_ID]: this.memberId,
            [NOTICEINFO_SUBJECT]: this.subject,
            [NOTICEINFO_CONTENTS]: this.contents,
            [NOTICEINFO_VIEW_COUNT]: this.viewCount,
            [NOTICEINFO_REGDATE]: this.regdate,
        };
        return dict;
    }

    /**
     * 등록에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForRegister() {
        var dict = {
            [NOTICEINFO_PROJECT_ID]: this.projectId,
            [NOTICEINFO_ORDINAL]: this.ordinal,
            [NOTICEINFO_MEMBER_ID]: this.memberId,
            [NOTICEINFO_SUBJECT]: this.subject,
            [NOTICEINFO_CONTENTS]: this.contents,
        };
        return dict;
    }

    /**
     * 삭제에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForDelete() {
        var dict = {
            [NOTICEINFO_ID]: this.noticeId,
            [NOTICEINFO_MEMBER_ID]: this.memberId,
        };
        return dict;
    }
}
