//
//  dataAction.js
//  datatown
//
//  Created by dasein55 on 2022. 11. 14..
//  Copyright © 2022년 Melephant. All rights reserved.
//

export const ACTION_DATA_REDIRECT   = 'action_data_redirect';
export const ACTION_DATA_PAYMENT    = 'action_data_payment';

export const dataRedirect = path => ({
    type: ACTION_DATA_REDIRECT,
    payload: path,
});

export const dataPayment = info => ({
    type: ACTION_DATA_PAYMENT,
    payload: info,
});
