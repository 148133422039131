//
//  helpM2mPostView.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 20..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
} from 'react';

// common
import { CommonKey } from 'constants/key';
import ConstantPath from 'constants/path';

// util
import ListUtil from 'util/listUtil';

// string
import { useTranslation } from 'react-i18next';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault, popupShowDefaultCancel } from 'redux/actions/popupAction';

// network
import NetworkM2m from 'network/networkM2m';

// info
import ServiceM2mInfo from 'info/service/serviceM2mInfo';

// component
import ImagePicker from 'components/image/imagePicker';
import LoginView  from 'components/subview/loginView';

// package
import { Form, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";


/**
 * @protocol HelpM2mPostView
 * @date 2022/12/20
 * @brief 자주찾는 질문 리스트 화면
 */
function HelpM2mPostView(props) {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    var user = useSelector(state => state.session.user);
    const isLogin = useSelector(state => state.session.isLogin);

    const [subject, setSubject] = useState('');
    const [question, setQuestion] = useState('');
    const [attachImage1, setAttachImage1] = useState(null);
    const [attachImage2, setAttachImage2] = useState(null);
    const [attachImage3, setAttachImage3] = useState(null);

    const subjectList = ListUtil.generateM2mSubjectList();

    useEffect(() => {
    }, []);

    const onSubjectPressed = ({ target: { value }}) => setSubject(value);
    const onQuestionChanged = ({ target: { value }}) => setQuestion(value);
    const onAttachImage1Changed = (image) => setAttachImage1(image);
    const onAttachImage2Changed = (image) => setAttachImage2(image);
    const onAttachImage3Changed = (image) => setAttachImage3(image);
    const onSubmitPressed = (event) => {
        event.preventDefault();

        // 로그인 체크 후, 화면 전환 처리
        if( isLogin ) {
            var m2mInfo = new ServiceM2mInfo();
            m2mInfo.memberId = user.memberId;
            m2mInfo.subject = subject;
            m2mInfo.question = question;
            m2mInfo.attachImgData1 = attachImage1;
            m2mInfo.attachImgData2 = attachImage2;
            m2mInfo.attachImgData3 = attachImage3;

            NetworkM2m.requestPostServiceM2m((rew) => {
                dispatch(popupShowDefault(t('alert_complete_post_m2m'), () => {
                    window.location.reload(false);
                }));
            }, (res) => {
                dispatch(popupShowDefault(t('alert_network_error')));
            }, m2mInfo);
        }
        else {
            dispatch(popupShowDefaultCancel(t('alert_not_login'), () => {
                setTimeout(() => {
                    navigate(ConstantPath.PATH_LOGIN, {
                        state: { [CommonKey.REDIRECT]: location.pathname }
                    });
                }, 100);
            }), null);
        }
    }

    return (
        <div className={"tab-pane show" + (props.defaultActive ? " active" : "")}
            id={props.tabId} >
            {
                isLogin ?
                <div className="signup-form">
                    <Form className="c-form" onSubmit={onSubmitPressed}>
                        <div className="col-lg-6">
                            <select aria-required
                                style={{backgroundColor: '#fff'}}
                                defaultValue={subject}
                                required
                                onChange={onSubjectPressed}>
                                {subjectList}
                            </select>
                        </div>
                        <div className="col-lg-6"/>
                        <div className="col-lg-12">
                            <textarea placeholder={t('input_m2m_question')}
                                style={{backgroundColor: '#fff'}}
                                rows="4"
                                className="uk-textarea"
                                required
                                onChange={onQuestionChanged}/>
                        </div>
                        <div className="gender mb-2">
                            <label>{t('input_attachimage_1')}</label>
                            <ImagePicker
                                onImageChanged={onAttachImage1Changed}/>
                        </div>
                        <div className="gender mb-2">
                            <label>{t('input_attachimage_2')}</label>
                            <ImagePicker
                                onImageChanged={onAttachImage2Changed}/>
                        </div>
                        <div className="gender mb-2">
                            <label>{t('input_attachimage_3')}</label>
                            <ImagePicker
                                onImageChanged={onAttachImage3Changed}/>
                        </div>
                        <div className="col-lg-12">
                            <Button className="main-btn" type="submit">
                                <i className="icofont-key"></i> {t('button_m2m_send')}
                            </Button>
                        </div>
                    </Form>
                </div> :
                <LoginView/>
            }
        </div>
    );
}

export default HelpM2mPostView;