//
//  dataUtil.js
//  datatown
//
//  Created by Mumakil on 2022. 8. 16..
//  Copyright © 2022년 Melephant. All rights reserved.
//


/**
 * @protocol DataUtil
 * @date 2022/08/16
 * @brief 데이터 관련 도구
 */
export default class DataUtil {
    /**
     * 숫자 적합성 체크
     * @param {number} value 값
     * @returns {boolean} 적합성 여부
     */
    static validateNumber(value) {
        if( typeof value === 'number' ) {
            return true;
        }
        return false;
    }

    /**
     * 숫자 영역 체크
     * @param {number} value 값
     * @param {number} min 최소값
     * @param {number} max 최대값
     * @returns {boolean} 적합성 여부
     */
    static validateNumberWithRange(value, min, max) {
        if( this.validateNumber(value) ) {
            if( min !== undefined && this.validateNumber(min)) {
                if( value < min ) {
                    return false;
                }
            }
            if( max !== undefined && this.validateNumber(max) ) {
                if( value > max ) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    /**
     * 문자 적합성 체크
     * @param {string} value 문자열
     * @returns {boolean} 적합성 여부
     */
    static validateString(value) {
        if( typeof value === 'string' && value.length > 0 ) {
            return true;
        }
        return false;
    }

    /**
     * 문자 길이 체크
     * @param {string} value 값
     * @param {number} min 최소 길이
     * @param {number} max 최대 길이
     * @returns {boolean} 적합성 여부
     */
    static validateStringWithRange(value, min, max) {
        if( this.validateString(value) ) {
            if( min !== undefined && this.validateNumber(min)) {
                if( value.length < min ) {
                    return false;
                }
            }
            if( max !== undefined && this.validateNumber(max) ) {
                if( value.length > max ) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    /**
     * 배열 적합성 체크
     * @param {array} value 배열
     * @returns {boolean} 적합 여부
     */
    static validateArray(value) {
        if( typeof value === 'object' ) {
            if( value && value.length > 0 ) {
                return true;
            }
        }
        return false;
    }

    /**
     * 숫자 콤마 표시
     * @param {number} value 원본 숫자
     * @returns 콤마 포함 문자열
     */
    static numberWithCommas(value) {
        if( this.validateNumber(value) ) {
            var valutStr = String(value);
            return valutStr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return null;
    }

    /**
     * 휴대폰 번호 자리수 분리
     * @param {String}} phone 휴대폰 번호
     * @returns {Array} 번호 목록
     */
    static generatePhoneNumber(phone) {
        if( this.validateString(phone) ) {
            if( phone.length === 10 ) {
                return [phone.substr(0, 3), phone.substr(3, 3), phone.substr(6, 4)];
            }
            else if( phone.length === 11 ) {
                return [phone.substr(0, 3), phone.substr(3, 4), phone.substr(7, 4)];
            }
        }
        return null;
    }

    /**
     * 사업자 등록번호 자리수 분리
     * @param {String}} 사업자 등록번호
     * @returns {Array} 번호 목록
     */
    static generateBizNumber(bizNumber) {
        if( this.validateString(bizNumber) ) {
            if( bizNumber.length === 10 ) {
                return [bizNumber.substr(0, 3), bizNumber.substr(3, 2), bizNumber.substr(5, 5)];
            }
        }
        return null;
    }

    /**
     * 날짜를 iso 문자열로 변경
     * @param {Date} date 날짜
     * @returns 문자열
     */
    static convertDateToISOString(date) {
        let offset = date.getTimezoneOffset() * 60000;
        let dateOffset = new Date(date.getTime() - offset);

        return dateOffset.toISOString().slice(0, 19).replace('T', ' ');
    }

    /**
     * 날짜를 문자열로 변경
     * @param {Date} date 날짜
     * @returns 문자열
     */
    static convertDateToString(date) {
        if( date ) {
            var dateStr = '';
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var day = date.getDate();
            dateStr += String(year);
            dateStr += '/';
            dateStr += month < 10 ? '0' : '';
            dateStr += String(month);
            dateStr += '/';
            dateStr += day < 10 ? '0' : '';
            dateStr += String(day);
            return dateStr;
        }
        return null;
    }

    /**
     * 시간을 문자열로 변경
     * @param {Date} date 날짜
     * @returns 문자열
     */
    static convertTimeToString(date) {
        if( date ) {
            var dateStr = '';
            var hour = date.getHours();
            var minute = date.getMinutes();
            var second = date.getSeconds();
            dateStr += hour < 10 ? '0' : '';
            dateStr += String(hour);
            dateStr += ':';
            dateStr += minute < 10 ? '0' : '';
            dateStr += String(minute);
            dateStr += ':';
            dateStr += second < 10 ? '0' : '';
            dateStr += String(second);
            return dateStr;
        }
        return null;
    }

    /**
     * 날짜 + 시간을 문자열로 변경
     * @param {Date} date 날짜
     * @returns 문자열
     */
    static convertDateTimeToString(date) {
        if( date ) {
            var dateStr = this.convertDateToString(date);
            dateStr += ' ';
            dateStr += this.convertTimeToString(date);
            return dateStr;
        }
        return null;
    }

    /**
     * 시간 문자열(ex: 09:00 PM)을 parsing
     * @param {String} timeStr 시간 문자열
     * @returns {Array} [시, 분]
     */
    static convertTimeStringToDate(timeStr) {
        if( this.validateString(timeStr) ) {
            var strArray = timeStr.split(' ');
            
            if( strArray.length > 1 ) {
                var time = strArray[0];
                var aa = strArray[1];

                var timeArray = time.split(':');
                var hour = Number(timeArray[0]);
                var minute = Number(timeArray[1]);

                // 오후에는 시간 + 처리
                if( aa === 'PM' ) {
                    hour += 12;
                }

                return [hour, minute];
            }
        }
        return null;
    }

    /**
     * 시간 비교
     * @param {Number} hour1 기준 시간의 시
     * @param {Number} minute1 기준 시간의 분
     * @param {Number} hour2 비교 시간의 시
     * @param {Number} minute2 비교 시간의 분
     * @param {Number} type 0(이전일때 체크), 1(이후일때 체크)
     * @returns {Boolean} 비교 결과
     */
    static compareTimes(hour1, minute1, hour2, minute2, type) {
        // 시간1 보다 시간2가 더 이후일때 true, 아니면 false
        if( type === 1 ) {
            if( hour2 > hour1 ) {
                return true;
            }
            else if( hour1 === hour2 ) {
                if( minute1 <= minute2 ) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        }
        // 시간1 보다 시간2가 더 이전일때 true, 아니면 false
        else {
            if( hour1 > hour2 ) {
                return true;
            }
            else if( hour1 === hour2 ) {
                if( minute2 <= minute1 ) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        }
    }
}
