//
//  helpM2mView.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 20..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
} from 'react';

// common
import { HelpM2mMenuKey } from 'constants/key';
import { MenuList } from 'constants/list';

// component
import HelpM2mPostView from 'components/subview/helpM2mPostView';
import HelpM2mListView from 'components/subview/helpM2mListView';


/**
 * @protocol HelpM2mView
 * @date 2022/12/20
 * @brief 고객센터 1:1 문의 화면
 */
function HelpM2mView(props) {
    const [defaultTab] = useState(HelpM2mMenuKey.HELP_M2M_MENU_INQUIRY);

    /**
     * 사이드 메뉴 목록 반환
     * @returns {Array} 사이드 메뉴 목록
     */
    const renderTypeMenu = () => {
        const itemList = [];
        const menuList = MenuList.HelpM2mMenuList();
        for( var i = 0; i < menuList.length; i++ ) {
            itemList.push(  <li className="nav-item"
                                key={"help_m2m_menu" + String(i)}>
                                <a className={menuList[i].id === defaultTab ? "active" : ""}
                                    href={'#' + menuList[i].id}
                                    data-toggle="tab">
                                    {menuList[i].title}
                                </a>
                            </li>);
        }
        return itemList;
    }

    return (
        <div className={"tab-pane fade show active"}>
            <div id="page-contents" className="row merged20">
                <div className="col-lg-12">
                    <div className="main-wraper">
                        <ul className="nav nav-tabs pix-filter">
                            {renderTypeMenu()}
                        </ul>
                        <div className="tab-content" id="components-nav">
                            <HelpM2mPostView tabId={HelpM2mMenuKey.HELP_M2M_MENU_INQUIRY}
                                defaultActive={defaultTab === HelpM2mMenuKey.HELP_M2M_MENU_INQUIRY ? true : false}/>
                            <HelpM2mListView tabId={HelpM2mMenuKey.HELP_M2M_MENU_HISTORY}
                                defaultActive={defaultTab === HelpM2mMenuKey.HELP_M2M_MENU_HISTORY ? true : false}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HelpM2mView;