//
//  ordinalScheduleInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 1..
//  Copyright © 2022년 Melephant. All rights reserved.
//


export const ORDINALSCHEDULEINFO_LIST               = 'schedule_list';
export const ORDINALSCHEDULEINFO_CONTAINER          = 'schedule_info';
export const ORDINALSCHEDULEINFO_ID                 = 'project_ordinal_schedule_idx';
export const ORDINALSCHEDULEINFO_PROJECT_ID         = 'project_id';
export const ORDINALSCHEDULEINFO_ORDINAL            = 'oridinal';
export const ORDINALSCHEDULEINFO_DEPTH              = 'depth';
export const ORDINALSCHEDULEINFO_WEEKS              = 'weeks';
export const ORDINALSCHEDULEINFO_SCHEDULE           = 'schedule';
export const ORDINALSCHEDULEINFO_SCHEDULE_STATUS    = 'schedule_status';


/**
 * @protocol OrdinalScheduleInfo
 * @date 2022/12/01
 * @brief 프로젝트 기수 스케쥴 정보
 */
export default class OrdinalScheduleInfo {
    constructor() {
        this.scheduleId = '';
        this.projectId = '';
        this.ordinal = '';
        this.depth = '';
        this.weeks = '';
        this.schedule = '';
        this.scheduleStatus = '';
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(ORDINALSCHEDULEINFO_ID) ) {
            this.scheduleId = dict[ORDINALSCHEDULEINFO_ID];
        }

        if( dict.hasOwnProperty(ORDINALSCHEDULEINFO_PROJECT_ID) ) {
            this.projectId = dict[ORDINALSCHEDULEINFO_PROJECT_ID];
        }

        if( dict.hasOwnProperty(ORDINALSCHEDULEINFO_ORDINAL) ) {
            this.ordinal = dict[ORDINALSCHEDULEINFO_ORDINAL];
        }

        if( dict.hasOwnProperty(ORDINALSCHEDULEINFO_DEPTH) ) {
            this.depth = dict[ORDINALSCHEDULEINFO_DEPTH];
        }

        if( dict.hasOwnProperty(ORDINALSCHEDULEINFO_WEEKS) ) {
            this.weeks = dict[ORDINALSCHEDULEINFO_WEEKS];
        }

        if( dict.hasOwnProperty(ORDINALSCHEDULEINFO_SCHEDULE) ) {
            this.schedule = dict[ORDINALSCHEDULEINFO_SCHEDULE];
        }

        if( dict.hasOwnProperty(ORDINALSCHEDULEINFO_SCHEDULE_STATUS) ) {
            this.scheduleStatus = dict[ORDINALSCHEDULEINFO_SCHEDULE_STATUS];
        }
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [ORDINALSCHEDULEINFO_ID]: this.scheduleId,
            [ORDINALSCHEDULEINFO_PROJECT_ID]: this.projectId,
            [ORDINALSCHEDULEINFO_ORDINAL]: this.ordinal,
            [ORDINALSCHEDULEINFO_DEPTH]: this.depth,
            [ORDINALSCHEDULEINFO_WEEKS]: this.weeks,
            [ORDINALSCHEDULEINFO_SCHEDULE]: this.schedule,
            [ORDINALSCHEDULEINFO_SCHEDULE_STATUS]: this.scheduleStatus,
        };
        return dict;
    }

    /**
     * 스케쥴 변경에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForUpdate() {
        var dict = {
            [ORDINALSCHEDULEINFO_ID]: this.scheduleId,
            [ORDINALSCHEDULEINFO_SCHEDULE]: this.schedule,
        };
        return dict;
    }

    /**
     * 스케쥴 변경에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForUpdateStatus() {
        var dict = {
            [ORDINALSCHEDULEINFO_ID]: this.scheduleId,
            [ORDINALSCHEDULEINFO_SCHEDULE_STATUS]: this.scheduleStatus,
        };
        return dict;
    }
}
