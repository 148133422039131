//
//  carouselList.js
//  datatown
//
//  Created by Mumakil on 2022. 10. 24..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// string
import { useTranslation } from 'react-i18next';


/**
 * @protocol WelcomeCarouselList
 * @date 2022/10/24
 * @brief 캐러셀 데이터 반환
 */
export function WelcomeCarouselList() {
    const { t } = useTranslation();
    return [{
        text: t('carousel_welcome_1'),
    },{
        text: t('carousel_welcome_2'),
    },{
        text: t('carousel_welcome_3'),
    }];
}
