//
//  solutionCounselScreen.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 13..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
    useEffect,
} from 'react';

// common
import ConstantPath from 'constants/path';
import ConstantValue from 'constants/value';
import { LIST_TOTAL } from 'constants/list';
import { CommonKey, CategoryTypeKey } from 'constants/key';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkSolution from 'network/networkSolution';
import NetworkCounsel from 'network/networkCounsel';

// info
import { PROJECTINFO_LIST } from 'info/solution/projectInfo';
import CategoryInfo, {
    CATEGORYINFO_LIST,
} from 'info/counsel/categoryInfo';

// redux
import { useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';

// component
import Layout from 'components/layout/layout';
import CounselCell from 'components/cell/counselCell';
import PageList from 'components/list/pageList';

// package
import { useNavigate, useLocation } from "react-router-dom";


/**
 * @protocol SolutionCounselScreen
 * @date 2023/01/13
 * @brief 사수해법 상담 화면
 */
function SolutionCounselScreen(props) {
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [dataList, setDataList] = useState(null);
    const [categoryList, setCategoryList] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [category, setCategory] = useState(null);

    const pageSize = ConstantValue.RECRUIT_COUNSEL_PAGE_SIZE;
    
    useEffect(() => {
        if( categoryList === null ) {
            NetworkCounsel.getCategoryList((res) => {
                var totalInfo = new CategoryInfo();
                totalInfo.category = LIST_TOTAL;
                totalInfo.name = t('word_total');
                var updateCategoryList = [totalInfo, ...res[CATEGORYINFO_LIST]];
                setCategoryList(updateCategoryList);
            }, () => {
            }, CategoryTypeKey.CATEGORY_TYPE_1);
        }
    }, [dataList, categoryList, dispatch, t]);

    const onFilterPressed = ({ target }) => {
        var filter = target.getAttribute('filter');
        var category= filter === LIST_TOTAL ? null : filter;
        setCategory(category);

        NetworkSolution.getRecruitCounselList((res) => {
            setDataList(res[PROJECTINFO_LIST]);
            setTotalCount(res[CommonKey.PAGE_TOTAL_COUNT]);
        }, (res) => {
            dispatch(popupShowDefault(t('alert_network_error')));
        }, category, 1, pageSize);
    }

    // 데이터 요청 처리
    const fetchData = async (pageNum, pageSize) => {
        NetworkSolution.getRecruitCounselList((res) => {
            setDataList(res[PROJECTINFO_LIST]);
            setTotalCount(res[CommonKey.PAGE_TOTAL_COUNT]);
        }, (res) => {
            dispatch(popupShowDefault(t('alert_network_error')));
        }, category, pageNum, pageSize);
    };

    /**
     * 상담 셀 ui 렌더링
     * @param {NoticeInfo} info 공지사항 정보
     * @returns {NoticeCell} 공지사항 셀
     */
    const renderContent = (info) => {
        return  <div className="col-lg-4 col-md-4 col-sm-4"
                    key={"cell" + String(info.projectId)}>
                    <CounselCell data={info}
                        onCellPressed={(id) => {
                            var path = ConstantPath.PATH_COUNSEL_INTRO.split("/");
                            var strippedPath = path.slice(0, path.length-1).join("/");
                            navigate(strippedPath + '/' + id);
                    }}/>
                </div>;
    }

    /**
     * 사이드 메뉴 목록 반환
     * @returns {Array} 사이드 메뉴 목록
     */
    const renderFilterMenu = () => {
        const itemList = [];

        if( DataUtil.validateArray(categoryList) ) {
            for( var i = 0; i < categoryList.length; i++ ) {
                itemList.push(  <li className="nav-item"
                                    key={"recruit_counsel_filter" + String(i)}>
                                    <a className={categoryList[i].category === LIST_TOTAL ? "active" : ""}
                                        filter={categoryList[i].category}
                                        href={'#' + props.tabId}
                                        data-toggle="tab"
                                        onClick={onFilterPressed}>
                                        {categoryList[i].name}
                                    </a>
                                </li>);
            }
        }
        
        return itemList;
    }

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}
            visibleSolutionHeader={true}
            location={location.pathname}>
			<div className="gap" style={{textAlign: 'left'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="main-wraper">
                                <ul className="nav nav-tab timeline-btn"
                                    style={{marginBottom: '30px'}}>
                                    {renderFilterMenu()}
                                </ul>
                                <PageList
                                    dataList={dataList}
                                    fetchData={fetchData}
                                    pageSize={pageSize}
                                    pageRangeDisplayed={5}
                                    className="row"
                                    totalCount={totalCount}
                                    renderCell={renderContent}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default SolutionCounselScreen;