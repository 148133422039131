//
//  applyList.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 19..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// string
import { useTranslation } from 'react-i18next';


/**
 * @protocol MentorContentList
 * @date 2022/12/23
 * @brief 사수지원 사수 컨텐츠 목록 반환
 */
export function MentorContentList() {
    const { t } = useTranslation();
    return [{
        title: t('apply_mentor_content1_title'),
        image: 'assets/images/image/img_mentor_02.png',
        list: [{
            title: t('apply_mentor_content1_list1_title'),
            description: t('apply_mentor_content1_list1_description'),
        }, {
            title: t('apply_mentor_content1_list2_title'),
            description: t('apply_mentor_content1_list2_description'),
        }, {
            title: t('apply_mentor_content1_list3_title'),
            description: t('apply_mentor_content1_list3_description'),
        }, {
            title: t('apply_mentor_content1_list4_title'),
            description: t('apply_mentor_content1_list4_description'),
        }, {
            title: t('apply_mentor_content1_list5_title'),
            description: t('apply_mentor_content1_list5_description'),
        }]
    }, {
        title: t('apply_mentor_content2_title'),
        image: 'assets/images/image/img_mentor_03.png',
        list: [{
            title: t('apply_mentor_content2_list1_title'),
            description: t('apply_mentor_content2_list1_description'),
        }, {
            title: t('apply_mentor_content2_list2_title'),
            description: t('apply_mentor_content2_list2_description'),
        }, {
            title: t('apply_mentor_content2_list3_title'),
            description: t('apply_mentor_content2_list3_description'),
        }, {
            title: t('apply_mentor_content2_list4_title'),
            description: t('apply_mentor_content2_list4_description'),
        }, {
            title: t('apply_mentor_content2_list5_title'),
            description: t('apply_mentor_content2_list5_description'),
        }]
    }];
}

/**
 * @protocol MentorTagList
 * @date 2022/12/23
 * @brief 사수지원 사수 태그 목록 반환
 */
export function MentorTagList() {
    const { t } = useTranslation();
    return [{
        title: t('apply_mentor_tag1'),
    }, {
        title: t('apply_mentor_tag2'),
    }, {
        title: t('apply_mentor_tag3'),
    }, {
        title: t('apply_mentor_tag4'),
    }, {
        title: t('apply_mentor_tag5'),
    }, {
        title: t('apply_mentor_tag6'),
    }, {
        title: t('apply_mentor_tag7'),
    }, {
        title: t('apply_mentor_tag8'),
    }, {
        title: t('apply_mentor_tag9'),
    }, {
        title: t('apply_mentor_tag10'),
    }];
}

/**
 * @protocol ExampleContentList
 * @date 2022/12/23
 * @brief 사수지원 사례 컨텐츠 목록 반환
 */
export function ExampleContentList() {
    const { t } = useTranslation();
    return [{
        title: t('apply_example_content1_title'),
        image: 'assets/images/image/img_mentor_example_01.png',
        description: t('apply_example_content1_description'),
    }, {
        title: t('apply_example_content2_title'),
        image: 'assets/images/image/img_mentor_example_02.png',
        description: t('apply_example_content2_description'),
    }, {
        title: t('apply_example_content3_title'),
        image: 'assets/images/image/img_mentor_example_03.png',
        description: t('apply_example_content3_description'),
    }, {
        title: t('apply_example_content4_title'),
        image: 'assets/images/image/img_mentor_example_04.png',
        description: t('apply_example_content4_description'),
    }, {
        title: t('apply_example_content5_title'),
        image: 'assets/images/image/img_mentor_example_05.png',
        description: t('apply_example_content5_description'),
    }];
}
