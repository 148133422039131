//
//  networkReview.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 12..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// common
import { CommonKey } from 'constants/key';

// network
import CommonNetwork from 'network/common/commonNetwork';
import { listParseFromDictionary } from 'info/listParse';

// info
import ResponseInfo, {
    RESPONSEINFO_CONTAINER,
} from 'info/common/responseInfo';
import ProjectReviewInfo, {
    PROJECTREVIEWINFO_REVIEW_LIST,
} from 'info/solution/projectReviewInfo';
import MemberReviewInfo, {
    MEMBERREVIEWINFO_REVIEW_LIST,
} from 'info/solution/memberReviewInfo';
import {
    PROJECTINFO_NAME,
    PROJECTINFO_INTRO,
} from 'info/solution/projectInfo';
import UserInfo, { USERINFO_CONTAINER } from 'info/user/userInfo';

// package
import axios from 'axios';
axios.defaults.withCredentials = true;

const NETWORK_PATH_GET_PROJECT_REVIEW_LIST  = '/get_project_review_list';
const NETWORK_PATH_GET_MENTOR_REVIEW_LIST   = '/get_mentor_review_list';
const NETWORK_PATH_POST_PROJECT_REVIEW      = '/post_project_review';
const NETWORK_PATH_POST_MENTOR_REVIEW       = '/post_mentor_review';
const NETWORK_PATH_DELETE_PROJECT_REVIEW    = '/delete_project_review';
const NETWORK_PATH_DELETE_MENTOR_REVIEW     = '/delete_mentor_review';

const NETWORK_PARAM_PROJECT_ID  = 'project_id';
const NETWORK_PARAM_MEMBER_ID   = 'member_id';
const NETWORK_PARAM_PAGE_NUM    = 'page_num';
const NETWORK_PARAM_PAGE_SIZE   = 'page_size';


/**
 * @protocol NetworkReview
 * @date 2022/12/12
 * @brief 리뷰 관련 통신
 */
export default class NetworkReview {
    /**
     * 팀프로젝트 리뷰 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} projectId 팀프로젝트 id
     * @param {Number} pageNum 페이지 번호
     * @param {Number} pageSize 페이지 크기
     */
    static getProjectReviewList = async (resSuccess, resFail, projectId, pageNum, pageSize) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_PROJECT_REVIEW_LIST])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_PROJECT_ID]: projectId,
            [NETWORK_PARAM_PAGE_NUM]: pageNum,
            [NETWORK_PARAM_PAGE_SIZE]: pageSize,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var reviewList = [];
                var projectName = '';
                var projectIntro = '';
                var totalCount = 0;

                if( responseInfo.data.hasOwnProperty(PROJECTREVIEWINFO_REVIEW_LIST) ) {
                    reviewList = listParseFromDictionary(ProjectReviewInfo, responseInfo.data[PROJECTREVIEWINFO_REVIEW_LIST], '');
                }

                if( responseInfo.data.hasOwnProperty(PROJECTINFO_NAME) ) {
                    projectName = responseInfo.data[PROJECTINFO_NAME];
                }

                if( responseInfo.data.hasOwnProperty(PROJECTINFO_INTRO) ) {
                    projectIntro = responseInfo.data[PROJECTINFO_INTRO];
                }

                if( responseInfo.data.hasOwnProperty(CommonKey.PAGE_TOTAL_COUNT) ) {
                    totalCount = responseInfo.data[CommonKey.PAGE_TOTAL_COUNT];
                }

                resSuccess({
                    [PROJECTREVIEWINFO_REVIEW_LIST]: reviewList,
                    [PROJECTINFO_NAME]: projectName,
                    [PROJECTINFO_INTRO]: projectIntro,
                    [CommonKey.PAGE_TOTAL_COUNT]: totalCount,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 사수 리뷰 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} memberId 사수 id
     * @param {Number} pageNum 페이지 번호
     * @param {Number} pageSize 페이지 크기
     */
    static getMentorReviewList = async (resSuccess, resFail, memberId, pageNum, pageSize) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_MENTOR_REVIEW_LIST])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_MEMBER_ID]: memberId,
            [NETWORK_PARAM_PAGE_NUM]: pageNum,
            [NETWORK_PARAM_PAGE_SIZE]: pageSize,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var reviewList = [];
                var memberInfo = new UserInfo();
                var totalCount = 0;

                if( responseInfo.data.hasOwnProperty(MEMBERREVIEWINFO_REVIEW_LIST) ) {
                    reviewList = listParseFromDictionary(MemberReviewInfo, responseInfo.data[MEMBERREVIEWINFO_REVIEW_LIST], '');
                }

                if( responseInfo.data.hasOwnProperty(USERINFO_CONTAINER) ) {
                    memberInfo.parseFromDictionary(responseInfo.data[USERINFO_CONTAINER]);
                }

                if( responseInfo.data.hasOwnProperty(CommonKey.PAGE_TOTAL_COUNT) ) {
                    totalCount = responseInfo.data[CommonKey.PAGE_TOTAL_COUNT];
                }

                resSuccess({
                    [MEMBERREVIEWINFO_REVIEW_LIST]: reviewList,
                    [USERINFO_CONTAINER]: memberInfo,
                    [CommonKey.PAGE_TOTAL_COUNT]: totalCount,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 팀프로젝트 리뷰 등록 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {ProjectReviewInfo} reviewInfo 리뷰 정보
     */
    static requestPostProjectReview = async (resSuccess, resFail, reviewInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_POST_PROJECT_REVIEW]);

        try {
            await axios.post(url, reviewInfo.dictionaryForRegister()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 사수 리뷰 등록 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {MemberReviewInfo} reviewInfo 리뷰 정보
     */
    static requestPostMemberReview = async (resSuccess, resFail, reviewInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_POST_MENTOR_REVIEW]);

        try {
            await axios.post(url, reviewInfo.dictionaryForRegister()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 팀프로젝트 리뷰 삭제 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {ProjectReviewInfo} reviewInfo 리뷰 정보
     */
    static requestDeleteProjectReview = async (resSuccess, resFail, reviewInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_DELETE_PROJECT_REVIEW]);

        try {
            await axios.post(url, reviewInfo.dictionaryForDelete()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 사수 리뷰 삭제 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {MemberReviewInfo} reviewInfo 리뷰 정보
     */
    static requestDeleteMemberReview = async (resSuccess, resFail, reviewInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_DELETE_MENTOR_REVIEW]);

        try {
            await axios.post(url, reviewInfo.dictionaryForDelete()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }
}
