//
//  scheduleInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 28..
//  Copyright © 2022년 Melephant. All rights reserved.
//


export const SCHEDULEINFO_LIST          = 'schedule_list';
export const SCHEDULEINFO_CONTAINER     = 'schedule_info';
export const SCHEDULEINFO_ID            = 'project_schedule_idx';
export const SCHEDULEINFO_PROJECT_ID    = 'project_id';
export const SCHEDULEINFO_DEPTH         = 'depth';
export const SCHEDULEINFO_WEEKS         = 'weeks';
export const SCHEDULEINFO_SCHEDULE      = 'schedule';


/**
 * @protocol ScheduleInfo
 * @date 2022/11/28
 * @brief 프로젝트 스케쥴 정보
 */
export default class ScheduleInfo {
    constructor() {
        this.scheduleId = '';
        this.projectId = '';
        this.depth = '';
        this.weeks = '';
        this.schedule = '';
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(SCHEDULEINFO_ID) ) {
            this.scheduleId = dict[SCHEDULEINFO_ID];
        }

        if( dict.hasOwnProperty(SCHEDULEINFO_PROJECT_ID) ) {
            this.projectId = dict[SCHEDULEINFO_PROJECT_ID];
        }

        if( dict.hasOwnProperty(SCHEDULEINFO_DEPTH) ) {
            this.depth = dict[SCHEDULEINFO_DEPTH];
        }

        if( dict.hasOwnProperty(SCHEDULEINFO_WEEKS) ) {
            this.weeks = dict[SCHEDULEINFO_WEEKS];
        }

        if( dict.hasOwnProperty(SCHEDULEINFO_SCHEDULE) ) {
            this.schedule = dict[SCHEDULEINFO_SCHEDULE];
        }
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [SCHEDULEINFO_ID]: this.scheduleId,
            [SCHEDULEINFO_PROJECT_ID]: this.projectId,
            [SCHEDULEINFO_DEPTH]: this.depth,
            [SCHEDULEINFO_WEEKS]: this.weeks,
            [SCHEDULEINFO_SCHEDULE]: this.schedule,
        };
        return dict;
    }
}
