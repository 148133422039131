//
//  responseInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 8. 11..
//  Copyright © 2022년 Melephant. All rights reserved.
//


// network
import NetworkStatus from 'network/common/networkStatus';


export const RESPONSEINFO_CONTAINER = 'response_info';
export const RESPONSEINFO_CODE      = 'code';
export const RESPONSEINFO_DATA      = 'data';


/**
 * @protocol ResponseInfo
 * @date 2022/08/11
 * @brief 응답 정보
 */
export default class ResponseInfo {
    constructor() {
        this.code = 0;
        this.data = null;
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(RESPONSEINFO_CODE) ) {
            this.code = Number(dict[RESPONSEINFO_CODE]);

            if( dict.hasOwnProperty(RESPONSEINFO_DATA) ) {
                this.data = dict[RESPONSEINFO_DATA];
            }
        }
        else {
            this.code = NetworkStatus.SERVER_ERROR;
        }
    }
}
