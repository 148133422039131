//
//  counselCell.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 13..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
} from 'react';

// common
import { CommonList } from 'constants/list';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';


/**
 * @protocol CounselCell
 * @date 2022/12/13
 * @brief 상담 셀
 */
function CounselCell(props) {
    const { t } = useTranslation();

    const weekList = CommonList.WeekList();
    
    /**
     * 상담 시간 문구 반환
     * @returns {String} 상담 시간 문구
     */
    const getCounselScheduleStr = () => {
        var str = '';
        
        if( DataUtil.validateArray(props.data.counselScheduleList) ) {
            props.data.counselScheduleList.forEach(element => {
                var index = weekList.findIndex(item => item.id === element.weekofday);
                if( index >= 0 ) {
                    if( str.length !== 0 ) {
                        str += '\n';
                    }
                    str += weekList[index].title + ' ' + element.start + '~' + element.end;
                }
            });
        }
        return str;
    }
    
    /**
     * 셀 클릭시 호출
     * @param {Event} event 클릭 이벤트
     */
    const onCellPressed = (event) => {
        if( props.onCellPressed ) {
            props.onCellPressed(props.data.projectId);
        }
    }

    return (
        <div className="course"
            onClick={onCellPressed}>
            <figure>
                <img src={props.data.gateImgUrl} style={{aspectRatio: 870/470}} alt=""/>
                {
                    DataUtil.validateString(props.data.categoryInfo.name) ?
                    <em>{props.data.categoryInfo.name}</em> :
                    null
                }
            </figure>
            <div className="course-meta">
                <div className="post-by">
                    <figure><img src={props.data.mentor.deskImgUrl} alt=""/></figure>
                    <div className="course-cat">
                        <span>{props.data.mentor.nickname}</span>
                    </div>
                </div>
                <h5 className="course-title">{'[' + t('word_counsel') + '] ' +
                (DataUtil.validateString(props.data.categoryInfo) ? ' ' + props.data.categoryInfo.name : '') +
                (DataUtil.validateString(props.data.name) ? ' ' + props.data.name : '')}</h5>
                <div className="course-info" style={{whiteSpace: 'pre-wrap'}}>
                    { getCounselScheduleStr() }
                </div>
            </div>
        </div>
    );
}

export default CounselCell;