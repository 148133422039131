//
//  helpNoticeDetailScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 21..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
} from 'react';

// common
import { HelpMenuKey } from 'constants/key';
import { MenuList } from 'constants/list';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkNotice from 'network/networkNotice';

// info
import {
    SERVICENOTICEINFO_CONTAINER,
    SERVICENOTICEINFO_ID,
} from 'info/service/serviceNoticeInfo';

// style
import styles from 'css/screen/helpNoticeDetailScreen.module.css';

// redux
import { useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';

// component
import Layout from 'components/layout/layout';
import SideTab from 'components/tab/sideTab';

// package
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";


/**
 * @protocol HelpNoticeDetailScreen
 * @date 2022/12/21
 * @brief 공지사항 상세 화면
 */
function HelpNoticeDetailScreen(props) {
    var params = useParams();
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const location = useLocation();

    const [data, setData] = useState(null);

    const menuList = MenuList.HelpMenuList();
    const firstTab = HelpMenuKey.HELP_MENU_NOTICE;

    useEffect(() => {
        if( data === null ) {
            NetworkNotice.getServiceNoticeInfo((res) => {
                setData(res[SERVICENOTICEINFO_CONTAINER]);
            }, (res) => {
                dispatch(popupShowDefault(t('alert_network_error')));
            }, location.state[SERVICENOTICEINFO_ID]);
        }
    }, [dispatch, t, params, data, location.state]);

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}>
			<div className="gap" style={{textAlign: 'left'}}>
                {
                    DataUtil.validateArray(menuList) && DataUtil.validateString(firstTab) ?
                    <div className="container">
                        <SideTab
                            defaultTab={firstTab}
                            menuList={menuList}/>
                        <div className="col-lg-12">
                            <div className="tab-content" id="components-nav">
                                <div className={"tab-pane fade show active"}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div id="page-contents" className="row merged20">
                                                    <div className="col-lg-12">
                                                        <div className="main-wraper">
                                                            {
                                                                data ?
                                                                <div className="tab-content" id="components-nav">
                                                                    <div className={styles.container}>
                                                                        <div className={styles.header}>
                                                                            <div className={styles.subject}>
                                                                                <h4>
                                                                                    {data.category}
                                                                                </h4>
                                                                                <h5>
                                                                                    {data.subject}
                                                                                </h5>
                                                                            </div>
                                                                            <h6 className={styles.date}>
                                                                                {DataUtil.convertDateTimeToString(data.regdate)}
                                                                            </h6>
                                                                        </div>
                                                                        <p style={{whiteSpace: 'pre-wrap'}}>
                                                                            {data.contents}
                                                                        </p>
                                                                    </div>
                                                                </div> :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    null
                }
            </div>
        </Layout>
    );
}

export default HelpNoticeDetailScreen;