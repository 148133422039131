//
//  kakaoLoginButton.js
//  datatown
//
//  Created by Mumakil on 2022. 10. 24..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React from 'react';

// style
import styles from 'css/component/kakaoLoginButton.module.css';

// util
import CommonUtil from 'util/commonUtil';


/**
 * @protocol KakaoLoginButton
 * @date 2022/10/25
 * @brief 카카로 로그인 버튼
 */
function KakaoLoginButton(props) {
    return  <>
                <a href={CommonUtil.generateKakaoAuthUri()}
                    className={styles.kakao_btn}
                    style={{backgroundImage: "url(assets/images/image/img_login_kakao.png)"}}>
                </a>
            </>
}

export default KakaoLoginButton;