//
//  counselTimeInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 18..
//  Copyright © 2022년 Melephant. All rights reserved.
//


export const COUNSELTIMEINFO_CONTAINER  = 'counsel_time_info';
export const COUNSELTIMEINFO_ID         = 'member_time_table_idx';
export const COUNSELTIMEINFO_MEMBER_ID  = 'membeR_id';
export const COUNSELTIMEINFO_WEEKOFDAY  = 'weekofday';
export const COUNSELTIMEINFO_START_HOUR = 'start_hour';
export const COUNSELTIMEINFO_START_MIN  = 'start_min';
export const COUNSELTIMEINFO_FIN_HOUR   = 'fin_hour';
export const COUNSELTIMEINFO_FIN_MIN    = 'fin_min';


/**
 * @protocol CounselTimeInfo
 * @date 2022/11/18
 * @brief 상담 시간 정보
 */
export default class CounselTimeInfo {
    constructor() {
        this.id = '';
        this.memberId = '';
        this.weekofday = '';
        this.startHour = '';
        this.startMin = '';
        this.finHour = '';
        this.finMin = '';
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(COUNSELTIMEINFO_ID) ) {
            this.id = dict[COUNSELTIMEINFO_ID];
        }
        if( dict.hasOwnProperty(COUNSELTIMEINFO_MEMBER_ID) ) {
            this.memberId = dict[COUNSELTIMEINFO_MEMBER_ID];
        }
        if( dict.hasOwnProperty(COUNSELTIMEINFO_WEEKOFDAY) ) {
            this.weekofday = dict[COUNSELTIMEINFO_WEEKOFDAY];
        }
        if( dict.hasOwnProperty(COUNSELTIMEINFO_START_HOUR) ) {
            this.startHour = dict[COUNSELTIMEINFO_START_HOUR];
        }
        if( dict.hasOwnProperty(COUNSELTIMEINFO_START_MIN) ) {
            this.startMin = dict[COUNSELTIMEINFO_START_MIN];
        }
        if( dict.hasOwnProperty(COUNSELTIMEINFO_FIN_HOUR) ) {
            this.finHour = dict[COUNSELTIMEINFO_FIN_HOUR];
        }
        if( dict.hasOwnProperty(COUNSELTIMEINFO_FIN_MIN) ) {
            this.finMin = dict[COUNSELTIMEINFO_FIN_MIN];
        }
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {json} json 데이터
     */
    dictionaryForAllKey() {
        var json = {
            [COUNSELTIMEINFO_ID]: this.id,
            [COUNSELTIMEINFO_MEMBER_ID]: this.memberId,
            [COUNSELTIMEINFO_WEEKOFDAY]: this.weekofday,
            [COUNSELTIMEINFO_START_HOUR]: this.startHour,
            [COUNSELTIMEINFO_START_MIN]: this.startMin,
            [COUNSELTIMEINFO_FIN_HOUR]: this.finHour,
            [COUNSELTIMEINFO_FIN_MIN]: this.finMin,
        };
        return  JSON.stringify(json);
    }

    /**
     * 사수 신청에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForApply() {
        var dict = {
            [COUNSELTIMEINFO_WEEKOFDAY]: this.weekofday,
            [COUNSELTIMEINFO_START_HOUR]: this.startHour,
            [COUNSELTIMEINFO_START_MIN]: this.startMin,
            [COUNSELTIMEINFO_FIN_HOUR]: this.finHour,
            [COUNSELTIMEINFO_FIN_MIN]: this.finMin,
        };
        return  dict;
    }
    
    /**
     * 입력 적합성 체크
     * @returns {Boolean} 입력 적합성
     */
    validateInput() {
        if( this.isSelect ) {
            if( !this.startHour || !this.startMin || !this.finHour || !this.finMin ) {
                return false;
            }

            var startTime = new Date();
            var finTime = new Date();

            startTime.setHours(this.startHour);
            startTime.setMinutes(this.startMin);
            finTime.setHours(this.finHour);
            finTime.setMinutes(this.finMin);
            
            if( startTime > finTime ) {
                return false;
            }
        }

        return true;
    }
}
