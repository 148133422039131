//
//  pageLoader.js
//  datatown
//
//  Created by Mumakil on 2022. 10. 24..
//  Copyright © 2022년 Melephant. All rights reserved.
//


import React from 'react';


/**
 * @protocol PageLoader
 * @date 2022/10/24
 * @brief 페이지 로딩 객체
 */
export default function PageLoader(props) {
    return (
        <div className="page-loader" id="page-loader">
            <div className="loader">
                <span className="loader-item"></span>
                <span className="loader-item"></span>
                <span className="loader-item"></span>
                <span className="loader-item"></span>
                <span className="loader-item"></span>
                <span className="loader-item"></span>
                <span className="loader-item"></span>
                <span className="loader-item"></span>
                <span className="loader-item"></span>
                <span className="loader-item"></span>
            </div>
        </div>
    );
}
