//
//  helpM2mListView.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 20..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
} from 'react';

// common
import {
    CommonKey,
    TableKey,
} from 'constants/key';
import ConstantPath from 'constants/path';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';

// network
import NetworkM2m from 'network/networkM2m';

// info
import {
    SERVICEM2MINFO_LIST,
    SERVICEM2MINFO_ID
} from 'info/service/serviceM2mInfo';

// component
import PageTable from 'components/table/pageTable';
import LoginView  from 'components/subview/loginView';

// package
import { Link } from "react-router-dom";


/**
 * @protocol HelpM2mListView
 * @date 2022/12/20
 * @brief 1:1 문의 리스트 화면
 */
function HelpM2mListView(props) {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    var user = useSelector(state => state.session.user);
    const isLogin = useSelector(state => state.session.isLogin);

    const [m2mList, setM2mList] = useState(null);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
    }, []);

    // 데이터 요청 처리
    const fetchData = async (pageNum, pageSize) => {
        if( isLogin && user ) {
            NetworkM2m.getServiceM2mSendList((res) => {
                setM2mList(res[SERVICEM2MINFO_LIST]);
                setTotalCount(res[CommonKey.PAGE_TOTAL_COUNT]);
            }, (res) => {
                dispatch(popupShowDefault(t('alert_network_error')));
            }, user.memberId, pageNum, pageSize);
        }
        // else {
        //     setM2mList([]);
        //     setTotalCount(0);
        // }
    };

    /**
     * 스케쥴 column 목록 생성
     * @returns {Array} 기수 column 목록
     */
    const generateM2mColumnList = () => {
        return [{
            id: TableKey.TABLE_SUBJECT,
            name: t('word_m2m_subject'),
            isClickable: true,
        }, {
            id: TableKey.TABLE_CONTENT,
            name: t('word_m2m_content'),
            isClickable: true,
        }, {
            id: TableKey.TABLE_REGDATE,
            name: t('word_reg_date'),
            isClickable: false,
        }, {
            id: TableKey.TABLE_ANSWER_EXIST,
            name: t('word_anser_exist'),
            isClickable: false,
        }];
    }

    /**
     * 스케쥴 데이터 목록 생성
     * @returns {Array} 기수 데이터 목록
     */
    const generateM2mDataList = () => {
        if( DataUtil.validateArray(m2mList) ) {
            var itemList = [];
            for( let i = 0; i < m2mList.length; i++ ) {
                itemList.push({
                    [TableKey.TABLE_SUBJECT]: m2mList[i].subject,
                    [TableKey.TABLE_CONTENT]: m2mList[i].question,
                    [TableKey.TABLE_REGDATE]: DataUtil.convertDateTimeToString(m2mList[i].regdate),
                    [TableKey.TABLE_ANSWER_EXIST]: m2mList[i].m2mStatus === 'Y' ? 'O' : 'X',
                });
            }
            return itemList;
        }
        return null;
    }

    /**
     * 테이블 셀 커텀 
     * @param {*} element 
     * @param {*} info 
     * @returns 
     */
    const renderContentCell = (element, info) => {
        if( DataUtil.validateArray(m2mList) ) {
            if( info.row.index < m2mList.length ) {
                var id = m2mList[info.row.index].m2mId;

                return  <Link to={ConstantPath.PATH_HELP_M2M_DETAIL} state={{[SERVICEM2MINFO_ID]: id}}>
                            {info.cell.getValue()}
                        </Link>;
            }
        }
        
        return null;
    }

    return (
        <div className={"tab-pane show" + (props.defaultActive ? " active" : "")}
            id={props.tabId} >
            {
                isLogin ?
                <PageTable
                    dataList={generateM2mDataList()}
                    columnList={generateM2mColumnList()}
                    fetchData={fetchData}
                    pageSize={10}
                    pageRangeDisplayed={5}
                    totalCount={totalCount}
                    renderCell={renderContentCell}/> :
                <LoginView/>
            }
        </div>
    );
}

export default HelpM2mListView;