//
//  paymentInfo.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 25..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// info
import { USERINFO_NICKNAME } from "info/user/userInfo";
import { PROJECTINFO_NAME } from "info/solution/projectInfo";
import {
    PROJECTPAYMENTINFO_PROJECT_ID,
    PROJECTPAYMENTINFO_ORDINAL,
} from "info/payment/projectPaymentInfo";


export const PAYMENTINFO_LIST           = 'payment_info_list';
export const PAYMENTINFO_CONTAINER      = 'payment_info';
export const PAYMENTINFO_PAYMENT_ID     = 'payment_idx';
export const PAYMENTINFO_MEMBER_ID      = 'member_id';
export const PAYMENTINFO_PAYMENT_KEY    = 'payment_key';
export const PAYMENTINFO_PAY_TYPE       = 'pay_type';
export const PAYMENTINFO_ORDER_ID       = 'order_id';
export const PAYMENTINFO_ORDER_NAME     = 'order_name';
export const PAYMENTINFO_RECEIPT        = 'receipt';
export const PAYMENTINFO_PRICE          = 'price';
export const PAYMENTINFO_REGDATE        = 'regdate';
export const PAYMENTINFO_APPROVEDDATE   = 'approveddate';
export const PAYMENTINFO_CANCEL_YN      = 'cancel_yn';
export const PAYMENTINFO_CANCEL_REASON  = 'cancelreason';
export const PAYMENTINFO_CANCEL_DATE    = 'canceldate';


/**
 * @protocol PaymentInfo
 * @date 2023/01/25
 * @brief 결제 정보
 */
export default class PaymentInfo {
    constructor() {
        this.paymentId = '';
        this.memberId = '';
        this.paymentKey = '';
        this.payType = '';
        this.orderId = '';
        this.orderName = '';
        this.receipt = '';
        this.price = '';
        this.regdate = null;
        this.approveddate = null;
        this.cancelYn = false;
        this.cancelReason = '';
        this.canceldate = null;

        this.projectId = '';
        this.ordinal = '';
        this.nickname = '';
        this.projectName = '';
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(PAYMENTINFO_PAYMENT_ID) ) {
            this.paymentId = dict[PAYMENTINFO_PAYMENT_ID];
        }

        if( dict.hasOwnProperty(PAYMENTINFO_MEMBER_ID) ) {
            this.memberId = dict[PAYMENTINFO_MEMBER_ID];
        }

        if( dict.hasOwnProperty(PAYMENTINFO_PAYMENT_KEY) ) {
            this.paymentKey = dict[PAYMENTINFO_PAYMENT_KEY];
        }

        if( dict.hasOwnProperty(PAYMENTINFO_PAY_TYPE) ) {
            this.payType = dict[PAYMENTINFO_PAY_TYPE];
        }

        if( dict.hasOwnProperty(PAYMENTINFO_ORDER_ID) ) {
            this.orderId = dict[PAYMENTINFO_ORDER_ID];
        }

        if( dict.hasOwnProperty(PAYMENTINFO_ORDER_NAME) ) {
            this.orderName = dict[PAYMENTINFO_ORDER_NAME];
        }

        if( dict.hasOwnProperty(PAYMENTINFO_RECEIPT) ) {
            this.receipt = dict[PAYMENTINFO_RECEIPT];
        }

        if( dict.hasOwnProperty(PAYMENTINFO_PRICE) ) {
            this.price = dict[PAYMENTINFO_PRICE];
        }

        if( dict.hasOwnProperty(PAYMENTINFO_REGDATE) ) {
            this.regdate = new Date(dict[PAYMENTINFO_REGDATE]);
        }

        if( dict.hasOwnProperty(PAYMENTINFO_APPROVEDDATE) ) {
            if( dict[PAYMENTINFO_APPROVEDDATE] ) {
                this.approveddate = new Date(dict[PAYMENTINFO_APPROVEDDATE]);
            }
            else {
                this.approveddate = null;
            }
        }

        if( dict.hasOwnProperty(PAYMENTINFO_CANCEL_YN) ) {
            this.cancelYn = dict[PAYMENTINFO_CANCEL_YN] === 'Y' ? true : false;
        }

        if( dict.hasOwnProperty(PAYMENTINFO_CANCEL_REASON) ) {
            this.cancelReason = dict[PAYMENTINFO_CANCEL_REASON];
        }

        if( dict.hasOwnProperty(PAYMENTINFO_CANCEL_DATE) ) {
            if( dict[PAYMENTINFO_CANCEL_DATE] ) {
                this.canceldate = new Date(dict[PAYMENTINFO_CANCEL_DATE]);
            }
            else {
                this.canceldate = null;
            }
        }


        if( dict.hasOwnProperty(USERINFO_NICKNAME) ) {
            this.nickname = dict[USERINFO_NICKNAME];
        }

        if( dict.hasOwnProperty(PROJECTINFO_NAME) ) {
            this.projectName = dict[PROJECTINFO_NAME];
        }

        if( dict.hasOwnProperty(PROJECTPAYMENTINFO_PROJECT_ID) ) {
            this.projectId = dict[PROJECTPAYMENTINFO_PROJECT_ID];
        }

        if( dict.hasOwnProperty(PROJECTPAYMENTINFO_ORDINAL) ) {
            this.ordinal = dict[PROJECTPAYMENTINFO_ORDINAL];
        }
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [PAYMENTINFO_PAYMENT_ID]: this.paymentId,
            [PAYMENTINFO_MEMBER_ID]: this.memberId,
            [PAYMENTINFO_PAYMENT_KEY]: this.paymentKey,
            [PAYMENTINFO_PAY_TYPE]: this.payType,
            [PAYMENTINFO_ORDER_ID]: this.orderId,
            [PAYMENTINFO_ORDER_NAME]: this.orderName,
            [PAYMENTINFO_RECEIPT]: this.receipt,
            [PAYMENTINFO_PRICE]: this.price,
            [PAYMENTINFO_REGDATE]: this.regdate,
            [PAYMENTINFO_APPROVEDDATE]: this.approveddate,
            [PAYMENTINFO_CANCEL_YN]: this.cancelYn ? 'Y' : 'N',
            [PAYMENTINFO_CANCEL_DATE]: this.canceldate
        };
        return dict;
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForComplete() {
        var dict = {
            [PAYMENTINFO_PAYMENT_KEY]: this.paymentKey,
            [PAYMENTINFO_PAY_TYPE]: this.payType,
            [PAYMENTINFO_ORDER_ID]: this.orderId,
            [PAYMENTINFO_RECEIPT]: this.receipt,
            [PAYMENTINFO_APPROVEDDATE]: this.approveddate,
        };
        return dict;
    }
}
