//
//  projectCell.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 14..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
} from 'react';

// common
import { CommonList } from 'constants/list';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';


/**
 * @protocol ProjectCell
 * @date 2022/12/14
 * @brief 팀프로젝트 셀
 */
function ProjectCell(props) {
    const { t } = useTranslation();
    
    const ordinalStatusList = CommonList.OrdinalStatusList();

    /**
     * 기수 상태 문구 반환
     * @returns {String} 기수 상태 문구
     */
    const getOrdinalStatusStr = () => {
        var ordinalStatusIndex = ordinalStatusList.findIndex(element => element.id === props.data.ordinalInfo.ordinalStatus);
        if( ordinalStatusIndex >= 0 ) {
            return ordinalStatusList[ordinalStatusIndex].title;
        }
        return '';
    }

    /**
     * 셀 클릭시 호출
     * @param {Event} event 클릭 이벤트
     */
    const onCellPressed = (event) => {
        if( props.onCellPressed ) {
            props.onCellPressed(props.data.projectId, props.data.ordinalInfo.ordinal);
        }
    }
    
    return (
        <div className="course"
            onClick={onCellPressed}>
            <figure>
                <img src={props.data.gateImgUrl} style={{aspectRatio: '1.77'}} alt=""/>
            </figure>
            <div className="course-meta">
                <div className="post-by">
                    <figure><img src={props.data.mentor.deskImgUrl} alt=""/></figure>
                    <div className="course-cat">
                        <span>{props.data.mentor.nickname}</span>
                    </div>
                </div>
                <h5 className="course-title">{'[' + t('word_teamproject') + '] ' + props.data.name + ' - ' + props.data.ordinalInfo.ordinal + t('word_ordinary_short')}</h5>
                <div className="course-info">
                    <span className="lecturez">
                        {' ' + DataUtil.convertDateToString(props.data.ordinalInfo.startdate) +
                        '~' + DataUtil.convertDateToString(props.data.ordinalInfo.enddate)}
                    </span>
                    <span className="time">{getOrdinalStatusStr()}</span>
                </div>
            </div>
        </div>
    );
}

export default ProjectCell;