//
//  projectAttendanceScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 8..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
} from 'react';

// common
import {
    TableKey,
    OrdinalScheduleStatusKey,
    ReplaceKey,
} from 'constants/key';
import ConstantPath from 'constants/path';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkSolution from 'network/networkSolution';

// info
import {
    ORDINALINFO_PROJECT_ID,
    ORDINALINFO_ORDINAL,
} from 'info/solution/ordinalInfo';
import {
    USERINFO_MEMBER_ID,
    USERINFO_NICKNAME,
} from 'info/user/userInfo';
import { ORDINALSCHEDULEINFO_LIST } from 'info/solution/ordinalScheduleInfo';
import AttendanceInfo, {
    ATTENDANCEINFO_LIST,
} from 'info/solution/attendanceInfo';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';

// component
import Layout from 'components/layout/layout';
import DefaultTable from 'components/table/defaultTable';

// package
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap';


/**
 * @protocol ProjectAttendanceScreen
 * @date 2022/12/08
 * @brief 팀프로젝트 출석관리 화면
 */
function ProjectAttendanceScreen(props) {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    
    const user = useSelector(state => state.session.user);

    const [scheduleList, setScheduleList] = useState(null);
    const [attendanceList, setAttendanceList] = useState(null);
    const [nickname, setNickname] = useState('');

    useEffect(() => {
        // 이전 설정 데이터 없으면 초기화면으로 이동
        if( location.state === null ||
            location.state[ORDINALINFO_PROJECT_ID] === null ||
            location.state[ORDINALINFO_ORDINAL] === null ||
            location.state[USERINFO_MEMBER_ID] === null ) {
            navigate(-1);
        }
        else {
            var projectId = location.state[ORDINALINFO_PROJECT_ID];
            var ordinal = location.state[ORDINALINFO_ORDINAL];
            var memberId = location.state[USERINFO_MEMBER_ID];

            NetworkSolution.getProjectAttendance((res) => {
                var scheduleList = res[ORDINALSCHEDULEINFO_LIST];
                var attendanceList = res[ATTENDANCEINFO_LIST];
                
                setNickname(res[USERINFO_NICKNAME]);
                setAttendanceList(attendanceList);
                setScheduleList(scheduleList);
            }, (res) => {
                dispatch(popupShowDefault(t('alert_network_error')));
            }, projectId, ordinal, memberId);
        }
    }, [location.state, navigate, user.memberId, dispatch, t]);

    /**
     * 스케쥴 column 목록 생성
     * @returns {Array} 기수 column 목록
     */
    const generateScheduleColumnList = () => {
        return [{
            id: TableKey.TABLE_WEEKS,
            name: t('word_schedule_week'),
            isClickable: false,
        }, {
            id: TableKey.TABLE_SCHEDULE,
            name: t('word_schedule'),
            isClickable: false,
        }, {
            id: TableKey.TABLE_ATTENDANCE,
            name: t('word_attendance_absense'),
            isClickable: false,
        }, {
            id: TableKey.TABLE_ETC,
            name: t('word_etc'),
            isClickable: true,
        }];
    }

    /**
     * 스케쥴 데이터 목록 생성
     * @returns {Array} 기수 데이터 목록
     */
    const generateScheduleDataList = () => {
        var itemList = [];
        if( DataUtil.validateArray(scheduleList) ) {
            for( let i = 0; i < scheduleList.length; i++ ) {
                var attendance = false;
                var index = attendanceList.findIndex(element => String(element.weeks) === String(scheduleList[i].weeks));
                // 출석 정보에 해당 주차 출석 정보가 있으면 출석
                // 없으면 결석
                if( index >= 0 ) {
                    attendance = true;
                }
                
                // 스케쥴 상태가 진행 완료일때는 출석 / 결석 표시
                itemList.push({
                    [TableKey.TABLE_WEEKS]: scheduleList[i].weeks + t('word_schedule_week'),
                    [TableKey.TABLE_SCHEDULE]: scheduleList[i].schedule,
                    [TableKey.TABLE_ATTENDANCE]: scheduleList[i].scheduleStatus === OrdinalScheduleStatusKey.ORDINAL_SCHEDULE_STATUS_AFTER ?
                    (attendance ? t('word_attendance') : t('word_absense')) :
                    '',
                    [TableKey.TABLE_ETC]: t('button_modify'),
                });
            }
        }
        return itemList;
    }

    /**
     * 테이블 기타 셀 렌더링
     * @param {*} element 구성요소
     * @param {*} info 테이블 정보
     * @returns {*} cell
     */
    const renderEtcCell = (element, info) => {
        if( scheduleList[info.row.index].scheduleStatus === OrdinalScheduleStatusKey.ORDINAL_SCHEDULE_STATUS_AFTER ) {
            return  <div>
                        <Button className="button primary circle"
                            onClick={() => {
                                // 출석 요청
                                var attendanceInfo = new AttendanceInfo();
                                attendanceInfo.projectId = location.state[ORDINALINFO_PROJECT_ID];
                                attendanceInfo.ordinal = location.state[ORDINALINFO_ORDINAL];
                                attendanceInfo.memberId = location.state[USERINFO_MEMBER_ID];
                                attendanceInfo.weeks = scheduleList[info.row.index].weeks;

                                NetworkSolution.requestProjectAttendance((res) => {
                                    dispatch(popupShowDefault(t('alert_complete_update_attendance'), () => {
                                        window.location.reload(false);
                                    }));
                                }, (res) => {
                                    dispatch(popupShowDefault(t('alert_network_error')));
                                }, attendanceInfo);
                            }}>
                            {t('button_attendance')}
                        </Button>
                        <Button className="button primary circle"
                            style={{marginLeft: '10px'}}
                            onClick={() => {
                                // 출석 항목이 있으면 id 로 결석 요청
                                var index = attendanceList.findIndex(element => String(element.weeks) === String(scheduleList[info.row.index].weeks));
                                if( index >= 0 ) {
                                    var attendanceInfo = new AttendanceInfo();
                                    attendanceInfo.attendanceId = attendanceList[index].attendanceId;

                                    NetworkSolution.requestProjectAbsense((res) => {
                                        dispatch(popupShowDefault(t('alert_complete_update_attendance'), () => {
                                            window.location.reload(false);
                                        }));
                                    }, (res) => {
                                        dispatch(popupShowDefault(t('alert_network_error')));
                                    }, attendanceInfo);
                                }
                            }}>
                            {t('button_absense')}
                        </Button>
                    </div>
        }
        return null;
    };

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}
            visibleSolutionHeader={true}
            location={ConstantPath.PATH_SOLUTION_PROJECT}>
			<div className="gap" style={{textAlign: 'left'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div id="page-contents" className="row merged20">
                                <div className="col-lg-12">
                                    <div className="main-wraper">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="create-by">
                                                    <h4 className="main-title">{t('project_attendance_section_attendance').replace(ReplaceKey.REPLACE_NAME, nickname)}</h4>
                                                    {
                                                        scheduleList ?
                                                        <DefaultTable
                                                            dataList={generateScheduleDataList()}
                                                            columnList={generateScheduleColumnList()}
                                                            renderCell={renderEtcCell}/> :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ProjectAttendanceScreen;