//
//  counselScheduleInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 9..
//  Copyright © 2022년 Melephant. All rights reserved.
//


export const COUNSELSCHEDULEINFO_LIST       = 'counsel_schedule_list';
export const COUNSELSCHEDULEINFO_CONTAINER  = 'counselschedule_info';
export const COUNSELSCHEDULEINFO_ID         = 'project_counsel_schedule_idx';
export const COUNSELSCHEDULEINFO_PROJECT_ID = 'project_id';
export const COUNSELSCHEDULEINFO_WOA        = 'woa';
export const COUNSELSCHEDULEINFO_WEEKOFDAY  = 'weekofday';
export const COUNSELSCHEDULEINFO_ALLDAY     = 'allday';
export const COUNSELSCHEDULEINFO_START      = 'start';
export const COUNSELSCHEDULEINFO_END        = 'end';


/**
 * @protocol CounselScheduleInfo
 * @date 2022/12/09
 * @brief 상담 스케쥴 정보
 */
export default class CounselScheduleInfo {
    constructor() {
        this.scheduleId = '';
        this.projectId = '';
        this.woa = '';
        this.weekofday = '';
        this.allday = '';
        this.start = '';
        this.end = '';
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(COUNSELSCHEDULEINFO_ID) ) {
            this.scheduleId = dict[COUNSELSCHEDULEINFO_ID];
        }

        if( dict.hasOwnProperty(COUNSELSCHEDULEINFO_PROJECT_ID) ) {
            this.projectId = dict[COUNSELSCHEDULEINFO_PROJECT_ID];
        }

        if( dict.hasOwnProperty(COUNSELSCHEDULEINFO_WOA) ) {
            this.woa = dict[COUNSELSCHEDULEINFO_WOA];
        }

        if( dict.hasOwnProperty(COUNSELSCHEDULEINFO_WEEKOFDAY) ) {
            this.weekofday = dict[COUNSELSCHEDULEINFO_WEEKOFDAY];
        }

        if( dict.hasOwnProperty(COUNSELSCHEDULEINFO_ALLDAY) ) {
            this.allday = dict[COUNSELSCHEDULEINFO_ALLDAY];
        }

        if( dict.hasOwnProperty(COUNSELSCHEDULEINFO_START) ) {
            this.start = dict[COUNSELSCHEDULEINFO_START];
        }

        if( dict.hasOwnProperty(COUNSELSCHEDULEINFO_END) ) {
            this.end = dict[COUNSELSCHEDULEINFO_END];
        }
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [COUNSELSCHEDULEINFO_ID]: this.scheduleId,
            [COUNSELSCHEDULEINFO_PROJECT_ID]: this.projectId,
            [COUNSELSCHEDULEINFO_WOA]: this.woa,
            [COUNSELSCHEDULEINFO_WEEKOFDAY]: this.weekofday,
            [COUNSELSCHEDULEINFO_ALLDAY]: this.allday,
            [COUNSELSCHEDULEINFO_START]: this.start,
            [COUNSELSCHEDULEINFO_END]: this.end,
        };
        return dict;
    }
}
