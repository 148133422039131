//
//  weekTimetableInput.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 18..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React from 'react';

// util
import DataUtil from 'util/dataUtil';

// component
import DayTimetableInput from 'components/input/daytableInput';


/**
 * @protocol WeekTimetableInput
 * @date 2022/11/18
 * @brief 주간 타임테이블 수정 화면
 */
function WeekTimetableInput(props) {
    /**
     * 일별 타임테이블 입력기 목록 반환
     * @returns {Array} 타임테이블 입력기 목록
     */
	const renderDayTimetableInput = () => {
        if( DataUtil.validateArray(props.data) ) {
            var itemList = [];

            for( var i = 0; i < props.data.length; i++ ) {
                itemList.push(
                    <DayTimetableInput
                        key={'week_input' + String(i)}
                        data={props.data[i]}
                        onCheckPressed={props.onCheckPressed}
                        onStartChanged={props.onStartChanged}
                        onFinChanged={props.onFinChanged}/>
                );
            }

            return itemList;
        }
        return null;
    }

    return (
        <div className="col-lg-12" style={{marginBottom: '30px'}}>
            {renderDayTimetableInput()}
        </div>
    );
}

export default WeekTimetableInput;