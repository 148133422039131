//
//  categoryInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 4..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// util
import DataUtil from "util/dataUtil";


export const CATEGORYINFO_LIST              = 'category_info_list';
export const CATEGORYINFO_CONTAINER         = 'category_info';
export const CATEGORYINFO_ID                = 'category_idx';
export const CATEGORYINFO_CATEGORY          = 'category';
export const CATEGORYINFO_NAME              = 'category_name';
export const CATEGORYINFO_TYPE              = 'category_type';
export const CATEGORYINFO_TYPE_NAME         = 'category_type_name';
export const CATEGORYINFO_SORT_ORDER        = 'sort_order';
export const CATEGORYINFO_UPPER_CATEGORY    = 'upper_category'
export const CATEGORYINFO_ICON              = 'icon';
export const CATEGORYINFO_DESC              = 'desc';


/**
 * @protocol CategoryInfo
 * @date 2022/11/04
 * @brief 카테고리 정보
 */
export default class CategoryInfo {
    constructor() {
        this.categoryId = 0;
        this.category = '';
        this.name = '';
        this.type = '';
        this.typeName = '';
        this.sortOrder = 0;
        this.upperCategory = '';
        this.icon = '';
        this.desc = '';
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(CATEGORYINFO_ID) ) {
            this.categoryId = Number(dict[CATEGORYINFO_ID]);
        }

        if( dict.hasOwnProperty(CATEGORYINFO_CATEGORY) ) {
            this.category = dict[CATEGORYINFO_CATEGORY];
        }

        if( dict.hasOwnProperty(CATEGORYINFO_NAME) ) {
            this.name = dict[CATEGORYINFO_NAME];
        }

        if( dict.hasOwnProperty(CATEGORYINFO_TYPE) ) {
            this.type = dict[CATEGORYINFO_TYPE];
        }

        if( dict.hasOwnProperty(CATEGORYINFO_TYPE_NAME) ) {
            this.typeName = dict[CATEGORYINFO_TYPE_NAME];
        }

        if( dict.hasOwnProperty(CATEGORYINFO_SORT_ORDER) ) {
            this.sortOrder = Number(dict[CATEGORYINFO_SORT_ORDER]);
        }

        if( dict.hasOwnProperty(CATEGORYINFO_UPPER_CATEGORY) ) {
            this.upperCategory = dict[CATEGORYINFO_UPPER_CATEGORY];
        }

        if( dict.hasOwnProperty(CATEGORYINFO_ICON) ) {
            this.icon = dict[CATEGORYINFO_ICON];
        }

        if( dict.hasOwnProperty(CATEGORYINFO_DESC) ) {
            this.desc = dict[CATEGORYINFO_DESC]
            
            if( DataUtil.validateString(this.desc) ) {
                this.desc = this.desc.replaceAll('\\n', '\n');
            }
        }
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {json} json 데이터
     */
    dictionaryForAllKey() {
        var json = {
            [CATEGORYINFO_ID]: this.categoryId,
            [CATEGORYINFO_CATEGORY]: this.category,
            [CATEGORYINFO_NAME]: this.name,
            [CATEGORYINFO_TYPE]: this.type,
            [CATEGORYINFO_TYPE_NAME]: this.typeName,
            [CATEGORYINFO_SORT_ORDER]: this.sortOrder,
            [CATEGORYINFO_UPPER_CATEGORY]: this.upperCategory,
            [CATEGORYINFO_ICON]: this.icon,
            [CATEGORYINFO_DESC]: this.desc,
        };
        return  JSON.stringify(json);
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForApply() {
        var dict = {
            [CATEGORYINFO_ID]: String(this.categoryId),
            [CATEGORYINFO_CATEGORY]: this.category,
            [CATEGORYINFO_NAME]: this.name,
            [CATEGORYINFO_TYPE]: this.type,
            [CATEGORYINFO_TYPE_NAME]: this.typeName,
            [CATEGORYINFO_SORT_ORDER]: String(this.sortOrder),
            [CATEGORYINFO_UPPER_CATEGORY]: this.upperCategory,
        };
        return dict;
    }
}
