//
//  popupController.js
//  datatown
//
//  Created by Mumakil on 2022. 8. 16..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// common
import React from 'react';
import ConstantValue from 'constants/value';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupHide, popupShowAgain } from 'redux/actions/popupAction';

// manager
import PopupManager from 'manager/popupManager';

// component
import PopupView from 'components/modal/popupView';


/**
 * @protocol PopupController
 * @date 2022/08/16
 * @brief 팝업 관리 화면
 */
export default function PopupController() {
    const dispatch = useDispatch();

    // 현재 표시할 팝업 정보 변경시 재호출
    useSelector(state => state.popup.current);

    // 팝업이 닫힐 경우, 팝업 삭제 액션 호출
    const onPopupClosed = e => {
        dispatch(popupHide());

        setTimeout(() => {
            // 팝업 queue 체크하여 남은게 있다면 표시 요청
            if( !PopupManager.getInstance().checkPopupEmpty() ) {
                var updatePopup = PopupManager.getInstance().getCurrentPopupInfo();
                dispatch(popupShowAgain(updatePopup));
            }
        }, ConstantValue.ANIMATION_TIME_POPUP + ConstantValue.ANIMATION_TIME_POPUP_TERM);
    };

    // 현재 표시할 팝업 체크
    var currentPopup = PopupManager.getInstance().getCurrentPopupInfo();

    return <PopupView
                info={currentPopup}
                onPopupClosed={onPopupClosed}/>;
}
