//
//  noticeCell.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 1..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
} from 'react';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// style
import styles from 'css/component/noticeCell.module.css';


/**
 * @protocol NoticeCell
 * @date 2022/12/01
 * @brief 공지사항 셀
 */
function NoticeCell(props) {
    const { t } = useTranslation();

    const onViewPressed = () => {
        if( props.onViewPressed ) {
            props.onViewPressed(props.data.noticeId);
        }
    }
    return (
        <div className={styles.container}
            style={props.style}>
            <div className={styles.header}>
                <h4>{props.data.subject}</h4>
                <p className={styles.contents}>{props.data.contents}</p>
            </div>
            <div className={styles.body}>
                <div className={styles.user}>
                    <img src={props.data.userInfo && props.data.userInfo.deskImgUrl ? props.data.userInfo.deskImgUrl : 'assets/images/image/img_user.jpg'} alt=""/>
                    <h4 className={styles.nickname}>{props.data.userInfo.nickname}</h4>
                    <h6 className={styles.date}>{DataUtil.convertDateTimeToString(props.data.regdate)}</h6>
                </div>
                <button className='button primary circle' onClick={onViewPressed}>{t('button_view_content')}</button>
            </div>
        </div>
    );
}

export default NoticeCell;