//
//  requestCounselView.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 18..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
} from 'react';

// common
import ConstantPath from 'constants/path';
import {
    CommonKey,
    TableKey,
    ApplyCounselStatusKey,
} from 'constants/key';
import { CommonList } from 'constants/list';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkSolution from 'network/networkSolution';
import NetworkPayment from 'network/networkPayment';

// info
import { COUNSELAPPLYINFO_LIST } from 'info/solution/counselApplyInfo';
import PaymentInfo, {
    PAYMENTINFO_CONTAINER,
    PAYMENTINFO_ORDER_ID,
} from 'info/payment/paymentInfo';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';
import { dataPayment } from 'redux/actions/dataAction';

// component
import PageTable from 'components/table/pageTable';

// package
import { Link, useNavigate } from 'react-router-dom';


/**
 * @protocol RequestCounselView
 * @date 2023/01/18
 * @brief 사수해법 신청한 상담 목록 화면
 */
function RequestCounselView(props) {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector(state => state.session.user);

    const [dataList, setDataList] = useState(null);
    const [totalCount, setTotalCount] = useState(0);

    const applyCounselStatusList = CommonList.ApplyCounselStatusList();

    // 데이터 요청 처리
    const fetchData = async (pageNum, pageSize) => {
        NetworkSolution.getRequestCounselList((res) => {
            setDataList(res[COUNSELAPPLYINFO_LIST]);
            setTotalCount(res[CommonKey.PAGE_TOTAL_COUNT]);
        }, (res) => {
            dispatch(popupShowDefault(t('alert_network_error')));
        }, user.memberId, pageNum, pageSize);
    };

    /**
     * 스케쥴 column 목록 생성
     * @returns {Array} 기수 column 목록
     */
    const generateColumnList = () => {
        return [{
            id: TableKey.TABLE_NAME,
            name: t('word_counsel_name'),
            isClickable: true,
        }, {
            id: TableKey.TABLE_PRICE,
            name: t('word_price'),
            isClickable: false,
        }, {
            id: TableKey.TABLE_STATUS,
            name: t('word_progress'),
            isClickable: true,
        }, {
            id: TableKey.TABLE_MENTOR,
            name: t('word_mentor'),
            isClickable: false,
        }, {
            id: TableKey.TABLE_PERIOD,
            name: t('word_plan'),
            isClickable: false,
        }];
    }

    /**
     * 스케쥴 데이터 목록 생성
     * @returns {Array} 기수 데이터 목록
     */
    const generateDataList = () => {
        if( DataUtil.validateArray(dataList) ) {
            var itemList = [];
            for( let i = 0; i < dataList.length; i++ ) {
                var applyCounselStatusIndex = applyCounselStatusList.findIndex(element => element.id === dataList[i].applyStatus);
                itemList.push({
                    [TableKey.TABLE_NAME]: dataList[i].projectName,
                    [TableKey.TABLE_PRICE]: (
                        Number(dataList[i].price) === 0 ?
                        t('word_free') :
                        t('word_currency') + DataUtil.numberWithCommas(Number(dataList[i].price))
                    ),
                    [TableKey.TABLE_STATUS]: (applyCounselStatusIndex >= 0 ? applyCounselStatusList[applyCounselStatusIndex].title : ''),
                    [TableKey.TABLE_MENTOR]: dataList[i].mentorNickname,
                    [TableKey.TABLE_PERIOD]: dataList[i].approvedate ? DataUtil.convertDateTimeToString(dataList[i].approvedate) : '',
                });
            }
            return itemList;
        }
        return null;
    }

    /**
     * 테이블 셀 커텀 
     * @param {*} element 
     * @param {*} info 
     * @returns 
     */
    const renderContentCell = (element, info) => {
        if( DataUtil.validateArray(dataList) ) {
            if( info.row.index < dataList.length ) {
                switch( info.column.columnDef.header ) {
                    case t('word_counsel_name'): {
                        var path = ConstantPath.PATH_COUNSEL_INTRO.split("/");
                        var strippedPath = path.slice(0, path.length-1).join("/");
                        var id = dataList[info.row.index].projectId;

                        return  <Link to={strippedPath+ '/' + id}>
                                    {info.cell.getValue()}
                                </Link>;
                    }
                    case t('word_progress'): {
                        if( dataList[info.row.index].applyStatus === ApplyCounselStatusKey.APPLY_COUNSEL_STATUS_ACCEPT ) {
                            return  <Link to={'#!'} onClick={(e) => {
                                        e.preventDefault();

                                        var selectInfo = dataList[info.row.index];
                                        selectInfo.applyMemberId = user.memberId;

                                        // 무료일때와 구분
                                        if( Number(selectInfo.price) === 0 ) {
                                            NetworkPayment.requestPaymentCounsel((res) => {
                                                var payment = res[PAYMENTINFO_CONTAINER];

                                                var compPayment = new PaymentInfo();
                                                compPayment.orderId = payment.orderId;
                                                compPayment.receipt = '';
                                                compPayment.payType = '무료';
                                                compPayment.paymentKey = '';
                                                compPayment.approveddate = DataUtil.convertDateToISOString(new Date());

                                                // 결제 완료 요청
                                                NetworkPayment.completePaymentCounsel((res) => {
                                                    dispatch(dataPayment(null));
                                                    navigate(ConstantPath.PATH_COUNSEL_INVOICE, {
                                                        state: { [PAYMENTINFO_ORDER_ID]: compPayment.orderId }
                                                    });
                                                }, (res) => {
                                                    dispatch(popupShowDefault(t('alert_network_error'), () => {
                                                        dispatch(dataPayment(null));
                                                        navigate(ConstantPath.PATH_SOLUTION_REQUEST);
                                                    }));
                                                }, compPayment);
                                            }, (res) => {
                                                dispatch(popupShowDefault(t('alert_network_error')));
                                            }, selectInfo);
                                        }
                                        else {
                                            NetworkPayment.requestPaymentCounsel((res) => {
                                                var paymentInfo = res[PAYMENTINFO_CONTAINER];
                                                
                                                // 검증용 데이터 보관
                                                dispatch(dataPayment(paymentInfo));

                                                navigate(ConstantPath.PATH_PAYMENT_COUNSEL, {
                                                    state: { [PAYMENTINFO_CONTAINER]: paymentInfo }
                                                });
                                            }, (res) => {
                                                dispatch(popupShowDefault(t('alert_network_error')));
                                            }, selectInfo);
                                        }
                                    }}>
                                        {info.cell.getValue()}
                                    </Link>;
                        }
                        else {
                            return info.cell.getValue();
                        }
                    }
                    default: {
                        break;
                    }
                }
                return  <Link to={'/'}>
                            {info.cell.getValue()}
                        </Link>;
            }
        }
        
        return null;
    }

    return (
        <div className={"tab-pane show" + (props.defaultActive ? " active" : "")}
            id={props.tabId} >
            <div className="row">
                <PageTable
                    dataList={generateDataList()}
                    columnList={generateColumnList()}
                    fetchData={fetchData}
                    pageSize={10}
                    pageRangeDisplayed={5}
                    totalCount={totalCount}
                    renderCell={renderContentCell}/>
            </div>
        </div>
    );
}

export default RequestCounselView;