//
//  applyInfo.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 3..
//  Copyright © 2022년 Melephant. All rights reserved.
//


export const APPLYINFO_LIST             = 'tendency_info_list';
export const APPLYINFO_CONTAINER        = 'apply_info';
export const APPLYINFO_ID               = 'member_apply_idx';
export const APPLYINFO_MEMBER_ID        = 'member_id';
export const APPLYINFO_APPLY_STATUS     = 'apply_status';
export const APPLYINFO_REGDATE          = 'regdate';
export const APPLYINFO_ACCEPTDATE       = 'acceptdate';
export const APPLYINFO_CONFIRMDATE      = 'confirmdate';
export const APPLYINFO_REJECTDATE       = 'rejectdate';


/**
 * @protocol ApplyInfo
 * @date 2023/01/03
 * @brief 지원 정보
 */
export default class ApplyInfo {
    constructor() {
        this.applyId = '';
        this.memberId = '';
        this.applyStatus = '';
        this.regdate = null;
        this.acceptdate = null;
        this.confirmdate = null;
        this.rejectdate = null;
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(APPLYINFO_ID) ) {
            this.applyId = dict[APPLYINFO_ID];
        }

        if( dict.hasOwnProperty(APPLYINFO_MEMBER_ID) ) {
            this.memberId = dict[APPLYINFO_MEMBER_ID];
        }

        if( dict.hasOwnProperty(APPLYINFO_APPLY_STATUS) ) {
            this.applyStatus = dict[APPLYINFO_APPLY_STATUS];
        }

        if( dict.hasOwnProperty(APPLYINFO_REGDATE) ) {
            if( dict[APPLYINFO_REGDATE] ) {
                this.regdate = new Date(dict[APPLYINFO_REGDATE]);
            }
        }

        if( dict.hasOwnProperty(APPLYINFO_ACCEPTDATE) ) {
            if( dict[APPLYINFO_ACCEPTDATE] ) {
                this.acceptdate = new Date(dict[APPLYINFO_ACCEPTDATE]);
            }
        }

        if( dict.hasOwnProperty(APPLYINFO_CONFIRMDATE) ) {
            if( dict[APPLYINFO_CONFIRMDATE] ) {
                this.confirmdate = new Date(dict[APPLYINFO_CONFIRMDATE]);
            }
        }

        if( dict.hasOwnProperty(APPLYINFO_REJECTDATE) ) {
            if( dict[APPLYINFO_REJECTDATE] ) {
                this.rejectdate = new Date(dict[APPLYINFO_REJECTDATE]);
            }
        }
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [APPLYINFO_ID]: this.applyId,
            [APPLYINFO_MEMBER_ID]: this.memberId,
            [APPLYINFO_APPLY_STATUS]: this.applyStatus,
            [APPLYINFO_REGDATE]: this.regdate,
            [APPLYINFO_ACCEPTDATE]: this.acceptdate,
            [APPLYINFO_CONFIRMDATE]: this.confirmdate,
            [APPLYINFO_REJECTDATE]: this.rejectdate,
        };
        return dict;
    }
}
