//
//  m2mReceiveView.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 15..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
} from 'react';

// common
import ConstantPath from 'constants/path';
import ConstantValue from 'constants/value';
import {
    CommonKey,
    TableKey,
} from 'constants/key';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkM2m from 'network/networkM2m';

// info
import {
    M2MINFO_LIST,
    M2MINFO_ID,
} from 'info/solution/m2mInfo';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';

// component
import PageTable from 'components/table/pageTable';

// package
import { Link } from 'react-router-dom';


/**
 * @protocol M2mReceiveView
 * @date 2022/12/15
 * @brief 사수해법 1:1문의 수신 목록 화면
 */
function M2mReceiveView(props) {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const user = useSelector(state => state.session.user);

    const [m2mList, setM2mList] = useState(null);
    const [totalCount, setTotalCount] = useState(0);

    // 데이터 요청 처리
    const fetchData = async (pageNum, pageSize) => {
        NetworkM2m.getProjectM2mReceiveList((res) => {
            setM2mList(res[M2MINFO_LIST]);
            setTotalCount(res[CommonKey.PAGE_TOTAL_COUNT]);
        }, (res) => {
            dispatch(popupShowDefault(t('alert_network_error')));
        }, user.memberId, pageNum, pageSize);
    };

    /**
     * 스케쥴 column 목록 생성
     * @returns {Array} 기수 column 목록
     */
    const generateM2mColumnList = () => {
        return [{
            id: TableKey.TABLE_CONTENT,
            name: t('word_m2m_content'),
            isClickable: true,
        }, {
            id: TableKey.TABLE_SENDER,
            name: t('word_m2m_sender'),
            isClickable: false,
        }, {
            id: TableKey.TABLE_SEND_DATE,
            name: t('word_m2m_send_date'),
            isClickable: false,
        }, {
            id: TableKey.TABLE_ANSWER_COUNT,
            name: t('word_m2m_answer_count'),
            isClickable: false,
        }];
    }

    /**
     * 스케쥴 데이터 목록 생성
     * @returns {Array} 기수 데이터 목록
     */
    const generateM2mDataList = () => {
        if( DataUtil.validateArray(m2mList) ) {
            var itemList = [];
            for( let i = 0; i < m2mList.length; i++ ) {
                itemList.push({
                    [TableKey.TABLE_CONTENT]: m2mList[i].contents.slice(0, ConstantValue.MAX_LENGTH_M2M_TABLE_CONTENT),
                    [TableKey.TABLE_SENDER]: m2mList[i].userInfo.nickname,
                    [TableKey.TABLE_SEND_DATE]: DataUtil.convertDateTimeToString(m2mList[i].regdate),
                    [TableKey.TABLE_ANSWER_COUNT]: m2mList[i].answerCount,
                });
            }
            return itemList;
        }
        return null;
    }

    /**
     * 테이블 셀 커텀 
     * @param {*} element 
     * @param {*} info 
     * @returns 
     */
    const renderContentCell = (element, info) => {
        if( DataUtil.validateArray(m2mList) ) {
            if( info.row.index < m2mList.length ) {
                var id = m2mList[info.row.index].m2mId;

                return  <Link to={ConstantPath.PATH_SOLUTION_QNA_DETAIL} state={{[M2MINFO_ID]: id}}>
                            {info.cell.getValue()}
                        </Link>;
            }
        }
        
        return null;
    }

    return (
        <div className={"tab-pane show" + (props.defaultActive ? " active" : "")}
            id={props.tabId} >
            <div className="row">
                <PageTable
                    dataList={generateM2mDataList()}
                    columnList={generateM2mColumnList()}
                    fetchData={fetchData}
                    pageSize={10}
                    pageRangeDisplayed={5}
                    totalCount={totalCount}
                    renderCell={renderContentCell}/> 
            </div>
        </div>
    );
}

export default M2mReceiveView;