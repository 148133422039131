//
//  defaultTable.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 28..
//  Copyright © 2022년 Melephant. All rights reserved.
//


import React, {
    useState,
    useEffect,
} from 'react';

// common
import DataUtil from 'util/dataUtil';

// package
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
    flexRender,
    getFilteredRowModel,
    getPaginationRowModel,
} from '@tanstack/react-table';
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';


/**
 * @protocol DefaultTable
 * @date 2022/11/28
 * @brief 게시판 객체
 */
export default function DefaultTable(props) {
    const [data, setData] = useState([...props.dataList]);

    useEffect(() => {
        if( JSON.stringify(props.dataList) !== data ) {
            setData(props.dataList);
        }
    }, [data, props.dataList]);

    const renderCell = (element, info) => {
        return  <Button className="button primary circle"
                    onClick={() => {
                        if( props.onCellPressed ) {
                            props.onCellPressed(info.row.index);
                        }
                    }}>
                    {info.cell.getValue()}
                </Button>};

    const columnHelper = createColumnHelper();
    var columns = [];
    
    if( DataUtil.validateArray(props.columnList) ) {
        props.columnList.forEach(element => {
            if( element.isClickable ) {
                columns.push(columnHelper.accessor(element.id, {
                    header: element.name,
                    cell: (info) => props.renderCell ? props.renderCell(element, info) : renderCell(element, info),
                }));
            }
            else {
                columns.push(columnHelper.accessor(element.id, { header: element.name }));
            }
        });
    }

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    return (
        <div className="p-2" style={{width: '100%'}}>
            <div className="h-2" />
            <Table className="table table-default table-striped">
                <thead>
                    {table.getHeaderGroups().map((headersGroup) => (
                    <tr key={headersGroup.id}>
                        {headersGroup.headers.map((header) => (
                            <th key={header.id}>
                                {header.isPlaceholder ? null : flexRender(
                                    header.column.columnDef.header, header.getContext()
                                )}
                            </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <td key={cell.id} style={{ wordBreak: 'break-all'}}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
