//
//  projectPaymentSuccessScreen.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 31..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React from 'react';

// common
import ConstantPath from 'constants/path';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { dataPayment } from 'redux/actions/dataAction';
import { popupShowDefault } from 'redux/actions/popupAction';

// network
import NetworkPayment from 'network/networkPayment';

// info
import PaymentInfo, {
	PAYMENTINFO_ORDER_ID,
} from 'info/payment/paymentInfo';

// component
import Layout from 'components/layout/layout';

// package
import { useNavigate } from 'react-router';


/**
 * @protocol ProjectPaymentSuccessScreen
 * @date 2023/01/31
 * @brief 팀플 결제 성공 화면
 */
function ProjectPaymentSuccessScreen(props) {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	const requestPayment = useSelector(state => state.data.payment);

	let orderId = new URL(window.location.href).searchParams.get("orderId");
	let paymentKey = new URL(window.location.href).searchParams.get("paymentKey");
	let amount = new URL(window.location.href).searchParams.get("amount");
	
	// 신청 비용과 결제 비용 확인
	if( Number(requestPayment.price) === Number(amount) ) {
		var reqInfo = new PaymentInfo();
		reqInfo.orderId = orderId;
		reqInfo.paymentKey = paymentKey;
		reqInfo.price = amount;
		
		// 결제 승인 요청
		NetworkPayment.confirmPaymentProject((res) => {
			var payment = new PaymentInfo();
			payment.orderId = res.orderId;
			payment.receipt = res.receipt.url;
			if( res.easypay && res.easypay.provider ) {
				payment.payType = res.easypay.provider;
			}
			if( res.card && res.card.cardType ) {
				payment.payType = res.card.cardType;
			}
			payment.paymentKey = res.paymentKey;
			payment.approveddate = DataUtil.convertDateToISOString(new Date(res.approvedAt));

			// 결제 완료 요청
			NetworkPayment.completePaymentProject((res) => {
				dispatch(dataPayment(null));
				navigate(ConstantPath.PATH_PROJECT_INVOICE, {
					state: { [PAYMENTINFO_ORDER_ID]: payment.orderId }
				});
			}, (res) => {
				dispatch(popupShowDefault(t('alert_network_error'), () => {
					dispatch(dataPayment(null));
					navigate(ConstantPath.PATH_SOLUTION_PROJECT);
				}));
			}, payment);
		}, (res) => {
			dispatch(popupShowDefault(t('alert_fail_confirm_payment'), () => {
				dispatch(dataPayment(null));
				navigate(ConstantPath.PATH_SOLUTION_PROJECT);
			}));
		}, reqInfo);
	}
	else {
		dispatch(popupShowDefault(t('alert_invalid_payment_request'), () => {
			dispatch(dataPayment(null));
			navigate(ConstantPath.PATH_SOLUTION_PROJECT);
		}));
	}

    return (
		<Layout
            visibleHeader={false}
            visibleFooter={false}
            visibleLoader={true}>
        </Layout>
    );
}

export default ProjectPaymentSuccessScreen;
