//
//  worryScreen.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 12..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
    useEffect,
} from 'react';

// common
import { CommonKey, CategoryTypeKey } from 'constants/key';
import ConstantPath from 'constants/path';
import ConstantValue from 'constants/value';

// util
import DataUtil from 'util/dataUtil';
import ListUtil from 'util/listUtil';

// info
import {
    CATEGORYINFO_LIST,
} from 'info/counsel/categoryInfo';

// string
import { useTranslation } from 'react-i18next';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault, popupShowDefaultCancel } from 'redux/actions/popupAction';

// network
import NetworkCounsel from 'network/networkCounsel';

// component
import Layout from 'components/layout/layout';

// package
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';


/**
 * @protocol WorryScreen
 * @date 2023/01/12
 * @brief 고민상담 화면
 */
function WorryScreen() {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    
    const user = useSelector(state => state.session.user);
    const isLogin = useSelector(state => state.session.isLogin);

    const [visibleLoader, setVisibleLoader] = useState(true);
    const [categoryAList, setCategoryAList] = useState(null);
    const [categoryBList, setCategoryBList] = useState(null);
    const [categoryCList, setCategoryCList] = useState(null);
    
    const [disposition, setDisposition] = useState("");
    const [mbti, setMBTI] = useState("");

    useEffect(() => {
        if( categoryAList === null ) {
            NetworkCounsel.getCategoryList((res) => {
                setCategoryAList(res[CATEGORYINFO_LIST]);
            }, () => {
                dispatch(popupShowDefault(t('alert_network_error')));
            }, CategoryTypeKey.CATEGORY_TYPE_1);
        }

        if( categoryBList === null ) {
            NetworkCounsel.getCategoryList((res) => {
                setCategoryBList(res[CATEGORYINFO_LIST]);
            }, () => {
                dispatch(popupShowDefault(t('alert_network_error')));
            }, CategoryTypeKey.CATEGORY_TYPE_2);
        }

        if( categoryCList === null ) {
            NetworkCounsel.getCategoryList((res) => {
                setCategoryCList(res[CATEGORYINFO_LIST]);
            }, () => {
                dispatch(popupShowDefault(t('alert_network_error')));
            }, CategoryTypeKey.CATEGORY_TYPE_3);
        }

        if( DataUtil.validateArray(categoryAList) && DataUtil.validateArray(categoryBList) && DataUtil.validateArray(categoryCList) && visibleLoader ) {
            setVisibleLoader(false);
        }
    }, [categoryAList, categoryBList, categoryCList, visibleLoader, t, dispatch]);

    const onMBTIPressed = ({ target: { value}}) => setMBTI(value);
    const onDispotionPressed = ({ target: { value }}) => setDisposition(value);

    /**
     * 다음 버튼 클릭시 호출
     * @param {*} event 이벤트
     */
    const onSubmitPressed = (event) => {
        event.preventDefault();

        // 로그인 체크 후, 서버에 신청 요청
        if( isLogin ) {
            var selectCategoryList = [];

            var selectCategoryACount = 0;
            var selectCategoryBCount = 0;

            // 선택한 데이터 서버에 올릴 형태로 변경
            for( let i = 0; i < categoryAList.length; i++ ) {
                if( categoryAList[i].isSelect ) {
                    selectCategoryACount += 1;
                    selectCategoryList.push(categoryAList[i].dictionaryForApply());
                }
            }

            for( let i = 0; i < categoryBList.length; i++ ) {
                if( categoryBList[i].isSelect ) {
                    selectCategoryBCount += 1;
                    selectCategoryList.push(categoryBList[i].dictionaryForApply());
                }
            }

            // 선택한 카테고리가 없을 때 팝업 표시
            if( selectCategoryACount === 0 ) {
                dispatch(popupShowDefault(t('alert_not_entered_select_category')));
                return;
            }

            if( selectCategoryBCount === 0 ) {
                dispatch(popupShowDefault(t('alert_not_entered_select_job')));
                return;
            }

            // c타입은 하위 그룹 검사
            var cMaxLength = Math.floor(categoryCList.length / 4);
            
            if( cMaxLength > 4 ) {
                cMaxLength = 4;
            }

            for( let i = 0; i < cMaxLength; i++ ) {
                let count = 0;

                for( let j = 0; j < 4; j++ ) {
                    let cIndex = i * 4 + j;
                    
                    if( cIndex < categoryCList.length ) {
                        if( categoryCList[cIndex].isSelect ) {
                            count += 1;
                            selectCategoryList.push(categoryCList[cIndex].dictionaryForApply());
                        }
                    }
                }
                
                // 그룹별 선택한 항목이 없으면, 팝업 표시
                if( count === 0 ) {
                    dispatch(popupShowDefault(t('alert_not_entered_select_keyword' + String(i + 1))));
                    return;
                }
            }

            // 성공시, 완료 팝업 후 초기 화면 이동
            NetworkCounsel.requestApplyCounsel((res) => {
                dispatch(popupShowDefault(t('alert_complete_apply_counsel'), () => {
                    navigate(ConstantPath.PATH_SOLUTION_COUNSEL);
                }))
            }, (res) => {
                dispatch(popupShowDefault(t('alert_network_error')));
            }, user.memberId, selectCategoryList, disposition, mbti);
        }
        else {
            dispatch(popupShowDefaultCancel(t('alert_not_login'), () => {
                setTimeout(() => {
                    navigate(ConstantPath.PATH_LOGIN, {
                        state: { [CommonKey.REDIRECT]: location.pathname }
                    });
                }, 100);
            }), null);
        }
    }

    /**
     * 항목 클릭시 호출
     * @param {*} event 이벤트
     */
    const onCategoryAPressed = (event) => {
        // 로그인 체크 후, 선택 처리
        if( isLogin ) {
            var index = categoryAList.findIndex(element => element.categoryId === Number(event.target.id));
        
            if( index >= 0 ) {
                var updateDataList = categoryAList.splice(0, categoryAList.length);

                // 선택 해제
                if( !event.target.checked ) {
                    updateDataList[index].isSelect = false;
                    setCategoryAList(updateDataList);
                }
                else {
                    // 선택 개수 체크 후, 선택 설정
                    var count = updateDataList.filter(element => element.isSelect === true).length;
                    if( count < ConstantValue.CATEGORY_CATEGORY_SELECT_LIMIT ) {
                        updateDataList[index].isSelect = true;
                        setCategoryAList(updateDataList);
                    }
                    else {
                        setCategoryAList(updateDataList);
                    }
                }
            }
        }
        else {
            dispatch(popupShowDefaultCancel(t('alert_not_login'), () => {
                setTimeout(() => {
                    navigate(ConstantPath.PATH_LOGIN, {
                        state: { [CommonKey.REDIRECT]: location.pathname }
                    });
                }, 100);
            }), null);
        }
    };

    /**
     * 항목 클릭시 호출
     * @param {*} event 이벤트
     */
    const onCategoryBPressed = (event) => {
        // 로그인 체크 후, 선택 처리
        if( isLogin ) {
            var index = categoryBList.findIndex(element => element.categoryId === Number(event.target.id));
        
            if( index >= 0 ) {
                var updateDataList = categoryBList.splice(0, categoryBList.length);

                // 선택 해제
                if( !event.target.checked ) {
                    updateDataList[index].isSelect = false;
                    setCategoryBList(updateDataList);
                }
                else {
                    // 선택 개수 체크 후, 선택 설정
                    var count = updateDataList.filter(element => element.isSelect === true).length;
                    if( count < ConstantValue.CATEGORY_JOB_SELECT_LIMIT ) {
                        updateDataList[index].isSelect = true;
                        setCategoryBList(updateDataList);
                    }
                    else {
                        setCategoryBList(updateDataList);
                    }
                }
            }
        }
        else {
            dispatch(popupShowDefaultCancel(t('alert_not_login'), () => {
                setTimeout(() => {
                    navigate(ConstantPath.PATH_LOGIN, {
                        state: { [CommonKey.REDIRECT]: location.pathname }
                    });
                }, 100);
            }), null);
        }
    };

    /**
     * 항목 클릭시 호출
     * @param {*} event 이벤트
     */
    const onCategoryCPressed = (event) => {
        // 로그인 체크 후, 선택 처리
        if( isLogin ) {
            var index = categoryCList.findIndex(element => element.categoryId === Number(event.target.id));
        
            if( index >= 0 ) {
                var updateDataList = categoryCList.splice(0, categoryCList.length);

                // 선택 해제
                if( !event.target.checked ) {
                    updateDataList[index].isSelect = false;
                    setCategoryCList(updateDataList);
                }
                else {
                    // 선택 개수 체크 후, 선택 설정
                    var groupIndex = Math.floor(index / 4);

                    var count = 0;

                    for( var i = groupIndex * 4; i < groupIndex * 4 + 4; i++ ) {
                        if( i < updateDataList.length ) {
                            if( updateDataList[i].isSelect ) {
                                count++;
                            }
                        }
                    }

                    if( count < ConstantValue.CATEGORY_KEYWORD_SELECT_LIMIT ) {
                        updateDataList[index].isSelect = true;
                        setCategoryCList(updateDataList);
                    }
                    else {
                        setCategoryCList(updateDataList);
                    }
                }
            }
        }
        else {
            dispatch(popupShowDefaultCancel(t('alert_not_login'), () => {
                setTimeout(() => {
                    navigate(ConstantPath.PATH_LOGIN, {
                        state: { [CommonKey.REDIRECT]: location.pathname }
                    });
                }, 100);
            }), null);
        }
    };

    /**
     * 카테고리 ui 목록 반환
     * @returns {Array} 카테고리 ui 목록
     */
    const renderCategoryAList = () => {
        if( DataUtil.validateArray(categoryAList) ) {
            var itemList = [];

            for( var i = 0; i < categoryAList.length; i++ ) {
                itemList.push(
                    <div key={"category" + String(i)}
                        className="col-lg-3 col-md-4 col-sm-4">
                        <div className="group-box">
                            {
                                DataUtil.validateString(categoryAList[i].icon) ?
                                <figure>
                                    <img alt="" src={categoryAList[i].icon}/>
                                </figure> :
                                null
                            }
                            <p>{categoryAList[i].name}</p>
                            <input type="checkbox"
                                id={categoryAList[i].categoryId}
                                multiple
                                checked={categoryAList[i].isSelect ? true : false}
                                onChange={onCategoryAPressed}/>
                            <label htmlFor={categoryAList[i].categoryId}>{t('button_select')}</label> 
                        </div>
                    </div>
                );
            }
            return itemList;
        }
    }

    /**
     * 카테고리 목록 반환
     * @returns {Array} 카테고리 목록
     */
    const renderCategoryBList = () => {
        if( DataUtil.validateArray(categoryBList) ) {
            var itemList = [];
            
            for( var i = 0; i < categoryBList.length; i++ ) {
                itemList.push(
                    <div key={"job" + String(i)}
                        className="col-lg-3 col-md-4 col-sm-4">
                        <div className="group-box">
                            <img src={categoryBList[i].icon} alt=""/>
                            <input type="checkbox"
                                id={categoryBList[i].categoryId}
                                multiple
                                checked={categoryBList[i].isSelect ? true : false}
                                onChange={onCategoryBPressed}/>
                            <label htmlFor={categoryBList[i].categoryId}>{t('button_select')}</label> 
                        </div>
                    </div>
                );
            }
            return itemList;
        }
        return null;
    }

    /**
     * 카테고리 하위 목록 반환
     * @param {Number} index C타입 카테고리 인덱스
     * @returns {Array} 카테고리 목록
     */
    const renderCategoryCSubList = (index) => {
        if( DataUtil.validateArray(categoryCList) ) {
            var itemList = [];
            
            for( var i = index * 4; i < index * 4 + 4; i++ ) {
                itemList.push(
                    <div key={"keyword_sub" + String(i)}
                        className="col-lg-3 col-md-4 col-sm-4">
                        <div className="group-box">
                            <input type="checkbox"
                                id={categoryCList[i].categoryId}
                                multiple
                                checked={categoryCList[i].isSelect ? true : false}
                                onChange={onCategoryCPressed}/>
                            <label htmlFor={categoryCList[i].categoryId}>{categoryCList[i].name}</label> 
                        </div>
                    </div>
                );
            }
            return itemList;
        }

        return null;
    }

    /**
     * 카테고리 목록 반환
     * @returns {Array} 카테고리 목록
     */
    const renderCategoryCList = () => {
        if( DataUtil.validateArray(categoryCList) ) {
            var itemList = [];
            
            var maxLength = Math.floor(categoryCList.length / 4);
            
            if( maxLength > 4 ) {
                maxLength = 4;
            }

            for( var i = 0; i < maxLength; i++ ) {
                var title = t('worry_keyword_title' + String(i + 1));
                
                itemList.push(
                    <div className="main-wraper"
                        key={"keyword" + String(i)}>
                        <h4 className="main-title">
                            {title}
                        </h4>
                        <div className="row col-xs-6">
                            {renderCategoryCSubList(i)}
                        </div>
                    </div>
                );
            }

            return itemList;
        }
        return null;
    }
    
    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}
            visibleLoader={visibleLoader}>
			<div className="gap" style={{textAlign: 'left'}}>
                <div className='container'>
                    <div className="col-lg-12">
                        <div className="main-wraper">
                            <h4 className="main-title">
                                {t('worry_category_title')}
                            </h4>
                            <div className="row col-xs-6">
                                {renderCategoryAList()}
                            </div>
                        </div>
                        <div className="main-wraper">
                            <h4 className="main-title">
                                {t('worry_job_title')}
                            </h4>
                            <div className="row col-xs-6">
                                {renderCategoryBList()}
                            </div>
                        </div>
                        {renderCategoryCList()}
                        <div className="main-wraper">
                            <h4 className="main-title">
                                {t('worry_dispotion_title')}
                            </h4>
                            <Form method="post" className='w-form' onSubmit={onSubmitPressed}>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <label>{t('input_dispotion')}</label>
                                        <select required
                                            onChange={onDispotionPressed}>
                                            {ListUtil.generateDispositionList()}
                                        </select>
                                    </div>
                                    <div className="col-lg-4">
                                        <label>{t('input_mbti')}</label>
                                        <select required
                                            onChange={onMBTIPressed}>
                                            {ListUtil.generateMBTIList()}
                                        </select>
                                    </div>
                                </div>
                                <Button type="submit" className="main-btn">{t('button_sumbit')}</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default WorryScreen;