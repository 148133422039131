//
//  counselIntroScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 9..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
} from 'react';

// common
import { CommonList } from 'constants/list';
import { CommonKey } from 'constants/key';
import ConstantPath from 'constants/path';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkSolution from 'network/networkSolution';

// info
import { PROJECTINFO_CONTAINER } from 'info/solution/projectInfo';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefaultCancel } from 'redux/actions/popupAction';

// component
import Layout from 'components/layout/layout';
import CounselRequestBar from 'components/bar/counselRequestBar';
import ProjectImageView from 'components/image/projectImageView';

// package
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";


/**
 * @protocol CounselIntroScreen
 * @date 2022/12/09
 * @brief 상담 소개 화면
 */
function CounselIntroScreen(props) {
    var params = useParams();
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    
    const isLogin = useSelector(state => state.session.isLogin);
    const user = useSelector(state => state.session.user);

    const [project, setProject] = useState(null);
    const [currentJob, setCurrentJob] = useState(null);
    const [tos, setTos] = useState(null);
    const [mentorReviewUrl, setMentorReviewUrl] = useState(null);
    const [reapply, setReapply] = useState(false);

    const jobList = CommonList.JobList();
    const tosList = CommonList.TermOfServiceList();
    const weekList = CommonList.WeekList();
    const dispositionList = CommonList.DispositionList();

    useEffect(() => {
        if( project === null ) {
            NetworkSolution.getCounselInfo((res) => {
                var projectInfo = res[PROJECTINFO_CONTAINER];
                
                if( projectInfo ) {
                    setProject(projectInfo);
                    
                    var jobCodeIndex = jobList.findIndex(element => element.id === projectInfo.mentor.currentJobCode);
                    if( jobCodeIndex >= 0 ) {
                        setCurrentJob(jobList[jobCodeIndex].title);
                    }
                    var tosIndex = tosList.findIndex(element => element.id === projectInfo.mentor.empPeriod);
                    if( tosIndex >= 0 ) {
                        setTos(tosList[tosIndex].title);
                    }

                    var path = ConstantPath.PATH_MENTOR_REVIEW.split("/");
                    var strippedPath = path.slice(0, path.length-1).join("/");
                    setMentorReviewUrl(strippedPath + '/' + projectInfo.mentor.memberId);
                }
                
                if( res[CommonKey.USER_EXIST] ) {
                    setReapply(res[CommonKey.USER_EXIST]);
                }
            }, (res) => {
            }, params.project_id, user ? user.memberId : null);
        }
        // 사용자 로그아웃시, 재신청 활성화 상태라면 비활성화 처리
        if( !user && reapply ) {
            setReapply(false);
        }
    }, [project, params.project_id, jobList, tosList, user, reapply]);

    /**
     * 신청 클릭시 호출
     * @param {Event} event 클릭 이벤트
     */
    const onRequestPressed = (event) => {
        event.preventDefault();

        // 로그인 체크 후, 화면 전환 처리
        if( isLogin ) {
            navigate(ConstantPath.PATH_COUNSEL_CONFIRM, {
                state: {
                    [PROJECTINFO_CONTAINER]: project,
                }
            });
        }
        else {
            dispatch(popupShowDefaultCancel(t('alert_not_login'), () => {
                setTimeout(() => {
                    navigate(ConstantPath.PATH_LOGIN, {
                        state: { [CommonKey.REDIRECT]: location.pathname }
                    });
                }, 100);
            }), null);
        }
    }

    /**
     * 스케쥴 목록 ui 렌더링
     * @returns {Array} 스케쥴 목록
     */
    const renderSchedule = () => {
        var itemList = [];
        
        if( DataUtil.validateArray(project.counselScheduleList) ) {
            var index = 0;
            project.counselScheduleList.forEach(element => {
                var weekIndex = weekList.findIndex(item => item.id === element.weekofday);
                itemList.push(  <div className="card"
                                    key={"schedule" + String(index)}>
                                    <div className="card-header" id="headingOne">
                                        <h5 className="mb-0">
                                            <p>{weekList[weekIndex].title + ' ' +
                                            element.start + '~' + element.end}</p>
                                        </h5>
                                    </div>
                                </div>);
                index++;
            });
        }
        return itemList;
    }

    /**
     * 사수 성향 ui 렌더링
     * @returns {Array} 사수 성향 목록
     */
    const renderTendencyCode = () => {
        var itemList = [];

        if( DataUtil.validateArray(project.mentor.tendencyList) ) {
            var i = 0;
            project.mentor.tendencyList.forEach(element => {
                var index = dispositionList.findIndex(item => item.id === element.tendencyCode);
                
                if( index >= 0 ){
                    itemList.push(<h6 key={"tendency" + String(i)}>{dispositionList[index].title}</h6>);
                }
                i++;
            })
        }

        return itemList;
    }

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}
            visibleSolutionHeader={true}
            existBottomBar={true}
            location={ConstantPath.PATH_SOLUTION_COUNSEL}>
			<div className="gap" style={{textAlign: 'left'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                project ?
                                <div id="page-contents" className="row merged20">
                                    <div className="col-lg-12">
                                        <div className="main-wraper">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="course-details">
                                                        <h4 style={{width: '100%'}}>{t('counsel_intro_category') + 
                                                        (project.categoryInfo && DataUtil.validateString(project.categoryInfo.name) ? ' ' + project.categoryInfo.name : '') +
                                                        (DataUtil.validateString(project.name) ? ' ' + project.name : '')}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-5">
                                                    <div className="course-video">
                                                        <ProjectImageView
                                                            maxWidth="100%"
                                                            imageUrl={project.gateImgUrl}/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-7 col-md-7">
                                                    <div className="course-description"
                                                        style={{
                                                            marginTop: '0px',
                                                            height: '100%',
                                                            paddingBottom: '50px'
                                                        }}>
                                                        <div style={{
                                                            height: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'space-between',
                                                        }}>
                                                            <h6>{project.mentor.nickname}</h6>
                                                            <div>
                                                                <h6 style={{
                                                                    whiteSpace: 'pre-wrap',
                                                                }}>{project.intro}</h6>
                                                            </div>
                                                            <h6>
                                                            {
                                                                Number(project.price) === 0 ?
                                                                t('word_free') :
                                                                t('word_currency') + DataUtil.numberWithCommas(Number(project.price))
                                                            }</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-5">
                                                    <div className="course-description">
                                                        <h4 className="main-title">{t('project_intro_mentor')}
                                                            <a className="view-all" href={mentorReviewUrl}>{t('project_intro_mentor_review')}</a>
                                                        </h4>
                                                        <div className="prod-stat">
                                                            <ul>
                                                                <li><span>{t('counsel_intro_intro')}:</span> {project.mentor.onelineIntro}</li>
                                                                <li><span>{t('counsel_intro_current_job')}:</span> {currentJob}</li>
                                                                <li><span>{t('counsel_intro_company')}:</span> {project.mentor.company}</li>
                                                                <li><span>{t('counsel_intro_tos')}:</span> {tos}</li>
                                                                <li><span>{t('counsel_intro_career')}:</span> {project.mentor.career}</li>
                                                                <li><br/></li>
                                                                <li><span>{t('counsel_intro_tendency')}:</span> {renderTendencyCode()}</li>
                                                                <li><span>{t('counsel_intro_mbti')}:</span> {project.mentor.mbtiCode}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-7 col-md-7">
                                                    <div className="course-description">
                                                        <h4 className="main-title">{t('project_intro_schedule')}</h4>
                                                        <div className="question-collaps">
                                                            <div id="accordion">
                                                                {renderSchedule()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <CounselRequestBar
                                        data={project}
                                        reapply={reapply}
                                        onRequestPressed={onRequestPressed}/>
                                </div> :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default CounselIntroScreen;