//
//  header.js
//  datatown
//
//  Created by Mumakil on 2022. 10. 14..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
} from 'react';

// common
import ConstantPath from 'constants/path';
import { CommonKey } from 'constants/key';
import { MenuList } from 'constants/list';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkUser from 'network/networkUser';

// info
import UserInfo, {
    USERINFO_CONTAINER,
} from 'info/user/userInfo';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';
import { sessionLogout, sessionLogin } from 'redux/actions/sessionAction';

// component
import SolutionHeader from 'components/layout/solutionHeader';

// package
import { Nav, Button } from 'react-bootstrap';
import { useLocation, useNavigate, Link } from "react-router-dom";


/**
 * @protocol Header
 * @date 2022/10/14
 * @brief 공통 상단 화면
 */
export default function Header(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const isLogin = useSelector(state => state.session.isLogin);
    const isMentor = useSelector(state => state.session.isMentor);
    const user = useSelector(state => state.session.user);
    
    const userMenuList = MenuList.UserMenuList();
    const mentorMenuList = MenuList.MentorMenuList();

    useEffect(() => {
        if( user === null ) {
            NetworkUser.getUserInfo((res) => {
                // 세션 정보 설정
                dispatch(sessionLogin(res[USERINFO_CONTAINER]));
            }, () => {
                dispatch(sessionLogout());
            });
        }
    }, [isLogin, user, dispatch]);

    /**
     * 로그아웃 클릭시 호출
     */
    const onLogoutPressed = () => {
        var userInfo = new UserInfo();
        userInfo.parseFromDictionary(user);
        
        NetworkUser.requestLogout(() => {
            dispatch(sessionLogout());
        }, () => {
            dispatch(popupShowDefault(t('alert_network_error')));
        }, userInfo);
    };

    /**
     * 메뉴 목록 반환
     * @returns {Array} 메뉴 목록
     */
    const renderMenuList = () => {
        var itemList = [];

        if( isMentor ) {
            for( let i = 0; i < mentorMenuList.length; i++ ) {
                itemList.push(  <li key={mentorMenuList[i].id}>
                                    <a href={mentorMenuList[i].link} title="">
                                        <div>
                                            <img src={mentorMenuList[i].icon} alt=""/>
                                        </div>
                                        {mentorMenuList[i].title}
                                    </a>
                                </li>);
            }
        }
        else {
            for( let i = 0; i < userMenuList.length; i++ ) {
                itemList.push(  <li key={userMenuList[i].id}>
                                    <a href={userMenuList[i].link} title="">
                                        <div>
                                            <img src={userMenuList[i].icon} alt=""/>
                                        </div>
                                        {userMenuList[i].title}
                                    </a>
                                </li>);
            }
        }
        return itemList;
    }

    /**
     * 사이드 메뉴 목록 반환
     * @returns {Array} 사이드 메뉴 목록
     */
    const renderSideMenuList = () => {
        var itemList = [];

        if( isMentor ) {
            for( let i = 0; i < mentorMenuList.length; i++ ) {
                itemList.push(<Nav.Link key={mentorMenuList[i].id} href={mentorMenuList[i].link}>{mentorMenuList[i].title}</Nav.Link>);
            }
        }
        else {
            for( let i = 0; i < userMenuList.length; i++ ) {
                itemList.push(<Nav.Link key={userMenuList[i].id} href={userMenuList[i].link}>{userMenuList[i].title}</Nav.Link>);
            }
        }
        return itemList;
    }

    return (
        <div>
            {/* 반응형 헤더 */}
            <div className="responsive-header">
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'}}>
                    <div className="logo res"
                        style={{display: 'flex'}}>
                        <Link to={ConstantPath.PATH_SOLUTION}>
                            <img src="assets/images/image/img_logo.png" alt="" style={{maxWidth: '50px'}} />
                        </Link>
                    </div>
                    {
                        user ?
                        <div className="user-dp">
                            <div>
                                <img alt="" src={user.deskImgUrl ? user.deskImgUrl : 'assets/images/image/img_user.jpg'}/>
                                <div className="name">
                                    <h4>{user.nickname}</h4>
                                </div>
                            </div>
                        </div> :
                        null
                    }
                    <div className="right-compact">
                        <div className="sidemenu">
                            <i>
                                <svg id="side-menu2" xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="26"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-menu">
                                    <line x1="3" y1="12" x2="21" y2="12"></line>
                                    <line x1="3" y1="6" x2="21" y2="6"></line>
                                    <line x1="3" y1="18" x2="21" y2="18"></line>
                                </svg>
                            </i>
                        </div>
                    </div>
                </div>
                {props.visibleSolutionHeader ? <SolutionHeader location={props.solutionLocation} responsive={true}/> : null}
            </div>
            {/* 기본 헤더 */}
            <header className="">
                <div className="topbar" style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'}}>
                    <div className="row" style={{display: 'inline-block'}}>
                        <div className="logo">
                            <Link to={ConstantPath.PATH_SOLUTION}>
                                <img src="assets/images/image/img_logo.png" alt="" style={{ marginLeft: '20px', maxWidth: '120px'}} />
                            </Link>
                        </div>
                        <div style={{display: 'inline-block', marginLeft: '20px'}}>
                            <Nav className="me-auto">
                            </Nav>
                        </div>
                    </div>
                    <ul className="web-elements">
                        <li>
                            <Nav className="me-auto">
                                <Nav.Link href={ConstantPath.PATH_SOLUTION}>{t('menu_solution')}</Nav.Link>
                                <Nav.Link href={ConstantPath.PATH_WORRY}>{t('menu_worry')}</Nav.Link>
                                {/* <Nav.Link href={ConstantPath.PATH_NFT}>{t('menu_nft')}</Nav.Link> */}
                                <Nav.Link href={ConstantPath.PATH_HELP}>{t('menu_help')}</Nav.Link>
                            </Nav>
                        </li>
                        <li>
                            {
                                isLogin ?
                                <div className="user-dp">
                                    <div>
                                        <img alt="" src={user && user.deskImgUrl ? user.deskImgUrl : 'assets/images/image/img_user.jpg'}/>
                                        <div className="name" style={{marginLeft: '10px'}}>
                                            <h4>{user ? user.nickname : ''}</h4>
                                        </div>
                                    </div>
                                    <ul className="profile-menu">
                                        {renderMenuList()}
                                        <li className="logout">
                                            <button onClick={onLogoutPressed}>
                                                <i className="icofont-power"> {t('button_logout')}</i>
                                            </button>
                                            {/* <a href={ConstantPath.PATH_HELP} title="">
                                                {t('menu_help')}
                                                <i className="icofont-thin-right" style={{color: '#1f273f'}}></i>
                                            </a> */}
                                        </li>
                                    </ul>
                                </div> :
                                <div>
                                    <Button className="main-btn"
                                        variant="primary"
                                        onClick={() => {
                                            navigate(ConstantPath.PATH_LOGIN, {
                                                state: { [CommonKey.REDIRECT]: location.pathname }
                                            });
                                        }}>
                                        {t('menu_login')}
                                    </Button>{' '}
                                </div>
                            }
                        </li>
                    </ul>
                </div>
                {props.visibleSolutionHeader ? <SolutionHeader location={props.solutionLocation} responsive={false}/> : null}
            </header>

            <nav className="sidebar">
                <ul className="menu-slide">
                    <li className="">
                        <Nav.Link href={ConstantPath.PATH_WORRY}>{t('menu_worry')}</Nav.Link>
                        <Nav.Link href={ConstantPath.PATH_SOLUTION}>{t('menu_solution')}</Nav.Link>
                        {/* <Nav.Link href={ConstantPath.PATH_NFT}>{t('menu_nft')}</Nav.Link> */}
                        <Nav.Link href={ConstantPath.PATH_HELP}>{t('menu_help')}</Nav.Link>
                        {renderSideMenuList()}
                        {
                            isLogin ?
                            <Nav.Link onClick={onLogoutPressed}>{t('button_logout')}</Nav.Link> :
                            <Nav.Link onClick={() => {
                                navigate(ConstantPath.PATH_LOGIN, {
                                    state: { [CommonKey.REDIRECT]: location.pathname }
                                });
                            }}>{t('menu_login')}</Nav.Link>
                        }
                    </li>
                </ul>
            </nav>
        </div>
    );
}
