//
//  persistReducer.js
//  datatown
//
//  Created by Mumakil on 2022. 8. 23..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// redux
import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";

// reducer
import SessionReducer from 'redux/reducers/sessionReducer';
import PopupReducer from 'redux/reducers/popupReducer';
import DataReducer from 'redux/reducers/dataReducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['session', 'popup', 'data']
};

export const rootReducer = combineReducers({
    session: SessionReducer,
    popup: PopupReducer,
    data: DataReducer,
});

export default persistReducer(persistConfig, rootReducer);
