//
//  projectM2mDetailScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 16..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
} from 'react';

// common
import { CommonKey } from 'constants/key';
import ConstantPath from 'constants/path';
import ConstantValue from 'constants/value';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkM2m from 'network/networkM2m';

// info
import {
    M2MINFO_CONTAINER,
    M2MINFO_ID,
} from 'info/solution/m2mInfo';
import M2mAnswerInfo, {
    M2MANSWERINFO_LIST,
} from 'info/solution/m2mAnswerInfo';

// style
import styles from 'css/screen/projectM2mDetailScreen.module.css';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault, popupShowDefaultCancel } from 'redux/actions/popupAction';

// component
import Layout from 'components/layout/layout';
import AnswerCell from 'components/cell/answerCell';

// package
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';


/**
 * @protocol ProjectM2mDetailScreen
 * @date 2022/12/12
 * @brief 팀프로젝트 후기 화면
 */
function ProjectM2mDetailScreen(props) {
    var params = useParams();
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    
    const isLogin = useSelector(state => state.session.isLogin);
    const user = useSelector(state => state.session.user);

    const [data, setData] = useState(null);
    const [answerList, setAnswerList] = useState(null);
    const [inputAnswer, setInputAnswer] = useState('');

    useEffect(() => {
        if( data === null ) {
            NetworkM2m.getProjectM2mInfo((res) => {
                setData(res[M2MINFO_CONTAINER]);
                setAnswerList(res[M2MANSWERINFO_LIST]);
            }, (res) => {
                dispatch(popupShowDefault(t('alert_network_error')));
            }, location.state[M2MINFO_ID]);
        }
    }, [dispatch, t, params, data, location.state]);

    const onInputAnswerChanged = ({ target: { value}}) => setInputAnswer(value);

    const onSubmitPressed = (event) => {
        event.preventDefault();

        // 로그인 체크 후, 등록 요청
        if( isLogin ) {
            var answerInfo = new M2mAnswerInfo();
            answerInfo.m2mId = data.m2mId;
            answerInfo.memberId = user.memberId;
            answerInfo.answer = inputAnswer;

            NetworkM2m.requestPostProjectM2mAnswer((rew) => {
                dispatch(popupShowDefault(t('alert_complete_register_answer'), () => {
                    window.location.reload(false);
                }));
            }, (res) => {
                dispatch(popupShowDefault(t('alert_network_error')));
            }, answerInfo);
        }
        else {
            dispatch(popupShowDefaultCancel(t('alert_not_login'), () => {
                setTimeout(() => {
                    navigate(ConstantPath.PATH_LOGIN, {
                        state: { [CommonKey.REDIRECT]: location.pathname }
                    });
                }, 100);
            }), null);
        }
    }

    /**
     * 리뷰 셀 ui 렌더링
     * @param {NoticeInfo} info 공지사항 정보
     * @returns {NoticeCell} 공지사항 셀
     */
    const renderAnswer = () => {
        var itemList = [];
        if( DataUtil.validateArray(answerList) ) {
            answerList.forEach(element => {
                itemList.push(  <div key={"answer" + String(element.m2mAnswerId)}
                                    style={{marginBottom: '30px'}}>
                                    <AnswerCell
                                        disableList={true}
                                        data={element}/>
                                </div>);
            });
        }
        return itemList;
    }

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}>
			<div className="gap" style={{textAlign: 'left'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                data ?
                                <div id="page-contents" className="row merged20">
                                    <div className="col-lg-12">
                                        <div className="main-wraper">
                                            <div className="row">
                                                <div className={styles.container}>
                                                    <div className={styles.header}>
                                                        <div className={styles.user}>
                                                            <img src={data.userInfo && data.userInfo.deskImgUrl ? data.userInfo.deskImgUrl : 'assets/images/image/img_user.jpg'} alt=""/>
                                                            <h4 className={styles.nickname}>{data.userInfo.nickname}</h4>
                                                            <h6 className={styles.date}>{DataUtil.convertDateTimeToString(data.regdate)}</h6>
                                                        </div>
                                                    </div>
                                                    <div className={styles.body}>
                                                        <p className={styles.contents}>{data.contents}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="create-by" style={{width: '100%'}}>
                                                        <Form className="c-form" onSubmit={onSubmitPressed}>
                                                            <div className="col-lg-12">
                                                                <textarea placeholder={t('input_answer')}
                                                                    rows="4"
                                                                    style={{backgroundColor: '#fff'}}
                                                                    className="uk-textarea"
                                                                    required
                                                                    value={inputAnswer}
                                                                    maxLength={ConstantValue.INPUT_MAX_LENGTH_ANSWER}
                                                                    onChange={onInputAnswerChanged}/>
                                                                <Button className="button primary circle" type="submit">
                                                                    {t('button_answer_send')}
                                                                </Button>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12"
                                                    style={{marginTop: '30px'}}>
                                                    {renderAnswer()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ProjectM2mDetailScreen;