//
//  commonUtil.js
//  datatown
//
//  Created by Mumakil on 2022. 10. 25..
//  Copyright © 2022년 Melephant. All rights reserved.
//


/**
 * @protocol CommonUtil
 * @date 2022/10/25
 * @brief 일반 도구
 */
 export default class CommonUtil {
    /**
     * 숫자 적합성 체크
     * @param {number} value 값
     * @returns {boolean} 적합성 여부
     */
    static generateKakaoAuthUri() {
        return `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URL}&response_type=code`;
    }
}
