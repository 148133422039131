//
//  helpNoticeListView.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 21..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
} from 'react';

// common
import {
    CommonKey,
    TableKey,
} from 'constants/key';
import ConstantPath from 'constants/path';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// redux
import { useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';

// network
import NetworkNotice from 'network/networkNotice';

// info
import {
    SERVICENOTICEINFO_LIST,
    SERVICENOTICEINFO_ID,
} from 'info/service/serviceNoticeInfo';

// component
import PageTable from 'components/table/pageTable';

// package
import { Link } from "react-router-dom";


/**
 * @protocol HelpNoticeListView
 * @date 2022/12/21
 * @brief 공지사항 리스트 화면
 */
function HelpNoticeListView(props) {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [noticeList, setNoticeList] = useState(null);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
    }, []);

    // 데이터 요청 처리
    const fetchData = async (pageNum, pageSize) => {
        NetworkNotice.getServiceNoticeList((res) => {
            setNoticeList(res[SERVICENOTICEINFO_LIST]);
            setTotalCount(res[CommonKey.PAGE_TOTAL_COUNT]);
        }, (res) => {
            dispatch(popupShowDefault(t('alert_network_error')));
        }, pageNum, pageSize);
    };

    /**
     * 공지사항 column 목록 생성
     * @returns {Array} 기수 column 목록
     */
    const generateNoticeColumnList = () => {
        return [{
            id: TableKey.TABLE_CATEGORY,
            name: t('word_notice_category'),
            isClickable: false,
        }, {
            id: TableKey.TABLE_SUBJECT,
            name: t('word_subject'),
            isClickable: true,
        }, {
            id: TableKey.TABLE_REGDATE,
            name: t('word_reg_date'),
            isClickable: false,
        }];
    }

    /**
     * 공지사항 데이터 목록 생성
     * @returns {Array} 기수 데이터 목록
     */
    const generateNoticeDataList = () => {
        if( DataUtil.validateArray(noticeList) ) {
            var itemList = [];
            for( let i = 0; i < noticeList.length; i++ ) {
                itemList.push({
                    [TableKey.TABLE_CATEGORY]: noticeList[i].category,
                    [TableKey.TABLE_SUBJECT]: noticeList[i].subject,
                    [TableKey.TABLE_REGDATE]: DataUtil.convertDateTimeToString(noticeList[i].regdate),
                });
            }
            return itemList;
        }
        return null;
    }

    /**
     * 테이블 셀 커텀 
     * @param {*} element 
     * @param {*} info 
     * @returns 
     */
    const renderContentCell = (element, info) => {
        if( DataUtil.validateArray(noticeList) ) {
            if( info.row.index < noticeList.length ) {
                var id = noticeList[info.row.index].noticeId;

                return  <Link to={ConstantPath.PATH_HELP_NOTICE_DETAIL} state={{[SERVICENOTICEINFO_ID]: id}}>
                            {info.cell.getValue()}
                        </Link>;
            }
        }
        
        return null;
    }

    return (
        <div className={"tab-pane fade show active"}>
            <div id="page-contents" className="row merged20">
                <div className="col-lg-12">
                    <div className="main-wraper">
                        <div className="tab-content" id="components-nav">
                            <PageTable
                                dataList={generateNoticeDataList()}
                                columnList={generateNoticeColumnList()}
                                fetchData={fetchData}
                                pageSize={10}
                                pageRangeDisplayed={5}
                                totalCount={totalCount}
                                renderCell={renderContentCell}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HelpNoticeListView;