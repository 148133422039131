//
//  networkAuth.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 16..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// network
import CommonNetwork from 'network/common/commonNetwork';

// info
import ResponseInfo, {
    RESPONSEINFO_CONTAINER,
} from 'info/common/responseInfo';

// package
import axios from 'axios';
axios.defaults.withCredentials = true;

const NETWORK_PATH_SEND_EMAIL       = '/send_email';
const NETWORK_PATH_AUTH_CODE        = '/auth_code';

const NETWORK_PARAM_EMAIL       = 'email';
const NETWORK_PARAM_CERT_NUM    = 'cert_num';


/**
 * @protocol NetworkAuth
 * @date 2022/11/16
 * @brief 인증 관련 통신
 */
export default class NetworkAuth {
    /**
     * 인증 이메일 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} email 수신 이메일
     */
    static requestSendEmail = async (resSuccess, resFail, email) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_SEND_EMAIL]);
        
        try {
            await axios.post(url, { email: email }).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 인증 코드 확인
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} email 수신 이메일
     * @param {String} authCode 인증 코드
     */
    static requestAuthCode = async (resSuccess, resFail, email, authCode) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_AUTH_CODE])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_EMAIL]: email,
            [NETWORK_PARAM_CERT_NUM]: authCode,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }
}
