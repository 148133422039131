//
//  projectInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 8..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// network
import { listParseFromDictionary } from 'info/listParse';

// info
import UserInfo from "info/user/userInfo";
import ScheduleInfo, {
    SCHEDULEINFO_LIST,
} from "info/solution/scheduleInfo";
import OrdinalInfo, {
    ORDINALINFO_LIST,
    ORDINALINFO_CONTAINER,
} from "info/solution/ordinalInfo";
import ProjectReviewInfo, {
    PROJECTREVIEWINFO_REVIEW_LIST,
} from 'info/solution/projectReviewInfo';
import CategoryInfo from 'info/counsel/categoryInfo';
import CounselScheduleInfo, {
    COUNSELSCHEDULEINFO_LIST,
} from 'info/solution/counselScheduleInfo';

export const PROJECTINFO_LIST           = 'project_info_list';
export const PROJECTINFO_CONTAINER      = 'project_info';
export const PROJECTINFO_ID             = 'project_id';
export const PROJECTINFO_NAME           = 'project_name';
export const PROJECTINFO_INTRO          = 'project_intro';
export const PROJECTINFO_DESCRIPTION    = 'project_description';
export const PROJECTINFO_GOAL           = 'project_goal';
export const PROJECTINFO_GATE_IMAGE_URL = 'gate_image_url';
export const PROJECTINFO_PRICE          = 'price';
export const PROJECTINFO_WEEKS          = 'weeks';
export const PROJECTINFO_STATUS         = 'project_status';
export const PROJECTINFO_QUOTA_PERSON   = 'quota_person';
export const PROJECTINFO_TYPE           = 'project_type';
export const PROJECTINFO_REGDATE        = 'regdate';
export const PROJECTINFO_CONFIRMDATE    = 'confirmdate';


/**
 * @protocol ProjectInfo
 * @date 2022/11/08
 * @brief 프로젝트 정보
 */
export default class ProjectInfo {
    constructor() {
        this.projectId = '';
        this.name = '';
        this.intro = '';
        this.description = '';
        this.goal = '';
        this.gateImgUrl = '';
        this.price = '';
        this.weeks = '';
        this.status = '';
        this.quotaPerson = 0;
        this.type = '';
        this.mentor = new UserInfo();
        this.ordinalInfo = new OrdinalInfo();
        this.categoryInfo = new CategoryInfo();
        this.scheduleList = [];
        this.ordinalList = [];
        this.reviewList = [];
        this.counselScheduleList = [];
        this.regdate = null;
        this.confirmdate = null;
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(PROJECTINFO_ID) ) {
            this.projectId = dict[PROJECTINFO_ID];
        }

        if( dict.hasOwnProperty(PROJECTINFO_NAME) ) {
            this.name = dict[PROJECTINFO_NAME];
        }

        if( dict.hasOwnProperty(PROJECTINFO_INTRO) ) {
            this.intro = dict[PROJECTINFO_INTRO];
        }

        if( dict.hasOwnProperty(PROJECTINFO_DESCRIPTION) ) {
            this.description = dict[PROJECTINFO_DESCRIPTION];
        }

        if( dict.hasOwnProperty(PROJECTINFO_GOAL) ) {
            this.goal = dict[PROJECTINFO_GOAL];
        }

        if( dict.hasOwnProperty(PROJECTINFO_GATE_IMAGE_URL) ) {
            this.gateImgUrl = dict[PROJECTINFO_GATE_IMAGE_URL];
        }

        if( dict.hasOwnProperty(PROJECTINFO_PRICE) ) {
            this.price = dict[PROJECTINFO_PRICE];
        }

        if( dict.hasOwnProperty(PROJECTINFO_WEEKS) ) {
            this.weeks = dict[PROJECTINFO_WEEKS];
        }

        if( dict.hasOwnProperty(PROJECTINFO_STATUS) ) {
            this.status = dict[PROJECTINFO_STATUS];
        }

        if( dict.hasOwnProperty(PROJECTINFO_QUOTA_PERSON) ) {
            this.quotaPerson = Number(dict[PROJECTINFO_QUOTA_PERSON]);
        }

        if( dict.hasOwnProperty(PROJECTINFO_TYPE) ) {
            this.type = dict[PROJECTINFO_TYPE];
        }

        this.mentor.parseFromDictionary(dict);
        this.categoryInfo.parseFromDictionary(dict);
        
        if( dict.hasOwnProperty(ORDINALINFO_CONTAINER) ) {
            this.ordinalInfo.parseFromDictionary(dict[ORDINALINFO_CONTAINER]);
        }

        if( dict.hasOwnProperty(SCHEDULEINFO_LIST) ) {
            this.scheduleList = listParseFromDictionary(ScheduleInfo, dict[SCHEDULEINFO_LIST], '');
        }

        if( dict.hasOwnProperty(ORDINALINFO_LIST) ) {
            this.ordinalList = listParseFromDictionary(OrdinalInfo, dict[ORDINALINFO_LIST], '');
        }

        if( dict.hasOwnProperty(PROJECTREVIEWINFO_REVIEW_LIST) ) {
            this.reviewList = listParseFromDictionary(ProjectReviewInfo, dict[PROJECTREVIEWINFO_REVIEW_LIST], '');
        }

        if( dict.hasOwnProperty(COUNSELSCHEDULEINFO_LIST) ) {
            this.counselScheduleList = listParseFromDictionary(CounselScheduleInfo, dict[COUNSELSCHEDULEINFO_LIST], '');
        }

        if( dict.hasOwnProperty(PROJECTINFO_REGDATE) ) {
            this.regdate = new Date(dict[PROJECTINFO_REGDATE]);
        }

        if( dict.hasOwnProperty(PROJECTINFO_CONFIRMDATE) ) {
            if( dict[PROJECTINFO_CONFIRMDATE] ) {
                this.confirmdate = new Date(dict[PROJECTINFO_CONFIRMDATE]);
            }
        }
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [PROJECTINFO_ID]: this.projectId,
            [PROJECTINFO_NAME]: this.name,
            [PROJECTINFO_INTRO]: this.intro,
            [PROJECTINFO_DESCRIPTION]: this.description,
            [PROJECTINFO_GOAL]: this.goal,
            [PROJECTINFO_GATE_IMAGE_URL]: this.gateImgUrl,
            [PROJECTINFO_PRICE]: this.price,
            [PROJECTINFO_WEEKS]: this.weeks,
            [PROJECTINFO_STATUS]: this.status,
            [PROJECTINFO_QUOTA_PERSON]: this.quotaPerson,
            [PROJECTINFO_TYPE]: this.type,
            [PROJECTINFO_REGDATE]: this.regdate,
            [PROJECTINFO_CONFIRMDATE]: this.confirmdate,
        };
        return dict;
    }
}
