//
//  networkPayment.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 30..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// common
import { CommonKey } from 'constants/key';

// network
import CommonNetwork from 'network/common/commonNetwork';

// info
import ResponseInfo, {
    RESPONSEINFO_CONTAINER,
} from 'info/common/responseInfo';
import ProjectPaymentInfo, {
    PROJECTPAYMENTINFO_CONTAINER,
} from 'info/payment/projectPaymentInfo';
import PaymentInfo, {
    PAYMENTINFO_CONTAINER,
    PAYMENTINFO_LIST,
} from 'info/payment/paymentInfo';
import CounselApplyInfo, {
    COUNSELAPPLYINFO_CONTAINER,
} from 'info/solution/counselApplyInfo';
import ProjectInfo, {
    PROJECTINFO_CONTAINER,
} from 'info/solution/projectInfo';
import { listParseFromDictionary } from 'info/listParse';

// package
import axios from 'axios';
axios.defaults.withCredentials = true;

const NETWORK_PATH_GET_SALES_PROJECT_LIST       = '/get_sales_project_list';
const NETWORK_PATH_GET_SALES_COUNSEL_LIST       = '/get_sales_counsel_list';
const NETWORK_PATH_GET_PROJECT_PAYMENT_INFO     = '/get_project_payment_info';
const NETWORK_PATH_GET_COUNSEL_PAYMENT_INFO     = '/get_counsel_payment_info';
const NETWORK_PATH_REQUEST_PAYMENT_PROJECT      = '/request_payment_project';
const NETWORK_PATH_REQUEST_PAYMENT_COUNSEL      = '/request_payment_counsel';
const NETWORK_PATH_REQUEST_PAYMENT_FREE_COUNSEL = '/request_payment_free_counsel';
const NETWORK_PATH_COMPLETE_PAYMENT_PROJECT     = '/complete_payment_project';
const NETWORK_PATH_COMPLETE_PAYMENT_COUNSEL     = '/complete_payment_counsel';

const NETWORK_PARAM_ORDER_ID    = 'order_id';
const NETWORK_PARAM_MEMBER_ID   = 'member_id';
const NETWORK_PARAM_PAGE_NUM    = 'page_num';
const NETWORK_PARAM_PAGE_SIZE   = 'page_size';


/**
 * @protocol NetworkPayment
 * @date 2022/11/30
 * @brief 결제 관련 통신
 */
export default class NetworkPayment {
    /**
     * 팀프로젝트 결제 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {ProjectApplyInfo} applyInfo 결제 정보
     */
    static requestPaymentProject = async (resSuccess, resFail, applyInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_REQUEST_PAYMENT_PROJECT]);
        
        try {
            await axios.post(url, applyInfo.dictionaryForPayment()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                if( responseInfo.data ) {
                    var paymentInfo = new PaymentInfo();
                    paymentInfo.parseFromDictionary(responseInfo.data);

                    resSuccess({
                        [RESPONSEINFO_CONTAINER]: responseInfo,
                        [PAYMENTINFO_CONTAINER]: paymentInfo,
                    });
                }
                else {
                    resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
                }
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 상담 결제 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {CounselApplyInfo} applyInfo 신청 정보
     */
    static requestPaymentCounsel = async (resSuccess, resFail, applyInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_REQUEST_PAYMENT_COUNSEL]);

        try {
            await axios.post(url, applyInfo.dictionaryForPayment()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                if( responseInfo.data ) {
                    var paymentInfo = new PaymentInfo();
                    paymentInfo.parseFromDictionary(responseInfo.data);

                    resSuccess({
                        [RESPONSEINFO_CONTAINER]: responseInfo,
                        [PAYMENTINFO_CONTAINER]: paymentInfo,
                    });
                }
                else {
                    resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
                }
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 무료 상담 결제 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {CounselApplyInfo} applyInfo 신청 정보
     */
    static requestPaymentFreeCounsel = async (resSuccess, resFail, applyInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_REQUEST_PAYMENT_FREE_COUNSEL]);

        try {
            await axios.post(url, applyInfo.dictionaryForPayment()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 팀플 결제 완료 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {PaymentInfo} paymentInfo 결제 정보
     */
    static completePaymentProject = async (resSuccess, resFail, paymentInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_COMPLETE_PAYMENT_PROJECT]);

        try {
            await axios.post(url, paymentInfo.dictionaryForComplete()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 상담 결제 완료 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {PaymentInfo} paymentInfo 결제 정보
     */
    static completePaymentCounsel = async (resSuccess, resFail, paymentInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_COMPLETE_PAYMENT_COUNSEL]);

        try {
            await axios.post(url, paymentInfo.dictionaryForComplete()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 결제 정보 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} orderId 주문 id
     */
    static getProjectPaymentInfo = async (resSuccess, resFail, orderId) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_PROJECT_PAYMENT_INFO])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_ORDER_ID]: orderId,
        });
        
        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                if( responseInfo.data ) {
                    var paymentInfo = new PaymentInfo();
                    var applyInfo = new ProjectPaymentInfo();

                    if( responseInfo.data.hasOwnProperty(PAYMENTINFO_CONTAINER) ) {
                        paymentInfo.parseFromDictionary(responseInfo.data[PAYMENTINFO_CONTAINER]);
                    }

                    if( responseInfo.data.hasOwnProperty(PROJECTPAYMENTINFO_CONTAINER) ) {
                        applyInfo.parseFromDictionary(responseInfo.data[PROJECTPAYMENTINFO_CONTAINER]);
                    }

                    resSuccess({
                        [PAYMENTINFO_CONTAINER]: paymentInfo,
                        [PROJECTPAYMENTINFO_CONTAINER]: applyInfo,
                    });
                }
                else {
                    resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
                }
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 결제 정보 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} orderId 주문 id
     */
    static getCounselPaymentInfo = async (resSuccess, resFail, orderId) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_COUNSEL_PAYMENT_INFO])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_ORDER_ID]: orderId,
        });
        
        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                if( responseInfo.data ) {
                    var paymentInfo = new PaymentInfo();
                    var projectInfo = new ProjectInfo();
                    var applyInfo = new CounselApplyInfo();

                    if( responseInfo.data.hasOwnProperty(PAYMENTINFO_CONTAINER) ) {    
                        paymentInfo.parseFromDictionary(responseInfo.data[PAYMENTINFO_CONTAINER]);
                    }

                    if( responseInfo.data.hasOwnProperty(PROJECTINFO_CONTAINER) ) {    
                        projectInfo.parseFromDictionary(responseInfo.data[PROJECTINFO_CONTAINER]);
                    }

                    if( responseInfo.data.hasOwnProperty(COUNSELAPPLYINFO_CONTAINER) ) {    
                        applyInfo.parseFromDictionary(responseInfo.data[COUNSELAPPLYINFO_CONTAINER]);
                    }

                    resSuccess({
                        [PAYMENTINFO_CONTAINER]: paymentInfo,
                        [PROJECTINFO_CONTAINER]: projectInfo,
                        [COUNSELAPPLYINFO_CONTAINER]: applyInfo,
                    });
                }
                else {
                    resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
                }
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 팀플 결제 완료 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {PaymentInfo} paymentInfo 결제 정보
     */
    static confirmPaymentProject = async (resSuccess, resFail, paymentInfo) => {
        try {
            var options = {
                headers: {
                    Authorization: 'Basic ' + btoa(process.env.REACT_APP_TOSS_PAYMENT_SECRET_KEY + ':'),
                    'Content-Type': 'application/json'
                },
            };
            await axios.post(process.env.REACT_APP_TOSS_PAYMENT_CONFIRM_URL, {
                paymentKey: paymentInfo.paymentKey,
                amount: Number(paymentInfo.price),
                orderId: paymentInfo.orderId
            }, options).then((res) => {
                resSuccess(res.data);
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 상담 결제 완료 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {PaymentInfo} paymentInfo 결제 정보
     */
    static confirmPaymentCounsel = async (resSuccess, resFail, paymentInfo) => {
        try {
            var options = {
                headers: {
                    Authorization: 'Basic ' + btoa(process.env.REACT_APP_TOSS_PAYMENT_SECRET_KEY + ':'),
                    'Content-Type': 'application/json'
                },
            };
            await axios.post(process.env.REACT_APP_TOSS_PAYMENT_CONFIRM_URL, {
                paymentKey: paymentInfo.paymentKey,
                amount: Number(paymentInfo.price),
                orderId: paymentInfo.orderId
            }, options).then((res) => {
                resSuccess(res.data);
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 팀프로젝트 매출 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} memberId 멤버 id
     * @param {Number} pageNum 페이지 번호
     * @param {Number} pageSize 페이지 크기
     */
    static getSalesProjectList = async (resSuccess, resFail, memberId, pageNum, pageSize) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_SALES_PROJECT_LIST])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_MEMBER_ID]: memberId,
            [NETWORK_PARAM_PAGE_NUM]: pageNum,
            [NETWORK_PARAM_PAGE_SIZE]: pageSize,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                var paymentList = [];
                var totalCount = 0;

                if( responseInfo.data.hasOwnProperty(PAYMENTINFO_LIST) ) {
                    paymentList = listParseFromDictionary(PaymentInfo, responseInfo.data[PAYMENTINFO_LIST], '');
                }

                if( responseInfo.data.hasOwnProperty(CommonKey.PAGE_TOTAL_COUNT) ) {
                    totalCount = responseInfo.data[CommonKey.PAGE_TOTAL_COUNT];
                }

                resSuccess({
                    [PAYMENTINFO_LIST]: paymentList,
                    [CommonKey.PAGE_TOTAL_COUNT]: totalCount,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 상담 매출 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} memberId 멤버 id
     * @param {Number} pageNum 페이지 번호
     * @param {Number} pageSize 페이지 크기
     */
    static getSalesCounselList = async (resSuccess, resFail, memberId, pageNum, pageSize) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_SALES_COUNSEL_LIST])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_MEMBER_ID]: memberId,
            [NETWORK_PARAM_PAGE_NUM]: pageNum,
            [NETWORK_PARAM_PAGE_SIZE]: pageSize,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                var paymentList = [];
                var totalCount = 0;

                if( responseInfo.data.hasOwnProperty(PAYMENTINFO_LIST) ) {
                    paymentList = listParseFromDictionary(PaymentInfo, responseInfo.data[PAYMENTINFO_LIST], '');
                }

                if( responseInfo.data.hasOwnProperty(CommonKey.PAGE_TOTAL_COUNT) ) {
                    totalCount = responseInfo.data[CommonKey.PAGE_TOTAL_COUNT];
                }

                resSuccess({
                    [PAYMENTINFO_LIST]: paymentList,
                    [CommonKey.PAGE_TOTAL_COUNT]: totalCount,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }
}
