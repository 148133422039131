//
//  ordinalInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 28..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// network
import { listParseFromDictionary } from 'info/listParse';

// info
import NoticeInfo, {
    NOTICEINFO_LIST,
} from "info/solution/noticeInfo";
import AttendanceInfo, {
    ATTENDANCEINFO_LIST,
} from "info/solution/attendanceInfo";
import OrdinalScheduleInfo, {
    ORDINALSCHEDULEINFO_LIST,
} from 'info/solution/ordinalScheduleInfo';
import UserInfo, {
    USERINFO_LIST,
} from 'info/user/userInfo';

export const ORDINALINFO_LIST           = 'ordinal_list';
export const ORDINALINFO_CONTAINER      = 'ordinal_info';
export const ORDINALINFO_PROJECT_ID     = 'project_id';
export const ORDINALINFO_ORDINAL        = 'ordinal';
export const ORDINALINFO_STARTDATE      = 'startdate';
export const ORDINALINFO_ENDDATE        = 'enddate';
export const ORDINALINFO_QUOTA_PERSON   = 'quota_person';
export const ORDINALINFO_GATERTOWN_URL  = 'gatertown_url';
export const ORDINALINFO_OPENCHAT_URL   = 'openchat_url';
export const ORDINALINFO_ORDINAL_STATUS = 'ordinal_status';


/**
 * @protocol OrdinalInfo
 * @date 2022/11/28
 * @brief 프로젝트 기수 정보
 */
export default class OrdinalInfo {
    constructor() {
        this.projectId = '';
        this.ordinal = '';
        this.startdate = null;
        this.enddate = null;
        this.quotaPersion = 0;
        this.gatertownUrl ='';
        this.openchatUrl = '';
        this.ordinalStatus = '';

        this.noticeList = [];
        this.attendanceList = [];
        this.scheduleList = [];
        this.memberList = [];
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(ORDINALINFO_PROJECT_ID) ) {
            this.projectId = dict[ORDINALINFO_PROJECT_ID];
        }

        if( dict.hasOwnProperty(ORDINALINFO_ORDINAL) ) {
            this.ordinal = dict[ORDINALINFO_ORDINAL];
        }

        if( dict.hasOwnProperty(ORDINALINFO_STARTDATE) ) {
            this.startdate = new Date(dict[ORDINALINFO_STARTDATE]);
        }

        if( dict.hasOwnProperty(ORDINALINFO_ENDDATE) ) {
            this.enddate = new Date(dict[ORDINALINFO_ENDDATE]);
        }

        if( dict.hasOwnProperty(ORDINALINFO_QUOTA_PERSON) ) {
            this.quotaPersion = dict[ORDINALINFO_QUOTA_PERSON];
        }

        if( dict.hasOwnProperty(ORDINALINFO_GATERTOWN_URL) ) {
            this.gatertownUrl = dict[ORDINALINFO_GATERTOWN_URL];
        }

        if( dict.hasOwnProperty(ORDINALINFO_OPENCHAT_URL) ) {
            this.openchatUrl = dict[ORDINALINFO_OPENCHAT_URL];
        }

        if( dict.hasOwnProperty(ORDINALINFO_ORDINAL_STATUS) ) {
            this.ordinalStatus = dict[ORDINALINFO_ORDINAL_STATUS];
        }

        if( dict.hasOwnProperty(USERINFO_LIST) ) {
            this.memberList = listParseFromDictionary(UserInfo, dict[USERINFO_LIST], '');
        }

        if( dict.hasOwnProperty(NOTICEINFO_LIST) ) {
            this.noticeList = listParseFromDictionary(NoticeInfo, dict[NOTICEINFO_LIST], '');
        }

        if( dict.hasOwnProperty(ATTENDANCEINFO_LIST) ) {
            this.attendanceList = listParseFromDictionary(AttendanceInfo, dict[ATTENDANCEINFO_LIST], '');
        }

        if( dict.hasOwnProperty(ORDINALSCHEDULEINFO_LIST) ) {
            this.scheduleList = listParseFromDictionary(OrdinalScheduleInfo, dict[ORDINALSCHEDULEINFO_LIST], '');
        }
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [ORDINALINFO_PROJECT_ID]: this.projectId,
            [ORDINALINFO_ORDINAL]: this.ordinal,
            [ORDINALINFO_STARTDATE]: this.startdate,
            [ORDINALINFO_ENDDATE]: this.enddate,
            [ORDINALINFO_QUOTA_PERSON]: this.quotaPersion,
            [ORDINALINFO_GATERTOWN_URL]: this.gatertownUrl,
            [ORDINALINFO_OPENCHAT_URL]: this.openchatUrl,
            [ORDINALINFO_ORDINAL_STATUS]: this.ordinalStatus,
        };
        return dict;
    }

    /**
     * 게더타운 주소 변경에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForUpdateGatertownUrl() {
        var dict = {
            [ORDINALINFO_PROJECT_ID]: this.projectId,
            [ORDINALINFO_ORDINAL]: this.ordinal,
            [ORDINALINFO_GATERTOWN_URL]: this.gatertownUrl,
        };
        return dict;
    }

    /**
     * 오픈챗 주소 변경에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForUpdateOpenchatUrl() {
        var dict = {
            [ORDINALINFO_PROJECT_ID]: this.projectId,
            [ORDINALINFO_ORDINAL]: this.ordinal,
            [ORDINALINFO_OPENCHAT_URL]: this.openchatUrl,
        };
        return dict;
    }
}
