//
//  projectNoticeScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 2..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
} from 'react';

// common
import ConstantPath from 'constants/path';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkNotice from 'network/networkNotice';
import NetworkStatus from 'network/common/networkStatus';

// info
import NoticeInfo, {
    NOTICEINFO_CONTAINER,
} from 'info/solution/noticeInfo';
import { RESPONSEINFO_CONTAINER } from 'info/common/responseInfo';

// style
import styles from 'css/screen/projectNoticeDetailScreen.module.css';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';

// component
import Layout from 'components/layout/layout';

// package
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";


/**
 * @protocol ProjectNoticeScreen
 * @date 2022/12/2
 * @brief 팀프로젝트 공지사항 화면
 */
function ProjectNoticeDetailScreen(props) {
    var params = useParams();
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    
    const isLogin = useSelector(state => state.session.isLogin);
    const user = useSelector(state => state.session.user);

    const [data, setData] = useState(null);

    useEffect(() => {
        if( data === null ) {
            NetworkNotice.getProjectNoticeInfo((res) => {
                setData(res[NOTICEINFO_CONTAINER]);
            }, (res) => {
                dispatch(popupShowDefault(t('alert_network_error')));
            }, params.notice_id)
        }
    }, [dispatch, t, params, data]);

    /**
     * 목록 클릭시 호출
     */
    const onListPressed = () => {
        var path = location.pathname.split("/");
        var strippedPath = path.slice(0, path.length-1).join("/");
        navigate(strippedPath);
    }

    /**
     * 삭제 클릭시 호출
     */
    const onDeletePressed = () => {
        var noticeInfo = new NoticeInfo();
        noticeInfo.noticeId = data.noticeId;
        noticeInfo.memberId = data.memberId;

        NetworkNotice.requestDeleteNoticeInfo((res) => {
            dispatch(popupShowDefault(t('alert_complete_delete_notice'), () => {
                onListPressed();
            }));
        }, (res) => {
            var response = res[RESPONSEINFO_CONTAINER];
            if( response.code === NetworkStatus.NOT_AUTHORIZED ) {
                dispatch(popupShowDefault(t('alert_not_authorized_register_notice')));
            }
            else if( response.code === NetworkStatus.NOT_FOUND ) {
                dispatch(popupShowDefault(t('alert_not_found_project')));
            }
            else {
                dispatch(popupShowDefault(t('alert_network_error')));
            }
        }, noticeInfo);
    }

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}
            visibleSolutionHeader={true}
            location={ConstantPath.PATH_SOLUTION_PROJECT}>
			<div className="gap" style={{textAlign: 'left'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div id="page-contents" className="row merged20">
                                <div className="col-lg-12">
                                    {
                                        data ?
                                        <div className="main-wraper">
                                            <div className="main-title">
                                                {data.subject}
                                            </div>
                                            <div className={styles.container}>
                                                <div className={styles.header}>
                                                    <div className={styles.user}>
                                                        <img src={data.userInfo && data.userInfo.deskImgUrl ? data.userInfo.deskImgUrl : 'assets/images/image/img_user.jpg'} alt=""/>
                                                        <h4 className={styles.nickname}>{data.userInfo.nickname}</h4>
                                                        <h6 className={styles.date}>{DataUtil.convertDateTimeToString(data.regdate)}</h6>
                                                    </div>
                                                </div>
                                                <div className={styles.body}>
                                                    <p className={styles.contents}
                                                        style={{whiteSpace: 'pre-wrap'}}>{data.contents}</p>
                                                </div>
                                            </div>
                                            <div className={styles.footer}>
                                                <button className='button primary circle' onClick={onListPressed}>{t('button_list')}</button>
                                                {
                                                    isLogin && user.memberId === data.memberId ?
                                                    <button className='button primary circle' onClick={onDeletePressed}>{t('button_delete')}</button> :
                                                    null
                                                }
                                            </div>
                                        </div> :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ProjectNoticeDetailScreen;