//
//  loginScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 10. 24..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React from 'react';

// common
import { CarouselList } from 'constants/list';
import { CommonKey } from 'constants/key';
import ConstantPath from 'constants/path';

// string
import { useTranslation } from 'react-i18next';

// redux
import { useDispatch } from 'react-redux';
import { dataRedirect } from 'redux/actions/dataAction';

// component
import WelcomeCarousel from 'components/carousel/welcomeCarousel';
import KakaoLoginButton from 'components/button/kakaoLoginButton';

// package
import { useLocation, useNavigate } from "react-router-dom";
import { Image, Button } from 'react-bootstrap';


/**
 * @protocol LoginScreen
 * @date 2022/10/24
 * @brief 로그인 화면
 */
function LoginScreen() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    
    if( location.state ) {
        const prevLocation = location.state[CommonKey.REDIRECT];

        // 로그인 redirect 상태 있을 시, 보관
        if( prevLocation ) {
            dispatch(dataRedirect(prevLocation));
        }
        else {
            dispatch(dataRedirect('/'));
        }
    }
    
    return (
        <div>
            <div className="authtication orange">
                <div className="verticle-center">
                    <div className="welcome-note">
                        <WelcomeCarousel
                            list={CarouselList.WelcomeCarouselList()}/>
                    </div>
                </div>
                <div className="mockup left" style={{
                    zIndex: "3",
                    marginTop: "10px",
                    }}>
                    <Button className="button primary" onClick={() => { navigate(-1); }}>
                        <i className="icofont-simple-left" style={{fontSize: '25pt'}}></i>
                    </Button>
                </div>
            </div>
            <div className="auth-login">
                <a className="logo" href={ConstantPath.PATH_SOLUTION}>
                    <Image src="assets/images/image/img_logo.png" alt=""/>
                    <span>{t('common_service_slogan')}</span>
                </a>
                <div className="mockup left-bottom">
                    <Image src="assets/images/image/img_mockup.png" alt=""/>
                </div>
                <div className="verticle-center">
                    <div className="login-form">
                        <h4 style={{textAlign: 'left'}}><i className="icofont-key-hole"></i> {t('login_title')}</h4>
                        <KakaoLoginButton/>
                    </div>
                </div>
                <div className="mockup right" style={{marginRight: '10px'}}><Image src="assets/images/image/img_star_shape.png" alt=""/></div>
            </div>
        </div>
    )
}

export default LoginScreen;