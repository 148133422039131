//
//  networkStatus.js
//  datatown
//
//  Created by Mumakil on 2022. 8. 12..
//  Copyright © 2022년 Melephant. All rights reserved.
//


/**
 * @protocol NetworkStatus
 * @date 2022/08/12
 * @brief 서버 통신에 사용되는 상태코드
 */
const NetworkStatus = {
    OK:                     200,
    NOT_FOUND:              404,
    SERVER_ERROR:           500,
    EXPIRED:                600,
    NOT_MATCHED:            601,
    ALREADY_EXIST:          602,
    ALREADY_FULL:           603,
    NOT_AUTHORIZED:         604,
};

export default NetworkStatus;
