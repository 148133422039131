import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ko from 'string/ko';
import en from 'string/en';

const resource = {
    'en': {
        translation: en,
    },
    'ko': {
        translation: ko,
    }
}

i18n.use(initReactI18next)
.init({
    resources: resource,  
    lng: 'ko',
    fallbackLng: 'ko',
    debug: false,
    defaultNS: 'translation',
    ns: 'translation',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: false,
    }
});

export default i18n;
