//
//  popupAction.js
//  datatown
//
//  Created by Mumakil on 2022. 8. 16..
//  Copyright © 2022년 Melephant. All rights reserved.
//


// common
import { PopupTypeKey } from 'constants/key';

// util
import DataUtil from 'util/dataUtil';


export const ACTION_POPUP_SHOW          = 'action_popup_show';
export const ACTION_POPUP_SHOW_AGAIN    = 'action_popup_show_again';
export const ACTION_POPUP_HIDE          = 'action_popup_hide';


/**
 * 토스트 팝업 표시 처리한다.
 * @param {string} message 팝업 내용
 * @returns {dictionary} 팝업 액션
 */
export const popupShowToast = (message) => ({
    type: ACTION_POPUP_SHOW,
    payload: {
        title: null,
        message: message,
        confirm: null,
        cancel: null,
        confirmCallback: null,
        cancelCallback: null,
        type: PopupTypeKey.POPUP_TYPE_TOAST,
    },
});

/**
 * 기본 팝업 표시 처리한다.
 * @param {string} message 팝업 내용
 * @param {function} confirmCallback 확인 버튼 클릭시 callback
 * @param {function} completeCallback 버튼 처리 완료시 callback
 * @returns {dictionary} 팝업 액션
 */
export const popupShowDefault = (message, confirmCallback) => ({
    type: ACTION_POPUP_SHOW,
    payload: {
        title: null,
        message: message,
        confirm: null,
        cancel: null,
        confirmCallback: confirmCallback,
        cancelCallback: null,
        type: PopupTypeKey.POPUP_TYPE_CONFIRM,
    },
});

/**
 * 확인 / 취소 팝업 표시 처리한다.
 * @param {string} message 팝업 내용
 * @param {function} confirmCallback 확인 버튼 클릭시 callback
 * @param {function} cancelCallback 취소 버튼 클릭시 callback
 * @returns {dictionary} 팝업 액션
 */
export const popupShowDefaultCancel = (message, confirmCallback, cancelCallback) => ({
    type: ACTION_POPUP_SHOW,
    payload: {
        title: null,
        message: message,
        confirm: null,
        cancel: null,
        confirmCallback: confirmCallback,
        cancelCallback: cancelCallback,
        type: PopupTypeKey.POPUP_TYPE_CANCEL,
    },
});

/**
 * 확인 팝업 표시 처리한다.
 * @param {string} message 팝업 내용
 * @param {string} confirm 확인 버튼 문구
 * @param {function} confirmCallback 확인 버튼 클릭시 callback
 * @returns {dictionary} 팝업 액션
 */
export const popupShowConfirm = (message, confirm, confirmCallback) => ({
    type: ACTION_POPUP_SHOW,
    payload: {
        title: null,
        message: message,
        confirm: DataUtil.validateString(confirm) ? confirm : null,
        cancel: null,
        confirmCallback: confirmCallback,
        cancelCallback: null,
        type: PopupTypeKey.POPUP_TYPE_CONFIRM,
    },
});

/**
 * 확인 / 취소 팝업 표시 처리한다.
 * @param {string} message 팝업 내용
 * @param {string} confirm 확인 버튼 문구
 * @param {function} confirmCallback 확인 버튼 클릭시 callback
 * @param {string} cancel 확인 버튼 문구
 * @param {function} cancelCallback 확인 버튼 클릭시 callback
 * @returns {dictionary} 팝업 액션
 */
export const popupShowCancel = (message, confirm, confirmCallback, cancel, cancelCallback) => ({
    type: ACTION_POPUP_SHOW,
    payload: {
        title: null,
        message: message,
        confirm: DataUtil.validateString(confirm) ? confirm : null,
        cancel: DataUtil.validateString(cancel) ? cancel : null,
        confirmCallback: confirmCallback,
        cancelCallback: cancelCallback,
        type: PopupTypeKey.POPUP_TYPE_CANCEL,
    },
});

/**
 * 기존 등록된 팝업 다시 표시 처리한다.
 * @param {dictionary} payload 팝업 정보
 * @returns {dictionary} 팝업 액션
 */
export const popupShowAgain = (payload) => ({
    type: ACTION_POPUP_SHOW_AGAIN,
    payload: payload,
});

/**
 * 기존 등록된 팝업 표시 해제 처리한다.
 * @returns {dictionary} 팝업 액션
 */
export const popupHide = () => ({
    type: ACTION_POPUP_HIDE,
    payload: null,
});
