//
//  helpM2mDetailScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 21..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
} from 'react';

// common
import {
    HelpMenuKey,
    HelpM2mMenuKey,
} from 'constants/key';
import { MenuList } from 'constants/list';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkM2m from 'network/networkM2m';

// info
import {
    SERVICEM2MINFO_CONTAINER,
    SERVICEM2MINFO_ID,
} from 'info/service/serviceM2mInfo';

// style
import styles from 'css/screen/helpM2mDetailScreen.module.css';

// redux
import { useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';

// component
import Layout from 'components/layout/layout';
import SideTab from 'components/tab/sideTab';

// package
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";


/**
 * @protocol HelpM2mDetailScreen
 * @date 2022/12/21
 * @brief 1:1 문의 상세 화면
 */
function HelpM2mDetailScreen(props) {
    var params = useParams();
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const location = useLocation();

    const [data, setData] = useState(null);

    const menuList = MenuList.HelpMenuList();
    const firstTab = HelpMenuKey.HELP_MENU_M2M;
    const secondTab = HelpM2mMenuKey.HELP_M2M_MENU_HISTORY;

    useEffect(() => {
        if( data === null ) {
            NetworkM2m.getServiceM2mInfo((res) => {
                setData(res[SERVICEM2MINFO_CONTAINER]);
            }, (res) => {
                dispatch(popupShowDefault(t('alert_network_error')));
            }, location.state[SERVICEM2MINFO_ID]);
        }
    }, [dispatch, t, params, data, location.state]);

    /**
     * 사이드 메뉴 목록 반환
     * @returns {Array} 사이드 메뉴 목록
     */
    const renderTypeMenu = () => {
        const itemList = [];
        const menuList = MenuList.HelpM2mMenuList();
        for( var i = 0; i < menuList.length; i++ ) {
            itemList.push(  <li className="nav-item"
                                key={"help_m2m_menu" + String(i)}>
                                <a className={menuList[i].id === secondTab ? "active" : ""}
                                    href={'#' + menuList[i].id}
                                    data-toggle="tab">
                                    {menuList[i].title}
                                </a>
                            </li>);
        }
        return itemList;
    }

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}>
			<div className="gap" style={{textAlign: 'left'}}>
                {
                    DataUtil.validateArray(menuList) && DataUtil.validateString(firstTab) ?
                    <div className="container">
                        <SideTab
                            defaultTab={firstTab}
                            menuList={menuList}/>
                        <div className="col-lg-12">
                            <div className="tab-content" id="components-nav">
                                <div className={"tab-pane fade show active"}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div id="page-contents" className="row merged20">
                                                    <div className="col-lg-12">
                                                        <div className="main-wraper">
                                                            <ul className="nav nav-tabs pix-filter">
                                                                {renderTypeMenu()}
                                                            </ul>
                                                            {
                                                                data ?
                                                                <div className="tab-content" id="components-nav">
                                                                    <div className={styles.qna_container}>
                                                                        <div className={styles.qna_header}>
                                                                            <h4>
                                                                                {data.subject}
                                                                            </h4>
                                                                            <h6 className={styles.date}>
                                                                                {DataUtil.convertDateTimeToString(data.regdate)}
                                                                            </h6>
                                                                        </div>
                                                                        <p style={{whiteSpace: 'pre-wrap'}}>
                                                                            {data.question}
                                                                        </p>
                                                                        <div className={styles.qna_footer}>
                                                                        {
                                                                            DataUtil.validateString(data.attachimageUrl1) ?
                                                                            <img src={data.attachimageUrl1} alt=""
                                                                                className={styles.image}/> :
                                                                            null
                                                                        }
                                                                        {
                                                                            DataUtil.validateString(data.attachimageUrl2) ?
                                                                            <img src={data.attachimageUrl2} alt=""
                                                                                className={styles.image}/> :
                                                                            null
                                                                        }
                                                                        {
                                                                            DataUtil.validateString(data.attachimageUrl3) ?
                                                                            <img src={data.attachimageUrl3} alt=""
                                                                                className={styles.image}/> :
                                                                            null
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        data.m2mStatus === 'Y' ?
                                                                        <div className={styles.answer_container}>
                                                                            {data.answer}
                                                                            <div className={styles.answer_footer}>
                                                                                <h6 className={styles.date}>
                                                                                    {DataUtil.convertDateTimeToString(data.findate)}
                                                                                </h6>
                                                                            </div>
                                                                        </div> :
                                                                        null
                                                                    }
                                                                </div> :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    null
                }
            </div>
        </Layout>
    );
}

export default HelpM2mDetailScreen;