//
//  dataReducer.js
//  datatown
//
//  Created by dasein55 on 2022. 11. 14..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import * as DataAction from 'redux/actions/dataAction';

// 세션 관련 초기 상태
export const INITIAL_STATE = {
    redirect: '/',
    payment: null,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch( action.type ) {
        case DataAction.ACTION_DATA_REDIRECT: {
            return {
                ...state, redirect: action.payload,
            };
        }
        case DataAction.ACTION_DATA_PAYMENT: {
            return {
                ...state, payment: action.payload,
            };
        }
        default:
            return state;
    }
};

export default reducer;
