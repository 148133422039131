//
//  blogCell.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 18..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
} from 'react';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';


/**
 * @protocol BlogCell
 * @date 2023/01/18
 * @brief 블로그 셀
 */
function BlogCell(props) {
    const { t } = useTranslation();
    
    /**
     * 셀 컨텐츠 스타일 반환
     * @returns {Dictionary} 스타일
     */
    const getContentStyle = () => {
        if( DataUtil.validateString(props.data.imageUrl) ) {
            return {width: '80%'};
        }
        return {width: '100%'};
    }
    
    /**
     * 셀 클릭시 호출
     * @param {Event} event 클릭 이벤트
     */
    const onCellPressed = (event) => {
        event.preventDefault();

        if( props.onCellPressed ) {
            props.onCellPressed(props.data.blogId);
        }
    }
    
    return (
        <div className="blog-posts">
            {
                DataUtil.validateString(props.data.imageUrl) ?
                <figure><img src={props.data.imageUrl} alt=""/></figure> :
                null
            }
            <div className="blog-post-meta" style={getContentStyle()}>
                <h4>{props.data.subject}</h4>
                <div className="contents" style={{maxHeight: '100px', overflow: "hidden"}} dangerouslySetInnerHTML={ {__html: props.data.contents} }></div>
                <span><i className="icofont-clock-time"></i> {DataUtil.convertDateTimeToString(props.data.regdate)}</span>
                <a href="#!" title="" className="button primary circle" onClick={onCellPressed}>{t('button_view_content')}</a>
            </div>
        </div>
    );
}

export default BlogCell;