//
//  pageList.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 2..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
    useEffect,
} from "react";

// util
import DataUtil from "util/dataUtil";

// component
import EmptyCell from "components/cell/emptyCell";

// package
import Pagination from "react-js-pagination";


/**
 * @protocol PageList
 * @date 2022/12/2
 * @brief 페이징 처리 리스트 화면
 */
function PageList(props) {
    const [pageNum, setPageNum] = useState(1);
    const pageSize = props.pageSize ? props.pageSize : 10;

    useEffect(() => {
        if( props.dataList === null ) {
            props.fetchData(pageNum, pageSize);
        }
    }, [pageNum, pageSize, props]);

    /**
     * 페이지 클릭시 호출
     * @param {Number} page 페이지 번호
     */
    const onPageChanged = (page) => {
        setPageNum(page);
        
        props.fetchData(page, pageSize);
    };

    /**
     * 컨텐츠 렌더링
     * @returns {Array} 컨텐츠 목록
     */
    const renderContent = () => {
        var itemList = [];

        if( DataUtil.validateArray(props.dataList) ) {
            for( var i = 0; i < props.dataList.length; i++ ) {
                itemList.push(props.renderCell(props.dataList[i]));
            }
        }
        else {
            itemList.push(<EmptyCell key={'list_empty'} disableList={true}/>);
        }
        return itemList;
    }

    return (
        <div className={props.className}>
            { renderContent() }
            <Pagination
                activePage={pageNum}
                itemsCountPerPage={pageSize}
                totalItemsCount={props.totalCount}
                pageRangeDisplayed={props.pageRangeDisplayed ? props.pageRangeDisplayed : 5}
                prevPageText={"‹"}
                nextPageText={"›"}
                onChange={onPageChanged}/>
        </div>
        
    );
};

export default PageList;
