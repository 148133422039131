//
//  serviceTerms.js
//  datatown
//
//  Created by Mumakil on 2022. 10. 25..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React from 'react';


/**
 * @protocol ServiceTerms
 * @date 2022/10/25
 * @brief 이용약관 문서
 */
export default function ServiceTerms(props) {
    return (
        <div className={"tab-pane fade show" + (props.defaultActive ? " active" : "")}
            id={props.tabId} >
            <h2>제 1장. 약관 및 이용 계약</h2>
            <h6>제 1조. 목적</h6>
            <p>이 약관은 데이터타운(이하 "사업자") 이 운영하는 인터넷사이트 사수톡(이하 "사수톡")에서 제공하는 서비스(이하 "서비스") 이용에 있어 사수톡과 이용자/회원/비회원의 권리<span className="T1">⋅</span>
            <span className="T2">의무 및 책임사항, 기타 필요한 사항을 구체적으로 규정함을 목적으로 합니다.</span>
            </p>
            <h6>제 2조. 정의</h6>
            <p>본 약관의 용어 정의는 다음 각 호와 같습니다.</p>
            <p>ㄴ사업자가 운영하는 사이트 : www.mentortalk.ai</p>
            <p>ㄴ서비스: 구현되는 단말기(PC, TV, 휴대형 단말기 등 각종 유무선 장치를 포함)와 상관없이 "이용자"가 사수톡 플랫폼에 접속하여 사수톡 및 사수톡이 제공하는 일체의 용역, 자료 DB화, 컴퓨터 기타 이와 유사한 정보통신처리장치를 이용한 일체의 행위</p>
            <p>ㄴ이용자: 사수톡에 접속하여 본 약관에 따라 “서비스”를 받는 “회원” 및 “비회원”</p>
            <p>ㄴ비회원: “비회원”이라 함은 “회원”으로 가입하지 않고 사수톡이 제공하는 “서비스”를 이용하는 자</p>
            <p>ㄴ신청회원 : 사업자에 개인정보를 제공하여 회원등록을 한 자로서, 사업자와 서비스 이용계약을 체결하고 회원ID를 부여받아 사이트 내에 게시된 사수회원 리스트를 열람하거나 사수회원에게 상담 및 팀프로젝트 신청을 요청하는 방식으로 서비스를 이용할 수 있는 자를 말합니다.</p>
            <p>ㄴ사수회원 : 사업자에 개인정보를 제공하여 회원등록을 한 자로서, 사업자와 서비스 이용계약을 체결하고 회원ID를 부여받아 상담 및 팀프로젝트 등의 방식으로 신청 회원에게 경험 및 노하우 등의 정보를 제공하고 그에 따른 대가를 받는자를 말합니다.</p>
            <p>ㄴ회원ID: “회원” 식별과 “서비스” 이용을 위하여 사수톡 “회원”임을 확인하고 “회원” 자신의 비밀보호를 위해 “회원”자신이 선정하고 사수톡이 승인하는 문자와 숫자의 조합</p>
            <p>ㄴ비밀번호: 사수톡 “회원”이 부여 받은 “회원ID”와 일치된 “회원”임을 확인하고 자신의 비밀 보호를 위해 “회원”이 설정한 문자와 숫자의 조합</p>
            <p>ㄴ개인정보: 생존하는 개인에 관한 정보로서 해당 정보에 포함되어 있는 회사, 직무, 학교, 학과, 이메일 주소, 휴대전화 번호 등의 사항에 의하여 해당 개인을 식별할 수 있는 정보(해당 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)</p>
            <p>ㄴ유료서비스 : 사수톡이 상담 및 팀프로젝트 강의 등에 대해 신청회원이 서비스 이용신청에 의해 유료로 제공되는 각종 콘텐츠 및 제반 “서비스”</p>
            <p>ㄴ서비스 이용요금(또는 서비스요금 또는 이용요금) : 사수톡이 제공하는 “서비스”를 사용하기 위해서 신청회원이 지불하는 대가</p>
            <p>ㄴ서비스 리워드 : 사수톡이 제공하는 “서비스”에 대해 사수회원에게 지불하는 대가</p>
            <p>ㄴ No show : 신청회원과 사수회원간의 상호협의 하에 약속된 시간에 사전 변경 절차 없이 서비스 시각으로부터 5분이 경과한 후에도 접속하지 않는 경우, 5분내 입장했을지라도 서비스 시각으로부터 5분동안 대기하지 않고 이탈한 경우</p>
            <p>ㄴ플랫폼 : 사수톡이 “서비스”를 제공하기 위해 온라인에 구축한 API(application programming interface), DB, 기타 이와 유사한 정보통신 처리 장치로서 앱 개발자와 웹 사이트 운영자를 비롯한 타인은 위 정보통신처리장치에 API, DB 등을 제공할 수 있고, 데이터를 검색할 있음</p>
            <p>ㄴ어플리케이션 : 플랫폼에 접속하여 사수톡이 제공하는 “서비스”를 이용할 수 있도록 하는 웹, 모바일 웹, 스마트폰 앱, 기타 이와 유사한 정보통신 처리장치</p>
            <p>ㄴ콘텐츠 : 사수톡이 생성, 수집, 기타 일체의 방법으로 플랫폼에 제공한 콘텐츠</p>
            <p>ㄴ이용자 콘텐츠 : "이용자"가 "서비스"를 이용함에 있어 "서비스”상에 게시한 부호, 문자, 음성, 음향, 화상, 동영상 등의 정보, 각종 파일과 링크</p>
            <p>ㄴ데이터 : 사수톡이 “서비스”를 제공하는 과정에서 수집하고 축적한 각종 정보, 자료 및 이용의 콘텐츠</p>
            <h6>제 3조. 약관 적용과 약관 외 준칙</h6>
            <p>사수톡의 “서비스” 이용에 관하여 본 약관을 적용하며, 사업자는 필요한 경우 서비스 내의 개별항목에 대하여 개별약관 또는 운영원칙(이하 '서비스 별 안내')을 정할 수 있으며, 회원은 각 서비스 별 안내에 대해 회원가입과 동시에 동의한 것으로 간주합니다. 본 약관과 서비스 별 안내의 내용이 상충되는 경우에는 서비스 별 안내의 내용을 우선하여 적용합니다.</p>
            <h6>제 4조. 약관의 공시, 준용, 효력 및 변경</h6>
            <p>본 약관은 “회원” 가입 시 “이용자”에게 공시되며, “이용자”는 회원 가입을 함으로써 “본 약관”에 동의하는 것으로 간주됩니다.</p>
            <p>다만, “이용자”가 “비회원”으로 “서비스”를 이용하면 이용 시 본 약관에 동의하는 것으로 간주됩니다.</p>
            <p>약관의 내용은 “서비스” 약관페이지에 게시하여 공지합니다. 사수톡는 사정변경의 경우와 영업상 중요한 사유가 있을 경우 관련법령을 위반하지 않는 범위에서 약관을 변경할 수 있으며, 변경된 약관은 “이용자”에게 적용일자를 명시하여 “서비스” 이용 약관 페이지와 공지사항에 변경 기준일로부터 최소7일 전에 공지하는 것을 원칙으로 합니다. 단, 변경 내용이 귀하에 불리한 영향을 미칠 것으로 판단되는 사항에 대해서는 최소30일 이상의 유예 기간을 두어 고지합니다. 또한 동 기간 동안 개정된 약관의 내용을 약관이 게시되는 “서비스” 화면에 함께 게시하며, 위의 유예기간 이후 변경 약관의 효력을 발생합니다.</p>
            <p>사수톡의 약관을 개정할 경우 그 개정 약관은 적용일자 이후에 발생하는 사안에 대해서만 적용됩니다. 사수톡이 전항에 따라 “이용자”에게 통지하면서 공지⋅고지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도 의사표시가 없는 경우에는 변경된 약관을 승인한 것으로 봅니다. 변경된 약관에 동의하지 않을 경우 “본 약관” 제19조 제1항에 따라 계약 해지를 신청할 수 있으며, 변경된 약관의 효력 발생일 이후에도 “서비스”를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주됩니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 사수톡는 이용계약을 해지할 수 있습니다.</p>
            <h6>제 5조. 이용 계약의 성립</h6>
            <p>사수톡 “회원”으로 가입하여 사수톡이 제공하는 “서비스”를 받고자 하는 자는 “회원”가입절차를 거쳐 가입하여야 합니다. “회원” 가입 희망자가 “본 약관”의 내용을 숙지하고 동의 여부를 선택할 수 있으며, “회원”은 “회원”가입절차를 거쳐 “회원”가입을 신청하면 “본 약관”에 동의하는 것으로 간주됩니다. 약관 변경 시 공지된 변경 약관에 동의하지 않을 경우 제19조 제1항에 따라 계약 해지를 신청이 가능합니다.</p>
            <p>“회원”과 사수톡 간의 이용계약은 “서비스” 이용 희망자의 이용약관 동의 후 이용 신청에 대하여 사수톡이 신청절차 상에서 승낙을 표시함으로써 성립합니다.</p>
            <p>단, “비회원”으로 사수톡이 제공하는 “서비스”를 이용 하고자 하는 자는 회원가입절차 없이 사수톡의 “서비스”를 이용함으로써 “본 약관”에 동의한 것으로 간주됩니다. 약관 변경 시에는, 변경된 약관이 공지된 이후 “비회원”으로 사수톡 “서비스”를 재이용할 시 변경된 약관에 동의한 것으로 간주됩니다. 약관 변경 시 공지된 변경 약관에 동의하지 않을 경우 제19조 제1항에 따라 계약 해지를 신청이 가능합니다.</p>
            <p>“비회원”과 사수톡 간의 이용계약은 “서비스” 이용 희망자가 “서비스” 이용을 신청하고 이를 사수톡이 승낙하여 “서비스”를 제공함으로써 성립합니다.</p>
            <h6>제 6조. 이용신청</h6>
            <p>“회원”으로 가입하여 “서비스”를 이용하기를 희망하는 자는 사수톡이 요청하는 소정의 가입 신청 양식에서 요구하는 사항을 기록하여 신청합니다.</p>
            <p>단, “비회원”으로 “서비스”를 이용하기를 희망하는 자는 사수톡의 “서비스”를 이용함으로써 자동으로 사수톡의 “비회원” “이용자”로 간주됩니다.</p>
            <p>온라인 “회원” 가입신청 양식 및 “비회원”이 “서비스” 이용에 기재하는 모든 “이용자” 정보는 실제 데이터인 것으로 간주하므로 실명이나 실제 정보를 입력하지 않은 “서비스” 사용의 제한을 받으시거나 법적 보호를 받지 못 하실 수 있습니다.</p>
            <h6>제 7조. 이용신청의 승낙</h6>
            <p>사수톡는 제6조에 따른 “이용자”의 이용신청에 대하여 신용정보의 이용 및 보호에 관한 법률이 정하는 특별한 사정이 없는 한 접수 순서대로 이용 신청을 승낙합니다.</p>
            <p>사수톡는 다음 각 호에 해당하는 경우 이용신청에 대한 승낙을 제한하거나, 그 사유가 해소될 때까지 승낙을 유보할 수 있으며, 사후에라도 이용계약을 해지할 수 있습니다.</p>
            <p>“서비스” 관련 설비에 여유가 없는 경우.</p>
            <p>기술상 지장이 있는 경우.</p>
            <p>기타 사수톡의 사정상 필요하다고 인정되는 경우.</p>
            <p>사수톡는 다음 각 호에 해당하는 이용계약 신청에 대하여는 이를 승낙하지 않을 수 있습니다.</p>
            <p>본인의 실명으로 신청하지 않은 경우.</p>
            <p>다른 사람의 명의를 사용하여 신청한 경우</p>
            <p>이용 신청 시 허위의 정보를 기재하거나, 허위서류를 첨부하는 경우, 사수톡이 제시하는 내용을 기재하지 않은 경우</p>
            <p>사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로 신청한 경우.</p>
            <p>이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우</p>
            <p>기타 사수톡이 정한 이용 신청 요건이 미흡한 경우</p>
            <p>사수톡는 관계법령에서 규정하는 미성년자일 경우에 이용계약 신청의 승낙을 보류할 수 있습니다.</p>
            <p>14세 미만 아동이 법정대리인(부모 등)의 동의를 얻지 아니한 경우</p>
            <p>"서비스"의 위상이나 명예에 부정적인 영향을 줄 수 있는 경우</p>
            <p>부정한 행위나 과정을 통해 공공질서 및 미풍양속에 저해되는 활동을 하는 경우</p>
            <p>타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우</p>
            <p>영리를 추구할 목적으로 "서비스"를 악용하는 경우</p>
            <p>"서비스"의 운영을 고의로 방해한 경우</p>
            <p>이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우</p>
            <p>서비스를 제공하지 않는 국가에서 비정상적이거나 우회적인 방법을 통해 서비스를 이용하는 경우</p>
            <p>임시 이메일 서비스를 이용하여 회원가입을 하는 경우</p>
            <p>기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우</p>
            <p>기타 이 약관에 위배되는 무리한 요청을 하는 경우</p>
            <p>제1항에 따른 신청에 있어 “회사”는 “회원”의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.</p>
            <p>제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, “회사”는 원칙적으로 이를 가입신청자에게 알리도록 합니다.</p>
            <p>이용계약의 성립 시기는 “회사”가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.</p>
            <p>회사”는 “회원”에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.</p>
            <h2>제2장. 계약자간 의무</h2>
            <h6>제 8조. 개인정보의 보호</h6>
            <p>사수톡은 “이용자”의 “개인정보” 보호를 위해 최선을 다하고 있습니다. “이용자”의 “개인정보” 보호 및 “이용자”가 사수톡를 이용하여 “이용자”의 콘텐츠를 타인과 공유하고 사수톡이 이를 수집 및 이용하는 방법에 대해 중요한 사항은 개인정보 취급방침이 적용됩니다.</p>
            <h6>제 9조. 개인정보의 변경</h6>
            <p>“이용자”는 “개인정보”관리를 통해 언제든지 본인의 “개인정보”를 열람하고 수정을 요청할 수 있습니다. 다만, “서비스” 관리를 위해 필요한 아이디 등은 수정이 불가능합니다.</p>
            <p>“이용자”는 이용신청 및 회원가입 시 기재한 사항이 변경되었을 경우 온라인 수정 및 전자우편으로 사수톡에 그 변경사항을 알려야 합니다. 변경사항의 미통보로 인하여 발생한 손해의 책임은 “회원”에게 있습니다.</p>
            <h6>제 10조. 사수톡의 의무</h6>
            <p>사수톡은 본 약관에서 정한 바에 따라 계속적이고 안정적인 “서비스”의 제공을 위하여 지속적으로 노력하며, 설비에 장애가 생기거나 멸실 된 때에는 지체 없이 이를 수리 복구합니다. 다만, 천재지변, 비상사태 또는 그밖에 부득이한 경우에는 그 “서비스”를 일시 중단하거나 중지할 수 있으며, 이 경우에 대한 손해에 대해서는 사수톡이 책임지지 않습니다. 또한 “서비스” 운영상, 기술상 필요하고 상당한 이유가 있는 경우 “서비스”의 전부, 또는 일부를 변경할 수 있습니다. “서비스”의 전부, 또는 일부를 변경할 경우 다음 각 호에서 정한 바에 따릅니다.</p>
            <p>“서비스”의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 “서비스”의 내용 및 제공일자 등을 그 변경 전에 사이트에 게시 또는 전자우편을 통해 “이용자”에게 공지합니다</p>
            <p>무료로 제공되는 “서비스”의 일부 또는 전부를 수정, 중단, 변경할 경우 관련법에 특별한 규정이 없는 한 “회원”에게 별도의 보상을 하지 않습니다.</p>
            <p>사수톡은 “회원”으로부터 소정의 절차에 의해 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 적절한 절차를 거처 처리합니다. 처리 시 일정 기간이 소요될 경우 “회원”에게 게시판, 전자우편, 기타 수단 등을 이용하여 그 사유와 처리 일정을 알려드립니다.</p>
            <p>사수톡은 다음 각 호에 해당하는 이용계약 신청에 대하여는 이를 승낙하지 않을 수 있습니다.</p>
            <p>사수톡은 정보통신망 이용촉진 등에 관한 법률, 통신비밀보호법, 전기통신기본법 등 “서비스”의 운영, 유지와 관련 있는 법규를 준수합니다.</p>
            <h6>제 11조. 이용자의 의무</h6>
            <p>“유료서비스”를 사용하는 사수톡 “이용자”는 “서비스” 이용에 대한 대가로 사수톡이 책정한 “서비스이용대금”을 사수톡에 지불하여야 합니다. </p>
            <p>“이용자”는 이 약관에서 규정하는 사항과 “서비스” 이용안내 또는 주의사항 등 사수톡이 공지 혹은 통지하는 사항을 준수하여야 하며, 기타 사수톡 운영에 방해되는 행위를 하여서는 안 됩니다. “본 약관”의 규정이나 정신을 위반하거나 사수톡에 위험이나 법적 피해를 초래한다면 사수톡 “서비스” 전체나 일부의 제공을 중단할 수 있습니다. 이와 같은 사실은 이메일 및 “이용자”에게 통지 가능한 기타 수단을 통해 알려 드립니다.</p>
            <p>“회원”은 “서비스” 이용을 위한 “회원ID”, “비밀번호”의 관리에 대한 책임, 본인 ID의 제3자에 의한 부정사용 등 “회원”의 고의⋅과실로 인해 발생하는 모든 불이익에 대한 책임을 부담합니다. 단, 이것이 사수톡의 고의⋅과실로 인하여 야기된 경우는 사수톡이 책임을 부담합니다. 사수톡는 다음 각 호의 경우 해당 아이디의 이용을 제한할 수 있습니다.</p>
            <p>“개인정보” 유출 우려가 있는 경우</p>
            <p>반사회적 또는 미풍양속에 어긋나는 경우</p>
            <p>사수톡이 “서비스”를 제공하는데 현저한 장애를 유발하는 경우</p>
            <p>“이용자”는 사수톡이 공지사항에 게시하거나 전자우편 등 “이용자”에게 통보 가능한 기타 수단으로 별도 공지한 이용제한 사항을 준수하여야 합니다.</p>
            <p>“이용자”는 사수톡의 명시적인 동의가 없는 한 “서비스”의 이용권한, 기타 이용계약 상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.</p>
            <p>사수톡를 안전하게 유지하기 위해 최선을 다하고 있지만 “이용자”가 다음 각 호의 행위를 하는 경우에는 안전을 보장할 수 없습니다. 안전을 위해서 “이용자”는 “서비스” 이용과 관련하여 다음 각 호에 해당되는 행위를 하여서는 아니 됩니다.</p>
            <p>다른 “이용자”의 ID와 “비밀번호” 및 다른 “이용자”의 “개인정보”를 도용하는 행위</p>
            <p>본 “서비스”를 통하여 얻은 정보를 사수톡의 사전 승낙 없이 “이용자”의 이용 이외 목적으로 복제하거나 이를 출판 및 방송 등에 사용하거나 제3자에게 제공하는 행위.</p>
            <p>타인의 특허, 상표, 영업비밀, 저작권 기타 지적재산권을 침해하는 내용을 게시, 전자메일 또는 기타의 방법으로 타인에게 유포하는 행위.</p>
            <p>공공질서 및 미풍양속에 위반되는 저속, 음란, 증오, 폭력적인 내용의 정보, 문장, 도형 등을 전송, 게시, 전자메일 또는 기타의 방법으로 타인에게 유포하는 행위.</p>
            <p>모욕적이거나 위협적이어서 타인의 프라이버시를 침해할 수 있는 내용을 전송, 게시, 전자메일 또는 기타의 방법으로 타인에게 유포하는 행위.</p>
            <p>사수톡이 제공하는 “서비스”를 이용하여 불법적이거나 오해의 소지가 있거나 악의적이거나 차별적인 활동을 하는 행위</p>
            <p>관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 등)를 전송 또는 게시하는 행위</p>
            <p>“서비스” 거부 공격과 같이 “서비스”의 적절한 작동을 무력하게 만들거나 과도한 부담을 주거나 저해할 수 있는 행위</p>
            <p>사수톡의 허가 없이 자동화된 수단(예, 수집로봇, 스파이더, 스크래퍼)을 이용하여 사용자의 콘텐츠나 정보를 수집하거나 다른 방식을 통해 접근하는 행위</p>
            <p>컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 전자우편으로 발송하는 행위.</p>
            <p>불특정 다수의 자를 대상으로 하여 광고 또는 선전을 게시하거나 스팸메일을 전송하는 등의 방법으로 사수톡의 “서비스”를 이용하여 영리목적의 활동을 하는 행위.</p>
            <p>정크메일(junk mail), 행운의 편지(chain letters), 피라미드 조직에 가입할 것을 권유하는 메일을 보내거나 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위.</p>
            <p>사수톡의 사전 서면 동의 없이 경연이나 경품 등(이하 "프로모션"라 함)을 사수톡 “서비스” 내에서 제공하는 행위. 사수톡이 동의하는 경우에도 홍보에 대한 책임은 전적으로 “이용자”에게 있으며, 사수톡의 프로모션가이드라인 및 관련 법규를 모두 준수해야 합니다.</p>
            <p>사수톡의 직원이나 관리자를 가장하거나 사칭하여 내용물을 게시, 등록, 변경하거나 메일을 발송하는 행위.</p>
            <p>범죄와 결부된다고 객관적으로 판단되는 행위.</p>
            <p>사수톡의 승인을 받지 않고 다른 사용자의 “개인정보”를 수집 또는 저장하는 행위</p>
            <p>본 약관의 위반을 조장하거나 권장하는 행위</p>
            <p>기타 관계법령에 위배되는 행위</p>
            <p>사수톡 및 “이용자”는 타인의 권리를 보호하기 위해 다음 각 호에서 정한 사항을 이행합니다.</p>
            <p>사수톡는 타인의 권리를 존중하며, “이용자”도 이를 존중해야 합니다.</p>
            <p>“이용자”는 타인의 권리를 침해하여서는 아니되며 법률을 위반하는 콘텐츠를 게시하거나 그러한 조치를 취할 수 없습니다. 이에 위반하는 콘텐츠나 정보를 사수톡이 임의로 제거할 수 있습니다.</p>
            <p>상습적으로 타인의 지적 재산권을 침해하는 경우, “이용자”의 “서비스” 이용이 제한될 수 있습니다.</p>
            <p>사수톡의 서면 허가 없이 사수톡의 저작권이나 상표권 및 사수톡와 혼동되는 유사 상표를 사용할 수 없습니다.</p>
            <p>“이용자”로부터 정보를 수집하는 경우에는 “이용자”의 동의를 구하고 정보를 수집하는 주체는 “이용자”임을 분명히 밝히고 “이용자”가 수집하는 정보와 이용 방법을 설명하는 “개인정보 취급방침"을 게시해야 합니다.</p>
            <p>타인의 신분 증명 문서나 민감한 금융 정보는 게시할 수 없습니다.</p>
            <p>사수톡의 모바일 “서비스”는 다음 각 호에서 정한 바에 따라 제공됩니다.</p>
            <p>사수톡는 현재 “서비스”를 무료로 제공하지만, “이용자”의 “서비스” 이용에 따른 문자 메시지 수수료와 같은 이동통신사의 정규 요금 및 수수료는 적용됩니다.</p>
            <p>“이용자”는 사수톡에 보이는 기본 정보 및 사용자 콘텐츠를 동기화(어플리케이션을 통한 동기화 포함)하는 데 필요한 모든 권리를 제공합니다.</p>
            <p>모바일에서 등록한 콘텐츠, 프로필, “개인정보”는 웹과 동일한 약관이 적용됩니다.</p>
            <p>"이용자"는 "서비스"를 이용하는 과정에서 알게 된 내용 일체와 사수회원 또는 이용자 정보를 제3자에게 누설, 공개하지 아니합니다. 본 항은 이용계약이 해지되거나, 서비스 제공이 중단된 이후에도 유효합니다.</p>
            <h2>제 3장. 서비스 이용</h2>
            <h6>제 12조. 정보 제공</h6>
            <p>“이용자”는 사수톡의 자체 “서비스” 및 공지내용을 이메일, 휴대폰 문자메시지로 받을 수 있으며, 광고 정보의 경우 수신 여부를 선택 할 수 있고, 수신에 동의한 “이용자”에 한하여 광고 정보를 받게 됩니다. 다만, “이용자”의 거래관련 정보 및 고객문의 등에 대한 회신에 있어서는 제외됩니다.</p>
            <h6>제 13조. 콘텐츠의 제공</h6>
            <p>“이용자”는 “이용자”가 사수톡의 “서비스”를 원활히 이용하기 위해 사수톡에서 요구하는 콘텐츠 및 정보를 성실히 제공하여야 합니다. “이용자”의 성실하지 않은 콘텐츠 및 정보 제공에 따라 사수톡의 “서비스”가 원활히 제공되지 못한 경우 사수톡는 이에 대한 어떤 책임도 지지 않습니다.</p>
            <p>“이용자”는 사수톡의 “서비스”를 통해 “사수회원”에게 콘텐츠 및 정보를 요청하는데 있어 다음 각 호의 내용에 대해서는 질의할 수 없습니다. 다음 각 호의 내용을 “이용자”가 질의할 경우 사수톡  및 ”사수회원"은 이에 대한 답변을 제공할 의무가 없습니다.</p>
            <p>회사의 영업비밀 및 기술비밀 등 회사의 기밀에 관련된 내용</p>
            <p>기타 상장회사 주가에 영향을 줄 수 있는 회사의 기밀 정보</p>
            <p>연봉정보 등 “사수회원”이 회사와 맺은 근로 계약에 따라 외부 공개가 금지된 인사 정보</p>
            <p>"사수회원”의 “개인정보"</p>
            <p>기타 서비스 목적에 관련 없는 정보</p>
            <h6>제 14조. 콘텐츠 관리</h6>
            <p>사수톡는 건전한 통신문화정착과 효율적인 “서비스” 운영을 위하여 “이용자”가 제공하는 콘텐츠가 다음에 해당한다고 판단되는 경우에 임의로 삭제, 적합한 위치로의 이동, 등록거부를 할 수 있습니다.</p>
            <p>사수톡에서 규정한 제공 기간을 초과한 경우</p>
            <p>“서비스”의 성격에 부합하지 않는 콘텐츠의 경우</p>
            <p>제3자 또는 사수톡의 명예, 신용을 훼손하거나 오용하는 내용 또는 영업을 방해하는 내용인 경우</p>
            <p>위법적 행위와 결부된다고 객관적으로 인정되는 내용인 경우</p>
            <p>제3자 또는 사수톡의 저작권 및 지적재산권 등 권리를 침해하는 내용인 경우</p>
            <p>공공질서 및 미풍양속에 위반되는 내용인 경우(예. 욕설, 음란 등)</p>
            <p>"정보통신망법" 및 "저작권법"등 관계법령 및 사수톡의 게시물 관리 규정에 위배된다고 판단되는 경우</p>
            <p>정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 "정보통신망법"이라 함)의 규정에 의해 다른 “이용자”의 공개된 콘텐츠 등이 본인의 사생활을 침해하거나 명예를 훼손하는 등 권리를 침해 받은 “이용자” 또는 제3자(이하 "삭제 등 신청인"이라 합니다)는 그 침해사실을 소명하여 사수톡에 해당 콘텐츠 등의 삭제 또는 반박 내용의 게재를 요청할 수 있습니다. 이 경우 사수톡는 해당 게시물 등의 권리 침해 여부를 판단할 수 없거나 당사자 간의 다툼이 예상되는 경우 해당 콘텐츠 등에 대한 접근을 임시적으로 차단하는 조치(이하 "임시조치"라 합니다)를 최장 30일까지 취합니다.</p>
            <p>제2항에 의해 본인의 게시물 등이 임시조치 된 “이용자”(이하 "제공자"라 합니다)는 임시조치기간 중 사수톡에 해당 콘텐츠 등을 복원해 줄 것을 요청(이하 "재게시 청구"라 함)할 수 있으며, 사수톡는 임시조치된 콘텐츠의 명예훼손 등 판단에 대한 방송통신심의위원회 심의 요청에 대한 제공자 및 삭제 등 신청인의 동의가 있는 경우 제공자 및 삭제 등 신청인을 대리하여 이를 요청하고 동의가 없는 경우 사수톡이 이를 판단하여 콘텐츠 등의 복원 여부를 결정합니다. 제공자의 재게시 청구가 있는 경우 임시조치 기간 내에 방송통신심의위원회 또는 사수톡의 결정이 있으면 그 결정에 따르고 그 결정이 임시조치 기간 내에 있지 않는 경우 해당 콘텐츠 등은 임시조치 만료일 이후 복원됩니다. 재게시 청구가 없는 경우 해당 콘텐츠 등은 임시조치 기간 만료 이후 삭제됩니다.</p>
            <p>사수톡은 서비스 내에 제공된 콘텐츠 등이 사생활 침해 또는 명예훼손 등 제3자의 권리를 침해한다고 인정하는 경우 제2항에 따른 “회원” 또는 제3자의 신고가 없는 경우에도 임시조치(이하 "임의의 임시조치"라 합니다)를 취할 수 있습니다. 임의의 임시조치된 콘텐츠의 처리 절차는 제2항 후단 및 제3항의 규정에 따릅니다.</p>
            <p>“이용자”의 콘텐츠 등으로 인한 법률상 이익 침해를 근거로, 다른 “회원” 또는 제3자가 “회원” 또는 사수톡를 대상으로 하여 민형사상의 법적 조치(예: 형사고소, 가처분 신청⋅손해배상청구 등 민사소송의 제기)를 취하는 경우, 사수톡는 동 법적 조치의 결과인 법원의 확정판결이 있을 때까지 관련 콘텐츠 등에 대한 접근을 잠정적으로 제한할 수 있습니다. 콘텐츠 등의 접근 제한과 관련한 법적 조치의 소명, 법원의 확정 판결에 대한 소명 책임은 콘텐츠 등에 대한 조치를 요청하는 자가 부담합니다.</p>
            <h6>제 15조. 콘텐츠의 저작권 및 권리의 귀속과 콘텐츠의 공유</h6>
            <p>“서비스”에 게재된 자료에 대한 권리는 다음 각 호의 내용과 같습니다.</p>
            <p>사수톡이 작성한 저작물에 대한 저작권 및 기타 지적재산권은 사수톡에 귀속합니다. 단, "이용자"의 콘텐츠 및 제휴계약에 따라 제공된 저작물 등은 제외합니다.</p>
            <p>사수톡는 “서비스”와 관련하여 "이용자"에게 사수톡이 정한 이용조건에 따라 “서비스”를 통해 콘텐츠를 이용할 수 있는 이용권한이 부여됩니다. “이용자”는 “서비스”를 이용함으로써 얻은 정보를 사수톡의 사전 승낙 없이 복제, 송신, 출판, 전송, 배포, 방송 기타 방법에 의하여 영리 및 비영리목적으로 이용하거나 판매, 담보제공 혹은 제3자에게 이용하게 할 수 없습니다.</p>
            <p>“이용자”가 제공하는 콘텐츠와 정보의 모든 소유권은 “이용자”에게 있습니다.</p>
            <p>단, “이용자”가 제공하는 콘텐츠와 정보(이하 "이용자 콘텐츠"라 함)에 대해 다음의 각 목에서 정한 바에 따라 “이용자”는 사수톡에게 사용권한을 부여합니다.</p>
            <p>사수톡이 서비스 운영을 위해 필요하다고 판단되는 경우 “이용자”의 콘텐츠가 사수톡의 “현직자” 및 제3자에게 제공될 수 있습니다. 단, “이용자”의 콘텐츠가 제공될 때 “이용자”의 “개인정보”는 제외하고 제공함으로써 “이용자”의 신분을 보호하기 위해 사수톡는 합리적인 모든 노력을 다합니다.</p>
            <p>“이용자”는 “이용자”의 이용자 콘텐츠를 “서비스” 개선 목적으로 분석할 수 있는 권한을 사수톡에게 부여합니다. 단, 사수톡에 부여된 권한은 전 세계를 대상으로 하며, 비독점적이고, 양도 또는 재면허를 부여할 수 있고, 그에 대한 사용료, 기타 반대급부는 무상으로 합니다.</p>
            <p>“이용자”가 사수톡에 부여한 권한은 “이용자”가 제20조에 따라 계약을 해지할 때 종료됩니다. 단, “이용자”가 콘텐츠를 타인과 공유하고 타인이 이를 삭제하지 않았을 경우에는 그러하지 아니합니다. 외부 API등 오픈플랫폼을 제공하는 “서비스”에 저장되는 경우 콘텐츠 유실 등의 책임은 사수톡이 부담하지 않습니다.</p>
            <p>“이용자 콘텐츠”를 삭제하면 컴퓨터의 휴지통을 비우는 것과 유사한 방식으로 “이용자 콘텐츠”가 삭제됩니다. 단, 제거된 “이용자 콘텐츠”는 일정 기간 동안 백업 사본으로 존속될 수 있으며, 백업 사본으로 존속되는 동안에도 타인에게 제공되지는 않습니다.</p>
            <p>어플리케이션을 이용할 경우 “이용자 콘텐츠”나 “이용자”의 정보는 어플리케이션과 공유됩니다. 해당 어플리케이션과 “이용자”의 계약은 어플리케이션이 해당 콘텐츠나 정보를 이용, 저장, 전송하는 방법에 적용됩니다.</p>
            <p>사수톡에 대한 의견을 포함한 제안은 언제든지 환영하지만, (“회원”에게 제안 의무가 없는 것처럼) 사수톡도 보상 의무 없이 이를 활용할 수 있습니다.</p>
            <p>사수톡는 “이용자”의 “이용자 콘텐츠”를 상업적으로 이용하고자 하는 경우, 전화, 팩스, 전자우편 등의 방법을 통해 사전에 “이용자”의 동의를 얻어야 합니다. 단, 회원정보 등 “이용자” 제공한 연락처가 사실과 다르거나 “이용자”가 사수톡의 연락에 응하지 않아 사전에 동의를 구하지 못한 경우, 사수톡는 사후에 동의 절차를 구할 수 있습니다.</p>
            <h6>제 16조. 광고게재 및 광고주와의 거래</h6>
            <p>사수톡는 본 “서비스” 상에 게재된 광고주의 판촉활동 및 광고주와 “회원”간의 교신 또는 거래의 결과로서 발생하는 모든 손실 또는 손해에 대해 책임을 지지 않습니다.</p>
            <h6>제 17조. 서비스 이용시간</h6>
            <p>“서비스”의 이용은 사수톡의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴1일 24시간 가능함을 원칙으로 합니다. 다만 정기 점검 등의 필요로 사수톡이 정한 기간은 예외입니다.</p>
            <p>“서비스”에서 보장하고 있는 사수톡이 “이용자”에게 제공하는 답변 시간은 국가가 지정하는 법정 공휴일 및 토요일, 일요일은 제외하고 계산됩니다.</p>
            <p>사수톡는 “서비스”를 일정범위로 분할하여 각 범위 별로 이용가능 시간을 별도로 정할 수 있습니다. 이 경우 사전에 공지를 통해 그 내용을 알립니다.</p>
            <h6>제 18조. 서비스 제공의 중지 등</h6>
            <p>사수톡는 다음에 해당하는 경우 “서비스” 제공을 중지할 수 있습니다.</p>
            <p>A. 정보통신설비의 보수점검⋅교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 부득이하게 “서비스”의 제공을 일시적으로 중단할 수 있습니다.</p>
            <p>B. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우.</p>
            <p>C. 기타 불가항력적 사유가 있는 경우</p>
            <p>사수톡는 국가비상사태, 정전, “서비스” 설비의 장애 또는 “서비스” 이용의 폭주 등으로 정상적인 “서비스” 이용에 지장이 있는 때에는 “서비스”의 전부 또는 일부를 제한하거나 중지할 수 있습니다.</p>
            <p>사수톡는 제1항 및 제2항의 규정에 의하여 “서비스”의 이용을 제한하거나 중지한 때에는 그 사유 및 제한기간 등을 사수톡를 통하여 공지하거나 전자우편 기타의 방법으로 지체 없이 “회원”에게 알려야 합니다. 다만, 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</p>
            <h2>제 4장. 유료서비스의 제공</h2>
            <h6>제19조. 유료서비스의 이용계약 및 철회 기준</h6>
            <p>“신청회원”은 “사업자”가 제공하는 절차에 의하여 유료서비스 이용계약(이하 “청약”)을 신청합니다. “회사”는 원칙적으로 “회원”의 청약 신청을 승낙합니다. 다만, 다음 각 호에 해당하는 경우에는 승낙하지 않거나 승낙을 유보할 수 있습니다.</p>
            <p>A.실명이 아니거나 타인의 명의를 이용한 경우</p>
            <p>B.허위의 정보를 기재하거나, "회사”가 제시하는 내용을 기재하지 않은 경우</p>
            <p>C.미성년자가 청소년보호법 등 관련법에 의해서 이용이 금지되는 유료 서비스를 이용하고자 하는 경우</p>
            <p>D."서비스"의 위상이나 명예에 부정적인 영향을 줄 수 있는 경우</p>
            <p>E.“유료서비스"에서 제공하는 서비스의 공급량의 제한의 이유로 "서비스"를 제공할 수 없는 경우</p>
            <p>F.이 약관에 위배되는 내용을 “회사”에 요구한 이력이 있는 “회원”일 경우</p>
            <p>“회사”는 계약 체결 전에 “회원”이 청약의 내용을 확인하고, 정정 또는 취소할 수 있도록 적절한 절차를 갖추어야 합니다.</p>
            <p>“회원”는 계약 전에 이 약관 및 개별 안내 페이지에서 “회사”가 안내하는 사항을 숙지하여 착오 없이 거래할 수 있도록 하여야 합니다.</p>
            <p>“회사”의 “유료서비스”는 청약철회가 가능한 것과 청약철회가 제한되는 것으로 구분되어 제공되며, 이러한 내용은 이 약관 또는 “회원”의 “결제” 시 고지됩니다.</p>
            <p>청약철회가 가능한 “유료서비스”를 “결제”한 “회원”은 “결제” 시점부터 7일 이내에 “회사”의 고객센터에 청약 철회를 할 수 있습니다.</p>
            <p>회원은 청약철회 의사를 고객센터 등을 통하여 회사에 그 의사를 표시하여야 하며, 회사는 환불요청을 접수하고 회원의 요청과 환불규정 확인 후, 유료서비스의 형태에 준하여, 환급하거나 환급에 필요한 조치를 취합니다. </p>
            <p>“회원”은 전항의 “청약철회” 기간이 경과되거나 전자상거래 등에서의 소비자 보호에 관한 법률, 콘텐츠 산업 진흥원, 온라인 디지털 콘텐츠 이용자 보호 지침 등 관련 법령에서 정한 청약철회 제한 사유에 해당하는 “유료서비스”의 경우 청약철회를 할 수 없습니다.</p>
            <h6>제20조. 이용계약 해지 및 이용 제한</h6>
            <p>“이용자”가 이용 계약을 해지하고자 하는 경우에는 이용계약해지를 고객센터 또는 회원 정보관리 메뉴를 신청할 수 있습니다. "사업자"는 관련법 등이 정하는 바에 따라 이를 즉시 처리합니다.</p>
            <p>“이용자”가 계약을 해지할 경우, 관련법 및 개인정보보호정책에 따라 사수톡이 회원정보를 보유하는 경우를 제외하고는 해지 즉시 “이용자”의 모든 데이터는 소멸됩니다.</p>
            <p>“회원”이 계약을 해지할 경우, 관련법 및 개인정보취급방침에 따라 “회사”가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 “회원”의 개인정보 데이터는 소멸됩니다.</p>
            <p>"회원"이 계약을 해지하는 경우, "회원"이 서비스 내에서 직접 작성한 "게시물" 및 덧글과 같이 본인 계정으로 등록된 게시물 일체는 삭제되지 않으므로, 본인이 직접 사전에 삭제 후 탈퇴 하시기 바랍니다.</p>
            <p>또한 타인에 의해 담기, 스크랩 등이 되어 재게시 되거나, 공용게시판에 등록된 "게시물" 또한 삭제되지 않으니, 본인이 직접 절차에 의해 삭제 처리를 해야 합니다.</p>
            <h6>제21조. 취소, 환불, 정산 기준</h6>
            <p>사업자는 신청회원이 아래각호의 사유로 청약을 철회한 때에는 지체없이 그 사유를 사수회원에게 통지하고 사전에 재화 등의 대금을 받은 경우 신청을 철회한 날로부터 5영업일 이내에 환불하거나 환불에 필요한 조치를 취합니다.</p>
            <p>사업자는 신청회원이 아래각호의 사유로 청약을 철회한 것을 모두 반영한 후, 사수회원이 제공한 서비스의 종료일 기준으로 익월 말일자에 서비스리워드를 지급합니다. </p>
            <p>사수와 회원이 1:1 형태의 서비스 형태일 경우, 취소 및 환불 기준</p>
            <p>1) 신청회원이 사수회원과의 사수톡 확정 전 철회를 요청하는 경우 신청 회원에게 서비스 이용대금의 100%를 환불합니다. 이 경우 사수회원에게 지급되는 서비스 리워드는 없습니다.</p>
            <p>2) 신청회원이 희망한 상담 일정에 대해 사수회원의 사수톡 수락 및 시간 확정 후에는 서비스 이용대금에 대한 환불이 불가합니다. 이 경우 사수회원에게 지급되는 서비스 리워드는 없으며, 전액 사업자에게 귀속합니다.</p>
            <p>3) 사수회원이 사수톡 수락시 신청회원의 사수톡을 신고하는경우, 신청회원에게 서비스 이용대금의 100%를 환불합니다. 이 경우 사수회원에게 지급되는 리워드는 없습니다.</p>
            <p>4) 사수회원의 요청에 의해 사수톡 수락 후 취소가 된 경우, 플랫폼 운영자는 신청회원에게 다른 사수회원은 제안할 수 있습니다.  신청회원이 제안을 거절할 경우 서비스 이용금액의 100%를 환불합니다. 이 경우 서비스를 취소한 사수회원에게 지급되는 리워드는 없습니다. </p>
            <p>5) 신청회원이 No show 가 발생한 경우, 신청회원은 기 지급한 서비스 이용대금을 환불받을 수 없고, 사수 회원은 서비스 리워드 금액의 50%를 지급받으며, 차액은 사업자에게 귀속합니다. 본 조항은 사수 회원이 서비스 시작시각으로부터 5분 내 해당 서비스를 위해 접속하고 5분이 될 때까지 사수톡에 접속상태인 경우로 제한합니다.</p>
            <p>6) 신청회원과 사수회원간의 시간 조율 요청 중에 신청회원이 취소 버튼을 클릭하거나 고객센터를 통해 환불의사를 밝힌 경우, 기 지급 서비스 이용대금은 신청회원에게 100% 환불됩니다. 이 경우 사수회원에게 지급되는 서비스 리워드는 없습니다.</p>
            <p>7) 사수회원이 No show 가 발생한 경우, 신청회원이 지급한 서비스 이용대금은 100% 환불됩니다. 이 경우 사수회원에게 지급되는 리워드는 없습니다. 본 항은 신청 회원이 서비스 시작시각으로부터 5분 내 해당 서비스를 위해 접속하고 5분이 될 때까지 사수톡에 접속상태인 경우로 제한합니다.</p>
            <p>8) 신청회원과 사수회원 모두 No show 가 발생한 경우 신청회원은 기 지급 서비스 이용대금을 환불받을 수 없고, 사수회원은 리워드 금액을 제공받을 수 없으며, 서비스 이용대금은 전액 사업자에게 귀속합니다.</p>
            <p>9) 사수 회원은 시간재조율을 1회 요청할 수 있으며, 1회를 초과하는 경우 해당 사수톡은 취소되며, 신청회원에게 100% 환불합니다.</p>
            <p>10) 사업자가 사업목적 및 정책과 무관한 사수톡을 취소하는 경우, 기 지급 서비스 이용대금은 신청회원에게 100% 환불됩니다. 이 경우 사수 회원에게 지급되는 서비스 리워드는 없습니다. </p>
            <p>사수와 회원이 1:N 형태의 서비스 형태일 경우, 취소 및 환불 기준</p>
            <p>1) 신청회원이 사수회원이 개설한 팀프로젝트의 모집마감일 이전에 취소 버튼을 클릭하거나 고객센터에 취소를 요청한 경우, 신청회원에게 서비스 이용대금의  100% 를 환불합니다. 이 경우 사수회원에게 지급되는 서비스 리워드는 없습니다.</p>
            <p>2) 신청회원이 사수회원이 개설한 팀프로젝트의 모집마감일이 지났으나, 서비스시작일이 지나지 않았을 때 고객센터로 환불을 요청한 경우, 신청회원이 기 지급한 서비스 이용대금의 90%가 환불됩니다. 이 경우 사수회원에게 지급되는 서비스 리워드는 없으며, 차액은 전액 사업자에게 귀속합니다. </p>
            <p>3) 사수회원이 정한 프로젝트 모집마감일까지 최소인원수가 확보되지 못한 경우, 해당 프로젝트는 서비스되지 않거나 모집 마감일을 연장할 수 있습니다. 단, 신청회원이 서비스 취소를 원할 경우 기 지급한 서비스 이용대금은 100% 환불됩니다. 이 경우 사수회원에게 지급되는 서비스 리워드는 없습니다.</p>
            <p>4) 사수회원이 정한 프로젝트의 1주차에 참여한 후, 신청회원이 고객센터로 취소의사를 밝힌 경우, 신청회원이 기 지급한 서비스 이용대금의 50%가 환불됩니다. 이 경우 사수회원에게 지급되는 서비스 리워드는 없으며, 차액은 전액 사업자에게 귀속합니다. </p>
            <p>5) 사수회원이 정한 프로젝트의 1,2주차에 참여한 후, 신청회원이 고객센터로 취소의사를 밝힌 경우, 신청회원이 기 지급한 서비스 이용대금에 대한 환불이 불가합니다. 이 경우 사수회원에게 지급되는 서비스 리워드는 없으며, 차액은 전액 사업자에게 귀속합니다. </p>
            <h6>제 22조. 청약철회 시 대금의 환급</h6>
            <p>“사업자”는 “유료서비스”를 “결제”한 “회원”에게 해당 “유료서비스”를 공급하기 곤란하다는 것을 알았을 때, 즉시 해당 사실을 해당 “회원”에게 통보하고 해당 “회원”의 동의를 얻은 후 취소하여야 하며, “회사”는 해당“회원”의 결제일로부터 3영업일 이내에 환불 및 환불에 필요한 조치를 취해야 합니다.</p>
            <p>결제대금의 환불은“사업자”가 환불을 승인한 날로부터 3영업일 이내에 대금의 “결제”와 동일한 방법(신용카드 결제취소 등)으로 이루어집니다.</p>
            <p>신용카드를 통한 “결제” 대금 환불 시 “결제” 수단의 전자 결제 대행 사업자, 전자 결제 대행 또는 중개서비스 사업자의 정책 상 특정 기간이 지나 동일한 결제수단의 취소가 불가능한 경우에는 환불 정산액으로 환불이 진행됩니다. 환불정산액의 경우 회원정보 수집 및 내부 프로세스에 따라 영업일 15일 이내 처리될 수 있습니다.</p>
            <p>휴대폰소액결제의 경우, 이동통신사 정책에 따라 결제승인 및 취소는 해당 월 내에만 가능하며 익월로 넘어갈 경우 계좌이체로 처리됩니다.</p>
            <p>환불 과정에서 금융수수료가 발생하는 경우, 관련 법률에 따라 결제금액의 10%를 공제하고 환불할 수 있습니다.</p>
            <h6>제23조. 부정이용 금지 및 차단</h6>
            <p>"사업자"는 다음 각호에 해당하는 경우를 부정 이용행위로 봅니다.</p>
            <p>동일한 ID로 2대 이상의 기기에서 동시접속이 발생하는 경우</p>
            <p>동일한 ID로 다수의 PC 또는 IP에서 서비스를 이용하는 경우</p>
            <p>자신의 ID 및 강의 등의 서비스를 타인이 이용하도록 하는 경우</p>
            <p>자신의 ID 및 강의 등의 서비스를 타인에게 판매, 대여, 양도하는 행위 및 이를 광고하는 행위</p>
            <p>서비스 이용 중, 복제프로그램을 실행하는 경우 또는 녹화를 하거나 시도하는 경우</p>
            <p>“사수회원”의 “게시물”을 재배포 하는경우</p>
            <p>회사는 전항에 따른 부정 이용자가 발견 되었을 경우, 다음 각호에 따른 조치를 취할 수 있습니다.</p>
            <p>[1차 발견 시] 전자우편, 쪽지, 팝업창을 통하여 경고합니다.</p>
            <p>[2차 발견 시] 강제 탈퇴 처리되며 “사업자”의 법률 대리인을 통한 고발조치와 민사소송을 진행하게 됩니다.</p>
            <p>"회원"은 전항 제2호의 조치를 이유로, 서비스 이용기간의 연장을 요구할 수 없습니다.</p>
            <p>"회원"은 회사로부터의 본 조 제2항의 조치에 이의가 있는 경우, 회사의 법률 대리인을 통해 소명할 수 있습니다.</p>
            <p>"사업자"는 회원의 서비스 이용 중에 수집ㆍ확인된 IP정보 등의 자료를 토대로, 서버를 통하여 부정이용 여부를 분류, 확인합니다.</p>
            <p>"사업자"는 이용자가 서비스 이용 중에 복제프로그램을 실행시키거나 동일한 ID로 동시 접속을 하는 경우, 서비스 이용 접속을 강제로 종료 시킵니다.</p>
            <h2>제 5장. 손해배상 및 기타 사항</h2>
            <h6>제 24조. 손해배상 및 면책</h6>
            <p>천재지변 또는 이에 준하는 불가항력으로 인하여 “서비스”를 제공할 수 없는 경우에는 사수톡의 “서비스” 제공에 관한 책임이 면제됩니다.</p>
            <p>사수톡은 “회원”의 귀책사유로 인한 “서비스” 이용의 장애에 대하여 책임을 지지 않습니다.</p>
            <p>사수톡은 “회원”이 “서비스”를 이용하여 기대하는 수익을 상실한 것이나 “서비스”를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.</p>
            <p>사수톡은 제휴회사가 제공하거나 “회원”이 “서비스”에 게재한 정보, 자료 등의 사실 유무 신뢰도, 정확성 등에 관하여는 책임을 지지 않습니다.</p>
            <p>사수톡은 “서비스” 이용과 관련하여 가입자에게 발생한 손해 가운데 가입자의 고의, 과실에 의한 손해에 대하여 책임을 지지 않습니다.</p>
            <h6>제 25조. 이용자 보호</h6>
            <p>사수톡은 “이용자”의 권익을 보호하기 위해, 상호명, 대표자 성명, E-mail, 등 사수톡의 신원정보를 홈페이지를 통해 표시하고 있습니다.</p>
            <p>사수톡은 “서비스” 이용약관을 공시하여 “이용자”의 피해를 최소화하기 위해 노력합니다.</p>
            <h6>제 26조. 분쟁 해결</h6>
            <p>사수톡은 “이용자”로부터 제기되는 불만사항 및 의견을 우선적으로 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 “이용자”에게 그 사유와 처리 일정을 즉시 통보합니다.</p>
            <p>사수톡은 “이용자”는 “서비스”와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 합니다.</p>
            <p>“이용자” 의 사수톡에서의 활동이나 콘텐츠, 정보와 관련하여 타인이 사수톡을 상대로 법적 소송을 제기하면, “이용자”는 해당 소송과 관련된 손해나 손실, 경비(합리적인 변호사 수임료와 비용 포함)로부터 사수톡을 면책 및 보호해야 합니다.</p>
            <p>사수톡는 제3자의 활동, 콘텐츠, 정보, 데이터에 대해 책임지지 않으며, 인지 여부에 관계 없이 해당 제3자를 상대로 제기하는 법적 소송으로 인하거나 그와 관련하여 발생하는 소송과 손해로부터 사수톡과 사수톡의 대표 및 임직원을 면책합니다. 본 조항이나 사수톡으로 인하여 발생하는 채무 총액은 지난 일년간 사수톡에 납부한 금액을 초과할 수 없습니다.</p>
            <p>제1항 내지 제4항과 관련하여 발생하는 법률분쟁은 서울중앙지방법원을 배타적인 제1심 전속관할 법원으로 하여 해결합니다.</p>
            <h6>제27조. 준거법 및 재판관할</h6>
            <p>본 약관은 대한민국법령에 의하여 규정되고 이행됩니다.</p>
            <p>본 약관에 명시되지 않은 사항에 대해서는 관련법령에 의하고, 법에 명시되지 않은 부분에 대하여는 관습에 의합니다.</p>
            <p>"회사"와 "회원"간 발생한 분쟁에 관한 소송은 제소 당시의 "회사"의 주소에 의한 관할 지방법원을 전속관할로 합니다.</p>
            <h2>부칙</h2>
            <p>이 약관은 2022년 12월 1일부터 시행됩니다.</p>
        </div>
    );
}
