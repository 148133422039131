//
//  welcomeCarousel.js
//  datatown
//
//  Created by Mumakil on 2022. 10. 24..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React from 'react';

// util
import DataUtil from 'util/dataUtil';

// package
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


/**
 * @protocol WelcomeCarousel
 * @date 2022/10/24
 * @brief 환영 메시지 관련 캐러셀
 */
function WelcomeCarousel(props) {
    var itemList = [];
    
    if( DataUtil.validateArray(props.list) ) {
        for( var i = 0; i < props.list.length; i++ ) {
            itemList.push(  <div
                                key={'carousel' + String(i)}
                                className="item">
                                <div className="inner">
                                    <p>{props.list[i].text ? props.list[i].text : ""}</p>
                                </div>
                            </div>)
        }
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (    <div
                    className="carousel">
                    <Slider {...settings}>
                        {itemList}
                    </Slider>
                </div>);
}

export default WelcomeCarousel;