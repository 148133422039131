//
//  serviceNoticeInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 21..
//  Copyright © 2022년 Melephant. All rights reserved.
//


export const SERVICENOTICEINFO_LIST         = 'notice_list';
export const SERVICENOTICEINFO_CONTAINER    = 'notice_info';
export const SERVICENOTICEINFO_ID           = 'notice_idx';
export const SERVICENOTICEINFO_CATEGORY     = 'category';
export const SERVICENOTICEINFO_SUBJECT      = 'subject';
export const SERVICENOTICEINFO_CONTENTS     = 'contents';
export const SERVICENOTICEINFO_VIEW_COUNT   = 'view_count';
export const SERVICENOTICEINFO_REGDATE      = 'regdate';


/**
 * @protocol ServiceNoticeInfo
 * @date 2022/12/21
 * @brief 서비스 공지사항 정보
 */
export default class ServiceNoticeInfo {
    constructor() {
        this.noticeId = 0;
        this.category = '';
        this.subject = '';
        this.contents = '';
        this.viewCount = 0;
        this.regdate = null;
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(SERVICENOTICEINFO_ID) ) {
            this.noticeId = dict[SERVICENOTICEINFO_ID];
        }

        if( dict.hasOwnProperty(SERVICENOTICEINFO_CATEGORY) ) {
            this.category = dict[SERVICENOTICEINFO_CATEGORY];
        }

        if( dict.hasOwnProperty(SERVICENOTICEINFO_SUBJECT) ) {
            this.subject = dict[SERVICENOTICEINFO_SUBJECT];
        }

        if( dict.hasOwnProperty(SERVICENOTICEINFO_CONTENTS) ) {
            this.contents = dict[SERVICENOTICEINFO_CONTENTS].replaceAll('\\n', '\n');;
        }

        if( dict.hasOwnProperty(SERVICENOTICEINFO_VIEW_COUNT) ) {
            this.viewCount = dict[SERVICENOTICEINFO_VIEW_COUNT];
        }

        if( dict.hasOwnProperty(SERVICENOTICEINFO_REGDATE) ) {
            this.regdate = new Date(dict[SERVICENOTICEINFO_REGDATE]);
        }
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [SERVICENOTICEINFO_ID]: this.noticeId,
            [SERVICENOTICEINFO_CATEGORY]: this.category,
            [SERVICENOTICEINFO_SUBJECT]: this.subject,
            [SERVICENOTICEINFO_CONTENTS]: this.contents,
            [SERVICENOTICEINFO_VIEW_COUNT]: this.viewCount,
            [SERVICENOTICEINFO_REGDATE]: this.regdate,
        };
        return dict;
    }
}
