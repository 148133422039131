//
//  App.js
//  datatown
//
//  Created by Mumakil on 2022. 8. 11..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import './App.css';

// common
import ConstantPath from 'constants/path';
import { ProfileMenuKey, TermsMenuKey } from 'constants/key';

// auth
import LoginScreen from 'screens/auth/loginScreen';
import TermsScreen from 'screens/auth/termsScreen';
import AdditionalScreen from 'screens/auth/additionalScreen';
import ApplyScreen from 'screens/auth/applyScreen';
import OAuthKakaoScreen from 'screens/auth/oauthKakaoScreen';

// worry
import WorryScreen from 'screens/worry/worryScreen';

// solution
import SolutionScreen from 'screens/solution/solutionScreen';
import SolutionCounselScreen from 'screens/solution/solutionCounselScreen';
import SolutionProjectScreen from 'screens/solution/solutionProjectScreen';
import SolutionSalesScreen from 'screens/solution/solutionSalesScreen';
import SolutionM2mScreen from 'screens/solution/solutionM2mScreen';
import SolutionRequestScreen from 'screens/solution/solutionReqeustScreen';
import SolutionManageScreen from 'screens/solution/solutionManageScreen';
import ProjectIntroScreen from 'screens/solution/projectIntroScreen';
import ProjectDetailScreen from 'screens/solution/projectDetailScreen';
import ProjectNoticeScreen from 'screens/solution/projectNoticeScreen';
import ProjectNoticeDetailScreen from 'screens/solution/projectNoticeDetailScreen';
import ProjectManageScreen from 'screens/solution/projectManageScreen';
import ProjectAttendanceScreen from 'screens/solution/projectAttendanceScreen';
import CounselIntroScreen from 'screens/solution/counselIntroScreen';
import ProjectM2mDetailScreen from 'screens/solution/projectM2mDetailScreen';

// review
import ProjectReviewScreen from 'screens/solution/projectReviewScreen';
import MentorReviewScreen from 'screens/solution/mentorReviewScreen';

// payment
import ProjectConfirmScreen from 'screens/payment/projectConfirmScreen';
import ProjectInvoiceScreen from 'screens/payment/projectInvoiceScreen';
import CounselConfirmScreen from 'screens/payment/counselConfirmScreen';
import CounselInvoiceScreen from 'screens/payment/counselInvoiceScreen';
import CounselPaymentScreen from 'screens/payment/counselPaymentsScreen';
import CounselPaymentSuccessScreen from 'screens/payment/counselPaymentsSuccessScreen';
import CounselPaymentFailScreen from 'screens/payment/counselPaymentsFailScreen';
import ProjectPaymentScreen from 'screens/payment/projectPaymentScreen';
import ProjectPaymentSuccessScreen from 'screens/payment/projectPaymentSuccessScreen';
import ProjectPaymentFailScreen from 'screens/payment/projectPaymentFailScreen';

// profile
import ProfileScreen from 'screens/profile/profileScreen';

// nft
import NftScreen from 'screens/nft/nftScreen';

// help
import HelpScreen from 'screens/help/helpScreen';
import HelpM2mDetailScreen from 'screens/help/helpM2mDetailScreen';
import HelpNoticeDetailScreen from 'screens/help/helpNoticeDetailScreen';

// company
import CompanyScreen from 'screens/main/companyScreen';

// blog
import BlogListScreen from 'screens/blog/blogListScreen';
import BlogDetailScreen from 'screens/blog/blogDetailScreen';

// common
import NotFoundScreen from 'screens/common/notFoundScreen';

// package
import { Routes, Route } from 'react-router';


/**
 * @protocol App
 * @date 2022/08/11
 * @brief App
 */
function App() {
	return (
		<div className="App">
			<Routes>
				<Route path={ConstantPath.PATH_LOGIN} element={<LoginScreen/>}/>
				<Route path={ConstantPath.PATH_SIGNUP_ADDITIONAL} element={<AdditionalScreen/>}/>
				<Route path={ConstantPath.PATH_OAUTH_KAKAO} element={<OAuthKakaoScreen/>}/>

				<Route path={ConstantPath.PATH_TERMS_SERVICE} element={<TermsScreen active={TermsMenuKey.TERM_MENU_SERVICE}/>}/>
				<Route path={ConstantPath.PATH_TERMS_PRIVACY} element={<TermsScreen active={TermsMenuKey.TERM_MENU_PRIVACY}/>}/>

				<Route path={ConstantPath.PATH_WORRY} element={<WorryScreen/>}/>
				
				<Route path={ConstantPath.PATH_SOLUTION} element={<SolutionProjectScreen/>}/>
				<Route path={ConstantPath.PATH_SOLUTION_COUNSEL} element={<SolutionCounselScreen/>}/>
				<Route path={ConstantPath.PATH_SOLUTION_PROJECT} element={<SolutionScreen/>}/>
				<Route path={ConstantPath.PATH_SOLUTION_APPLY} element={<ApplyScreen/>}/>
				<Route path={ConstantPath.PATH_SOLUTION_SALES} element={<SolutionSalesScreen/>}/>
				<Route path={ConstantPath.PATH_SOLUTION_QNA} element={<SolutionM2mScreen/>}/>
				<Route path={ConstantPath.PATH_SOLUTION_QNA_DETAIL} element={<ProjectM2mDetailScreen/>}/>
				<Route path={ConstantPath.PATH_SOLUTION_REQUEST} element={<SolutionRequestScreen/>}/>
				<Route path={ConstantPath.PATH_SOLUTION_MANAGE} element={<SolutionManageScreen/>}/>
				<Route path={ConstantPath.PATH_SOLUTION_BLOG} element={<BlogListScreen/>}/>
				<Route path={ConstantPath.PATH_SOLUTION_BLOG_DETAIL} element={<BlogDetailScreen/>}/>

				<Route path={ConstantPath.PATH_PROJECT} element={<ProjectDetailScreen/>}/>
				<Route path={ConstantPath.PATH_PROJECT_INTRO} element={<ProjectIntroScreen/>}/>
				<Route path={ConstantPath.PATH_COUNSEL_INTRO} element={<CounselIntroScreen/>}/>
				<Route path={ConstantPath.PATH_PROJECT_MANAGE} element={<ProjectManageScreen/>}/>
				<Route path={ConstantPath.PATH_PROJECT_ATTENDANCE} element={<ProjectAttendanceScreen/>}/>
				<Route path={ConstantPath.PATH_PROJECT_CONFIRM} element={<ProjectConfirmScreen/>}/>
				<Route path={ConstantPath.PATH_COUNSEL_CONFIRM} element={<CounselConfirmScreen/>}/>
				<Route path={ConstantPath.PATH_PROJECT_INVOICE} element={<ProjectInvoiceScreen/>}/>
				<Route path={ConstantPath.PATH_COUNSEL_INVOICE} element={<CounselInvoiceScreen/>}/>
				<Route path={ConstantPath.PATH_PROJECT_NOTICE} element={<ProjectNoticeScreen/>}/>
				<Route path={ConstantPath.PATH_PROJECT_NOTICE_DETAIL} element={<ProjectNoticeDetailScreen/>}/>

				<Route path={ConstantPath.PATH_PROJECT_REVIEW} element={<ProjectReviewScreen/>}/>
				<Route path={ConstantPath.PATH_MENTOR_REVIEW} element={<MentorReviewScreen/>}/>
				
				<Route path={ConstantPath.PATH_EDIT_PROFILE} element={<ProfileScreen active={ProfileMenuKey.PROFILE_MENU_EDIT_PROFILE}/>}/>
				<Route path={ConstantPath.PATH_EDIT_MENTOR} element={<ProfileScreen active={ProfileMenuKey.PROFILE_MENU_EDIT_MENTOR}/>}/>
				
				<Route path={ConstantPath.PATH_HELP} element={<HelpScreen/>}/>
				<Route path={ConstantPath.PATH_HELP_FAQ} element={<HelpScreen/>}/>
				<Route path={ConstantPath.PATH_HELP_M2M} element={<HelpScreen/>}/>
				<Route path={ConstantPath.PATH_HELP_M2M_DETAIL} element={<HelpM2mDetailScreen/>}/>
				<Route path={ConstantPath.PATH_HELP_NOTICE} element={<HelpScreen/>}/>
				<Route path={ConstantPath.PATH_HELP_NOTICE_DETAIL} element={<HelpNoticeDetailScreen/>}/>
				
				{/* <Route path={ConstantPath.PATH_NFT} element={<NftScreen/>}/> */}
				
				<Route path={ConstantPath.PATH_COMPANY} element={<CompanyScreen/>}/>

				<Route path={ConstantPath.PATH_PAYMENT_COUNSEL} element={<CounselPaymentScreen/>}/>
				<Route path={ConstantPath.PATH_PAYMENT_COUNSEL_SUCCESS} element={<CounselPaymentSuccessScreen/>}/>
				<Route path={ConstantPath.PATH_PAYMENT_COUNSEL_FAIL} element={<CounselPaymentFailScreen/>}/>
				<Route path={ConstantPath.PATH_PAYMENT_PROJECT} element={<ProjectPaymentScreen/>}/>
				<Route path={ConstantPath.PATH_PAYMENT_PROJECT_SUCCESS} element={<ProjectPaymentSuccessScreen/>}/>
				<Route path={ConstantPath.PATH_PAYMENT_PROJECT_FAIL} element={<ProjectPaymentFailScreen/>}/>

				<Route path='*' element={<NotFoundScreen/>}/>
			</Routes>
		</div>
	);
}

export default App;
