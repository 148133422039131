//
//  counselConfirmScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 29..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
} from 'react';

// common
import ConstantValue from 'constants/value';
import ConstantPath from 'constants/path';
import { CommonKey, ReplaceKey } from 'constants/key';
import { CommonList } from 'constants/list';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkSolution from 'network/networkSolution';

// info
import { PROJECTINFO_CONTAINER } from 'info/solution/projectInfo';
import CounselApplyInfo from 'info/solution/counselApplyInfo';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault, popupShowDefaultCancel } from 'redux/actions/popupAction';

// component
import Layout from 'components/layout/layout';
import ProjectImageView from 'components/image/projectImageView';

// package
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


/**
 * @protocol CounselConfirmScreen
 * @date 2022/11/29
 * @brief 팀프로젝트 결제 확인 화면
 */
function CounselConfirmScreen(props) {
    const { t } = useTranslation();
    
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isLogin = useSelector(state => state.session.isLogin);
    const user = useSelector(state => state.session.user);

    const [project, setProject] = useState(null);
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [contactCofirm, setContactConfirm] = useState('');
    const [agreement, setAgreement] = useState(false);
    const [currentJob, setCurrentJob] = useState(null);
    const [tos, setTos] = useState(null);
    const [dateFirst, setDateFirst] = useState(null);
    const [dateSecond, setDateSecond] = useState(null);
    const [dateThird, setDateThird] = useState(null);

    const jobList = CommonList.JobList();
    const tosList = CommonList.TermOfServiceList();
    const weekList = CommonList.WeekList();

    useEffect(() => {
        if( location.state && !project ) {
            var projectInfo = location.state[PROJECTINFO_CONTAINER];
            setProject(projectInfo);
            
            var jobCodeIndex = jobList.findIndex(element => element.id === projectInfo.mentor.currentJobCode);
            if( jobCodeIndex >= 0 ) {
                setCurrentJob(jobList[jobCodeIndex].title);
            }
            var tosIndex = tosList.findIndex(element => element.id === projectInfo.mentor.empPeriod);
            if( tosIndex >= 0 ) {
                setTos(tosList[tosIndex].title);
            }
        }
    }, [location.state, jobList, tosList, project]);

    /**
     * 상담 시간 적합성 확인
     * @param {Date} value 선택한 날짜
     * @returns {Boolean} 적합성
     */
    const validateCounselTime = (value) => {
        if( DataUtil.validateArray(project.counselScheduleList) ) {
            var index = project.counselScheduleList.findIndex(item => item.woa === String(value.getDay()));
            
            // 상담 스케쥴에서 요일 체크
            if( index >= 0 ){
                var selectHour = value.getHours();
                var selectMinute = value.getMinutes();
                var startArray = DataUtil.convertTimeStringToDate(project.counselScheduleList[index].start);
                var endArray = DataUtil.convertTimeStringToDate(project.counselScheduleList[index].end);
                
                // 해당 요일 시작일 이후, 종료일 이전 인지 체크
                if( DataUtil.compareTimes(selectHour, selectMinute, startArray[0], startArray[1], 0) &&
                DataUtil.compareTimes(selectHour, selectMinute, endArray[0], endArray[1], 1) ) {
                    return true;
                }
            }
        }

        return false;
    }
    
    const onNameChanged = ({ target: { value}}) => setName(value);
    const onContactChanged = ({ target: { value}}) => setContact(value);
    const onContactConfirmChanged = ({ target: { value}}) => setContactConfirm(value);
    const onAgreementChanged = ({ target: { value }}) => setAgreement(value);
    const onCancelPressed = () => {
        dispatch(popupShowDefault(t('alert_cancel_payment'), () => {
            navigate(-1);
        }));
    }
    const onDateFirstChanged = (value) => setDateFirst(value);
    const onDateSecondChanged = (value) => setDateSecond(value);
    const onDateThirdChanged = (value) => setDateThird(value);

    const onSubmitPressed = (event) => {
        event.preventDefault();

        // 로그인 체크 후, 화면 전환 처리
        if( isLogin ) {
            // 연락처 매칭 확인
            if( contact !== contactCofirm ) {
                dispatch(popupShowDefault(t('alert_not_match_contact')));
                return;
            }
    
            // 날짜 입력 확인
            if( dateFirst === null ) {
                dispatch(popupShowDefault(t('alert_not_entered_counsel_schedule').replace(ReplaceKey.REPLACE_DATE, t('input_date_first'))));
                return;
            }
    
            if( dateSecond === null ) {
                dispatch(popupShowDefault(t('alert_not_entered_counsel_schedule').replace(ReplaceKey.REPLACE_DATE, t('input_date_second'))));
                return;
            }
    
            if( dateThird === null ) {
                dispatch(popupShowDefault(t('alert_not_entered_counsel_schedule').replace(ReplaceKey.REPLACE_DATE, t('input_date_third'))));
                return;
            }
    
            // 날짜 요일별 적합한지 확인
            if( !validateCounselTime(dateFirst) ) {
                dispatch(popupShowDefault(t('alert_invalid_counsel_schedule').replace(ReplaceKey.REPLACE_DATE, t('input_date_first'))));
                return;
            }
    
            if( !validateCounselTime(dateSecond) ) {
                dispatch(popupShowDefault(t('alert_invalid_counsel_schedule').replace(ReplaceKey.REPLACE_DATE, t('input_date_second'))));
                return;
            }
    
            if( !validateCounselTime(dateThird) ) {
                dispatch(popupShowDefault(t('alert_invalid_counsel_schedule').replace(ReplaceKey.REPLACE_DATE, t('input_date_third'))));
                return;
            }

            var apply = new CounselApplyInfo();
            apply.projectId = project.projectId;
            apply.applyMemberId = user.memberId;
            apply.memberName = name;
            apply.contact = contact;
            apply.firstdate = dateFirst;
            apply.seconddate = dateSecond;
            apply.thirddate = dateThird;

            NetworkSolution.applyProjectCounsel((res) => {
                dispatch(popupShowDefault(t('alert_complete_apply_project_counsel'), () => {
                    navigate(ConstantPath.PATH_SOLUTION_REQUEST);
                }));
            }, (res) => {
                dispatch(popupShowDefault(t('alert_network_error')));
            }, apply);
        }
        else {
            dispatch(popupShowDefaultCancel(t('alert_not_login'), () => {
                setTimeout(() => {
                    navigate(ConstantPath.PATH_LOGIN, {
                        state: { [CommonKey.REDIRECT]: location.pathname }
                    });
                }, 100);
            }), null);
        }
    }

    /**
     * 스케쥴 목록 ui 렌더링
     * @returns {Array} 스케쥴 목록
     */
    const renderSchedule = () => {
        var itemList = [];
        
        if( DataUtil.validateArray(project.counselScheduleList) ) {
            var index = 0;
            project.counselScheduleList.forEach(element => {
                var weekIndex = weekList.findIndex(item => item.id === element.weekofday);
                itemList.push(  <div className="card"
                                    key={"schedule" + String(index)}>
                                    <div className="card-header" id="headingOne">
                                        <h5 className="mb-0">
                                            <p>{weekList[weekIndex].title + ' ' +
                                            element.start + '~' + element.end}</p>
                                        </h5>
                                    </div>
                                </div>);
                index++;
            });
        }
        return itemList;
    }

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}
            visibleSolutionHeader={true}
            location={ConstantPath.PATH_SOLUTION_COUNSEL}>
            <div className="gap" style={{textAlign: 'left'}}>
                <div className="container">
                    <div className="row">
                    <Form className="c-form" onSubmit={onSubmitPressed}>
                        <div className="col-lg-12">
                            <div id="page-contents" className="row merged20">
                                <div className="col-lg-12">
                                    <div className="main-wraper">
                                    {
                                        project ?
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <h4 className="main-title">{t('counsel_confirm_section_counsel')}</h4>
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-5">
                                                <div className="full-book">
                                                    <ProjectImageView
                                                        maxWidth='100%'
                                                        imageUrl={project.gateImgUrl}/>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-7">
                                                <div className="prod-detail">
                                                    <h4>{project.mentor.nickname}</h4>
                                                    <span>{t('counsel_confirm_price')}
                                                        <i>
                                                        {
                                                            Number(project.price) === 0 ?
                                                            t('word_free') :
                                                            t('word_currency') + DataUtil.numberWithCommas(Number(project.price))
                                                        }
                                                        </i>
                                                    </span>
                                                    <span style={{whiteSpace: 'pre-wrap'}}>{project.intro}</span>
                                                    <span>{currentJob}</span>
                                                    <span>{project.mentor.company}</span>
                                                    <span>{tos}</span>
                                                    <span style={{whiteSpace: 'pre-wrap'}}>{project.mentor.career}</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="prod-detail">
                                                    <h4 className="main-title">{t('counsel_confirm_section_schedule')}</h4>
                                                    <div className="question-collaps">
                                                        <div id="accordion">
                                                            {renderSchedule()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12"
                                                style={{marginTop: '20px'}}>
                                                <h4 className="main-title">{t('counsel_confirm_section_apply')}</h4>
                                                <div className="col-lg-6">
                                                    <input type="text"
                                                        style={{backgroundColor: '#fff'}}
                                                        value={name}
                                                        placeholder={t('input_participant')}
                                                        required
                                                        maxLength={ConstantValue.INPUT_MAX_LENGTH_NAME}
                                                        onChange={onNameChanged}/>
                                                </div>
                                                <div className="col-lg-6"/>
                                                <div className="col-lg-6">
                                                    <input type="text"
                                                        style={{backgroundColor: '#fff'}}
                                                        pattern="[0-9]+"
                                                        value={contact}
                                                        placeholder={t('input_contact')}
                                                        required
                                                        maxLength={ConstantValue.INPUT_MAX_LENGTH_PHONE}
                                                        onChange={onContactChanged}/>
                                                </div>
                                                <div className="col-lg-6">
                                                    <input type="text"
                                                        style={{backgroundColor: '#fff'}}
                                                        pattern="[0-9]+"
                                                        value={contactCofirm}
                                                        placeholder={t('input_contact_confirm')}
                                                        required
                                                        maxLength={ConstantValue.INPUT_MAX_LENGTH_PHONE}
                                                        onChange={onContactConfirmChanged}/>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12"
                                                style={{marginTop: '20px'}}>
                                                <h4 className="main-title">{t('counsel_confirm_section_wish')}</h4>
                                                <div className="col-lg-6"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                    <label htmlFor="date_first"
                                                        style={{marginBottom: '20px'}}>
                                                        {t('counsel_confirm_date_first')}
                                                    </label>
                                                    <div
                                                        style={{marginLeft: '20px', flex: 1}}>
                                                        <ReactDatePicker
                                                            selected={dateFirst}
                                                            onChange={(date) => onDateFirstChanged(date)}
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            timeIntervals={30}
                                                            timeCaption="time"
                                                            placeholderText={t('input_date_first')}
                                                            dateFormat="yyyy. MM. dd. hh:mm aa"/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                    <label htmlFor="date_second"
                                                        style={{marginBottom: '20px'}}>
                                                        {t('counsel_confirm_date_second')}
                                                    </label>
                                                    <div
                                                        style={{marginLeft: '20px', flex: 1}}>
                                                        <ReactDatePicker
                                                            selected={dateSecond}
                                                            onChange={(date) => onDateSecondChanged(date)}
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            timeIntervals={30}
                                                            timeCaption="time"
                                                            placeholderText={t('input_date_second')}
                                                            dateFormat="yyyy. MM. dd. hh:mm aa"/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                    <label htmlFor="date_third"
                                                        style={{marginBottom: '20px'}}>
                                                        {t('counsel_confirm_date_third')}
                                                    </label>
                                                    <div
                                                        style={{marginLeft: '20px', flex: 1}}>
                                                        <ReactDatePicker
                                                            selected={dateThird}
                                                            onChange={(date) => onDateThirdChanged(date)}
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            timeIntervals={30}
                                                            timeCaption="time"
                                                            placeholderText={t('input_date_third')}
                                                            dateFormat="yyyy. MM. dd. hh:mm aa"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12"
                                                style={{marginTop: '20px'}}>
                                                <h4 className="main-title">{t('counsel_confirm_section_note')}</h4>
                                                <span style={{whiteSpace: 'pre-wrap'}}>{t('counsel_confirm_note')}</span>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12"
                                                style={{marginTop: '20px'}}>
                                                <h4 className="main-title">{t('counsel_confirm_section_refund')}</h4>
                                                <span style={{whiteSpace: 'pre-wrap'}}>{t('counsel_confirm_refund')}</span>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12"
                                                style={{marginTop: '20px'}}>
                                                <h4 className="main-title">{t('counsel_confirm_section_noshow')}</h4>
                                                <span style={{whiteSpace: 'pre-wrap'}}>{t('counsel_confirm_noshow')}</span>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 row"
                                                style={{marginTop: '40px'}}>
                                                <div className="col-lg-6">
                                                    <input type="checkbox"
                                                        id="agreement"
                                                        value={agreement}
                                                        required
                                                        onClick={onAgreementChanged}/>
                                                    <label htmlFor="agreement">{t('counsel_confirm_agreement')}</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 row">
                                                <button className="cancel-btn"
                                                    style={{marginRight: '10px'}}
                                                    onClick={onCancelPressed}>{t('button_cancel')}</button>
                                                <button className="main-btn">{t('button_apply')}</button>
                                            </div>
                                        </div> :
                                    null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default CounselConfirmScreen;