//
//  notFoundScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 10. 24..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React from 'react';

// string
import { useTranslation } from 'react-i18next';

// component
import Layout from 'components/layout/layout';


/**
 * @protocol NotFoundScreen
 * @date 2022/10/14
 * @brief 메인 화면
 */
function NotFoundScreen() {
    const { t } = useTranslation();

    return (
        <Layout
            visibleHeader={false}
            visibleFooter={false}>
            <div className="theme-layout">
                <div className="error-page">
                    <div className="logo top-left">
                        <img src="images/logo.png" alt=""/>
                        <span>{t('common_service')}</span>
                    </div>
                    <div id="container-inside">
                        <div id="circle-small"></div>
                        <div id="circle-medium"></div>
                        <div id="circle-large"></div>
                        <div id="circle-xlarge"></div>
                        <div id="circle-xxlarge"></div>
                    </div>
                    <div className="error-data">
                        <figure>
                            <img src="images/resources/error.png" alt=""/>
                        </figure>
                        <h1>{t('notfound_message')}</h1>
                        <a className="main-btn" href="/" title="">{t('button_home')}</a>
                    </div>
                </div>	
            </div>
        </Layout>
    );
}

export default NotFoundScreen;