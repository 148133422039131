//
//  projectNoticeScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 2..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
} from 'react';

// common
import { CommonKey, ReplaceKey } from 'constants/key';
import ConstantPath from 'constants/path';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkNotice from 'network/networkNotice';
import NetworkStatus from 'network/common/networkStatus';

// info
import NoticeInfo, {
    NOTICEINFO_LIST,
} from 'info/solution/noticeInfo';
import { PROJECTINFO_NAME } from 'info/solution/projectInfo';
import { RESPONSEINFO_CONTAINER } from 'info/common/responseInfo';
import { USERINFO_MEMBER_ID } from 'info/user/userInfo';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';

// component
import Layout from 'components/layout/layout';
import NoticeCell from 'components/cell/noticeCell';
import PageList from 'components/list/pageList';
import NoticeRegisterPopupView from 'components/modal/noticeRegisterPopupView';

// package
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";


/**
 * @protocol ProjectNoticeScreen
 * @date 2022/12/2
 * @brief 팀프로젝트 공지사항 화면
 */
function ProjectNoticeScreen(props) {
    var params = useParams();
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    
    const isLogin = useSelector(state => state.session.isLogin);
    const user = useSelector(state => state.session.user);

    const [projectName, setProjectName] = useState('');
    const [mentorId, setMentorId] = useState('');
    const [dataList, setDataList] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [registerVisible, setRegisterVisible] = useState(false);

    useEffect(() => {

    }, [dispatch, t, params]);

    // 데이터 요청 처리
    const fetchData = async (pageNum, pageSize) => {
        NetworkNotice.getProjectNoticeList((res) => {
            setDataList(res[NOTICEINFO_LIST]);
            setMentorId(res[USERINFO_MEMBER_ID]);
            setTotalCount(res[CommonKey.PAGE_TOTAL_COUNT]);
            setProjectName(res[PROJECTINFO_NAME]);
        }, (res) => {
            dispatch(popupShowDefault(t('alert_network_error')));
        }, params.project_id, params.ordinal, pageNum, pageSize);
    };

    /**
     * 등록 버튼 클릭시 호출
     */
    const onRegisterPressed = () => {
        setRegisterVisible(true);
    }

    /**
     * 취소 버튼 클릭시 호출
     */
     const onRegisterCancelPressed = () => {
        setRegisterVisible(false);
    }

    /**
     * 작성 버튼 클릭시 호출
     * @param {String} title 공지사항 제목
     * @param {String} content 공지사항 내용
     */
    const onRegisterConfirmPressed = (title, content) => {
        setRegisterVisible(false);

        var noticeInfo = new NoticeInfo();
        noticeInfo.projectId = params.project_id;
        noticeInfo.ordinal = params.ordinal;
        noticeInfo.memberId = user.memberId;
        noticeInfo.subject = title;
        noticeInfo.contents = content;

        NetworkNotice.requestPostNoticeInfo((res) => {
            dispatch(popupShowDefault(t('alert_complete_register_notice'), () => {
                window.location.reload(false);
            }));
        }, (res) => {
            var response = res[RESPONSEINFO_CONTAINER];
            if( response.code === NetworkStatus.NOT_AUTHORIZED ) {
                dispatch(popupShowDefault(t('alert_not_authorized_register_notice')));
            }
            else if( response.code === NetworkStatus.NOT_FOUND ) {
                dispatch(popupShowDefault(t('alert_not_found_project')));
            }
            else {
                dispatch(popupShowDefault(t('alert_network_error')));
            }
        }, noticeInfo);
    }

    /**
     * 공지사항 ui 렌더링
     * @param {NoticeInfo} info 공지사항 정보
     * @returns {NoticeCell} 공지사항 셀
     */
    const renderNotice = (info) => {
        return <NoticeCell key={'notice' + String(info.noticeId)}
                    style={{marginBottom: '20px'}}
                    data={info}
                    onViewPressed={(id) => {
                        navigate(location.pathname + '/' + String(id));
                    }}/>
    }

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}
            visibleSolutionHeader={true}
            location={ConstantPath.PATH_SOLUTION_PROJECT}>
			<div className="gap" style={{textAlign: 'left'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div id="page-contents" className="row merged20">
                                <div className="col-lg-12">
                                    <div className="main-wraper">
                                        <div className="main-title">
                                            {t('project_notice_title').replace(ReplaceKey.REPLACE_PROJECT_NAME, projectName)}
                                        </div>
                                        <PageList
                                            dataList={dataList}
                                            fetchData={fetchData}
                                            pageSize={10}
                                            pageRangeDisplayed={5}
                                            totalCount={totalCount}
                                            renderCell={renderNotice}/>
                                        {
                                            isLogin && user.memberId === mentorId ?
                                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                <button className='button primary circle' onClick={onRegisterPressed}>{t('button_register')}</button> 
                                            </div> :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <NoticeRegisterPopupView
                        visible={registerVisible}
                        onCancelPressed={onRegisterCancelPressed}
                        onConfirmPressed={onRegisterConfirmPressed}/>
                </div>
            </div>
        </Layout>
    );
}

export default ProjectNoticeScreen;