//
//  mentorReviewScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 13..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
} from 'react';

// common
import { CommonKey } from 'constants/key';
import ConstantPath from 'constants/path';
import ConstantValue from 'constants/value';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkReview from 'network/networkReview';
import NetworkStatus from 'network/common/networkStatus';

// info
import MemberReviewInfo, {
    MEMBERREVIEWINFO_REVIEW_LIST,
} from 'info/solution/memberReviewInfo';
import { RESPONSEINFO_CONTAINER } from 'info/common/responseInfo';
import { USERINFO_CONTAINER } from 'info/user/userInfo';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault, popupShowDefaultCancel } from 'redux/actions/popupAction';

// component
import Layout from 'components/layout/layout';
import PageList from 'components/list/pageList';
import ReviewCell from 'components/cell/reviewCell';

// package
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';


/**
 * @protocol MentorReviewScreen
 * @date 2022/12/13
 * @brief 팀사수 후기 화면
 */
function MentorReviewScreen(props) {
    var params = useParams();
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    
    const isLogin = useSelector(state => state.session.isLogin);
    const user = useSelector(state => state.session.user);

    const [reviewList, setReviewList] = useState(null);
    const [mentor, setMentor] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [inputReview, setInputReview] = useState('');

    useEffect(() => {
    }, []);

    const onInputReviewChanged = ({ target: { value}}) => setInputReview(value);

    const onSubmitPressed = (event) => {
        event.preventDefault();

        // 로그인 체크 후, 등록 요청
        if( isLogin ) {
            var reviewInfo = new MemberReviewInfo();
            reviewInfo.memberId = params.mentor_id;
            reviewInfo.reviewerId = user.memberId;
            reviewInfo.review = inputReview;

            NetworkReview.requestPostMemberReview((rew) => {
                dispatch(popupShowDefault(t('alert_complete_register_review'), () => {
                    window.location.reload(false);
                }));
            }, (res) => {
                var response = res[RESPONSEINFO_CONTAINER];
                if( response.code === NetworkStatus.NOT_AUTHORIZED ) {
                    dispatch(popupShowDefault(t('alert_not_authorized_review')));
                }
                else {
                    dispatch(popupShowDefault(t('alert_network_error')));
                }
            }, reviewInfo);
        }
        else {
            dispatch(popupShowDefaultCancel(t('alert_not_login'), () => {
                setTimeout(() => {
                    navigate(ConstantPath.PATH_LOGIN, {
                        state: { [CommonKey.REDIRECT]: location.pathname }
                    });
                }, 100);
            }), null);
        }
    }

    // 데이터 요청 처리
    const fetchData = async (pageNum, pageSize) => {
        NetworkReview.getMentorReviewList((res) => {
            setReviewList(res[MEMBERREVIEWINFO_REVIEW_LIST]);
            setMentor(res[USERINFO_CONTAINER]);
            setTotalCount(res[CommonKey.PAGE_TOTAL_COUNT]);
        }, (res) => {
            dispatch(popupShowDefault(t('alert_network_error')));
        }, params.mentor_id, pageNum, pageSize);
    };

    /**
     * 리뷰 셀 ui 렌더링
     * @param {NoticeInfo} info 공지사항 정보
     * @returns {NoticeCell} 공지사항 셀
     */
    const renderReview = (info) => {
        return  <div key={"review" + String(info.reviewId)}
                    style={{marginBottom: '30px'}}>
                    <ReviewCell
                        disableList={true}
                        showDelete={true}
                        memberId={user ? user.memberId : '0'}
                        data={info}
                        onDeletePressed={(event) => {
                            // 확인 팝업 이후, 삭제 요청
                            dispatch(popupShowDefaultCancel(t('alert_confirm_delete'), () => {
                                var reviewInfo = new MemberReviewInfo();
                                reviewInfo.reviewId = event.target.id;
                                reviewInfo.reviewerId = user.memberId;
                                NetworkReview.requestDeleteMemberReview((res) => {
                                    setTimeout(() => {
                                        dispatch(popupShowDefault(t('alert_complete_delete_review'), () => {
                                            window.location.reload(false);
                                        }));
                                    }, ConstantValue.ANIMATION_TIME_POPUP);
                                }, (res) => {
                                    var response = res[RESPONSEINFO_CONTAINER];
                                    if( response.code === NetworkStatus.NOT_AUTHORIZED ) {
                                        dispatch(popupShowDefault(t('alert_not_authorized_delete_review')));
                                    }
                                    else {
                                        dispatch(popupShowDefault(t('alert_network_error')));
                                    }
                                }, reviewInfo);
                            }), null);
                            
                        }}/>
                    <div style={{height: '1px', width: '100%', borderBottom: '1px solid #e1e8ed', marginTop: '20px'}}/>
                </div>;
    }

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}
            visibleSolutionHeader={true}
            location={ConstantPath.PATH_SOLUTION_PROJECT}>
			<div className="gap" style={{textAlign: 'left'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div id="page-contents" className="row merged20">
                                <div className="col-lg-12">
                                    <div className="main-wraper">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="course-details">
                                                    <h4 style={{width: '100%'}}>
                                                        {t('mentor_review_title')}
                                                    </h4>
                                                    {
                                                        mentor ?
                                                        <div className="create-by"
                                                            style={{marginBottom: '20px'}}>
                                                            <figure><img src={mentor.deskImgUrl} alt=""/></figure>
                                                            <div>
                                                                <span>{mentor.nickname}</span>
                                                            </div>
                                                        </div> :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="create-by" style={{width: '100%'}}>
                                                    <h4 className="main-title">{t('mentor_review_section_review')}</h4>
                                                    <div className="create-date">
                                                        <PageList
                                                            dataList={reviewList}
                                                            fetchData={fetchData}
                                                            pageSize={10}
                                                            pageRangeDisplayed={5}
                                                            totalCount={totalCount}
                                                            renderCell={renderReview}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div id="page-contents" className="row merged20">
                                <div className="col-lg-12">
                                    <div className="main-wraper">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="create-by" style={{width: '100%'}}>
                                                    <h4 className="main-title">{t('mentor_review_section_register')}</h4>
                                                    <Form className="c-form" onSubmit={onSubmitPressed}>
                                                        <div className="col-lg-12">
                                                            <textarea placeholder={t('input_review')}
                                                                rows="4"
                                                                style={{backgroundColor: '#fff'}}
                                                                className="uk-textarea"
                                                                required
                                                                value={inputReview}
                                                                maxLength={ConstantValue.INPUT_MAX_LENGTH_REVIEW}
                                                                onChange={onInputReviewChanged}/>
                                                            <Button className="button primary circle" type="submit">
                                                                {t('button_register_review')}
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default MentorReviewScreen;