//
//  networkSolution.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 25..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// common
import { CommonKey } from 'constants/key';

// util
import DataUtil from 'util/dataUtil';

// network
import CommonNetwork from 'network/common/commonNetwork';

// info
import ResponseInfo, {
    RESPONSEINFO_CONTAINER,
} from 'info/common/responseInfo';
import ProjectInfo, {
    PROJECTINFO_LIST,
    PROJECTINFO_CONTAINER,
} from 'info/solution/projectInfo';
import OrdinalInfo, {
    ORDINALINFO_CONTAINER,
} from 'info/solution/ordinalInfo';
import OrdinalScheduleInfo, {
    ORDINALSCHEDULEINFO_LIST,
} from 'info/solution/ordinalScheduleInfo';
import AttendanceInfo, {
    ATTENDANCEINFO_LIST,
} from 'info/solution/attendanceInfo';
import CounselApplyInfo, {
    COUNSELAPPLYINFO_LIST,
} from 'info/solution/counselApplyInfo';
import { listParseFromDictionary } from 'info/listParse';
import { USERINFO_NICKNAME } from 'info/user/userInfo';

// package
import axios from 'axios';
axios.defaults.withCredentials = true;

const NETWORK_PATH_GET_MY_PROJECT_LIST          = '/get_my_project_list';
const NETWORK_PATH_GET_MY_COUNSEL_LIST          = '/get_my_counsel_list';
const NETWORK_PATH_GET_RECRUIT_PROJECT_LIST     = '/get_recruit_project_list';
const NETWORK_PATH_GET_RECRUIT_COUNSEL_LIST     = '/get_recruit_counsel_list';
const NETWORK_PATH_GET_PROJECT_INFO             = '/get_project_info';
const NETWORK_PATH_GET_ORDINAL_INFO             = '/get_ordinal_info';
const NETWORK_PATH_GET_COUNSEL_INFO             = '/get_counsel_info';
const NETWORK_PATH_GET_ORDINAL_MANAGE_INFO      = '/get_ordinal_manage_info';
const NETWORK_PATH_GET_PROJECT_ATTENDANCE       = '/get_project_attendance';
const NETWORK_PATH_GET_MAIN_PROJECT_LIST        = '/get_main_project_list';
const NETWORK_PATH_GET_REQUEST_PROJECT_LIST     = '/get_request_project_list';
const NETWORK_PATH_GET_REQUEST_COUNSEL_LIST     = '/get_request_counsel_list';
const NETWORK_PATH_GET_MANAGE_PROJECT_LIST      = '/get_manage_project_list';
const NETWORK_PATH_GET_MANAGE_COUNSEL_LIST      = '/get_manage_counsel_list';
const NETWORK_PATH_CHECK_PROJECT_APPLY          ='/check_project_apply';

const NETWORK_PATH_UPDATE_GATERTOWN_URL         = '/update_gatertown_url';
const NETWORK_PATH_UPDATE_OPENCHAT_URL          = '/update_openchat_url';
const NETWORK_PATH_UPDATE_ORDINAL_SCHEDULE      = '/update_ordinal_schedule';
const NETWORK_PATH_UPDATE_ORDINAL_SCHEDULE_STATUS   = '/update_ordinal_schedule_status';
const NETWORK_PATH_PROJECT_ATTENDANCE           = '/project_attendance';
const NETWORK_PATH_PROJECT_ABSENSE              = '/project_absence';
const NETWORK_PATH_APPLY_PROJECT_COUNSEL        = '/apply_project_counsel';


const NETWORK_PARAM_PROJECT_ID  = 'project_id';
const NETWORK_PARAM_ORDINAL     = 'ordinal';
const NETWORK_PARAM_MEMBER_ID   = 'member_id';
const NETWORK_PARAM_CATEGORY    = 'category';
const NETWORK_PARAM_PAGE_NUM    = 'page_num';
const NETWORK_PARAM_PAGE_SIZE   = 'page_size';


/**
 * @protocol NetworkSolution
 * @date 2022/11/25
 * @brief 사수해법 관련 통신
 */
export default class NetworkSolution {
    /**
     * 모집중 프로젝트 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} memberId 멤버 id
     * @param {Number} pageNum 페이지 번호
     * @param {Number} pageSize 페이지 크기
     */
    static getMyProjectList = async (resSuccess, resFail, memberId, pageNum, pageSize) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_MY_PROJECT_LIST])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_MEMBER_ID]: memberId,
            [NETWORK_PARAM_PAGE_NUM]: pageNum,
            [NETWORK_PARAM_PAGE_SIZE]: pageSize,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                var projectList = [];
                var totalCount = 0;

                if( responseInfo.data.hasOwnProperty(PROJECTINFO_LIST) ) {
                    projectList = listParseFromDictionary(ProjectInfo, responseInfo.data[PROJECTINFO_LIST], '');
                    var ordinalList = listParseFromDictionary(OrdinalInfo, responseInfo.data[PROJECTINFO_LIST], '');
                    for( var i = 0; i < projectList.length; i++ ) {
                        if( i < ordinalList.length ) {
                            projectList[i].ordinalInfo = ordinalList[i];
                        }
                    }
                }

                if( responseInfo.data.hasOwnProperty(CommonKey.PAGE_TOTAL_COUNT) ) {
                    totalCount = responseInfo.data[CommonKey.PAGE_TOTAL_COUNT];
                }

                resSuccess({
                    [PROJECTINFO_LIST]: projectList,
                    [CommonKey.PAGE_TOTAL_COUNT]: totalCount,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 나의 상담 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} memberId 멤버 id
     * @param {Number} pageNum 페이지 번호
     * @param {Number} pageSize 페이지 크기
     */
    static getMyCounselList = async (resSuccess, resFail, memberId, pageNum, pageSize) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_MY_COUNSEL_LIST])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_MEMBER_ID]: memberId,
            [NETWORK_PARAM_PAGE_NUM]: pageNum,
            [NETWORK_PARAM_PAGE_SIZE]: pageSize,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var projectList = [];
                var totalCount = 0;

                if( responseInfo.data.hasOwnProperty(PROJECTINFO_LIST) ) {
                    projectList = listParseFromDictionary(ProjectInfo, responseInfo.data[PROJECTINFO_LIST], '');
                }

                if( responseInfo.data.hasOwnProperty(CommonKey.PAGE_TOTAL_COUNT) ) {
                    totalCount = responseInfo.data[CommonKey.PAGE_TOTAL_COUNT];
                }

                resSuccess({
                    [PROJECTINFO_LIST]: projectList,
                    [CommonKey.PAGE_TOTAL_COUNT]: totalCount,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 모집중 프로젝트 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {Number} pageNum 페이지 번호
     * @param {Number} pageSize 페이지 크기
     */
    static getRecruitProjectList = async (resSuccess, resFail, pageNum, pageSize) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_RECRUIT_PROJECT_LIST])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_PAGE_NUM]: pageNum,
            [NETWORK_PARAM_PAGE_SIZE]: pageSize,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var projectList = [];
                var totalCount = 0;

                if( responseInfo.data.hasOwnProperty(PROJECTINFO_LIST) ) {
                    projectList = listParseFromDictionary(ProjectInfo, responseInfo.data[PROJECTINFO_LIST], '');
                    var ordinalList = listParseFromDictionary(OrdinalInfo, responseInfo.data[PROJECTINFO_LIST], '');
                    for( var i = 0; i < projectList.length; i++ ) {
                        if( i < ordinalList.length ) {
                            projectList[i].ordinalInfo = ordinalList[i];
                        }
                    }
                }

                if( responseInfo.data.hasOwnProperty(CommonKey.PAGE_TOTAL_COUNT) ) {
                    totalCount = responseInfo.data[CommonKey.PAGE_TOTAL_COUNT];
                }

                resSuccess({
                    [PROJECTINFO_LIST]: projectList,
                    [CommonKey.PAGE_TOTAL_COUNT]: totalCount,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 모집중 프로젝트 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} categoryId 카테고리 id
     * @param {Number} pageNum 페이지 번호
     * @param {Number} pageSize 페이지 크기
     */
    static getRecruitCounselList = async (resSuccess, resFail, categoryId, pageNum, pageSize) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_RECRUIT_COUNSEL_LIST])
        if( DataUtil.validateString(categoryId) ) {
            url += CommonNetwork.generateQuery({
                [NETWORK_PARAM_CATEGORY]: categoryId,
                [NETWORK_PARAM_PAGE_NUM]: pageNum,
                [NETWORK_PARAM_PAGE_SIZE]: pageSize,
            });
        }
        else {
            url += CommonNetwork.generateQuery({
                [NETWORK_PARAM_PAGE_NUM]: pageNum,
                [NETWORK_PARAM_PAGE_SIZE]: pageSize,
            });
        }

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var projectList = [];
                var totalCount = 0;

                if( responseInfo.data.hasOwnProperty(PROJECTINFO_LIST) ) {
                    projectList = listParseFromDictionary(ProjectInfo, responseInfo.data[PROJECTINFO_LIST], '');
                }

                if( responseInfo.data.hasOwnProperty(CommonKey.PAGE_TOTAL_COUNT) ) {
                    totalCount = responseInfo.data[CommonKey.PAGE_TOTAL_COUNT];
                }

                resSuccess({
                    [PROJECTINFO_LIST]: projectList,
                    [CommonKey.PAGE_TOTAL_COUNT]: totalCount,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 프로젝트 정보 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} projectId 프로젝트 id
     * @param {String} ordinal 기수 번호
     */
    static getProjectInfo = async (resSuccess, resFail, projectId, ordinal) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_PROJECT_INFO])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_PROJECT_ID]: projectId,
            [NETWORK_PARAM_ORDINAL]: ordinal,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var project = new ProjectInfo();
                project.parseFromDictionary(responseInfo.data);
                
                resSuccess({
                    [RESPONSEINFO_CONTAINER]: responseInfo,
                    [PROJECTINFO_CONTAINER]: project,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 프로젝트 기수 정보 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} projectId 프로젝트 id
     * @param {String} ordinal 기수 번호
     */
    static getOrdinalInfo = async (resSuccess, resFail, projectId, ordinal) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_ORDINAL_INFO])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_PROJECT_ID]: projectId,
            [NETWORK_PARAM_ORDINAL]: ordinal,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var project = new ProjectInfo();
                project.parseFromDictionary(responseInfo.data);
                
                resSuccess({
                    [RESPONSEINFO_CONTAINER]: responseInfo,
                    [PROJECTINFO_CONTAINER]: project,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 프로젝트 상담 정보 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} projectId 프로젝트 id
     * @param {String} memberId 멤버 id
     */
    static getCounselInfo = async (resSuccess, resFail, projectId, memberId) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_COUNSEL_INFO]);

        if( DataUtil.validateString(memberId) ) {
            url += CommonNetwork.generateQuery({
                [NETWORK_PARAM_PROJECT_ID]: projectId,
                [NETWORK_PARAM_MEMBER_ID]: memberId,
            });
        }
        else {
            url += CommonNetwork.generateQuery({
                [NETWORK_PARAM_PROJECT_ID]: projectId,
            });
        }

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var userExist = false;
                var project = new ProjectInfo();
                project.parseFromDictionary(responseInfo.data);
                
                if( responseInfo.data.hasOwnProperty(CommonKey.USER_EXIST) ) {
                    userExist = Boolean(responseInfo.data[CommonKey.USER_EXIST]);
                }
                resSuccess({
                    [RESPONSEINFO_CONTAINER]: responseInfo,
                    [PROJECTINFO_CONTAINER]: project,
                    [CommonKey.USER_EXIST]: userExist,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }
    
    /**
     * 프로젝트 기수 관리 정보 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} projectId 프로젝트 id
     * @param {String} ordinal 기수 번호
     * @param {Stirng} memberId 사용자 id
     */
    static getOrdinalManageInfo = async (resSuccess, resFail, projectId, ordinal, memberId) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_ORDINAL_MANAGE_INFO])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_PROJECT_ID]: projectId,
            [NETWORK_PARAM_ORDINAL]: ordinal,
            [NETWORK_PARAM_MEMBER_ID]: memberId,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var ordinal = new OrdinalInfo();
                ordinal.parseFromDictionary(responseInfo.data);
                
                resSuccess({
                    [ORDINALINFO_CONTAINER]: ordinal,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 프로젝트 기수 출석 정보 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} projectId 프로젝트 id
     * @param {String} ordinal 기수 번호
     * @param {Stirng} memberId 사용자 id
     */
    static getProjectAttendance = async (resSuccess, resFail, projectId, ordinal, memberId) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_PROJECT_ATTENDANCE])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_PROJECT_ID]: projectId,
            [NETWORK_PARAM_ORDINAL]: ordinal,
            [NETWORK_PARAM_MEMBER_ID]: memberId,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var scheduleList = listParseFromDictionary(OrdinalScheduleInfo, responseInfo.data, ORDINALSCHEDULEINFO_LIST);
                var attendanceList = listParseFromDictionary(AttendanceInfo, responseInfo.data, ATTENDANCEINFO_LIST);
                var nickname = responseInfo.data[USERINFO_NICKNAME];

                resSuccess({
                    [ORDINALSCHEDULEINFO_LIST]: scheduleList,
                    [ATTENDANCEINFO_LIST]: attendanceList,
                    [USERINFO_NICKNAME]: nickname,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 메인화면 프로젝트 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     */
    static getMainProjectList = async (resSuccess, resFail) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_MAIN_PROJECT_LIST]);

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var projectList = listParseFromDictionary(ProjectInfo, responseInfo.data, '');

                resSuccess({[PROJECTINFO_LIST]: projectList});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 신청 프로젝트 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} memberId 멤버 id
     * @param {Number} pageNum 페이지 번호
     * @param {Number} pageSize 페이지 크기
     */
    static getRequestProjectList = async (resSuccess, resFail, memberId, pageNum, pageSize) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_REQUEST_PROJECT_LIST])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_MEMBER_ID]: memberId,
            [NETWORK_PARAM_PAGE_NUM]: pageNum,
            [NETWORK_PARAM_PAGE_SIZE]: pageSize,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                var projectList = [];
                var totalCount = 0;

                if( responseInfo.data.hasOwnProperty(PROJECTINFO_LIST) ) {
                    projectList = listParseFromDictionary(ProjectInfo, responseInfo.data[PROJECTINFO_LIST], '');
                    var ordinalList = listParseFromDictionary(OrdinalInfo, responseInfo.data[PROJECTINFO_LIST], '');
                    for( var i = 0; i < projectList.length; i++ ) {
                        if( i < ordinalList.length ) {
                            projectList[i].ordinalInfo = ordinalList[i];
                        }
                    }
                }

                if( responseInfo.data.hasOwnProperty(CommonKey.PAGE_TOTAL_COUNT) ) {
                    totalCount = responseInfo.data[CommonKey.PAGE_TOTAL_COUNT];
                }

                resSuccess({
                    [PROJECTINFO_LIST]: projectList,
                    [CommonKey.PAGE_TOTAL_COUNT]: totalCount,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 신청 상담 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} memberId 멤버 id
     * @param {Number} pageNum 페이지 번호
     * @param {Number} pageSize 페이지 크기
     */
    static getRequestCounselList = async (resSuccess, resFail, memberId, pageNum, pageSize) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_REQUEST_COUNSEL_LIST])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_MEMBER_ID]: memberId,
            [NETWORK_PARAM_PAGE_NUM]: pageNum,
            [NETWORK_PARAM_PAGE_SIZE]: pageSize,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var applyList = [];
                var totalCount = 0;

                if( responseInfo.data.hasOwnProperty(COUNSELAPPLYINFO_LIST) ) {
                    applyList = listParseFromDictionary(CounselApplyInfo, responseInfo.data[COUNSELAPPLYINFO_LIST], '');
                }

                if( responseInfo.data.hasOwnProperty(CommonKey.PAGE_TOTAL_COUNT) ) {
                    totalCount = responseInfo.data[CommonKey.PAGE_TOTAL_COUNT];
                }

                resSuccess({
                    [COUNSELAPPLYINFO_LIST]: applyList,
                    [CommonKey.PAGE_TOTAL_COUNT]: totalCount,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 담당 프로젝트 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} memberId 멤버 id
     * @param {Number} pageNum 페이지 번호
     * @param {Number} pageSize 페이지 크기
     */
    static getManageProjectList = async (resSuccess, resFail, memberId, pageNum, pageSize) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_MANAGE_PROJECT_LIST])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_MEMBER_ID]: memberId,
            [NETWORK_PARAM_PAGE_NUM]: pageNum,
            [NETWORK_PARAM_PAGE_SIZE]: pageSize,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                var projectList = [];
                var totalCount = 0;

                if( responseInfo.data.hasOwnProperty(PROJECTINFO_LIST) ) {
                    projectList = listParseFromDictionary(ProjectInfo, responseInfo.data[PROJECTINFO_LIST], '');
                    var ordinalList = listParseFromDictionary(OrdinalInfo, responseInfo.data[PROJECTINFO_LIST], '');
                    for( var i = 0; i < projectList.length; i++ ) {
                        if( i < ordinalList.length ) {
                            projectList[i].ordinalInfo = ordinalList[i];
                        }
                    }
                }

                if( responseInfo.data.hasOwnProperty(CommonKey.PAGE_TOTAL_COUNT) ) {
                    totalCount = responseInfo.data[CommonKey.PAGE_TOTAL_COUNT];
                }

                resSuccess({
                    [PROJECTINFO_LIST]: projectList,
                    [CommonKey.PAGE_TOTAL_COUNT]: totalCount,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 담당 상담 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} memberId 멤버 id
     * @param {Number} pageNum 페이지 번호
     * @param {Number} pageSize 페이지 크기
     */
    static getManageCounselList = async (resSuccess, resFail, memberId, pageNum, pageSize) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_MANAGE_COUNSEL_LIST])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_MEMBER_ID]: memberId,
            [NETWORK_PARAM_PAGE_NUM]: pageNum,
            [NETWORK_PARAM_PAGE_SIZE]: pageSize,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                var applyList = [];
                var totalCount = 0;

                if( responseInfo.data.hasOwnProperty(COUNSELAPPLYINFO_LIST) ) {
                    applyList = listParseFromDictionary(CounselApplyInfo, responseInfo.data[COUNSELAPPLYINFO_LIST], '');
                }

                if( responseInfo.data.hasOwnProperty(CommonKey.PAGE_TOTAL_COUNT) ) {
                    totalCount = responseInfo.data[CommonKey.PAGE_TOTAL_COUNT];
                }

                resSuccess({
                    [COUNSELAPPLYINFO_LIST]: applyList,
                    [CommonKey.PAGE_TOTAL_COUNT]: totalCount,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 팀프로젝트 지원 가능한지 체크
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} memberId 멤버 id
     * @param {String} projectId 프로젝트 id
     * @param {String} ordinal 기수
     */
    static checkProjectApply = async (resSuccess, resFail, memberId, projectId, ordinal) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_CHECK_PROJECT_APPLY])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_MEMBER_ID]: memberId,
            [NETWORK_PARAM_PROJECT_ID]: projectId,
            [NETWORK_PARAM_ORDINAL]: ordinal,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 게더타운 url 수정 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {OrdinalInfo} ordinalInfo 입력한 기수 정보
     */
    static updateGatertownUrl = async (resSuccess, resFail, ordinalInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_UPDATE_GATERTOWN_URL]);
        
        try {
            await axios.post(url, ordinalInfo.dictionaryForUpdateGatertownUrl()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 오픈챗 url 수정 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {OrdinalInfo} ordinalInfo 입력한 기수 정보
     */
    static updateOpenchatUrl = async (resSuccess, resFail, ordinalInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_UPDATE_OPENCHAT_URL]);
        
        try {
            await axios.post(url, ordinalInfo.dictionaryForUpdateOpenchatUrl()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 기수 스케쥴 수정 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {OrdinalScheduleInfo} scheduleInfo 스케쥴 정보
     */
    static updateOrdinalSchedule = async (resSuccess, resFail, scheduleInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_UPDATE_ORDINAL_SCHEDULE]);
        
        try {
            await axios.post(url, scheduleInfo.dictionaryForUpdate()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 기수 스케쥴 상태 변경 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {OrdinalScheduleInfo} scheduleInfo 스케쥴 정보
     */
    static updateOrdinalScheduleStatus = async (resSuccess, resFail, scheduleInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_UPDATE_ORDINAL_SCHEDULE_STATUS]);
        
        try {
            await axios.post(url, scheduleInfo.dictionaryForUpdateStatus()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 기수 출석 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {AttendanceInfo} attendanceInfo 출석 정보
     */
    static requestProjectAttendance = async (resSuccess, resFail, attendanceInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_PROJECT_ATTENDANCE]);
        
        try {
            await axios.post(url, attendanceInfo.dictionaryForAttendance()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 기수 결석 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {AttendanceInfo} attendanceInfo 출석 정보
     */
    static requestProjectAbsense = async (resSuccess, resFail, attendanceInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_PROJECT_ABSENSE]);
        
        try {
            await axios.post(url, attendanceInfo.dictionaryForAbsense()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 상담 신청 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {CounselApplyInfo} applyInfo 신청 정보
     */
    static applyProjectCounsel = async (resSuccess, resFail, applyInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_APPLY_PROJECT_COUNSEL]);
        
        try {
            await axios.post(url, applyInfo.dictionaryForApply()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }
}
