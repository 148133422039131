//
//  projectManageScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 7..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
} from 'react';

// common
import { TableKey, OrdinalScheduleStatusKey } from 'constants/key';
import ConstantValue from 'constants/value';
import ConstantPath from 'constants/path';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkSolution from 'network/networkSolution';

// info
import OrdinalInfo, {
    ORDINALINFO_CONTAINER,
    ORDINALINFO_PROJECT_ID,
    ORDINALINFO_ORDINAL,
} from 'info/solution/ordinalInfo';
import OrdinalScheduleInfo from 'info/solution/ordinalScheduleInfo';
import { USERINFO_MEMBER_ID } from 'info/user/userInfo';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';

// component
import Layout from 'components/layout/layout';
import DefaultTable from 'components/table/defaultTable';
import ScheduleModifyPopupView from 'components/modal/scheduleModifyPopupView';

// package
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';


/**
 * @protocol ProjectManageScreen
 * @date 2022/11/30
 * @brief 팀프로젝트 소개 화면
 */
function ProjectManageScreen(props) {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    
    const user = useSelector(state => state.session.user);

    const [ordinal, setOrdinal] = useState(null);
    const [getertown, setGetertown] = useState('');
    const [openchat, setOpenchat] = useState('');
    const [modifyVisible, setModifyVisible] = useState(false);
    const [modifyIndex, setModifyIndex] = useState(-1);

    const onGetertownChanged = ({ target: { value}}) => setGetertown(value);
    const onOpenchatChanged = ({ target: { value}}) => setOpenchat(value);

    useEffect(() => {
        // 이전 설정 데이터 없으면 초기화면으로 이동
        if( location.state === null ||
            location.state[ORDINALINFO_PROJECT_ID] === null ||
            location.state[ORDINALINFO_ORDINAL] === null ) {
            navigate(-1);
        }
        else {
            var projectId = location.state[ORDINALINFO_PROJECT_ID];
            var ordinal = location.state[ORDINALINFO_ORDINAL];

            NetworkSolution.getOrdinalManageInfo((res) => {
                var ordinalInfo = res[ORDINALINFO_CONTAINER];
                
                setOrdinal(ordinalInfo);
                setGetertown(ordinalInfo.gatertownUrl);
                setOpenchat(ordinalInfo.openchatUrl);
            }, (res) => {
                dispatch(popupShowDefault(t('alert_network_error')));
            }, projectId, ordinal, user.memberId);
        }
    }, [location.state, navigate, user.memberId, dispatch, t]);

    /**
     * 멤버 column 목록 생성
     * @returns {Array} 기수 column 목록
     */
    const generateMemberColumnList = () => {
        return [{
            id: TableKey.TABLE_NAME,
            name: t('word_member_name'),
            isClickable: false,
        }, {
            id: TableKey.TABLE_ETC,
            name: t('word_etc'),
            isClickable: true,
        }];
    }

    /**
     * 스케쥴 column 목록 생성
     * @returns {Array} 기수 column 목록
     */
    const generateScheduleColumnList = () => {
        return [{
            id: TableKey.TABLE_WEEKS,
            name: t('word_schedule_week'),
            isClickable: false,
        }, {
            id: TableKey.TABLE_SCHEDULE,
            name: t('word_schedule'),
            isClickable: false,
        }, {
            id: TableKey.TABLE_SCHEDULE_STATUS,
            name: t('word_schedule_status'),
            isClickable: false,
        },{
            id: TableKey.TABLE_ETC,
            name: t('word_etc'),
            isClickable: true,
        }];
    }

    /**
     * 멤버 데이터 목록 생성
     * @returns {Array} 기수 데이터 목록
     */
    const generateMemberDataList = () => {
        var itemList = [];
        if( DataUtil.validateArray(ordinal.memberList) ) {
            for( let i = 0; i < ordinal.memberList.length; i++ ) {
                itemList.push({
                    [TableKey.TABLE_NAME]: ordinal.memberList[i].nickname,
                    [TableKey.TABLE_ETC]: t('button_manage_attendance'),
                });
            }
        }
        return itemList;
    }

    /**
     * 스케쥴 데이터 목록 생성
     * @returns {Array} 기수 데이터 목록
     */
    const generateScheduleDataList = () => {
        var itemList = [];
        if( DataUtil.validateArray(ordinal.scheduleList) ) {
            for( let i = 0; i < ordinal.scheduleList.length; i++ ) {
                itemList.push({
                    [TableKey.TABLE_WEEKS]: ordinal.scheduleList[i].weeks + t('word_schedule_week'),
                    [TableKey.TABLE_SCHEDULE]: ordinal.scheduleList[i].schedule,
                    [TableKey.TABLE_SCHEDULE_STATUS]: ordinal.scheduleList[i].scheduleStatus === OrdinalScheduleStatusKey.ORDINAL_SCHEDULE_STATUS_BEFORE ?
                    t('word_schedule_before') : t('word_schedule_after'),
                    [TableKey.TABLE_ETC]: t('button_modify'),
                });
            }
        }
        return itemList;
    }

    /**
     * 게더타운 주소 저장 클릭시 호출
     */
    const onGatertownUrlSavePressed = () => {
        var updateOrdinalInfo = new OrdinalInfo();
        updateOrdinalInfo.projectId = ordinal.projectId;
        updateOrdinalInfo.ordinal = ordinal.ordinal;
        updateOrdinalInfo.gatertownUrl = getertown;

        NetworkSolution.updateGatertownUrl((res) => {
        }, (res) => {
            dispatch(popupShowDefault(t('alert_network_error')));
        }, updateOrdinalInfo);
    }

    /**
     * 오픈채팅방 저장 클릭시 호출
     */
    const onOpenchatUrlSavePressed = () => {
        var updateOrdinalInfo = new OrdinalInfo();
        updateOrdinalInfo.projectId = ordinal.projectId;
        updateOrdinalInfo.ordinal = ordinal.ordinal;
        updateOrdinalInfo.openchatUrl = openchat;
        
        NetworkSolution.updateOpenchatUrl((res) => {
        }, (res) => {
            dispatch(popupShowDefault(t('alert_network_error')));
        }, updateOrdinalInfo);
    }

    /**
     * 취소 버튼 클릭시 호출
     */
    const onModifyCancelPressed = () => {
        setModifyIndex(-1);
        setModifyVisible(false);
    }

    /**
     * 작성 버튼 클릭시 호출
     * @param {String} content 1:1 문의 내용
     */
    const onModifyConfirmPressed = (id, content) => {
        setModifyIndex(-1);
        setModifyVisible(false);

        var scheduleInfo = new OrdinalScheduleInfo();
        scheduleInfo.scheduleId = id;
        scheduleInfo.schedule = content;
        
        NetworkSolution.updateOrdinalSchedule((res) => {
            dispatch(popupShowDefault(t('alert_complete_modify_schedule'), () => {
                window.location.reload(false);
            }));
        }, (res) => {
            dispatch(popupShowDefault(t('alert_network_error')));
        }, scheduleInfo);
    }
    
    /**
     * 테이블 기타 셀 렌더링
     * @param {*} element 구성 항목
     * @param {*} info 테이블 정보
     * @returns {*} cell
     */
    const renderEtcCell = (element, info) => {
        var status = ordinal.scheduleList[info.row.index].scheduleStatus;
        return  <div>
                    <Button className="button primary circle"
                        style={{marginRight: '10px', marginBottom: '5px'}}
                        onClick={() => {
                            setModifyIndex(info.row.index);
                            setModifyVisible(true);
                        }}>
                        {t('button_modify')}
                    </Button>
                    <Button className="button primary circle"
                        style={{marginRight: '10px', marginBottom: '5px'}}
                        onClick={() => {
                            var scheduleInfo = new OrdinalScheduleInfo();
                            scheduleInfo.scheduleId = ordinal.scheduleList[info.row.index].scheduleId;
                            scheduleInfo.scheduleStatus = (status === OrdinalScheduleStatusKey.ORDINAL_SCHEDULE_STATUS_AFTER ?
                            OrdinalScheduleStatusKey.ORDINAL_SCHEDULE_STATUS_BEFORE :
                            OrdinalScheduleStatusKey.ORDINAL_SCHEDULE_STATUS_AFTER);

                            NetworkSolution.updateOrdinalScheduleStatus((res) => {
                                dispatch(popupShowDefault(t('alert_complete_update_schedule_status'), () => {
                                    window.location.reload(false);
                                }));
                            }, (res) => {
                                dispatch(popupShowDefault(t('alert_network_error')));
                            }, scheduleInfo)
                        }}>
                        {t('button_change_status')}
                    </Button>
                </div>
    };

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}>
			<div className="gap" style={{textAlign: 'left'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                ordinal ?
                                <div id="page-contents" className="row merged20">
                                    <div className="col-lg-12">
                                        <div className="main-wraper">
                                            <Form className="c-form">
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="course-description" style={{marginTop: '0px'}}>
                                                            <h4 className="main-title">{t('project_manage_section_manage')}</h4>
                                                            <div className="col-lg-6 col-md-6">
                                                                <input className="uk-input" type="text"
                                                                    style={{backgroundColor: '#fff'}}
                                                                    value={getertown}
                                                                    placeholder={t('input_getertown')}
                                                                    maxLength={ConstantValue.INPUT_MAX_LENGTH_URL}
                                                                    onChange={onGetertownChanged}/>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6" style={{marginBottom: '20px'}}>
                                                                <Button className="main-btn"
                                                                    onClick={onGatertownUrlSavePressed}>
                                                                    {t('button_save')}
                                                                </Button>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <input className="uk-input" type="text"
                                                                    style={{backgroundColor: '#fff'}}
                                                                    value={openchat}
                                                                    placeholder={t('input_openchat')}
                                                                    maxLength={ConstantValue.INPUT_MAX_LENGTH_URL}
                                                                    onChange={onOpenchatChanged}/>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6" style={{marginBottom: '20px'}}>
                                                                <Button className="main-btn"
                                                                    onClick={onOpenchatUrlSavePressed}>
                                                                    {t('button_save')}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="course-description">
                                                            <h4 className="main-title">{t('project_manage_section_member')}</h4>
                                                            <DefaultTable
                                                                dataList={generateMemberDataList()}
                                                                columnList={generateMemberColumnList()}
                                                                onCellPressed={(index) => {
                                                                    navigate(ConstantPath.PATH_PROJECT_ATTENDANCE, {
                                                                        state: {
                                                                            [ORDINALINFO_PROJECT_ID]: ordinal.projectId,
                                                                            [ORDINALINFO_ORDINAL]: ordinal.ordinal,
                                                                            [USERINFO_MEMBER_ID]:ordinal.memberList[index].memberId
                                                                        }
                                                                    })
                                                                }}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="course-description">
                                                            <h4 className="main-title">{t('project_manage_section_schedule')}</h4>
                                                            <DefaultTable
                                                                dataList={generateScheduleDataList()}
                                                                columnList={generateScheduleColumnList()}
                                                                renderCell={renderEtcCell}
                                                                onCellPressed={(index) => {
                                                                    setModifyIndex(index);
                                                                    setModifyVisible(true);
                                                                }}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                    <ScheduleModifyPopupView
                                        visible={modifyVisible}
                                        data={modifyIndex < ordinal.scheduleList.length ? ordinal.scheduleList[modifyIndex] : null}
                                        onCancelPressed={onModifyCancelPressed}
                                        onConfirmPressed={onModifyConfirmPressed}/>
                                </div> :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ProjectManageScreen;