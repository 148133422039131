//
//  loginView.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 21..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React from 'react';

// common
import { CommonKey } from 'constants/key';
import ConstantPath from 'constants/path';

// string
import { useTranslation } from 'react-i18next';

// package
import { useLocation, useNavigate } from "react-router-dom";


/**
 * @protocol LoginView
 * @date 2022/12/21
 * @brief 로그인 연결 화면
 */
function LoginView(props) {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div>
            <div className="error-page">
                <div id="container-inside">
                    <div id="circle-small"></div>
                    <div id="circle-medium"></div>
                    <div id="circle-large"></div>
                    <div id="circle-xlarge"></div>
                    <div id="circle-xxlarge"></div>
                </div>
                <div className="thanks-purchase">
                    <div className="logo"><img src="assets/images/image/img_logo.png" alt=""/><span>{t('common_service')}</span></div>
                    <span>{t('subview_login_title')}</span>
                    <button className="button dark circle"
                        onClick={() => {
                            navigate(ConstantPath.PATH_LOGIN, {
                                state: { [CommonKey.REDIRECT]: location.pathname }
                            });
                        }}>{t('button_action_login')}</button>
                </div>
            </div>	
        </div>
    );
}

export default LoginView;