//
//  footer.js
//  datatown
//
//  Created by Mumakil on 2022. 10. 14..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React from 'react';

// common
import ConstantValue from 'constants/value';
import ConstantPath from 'constants/path';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// package
import Nav from 'react-bootstrap/Nav';


/**
 * @protocol Footer
 * @date 2022/10/14
 * @brief 공통 하단 화면
 */
export default function Footer() {
    const { t } = useTranslation();

    const spanTitleStyle = {
        display: 'inline-block',
        width: '100%',
        textAlign: 'left',
        fontWeight: 'bold',
    };
    const spanStyle = {
        display: 'inline-block',
        width: '100%',
        textAlign: 'left',
    };
    return (
        <footer>
            <div className="gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-sm-6">
                            <div className="widget">
                                <Nav className="ml-auto">
                                    <Nav.Link href={ConstantPath.PATH_SOLUTION}>{t('menu_solution')}</Nav.Link>
                                    <Nav.Link href={ConstantPath.PATH_SOLUTION_APPLY}>{t('menu_application')}</Nav.Link>
                                    <Nav.Link href={ConstantPath.PATH_WORRY}>{t('menu_worry')}</Nav.Link>
                                    {/* <Nav.Link href={ConstantPath.PATH_NFT}>{t('menu_nft')}</Nav.Link> */}
                                    <Nav.Link href={ConstantPath.PATH_HELP}>{t('menu_help')}</Nav.Link>
                                </Nav>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="sns">
								{
                                    DataUtil.validateString(ConstantValue.SNS_KAKAO) ?
                                    <a href={ConstantValue.SNS_KAKAO} title="" target="_blank" rel="noreferrer">
                                        <img src="assets/images/icon/icon_sns_kakao.png" alt=""/>
                                    </a> :
                                    null
                                }
                                {
                                    DataUtil.validateString(ConstantValue.SNS_LINKEDIN) ?
                                    <a href={ConstantValue.SNS_LINKEDIN} title="" target="_blank" rel="noreferrer">
                                        <img src="assets/images/icon/icon_sns_linkedin.png" alt=""/>
                                    </a> :
                                    null
                                }
                                {
                                    DataUtil.validateString(ConstantValue.SNS_NAVERBLOG) ?
                                    <a href={ConstantValue.SNS_NAVERBLOG} title="" target="_blank" rel="noreferrer">
                                        <img src="assets/images/icon/icon_sns_blog.png" alt=""/>
                                    </a> :
                                    null
                                }
                                {
                                    DataUtil.validateString(ConstantValue.SNS_INSTAGRAM) ?
                                    <a href={ConstantValue.SNS_INSTAGRAM} title="" target="_blank" rel="noreferrer">
                                        <img src="assets/images/icon/icon_sns_instagram.png" alt=""/>
                                    </a> :
                                    null
                                }
							</div>
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="widget">
                                <Nav className="ml-auto">
                                    <Nav.Link href={ConstantPath.PATH_COMPANY}>{t('menu_company')}</Nav.Link>
                                    <Nav.Link href={ConstantPath.PATH_TERMS_PRIVACY}>{t('menu_privacy')}</Nav.Link>
                                    <Nav.Link href={ConstantPath.PATH_TERMS_SERVICE}>{t('menu_service')}</Nav.Link>
                                </Nav>
                            </div>
                        </div>
                    </div>
                    <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
                        <span className="" style={spanTitleStyle}>{t('footer_company')}</span>
                        <span className="" style={spanStyle}>{t('footer_description')}</span>
                    </div>
                </div>
            </div>
            <div className="bottombar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className="">{t('footer_copyright')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
