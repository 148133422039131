//
//  value.js
//  datatown
//
//  Created by Mumakil on 2022. 10. 24..
//  Copyright © 2022년 Melephant. All rights reserved.
//


/**
 * @protocol ConstantValue
 * @date 2022/10/24
 * @brief 앱내 사용 값
 */
const ConstantValue = {
    // 데이터타운
    DATATOWN_EMAIL:     '010-56788-1234',
    DATATOWN_PHONE:     'townhall@mentortalk.ai',

    // 팝업 애니메이션 관련 수치
    ANIMATION_TIME_TOAST_POPUP: 3000,
    ANIMATION_TIME_POPUP:       500,
    ANIMATION_TIME_POPUP_TERM:  200,

    // 입력값 제한 수치
    INPUT_MAX_LENGTH_EMAIL:     100,
    INPUT_MAX_LENGTH_NICKNAME:  20,
    INPUT_MAX_LENGTH_COMPANY:   100,
    INPUT_MAX_LENGTH_INTRODUCE: 100,
    INPUT_MAX_LENGTH_NAME:      20,
    INPUT_MAX_LENGTH_PHONE:     20,
    INPUT_MAX_LENGTH_PHONE1:    3,
    INPUT_MAX_LENGTH_PHONE2:    4,
    INPUT_MAX_LENGTH_PHONE3:    4,
    INPUT_MAX_LENGTH_BIZNUMBER1:    3,
    INPUT_MAX_LENGTH_BIZNUMBER2:    2,
    INPUT_MAX_LENGTH_BIZNUMBER3:    5,
    INPUT_MAX_LENGTH_BANKNAME:      20,
    INPUT_MAX_LENGTH_BANKACCOUNT:   100,
    INPUT_MAX_LENGTH_URL:       45,
    INPUT_MAX_LENGTH_SCHEDULE:  100,
    INPUT_MAX_LENGTH_NOTICE_TITLE:      100,
    INPUT_MAX_LENGTH_NOTICE_CONTENT:    1000,
    INPUT_MAX_LENGTH_REVIEW:    500,
    INPUT_MAX_LENGTH_ANSWER:    1000,
    
    // 카테고리 선택 제한 수치
    CATEGORY_CATEGORY_SELECT_LIMIT:     1,
    CATEGORY_JOB_SELECT_LIMIT:          1,
    CATEGORY_KEYWORD_SELECT_LIMIT:      1,
    CATEGORY_MENTOR_DISPOSITION_LIMIT:  3,
    CATEGORY_MENTOR_LIMIT:              1,

    // 성별 코드
    USER_GENDER_MALE:   'M',
    USER_GENDER_FEMALE: 'F',

    // SNS 주소
    SNS_KAKAO:      'http://mentortalk.co.kr/',
    SNS_LINKEDIN:   'http://mentortalk.co.kr/',
    SNS_NAVERBLOG:  'http://mentortalk.co.kr/',
    SNS_INSTAGRAM:  'http://mentortalk.co.kr/',

    // nft 주소
    NFT_LINK1:  'https://blog.naver.com/mentortalk/222944173257',
    NFT_LINK2:  'https://blog.naver.com/mentortalk/222944152280',
    NFT_LINK3:  'https://warm-writer-e19.notion.site/NFT-3-0-bd3f296173eb42a3ac813a59298a911f',

    // 데이터 표시 제한
    MAX_LENGTH_M2M_TABLE_CONTENT:   100,

    // 메인화면내 컨텐츠 값
    MAIN_COUNTER_01:    24500,
    MAIN_COUNTER_02:    170,
    MAIN_COUNTER_03:    42000,
    MAIN_COUNTER_04:    58,

    // 페이징 값
    RECRUIT_COUNSEL_PAGE_SIZE:  15, // 모집중 상담의 페이지 사이즈
    RECRUIT_PROJECT_PAGE_SIZE:  15, // 모집중 팀플의 페이지 사이즈
    BLOG_PAGE_SIZE:             10, // 배많사 페이지 사이즈
};

export default ConstantValue;
