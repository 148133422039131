//
//  solutionRequestScreen.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 16..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React from 'react';

// common
import { RequestSolutionMenuKey } from 'constants/key';
import { MenuList } from 'constants/list';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// redux
import { useSelector } from 'react-redux';

// component
import Layout from 'components/layout/layout';
import RequestProjectView from 'components/subview/requestProjectView';
import RequestCounselView from 'components/subview/requestCounselView';
import LoginView from 'components/subview/loginView';


/**
 * @protocol SolutionRequestScreen
 * @date 2023/01/16
 * @brief 사수해법 신청한 내역 화면
 */
function SolutionRequestScreen(props) {
    const { t } = useTranslation();

    const isLogin = useSelector(state => state.session.isLogin);

    const menuList = MenuList.RequestSolutionMenuList();
    const defaultTab = RequestSolutionMenuKey.REQUEST_SOLUTION_MENU_COUNCEL;
    
    /**
     * 사이드 메뉴 목록 반환
     * @returns {Array} 사이드 메뉴 목록
     */
    const renderTypeMenu = () => {
        const itemList = [];
        
        for( var i = 0; i < menuList.length; i++ ) {
            itemList.push(  <li className="nav-item"
                                key={"request_solution_menu" + String(i)}>
                                <a className={menuList[i].id === defaultTab ? "active" : ""}
                                    href={'#' + menuList[i].id}
                                    data-toggle="tab">
                                    {menuList[i].title}
                                </a>
                            </li>);
        }
        return itemList;
    }

    /**
     * 메뉴별 컨텐츠 ui 렌더링
     * @returns {Array} 메뉴별 컨텐츠 목록
     */
    const renderContent = () => {
        var itemList = [];

        for( var i = 0; i < menuList.length; i++ ) {
            switch( menuList[i].id ) {
                case RequestSolutionMenuKey.REQUEST_SOLUTION_MENU_COUNCEL: {
                    itemList.push(
                        <RequestCounselView tabId={menuList[i].id}
                            key={menuList[i].id}
                            defaultActive={defaultTab === menuList[i].id ? true : false}/>);
                    break;
                }
                case RequestSolutionMenuKey.REQUEST_SOLUTION_MENU_PROJECT: {
                    itemList.push(
                        <RequestProjectView tabId={menuList[i].id}
                            key={menuList[i].id}
                            defaultActive={defaultTab === menuList[i].id ? true : false}/>);
                    break;
                }
                default: {
                    break;
                }
            }
        }
        return itemList;
    }

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}>
			<div className="gap" style={{textAlign: 'left'}}>
                <div className={"tab-pane fade show active"}>
                    <div className="container">
                        {
                            isLogin ?
                            (
                                DataUtil.validateArray(menuList) && DataUtil.validateString(defaultTab) ?
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div id="page-contents" className="row merged20">
                                            <div className="col-lg-12">
                                                <div className="main-wraper">
                                                    <h4 className="main-title">{t('request_solution_title')}</h4>
                                                    <ul className="nav nav-tabs pix-filter">
                                                        {renderTypeMenu()}
                                                    </ul>
                                                    <div className="tab-content" id="components-nav">
                                                        {renderContent()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                null
                            ) :
                            <LoginView/>
                        }
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default SolutionRequestScreen;