//
//  serviceM2mInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 20..
//  Copyright © 2022년 Melephant. All rights reserved.
//


export const SERVICEM2MINFO_LIST                = 'm2m_list';
export const SERVICEM2MINFO_CONTAINER           = 'm2m_info';
export const SERVICEM2MINFO_ID                  = 'm2m_idx';
export const SERVICEM2MINFO_MEMBER_ID           = 'member_id';
export const SERVICEM2MINFO_SUBJECT             = 'subject';
export const SERVICEM2MINFO_QUESTION            = 'question';
export const SERVICEM2MINFO_ANSWER              = 'answer';
export const SERVICEM2MINFO_M2M_STATUS          = 'm2m_status';
export const SERVICEM2MINFO_ATTACHIMAGE_URL1    = 'attachimage_url1';
export const SERVICEM2MINFO_ATTACHIMAGE_URL2    = 'attachimage_url2';
export const SERVICEM2MINFO_ATTACHIMAGE_URL3    = 'attachimage_url3';
export const SERVICEM2MINFO_REGDATE             = 'regdate';
export const SERVICEM2MINFO_FINDATE             = 'findate';
export const SERVICEM2MINFO_ATTACHIMAGE_URL     = 'attachimage_url';


/**
 * @protocol M2mInfo
 * @date 2022/12/02
 * @brief 1:1 문의 정보
 */
export default class ServiceM2mInfo {
    constructor() {
        this.m2mId = 0;
        this.memberId = '';
        this.subject = 0;
        this.question = '';
        this.answer = '';
        this.m2mStatus = 0;
        this.attachimageUrl1 = '';
        this.attachimageUrl2 = '';
        this.attachimageUrl3 = '';
        this.regdate = null;
        this.findate = null;
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(SERVICEM2MINFO_ID) ) {
            this.m2mId = dict[SERVICEM2MINFO_ID];
        }

        if( dict.hasOwnProperty(SERVICEM2MINFO_MEMBER_ID) ) {
            this.memberId = dict[SERVICEM2MINFO_MEMBER_ID];
        }

        if( dict.hasOwnProperty(SERVICEM2MINFO_SUBJECT) ) {
            this.subject = dict[SERVICEM2MINFO_SUBJECT];
        }

        if( dict.hasOwnProperty(SERVICEM2MINFO_QUESTION) ) {
            this.question = dict[SERVICEM2MINFO_QUESTION];
        }

        if( dict.hasOwnProperty(SERVICEM2MINFO_ANSWER) ) {
            this.answer = dict[SERVICEM2MINFO_ANSWER];
        }

        if( dict.hasOwnProperty(SERVICEM2MINFO_M2M_STATUS) ) {
            this.m2mStatus = dict[SERVICEM2MINFO_M2M_STATUS];
        }

        if( dict.hasOwnProperty(SERVICEM2MINFO_ATTACHIMAGE_URL1) ) {
            this.attachimageUrl1 = dict[SERVICEM2MINFO_ATTACHIMAGE_URL1];
        }

        if( dict.hasOwnProperty(SERVICEM2MINFO_ATTACHIMAGE_URL2) ) {
            this.attachimageUrl2 = dict[SERVICEM2MINFO_ATTACHIMAGE_URL2];
        }

        if( dict.hasOwnProperty(SERVICEM2MINFO_ATTACHIMAGE_URL3) ) {
            this.attachimageUrl3 = dict[SERVICEM2MINFO_ATTACHIMAGE_URL3];
        }

        if( dict.hasOwnProperty(SERVICEM2MINFO_REGDATE) ) {
            this.regdate = new Date(dict[SERVICEM2MINFO_REGDATE]);
        }

        if( dict.hasOwnProperty(SERVICEM2MINFO_FINDATE) ) {
            this.findate = new Date(dict[SERVICEM2MINFO_FINDATE]);
        }
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [SERVICEM2MINFO_ID]: this.m2mId,
            [SERVICEM2MINFO_MEMBER_ID]: this.memberId,
            [SERVICEM2MINFO_SUBJECT]: this.subject,
            [SERVICEM2MINFO_QUESTION]: this.question,
            [SERVICEM2MINFO_ANSWER]: this.answer,
            [SERVICEM2MINFO_M2M_STATUS]: this.m2mStatus,
            [SERVICEM2MINFO_ATTACHIMAGE_URL1]: this.attachimageUrl1,
            [SERVICEM2MINFO_ATTACHIMAGE_URL2]: this.attachimageUrl2,
            [SERVICEM2MINFO_ATTACHIMAGE_URL3]: this.attachimageUrl3,
            [SERVICEM2MINFO_REGDATE]: this.regdate,
            [SERVICEM2MINFO_FINDATE]: this.findate,
        };
        return dict;
    }

    /**
     * 생성 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    formDataForPost() {
        var formData = new FormData();
        
        formData.append([SERVICEM2MINFO_MEMBER_ID], this.memberId);
        formData.append([SERVICEM2MINFO_SUBJECT], this.subject ? this.subject : '');
        formData.append([SERVICEM2MINFO_QUESTION], this.question ? this.question : '');

        if( this.attachImgData1 ) {
            formData.append([SERVICEM2MINFO_ATTACHIMAGE_URL], this.attachImgData1);
        }

        if( this.attachImgData2 ) {
            formData.append([SERVICEM2MINFO_ATTACHIMAGE_URL], this.attachImgData2);
        }

        if( this.attachImgData3 ) {
            formData.append([SERVICEM2MINFO_ATTACHIMAGE_URL], this.attachImgData3);
        }
        
        return formData;
    }
}
