//
//  profileScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 17..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
} from 'react';

// common
import { MenuList } from 'constants/list';
import { ProfileMenuKey } from 'constants/key';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';

// component
import Layout from 'components/layout/layout';
import SideTab from 'components/tab/sideTab';
import EditProfileView from 'components/subview/editProfileView';
import EditMentorView from 'components/subview/editMentorView';

// package
import { useNavigate } from 'react-router';


/**
 * @protocol ProfileScreen
 * @date 2022/11/17
 * @brief 프로필 수정 화면
 */
function ProfileScreen(props) {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isMentor = useSelector(state => state.session.isMentor);
    const isLogin = useSelector(state => state.session.isLogin);

    var activeIndex = 0;
    const menuList = isMentor ? MenuList.ProfileMentorMenuList() : MenuList.ProfileUserMenuList();

    if( DataUtil.validateString(props.active) ) {
        var index = menuList.findIndex(element => element.id === props.active);
        activeIndex = index >= 0 ? index : 0;
    }

    const [defaultTab] = useState(menuList[activeIndex].id);

    useEffect(() => {
		// 로그인 상태 체크
        if( !isLogin ) {
            dispatch(popupShowDefault(t('alert_not_login'), () => {
                navigate('/');
            }));
        }
	}, [dispatch, navigate, t, isLogin]);
    
    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}>
            <div className="gap" style={{textAlign: 'left'}}>
                <div className="container">
                    <SideTab
                        defaultTab={defaultTab}
                        menuList={menuList}/>
                    <div className="col-lg-12">
                        <div className="tab-content" id="components-nav">
                            {
                                isLogin ?
                                <EditProfileView tabId={ProfileMenuKey.PROFILE_MENU_EDIT_PROFILE}
                                    defaultActive={defaultTab === ProfileMenuKey.PROFILE_MENU_EDIT_PROFILE ? true : false}/> :
                                null
                            }
                            {
                                isLogin && isMentor ?
                                <EditMentorView tabId={ProfileMenuKey.PROFILE_MENU_EDIT_MENTOR}
                                    defaultActive={defaultTab === ProfileMenuKey.PROFILE_MENU_EDIT_MENTOR ? true : false}/> :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ProfileScreen;
