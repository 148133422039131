//
//  key.js
//  datatown
//
//  Created by Mumakil on 2022. 8. 12..
//  Copyright © 2022년 Melephant. All rights reserved.
//


import { CommonKey } from "constants/key/commonKey";
import { TableKey } from "constants/key/tableKey";
import { ReplaceKey } from "constants/key/replaceKey";

// 팝업 종류 타입
const PopupTypeKey = {
    POPUP_TYPE_CONFIRM: 'popup_type_confirm',
    POPUP_TYPE_CANCEL:  'popup_type_cancel',
    POPUP_TYPE_TOAST:   'popup_type_popup',
}

// 고민 선택값 키
const WorrySelectKey = {
    WORRY_SELECT_CATEGORY:      'worry_select_category',
    WORRY_SELECT_JOB:           'worry_select_job',
    WORRY_SELECT_KEYWORD:       'worry_select_keyword',
    WORRY_SELECT_DISPOSITION:   'worry_select_disposition',
}

// 사수해법 메뉴 키
const SolutionMenuKey = {
    SOLUTION_MENU_MY:       'solution_menu_my',
    SOLUTION_MENU_RECRUIT:  'solution_menu_recruit',
    SOLUTION_MENU_QNA:      'solution_menu_qna',
    SOLUTION_MENU_MANAGE:   'solution_menu_manage',
    SOLUTION_MENU_SALES:    'solution_menu_sales',
}

// 나의 사수해법 메뉴 키
const MySolutionMenuKey = {
    MY_SOLUTION_TYPE_COUNCEL:   'my_solution_counsel',
    MY_SOLUTION_TYPE_PROJECT:   'my_solution_project',
}

// 진행중 사수해법 메뉴 키
const RecruitSolutionMenuKey = {
    RECRUIT_SOLUTION_TYPE_COUNCEL:  'recruit_solution_counsel',
    RECRUIT_SOLUTION_TYPE_PROJECT:  'recruit_solution_project',
}

// 신청 사수해법 메뉴 키
const RequestSolutionMenuKey = {
    REQUEST_SOLUTION_MENU_COUNCEL:  'request_solution_menu_counsel',
    REQUEST_SOLUTION_MENU_PROJECT:  'request_solution_menu_project',
}

// 담당 사수해법 메뉴 키
const ManageSolutionMenuKey = {
    MANAGE_SOLUTION_MENU_COUNCEL:   'manage_solution_menu_counsel',
    MANAGE_SOLUTION_MENU_PROJECT:   'manage_solution_menu_project',
}

// 1:1 문의 메뉴 키
const M2mSolutionMenuKey = {
    M2M_SOLUTION_TYPE_RECEIVE:  'm2m_solution_receive',
    M2M_SOLUTION_TYPE_SEND:     'm2m_solution_send',
}

// 프로필 메뉴 키
const ProfileMenuKey = {
    PROFILE_MENU_EDIT_PROFILE:  'profile_menu_edit_profile',
    PROFILE_MENU_EDIT_MENTOR:   'profile_menu_edit_mentor',
}

// 사용자 메뉴 키
const UserMenuKey = {
    USER_MENU_EDIT_PROFILE:     'user_menu_edit_profile',
    USER_MENU_EDIT_MENTOR:      'user_menu_edit_mentor',
    USER_MENU_SOLUTION_REQUEST: 'user_menu_solution_request',
    USER_MENU_SOLUTION_M2M:     'user_menu_m2m',
    USER_MENU_SOLUTION_MANANGE: 'user_menu_solution_manage',
    USER_MENU_SOLUTION_SALES:   'user_menu_sales',
}

// 카테고리 단계 키
const CategoryTypeKey = {
    CATEGORY_TYPE_1:    '1ST',
    CATEGORY_TYPE_2:    '2ND',
    CATEGORY_TYPE_3:    '3RD',
}

// 약관 메뉴 키
const TermsMenuKey = {
    TERM_MENU_SERVICE:  'term_menu_service',
    TERM_MENU_PRIVACY:  'term_menu_privacy',
}

// 기수 상태 키
const OrdinalStatusKey = {
    ORDINAL_STATUS_REQUEST:     'A',
    ORDINAL_STATUS_APPROVAL:    'B',
    ORDINAL_STATUS_RECRUITMENT: 'C',
    ORDINAL_STATUS_START:       'D',
    ORDINAL_STATUS_CLOSE:       'E',
}

// 기수 스케쥴 상태 키
const OrdinalScheduleStatusKey = {
    ORDINAL_SCHEDULE_STATUS_BEFORE: 'A',
    ORDINAL_SCHEDULE_STATUS_AFTER:  'B',
}

// 프로젝트 상태 키
const ProjectStatusKey = {
    PROJECT_STATUS_REQUEST:     'A',
    PROJECT_STATUS_APPROVAL:    'B',
    PROJECT_STATUS_RECRUITMENT: 'C',
    PROJECT_STATUS_CLOSE:       'D',
}

// 고객센터 메뉴 키
const HelpMenuKey = {
    HELP_MENU_FAQ:      'help_menu_faq',
    HELP_MENU_M2M:      'help_menu_m2m',
    HELP_MENU_NOTICE:   'help_menu_notice',
}

// 자주찾는 질문 메뉴 키
const HelpFaqMenuKey = {
    HELP_FAQ_MENU_SERVICE:              'help_faq_menu_service',
    HELP_FAQ_MENU_USAGE_COUNSEL:        'help_faq_menu_usage_counsel',
    HELP_FAQ_MENU_APPLY_MENTOR:         'help_faq_menu_apply_mentor',
    HELP_FAQ_MENU_PAYMENT_AND_REFUND:   'help_faq_menu_payment_and_refund',
}

// 1:1 문의 메뉴 키
const HelpM2mMenuKey = {
    HELP_M2M_MENU_INQUIRY:  'help_m2m_menu_inquiry',
    HELP_M2M_MENU_HISTORY:  'help_m2m_menu_history',
}

// 1:1 문의 주제 키
const HelpM2mSubjectKey = {
    HELP_M2M_SUBJECT_USAGE_COUNSEL: 'A',
    HELP_M2M_SUBJECT_USAGE_PROJECT: 'B',
    HELP_M2M_SUBJECT_APPLY_MENTOR:  'C',
    HELP_M2M_SUBJECT_PAYMENT:       'D',
    HELP_M2M_SUBJECT_ACCOUNT:       'E',
    HELP_M2M_SUBJECT_REFUND:        'F',
    HELP_M2M_SUBJECT_ERROR:         'G',
    HELP_M2M_SUBJECT_PROPOSAL:      'H',
}

// 사수지원 화면내 탭 키
const ApplyTabKey= {
    APPLY_TAB_MENTOR:   'apply_mentor',
    APPLY_TAB_EXAMPLE:  'apply_example',
    APPLY_TAB_FAQ:      'apply_faq',
}

// 사수 지원 상태 키
const ApplyStatusKey = {
    APPLY_STATUS_REQUEST:   'A',
    APPLY_STATUS_ACCEPT:    'B',
    APPLY_STATUS_APPROVAL:  'C',
    APPLY_STATUS_REJECT:    'D',
}

// 상담 신청 상태 키
const ApplyCounselStatusKey = {
    APPLY_COUNSEL_STATUS_REQUEST:   'A',
    APPLY_COUNSEL_STATUS_ACCEPT:    'B',
    APPLY_COUNSEL_STATUS_PAYMENT:   'C',
    APPLY_COUNSEL_STATUS_REJECT:    'D',
}

// 신청 사수해법 메뉴 키
const SalesSolutionMenuKey = {
    SALES_SOLUTION_MENU_COUNCEL:    'sales_solution_menu_counsel',
    SALES_SOLUTION_MENU_PROJECT:    'sales_solution_menu_project',
}

export {
    CommonKey,
    TableKey,
    PopupTypeKey,
    WorrySelectKey,
    ReplaceKey,
    SolutionMenuKey,
    MySolutionMenuKey,
    RecruitSolutionMenuKey,
    RequestSolutionMenuKey,
    ManageSolutionMenuKey,
    M2mSolutionMenuKey,
    ProfileMenuKey,
    UserMenuKey,
    CategoryTypeKey,
    TermsMenuKey,
    OrdinalStatusKey,
    OrdinalScheduleStatusKey,
    ProjectStatusKey,
    HelpMenuKey,
    HelpFaqMenuKey,
    HelpM2mMenuKey,
    HelpM2mSubjectKey,
    ApplyTabKey,
    ApplyStatusKey,
    ApplyCounselStatusKey,
    SalesSolutionMenuKey,
};
