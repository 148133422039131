//
//  counselRequestBar.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 9..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React from 'react';

// common
import { ProjectStatusKey } from 'constants/key';

// string
import { useTranslation } from 'react-i18next';

// style
import styles from 'css/component/projectRequestBar.module.css';


/**
 * @protocol CounselRequestBar
 * @date 2022/12/09
 * @brief 상담 신청 바
 */
function CounselRequestBar(props) {
    const { t } = useTranslation();

    /**
     * 기수 관련 메시지 반환
     * @returns {String} 기수 관련 메시지
     */
    const getCounselMessage = () => {
        if( props.data.status === ProjectStatusKey.PROJECT_STATUS_RECRUITMENT ) {
            return t('counsel_intro_request_possible');
        }
        else {
            return t('counsel_intro_request_impossible');
        }
    }

    return (
        <>
            <div className={styles.bar}>
                <form onSubmit={props.onRequestPressed}>
                    <div className={styles.container}>
                        <h4 className={styles.name}>{props.data.categoryInfo.name}</h4>
                        <h4 className={styles.recruit}>{getCounselMessage()}</h4>
                        {
                            props.data.status === ProjectStatusKey.PROJECT_STATUS_RECRUITMENT ?
                            <div className={styles.requestContainer}>
                                <div className={styles.ordinalContainer}>
                                </div>
                                <button className="main-btn">{props.reapply ? t('button_apply_counsel_re') : t('button_apply_counsel')}</button>
                            </div> :
                            null
                        }
                    </div>
                </form>
            </div>
            <div className={styles.responsive}>
                <form onSubmit={props.onRequestPressed}>
                    <div className={styles.container}>
                        <div className={styles.top}>
                            <h4 className={styles.name}>{props.data.categoryInfo.name}</h4>
                        </div>
                        {
                            props.data.status === ProjectStatusKey.PROJECT_STATUS_RECRUITMENT ?
                            <div className={styles.requestContainer}>
                                <div className={styles.ordinalContainer}>
                                <h4 className={styles.recruit}>{getCounselMessage()}</h4>
                                </div>
                                <button className="main-btn">{props.reapply ? t('button_apply_counsel_re') : t('button_apply_counsel')}</button>
                            </div> :
                            null
                        }
                    </div>
                </form>
            </div>
        </>
    );
}

export default CounselRequestBar;
