//
//  helpFaqView.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 19..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
} from 'react';

// common
import { HelpFaqMenuKey } from 'constants/key';
import { MenuList } from 'constants/list';

// component
import HelpFaqListView from 'components/subview/helpFaqListView';

/**
 * @protocol HelpFaqView
 * @date 2022/12/19
 * @brief faq 화면
 */
function HelpFaqView(props) {
    const [defaultTab] = useState(HelpFaqMenuKey.HELP_FAQ_MENU_SERVICE);

    /**
     * 사이드 메뉴 목록 반환
     * @returns {Array} 사이드 메뉴 목록
     */
    const renderTypeMenu = () => {
        const itemList = [];
        const menuList = MenuList.HelpFAQMenuList();
        for( var i = 0; i < menuList.length; i++ ) {
            itemList.push(  <li className="nav-item"
                                key={"help_faq_menu" + String(i)}>
                                <a className={menuList[i].id === defaultTab ? "active" : ""}
                                    href={'#' + menuList[i].id}
                                    data-toggle="tab">
                                    {menuList[i].title}
                                </a>
                            </li>);
        }
        return itemList;
    }

    /**
     * 사이드 메뉴 목록 반환
     * @returns {Array} 사이드 메뉴 목록
     */
    const renderContent = () => {
        const itemList = [];
        const menuList = MenuList.HelpFAQMenuList();
        for( var i = 0; i < menuList.length; i++ ) {
            itemList.push(  <HelpFaqListView key={menuList[i].id}
                                tabId={menuList[i].id}
                                tabName={menuList[i].title}
                                defaultActive={defaultTab === menuList[i].id ? true : false}/>);
        }
        return itemList;
    }
    return (
        <div className={"tab-pane fade show active"}>
            <div id="page-contents" className="row merged20">
                <div className="col-lg-12">
                    <div className="main-wraper">
                        <ul className="nav nav-tabs pix-filter">
                            {renderTypeMenu()}
                        </ul>
                        <div className="tab-content">
                            {renderContent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HelpFaqView;