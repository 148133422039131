//
//  manageCounselView.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 18..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
} from 'react';

// common
import ConstantPath from 'constants/path';
import {
    CommonKey,
    TableKey,
} from 'constants/key';
import { CommonList } from 'constants/list';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkSolution from 'network/networkSolution';

// info
import { COUNSELAPPLYINFO_LIST } from 'info/solution/counselApplyInfo';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';

// component
import PageTable from 'components/table/pageTable';
import ApplyUserInfoPopupView from 'components/modal/applyUserInfoPopupView';

// package
import { Link } from 'react-router-dom';


/**
 * @protocol ManageCounselView
 * @date 2023/01/18
 * @brief 사수해법 담당 상담 목록 화면
 */
function ManageCounselView(props) {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const user = useSelector(state => state.session.user);

    const [dataList, setDataList] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [infoVisible, setInfoVisible] = useState(false);
    const [selectApplyInfo, setSelectApplyInfo] = useState(null);

    const applyCounselStatusList = CommonList.ApplyCounselStatusList();

    // 데이터 요청 처리
    const fetchData = async (pageNum, pageSize) => {
        NetworkSolution.getManageCounselList((res) => {
            setDataList(res[COUNSELAPPLYINFO_LIST]);
            setTotalCount(res[CommonKey.PAGE_TOTAL_COUNT]);
        }, (res) => {
            dispatch(popupShowDefault(t('alert_network_error')));
        }, user.memberId, pageNum, pageSize);
    };

    /**
     * 스케쥴 column 목록 생성
     * @returns {Array} 기수 column 목록
     */
    const generateColumnList = () => {
        return [{
            id: TableKey.TABLE_NAME,
            name: t('word_counsel_name'),
            isClickable: true,
        }, {
            id: TableKey.TABLE_PRICE,
            name: t('word_price'),
            isClickable: false,
        }, {
            id: TableKey.TABLE_STATUS,
            name: t('word_progress'),
            isClickable: false,
        }, {
            id: TableKey.TABLE_ETC,
            name: t('manage_solution_apply_title'),
            isClickable: true,
        }];
    }

    /**
     * 스케쥴 데이터 목록 생성
     * @returns {Array} 기수 데이터 목록
     */
    const generateDataList = () => {
        if( DataUtil.validateArray(dataList) ) {
            var itemList = [];
            for( let i = 0; i < dataList.length; i++ ) {
                var applyCounselStatusIndex = applyCounselStatusList.findIndex(element => element.id === dataList[i].applyStatus);
                itemList.push({
                    [TableKey.TABLE_NAME]: dataList[i].projectName,
                    [TableKey.TABLE_PRICE]: (
                        Number(dataList[i].price) === 0 ?
                        t('word_free') :
                        t('word_currency') + DataUtil.numberWithCommas(Number(dataList[i].price))
                    ),
                    [TableKey.TABLE_STATUS]: (applyCounselStatusIndex >= 0 ? applyCounselStatusList[applyCounselStatusIndex].title : ''),
                    [TableKey.TABLE_PERIOD]: dataList[i].approvedate ? DataUtil.convertDateTimeToString(dataList[i].approvedate) : '',
                });
            }
            return itemList;
        }
        return null;
    }

    /**
     * 확인 버튼 클릭시 호출
     */
    const onInfoConfirmPressed = () => {
        setInfoVisible(false);
    }

    /**
     * 테이블 셀 커텀 
     * @param {*} element 
     * @param {*} info 
     * @returns 
     */
    const renderContentCell = (element, info) => {
        if( DataUtil.validateArray(dataList) ) {
            if( info.row.index < dataList.length ) {
                switch( info.column.columnDef.header ) {
                    case t('word_counsel_name'): {
                        var path = ConstantPath.PATH_COUNSEL_INTRO.split("/");
                        var strippedPath = path.slice(0, path.length-1).join("/");
                        var id = dataList[info.row.index].projectId;

                        return  <Link to={strippedPath+ '/' + id}>
                                    {info.cell.getValue()}
                                </Link>;
                    }
                    case t('manage_solution_apply_title'): {
                        return <button className="button primary circle"
                                    onClick={() => {
                                        setSelectApplyInfo(dataList[info.row.index]);
                                        setInfoVisible(true);
                                    }}>
                                    {t('button_view_content')}
                                </button>;
                    }
                    default: {
                        break;
                    }
                }
                return  <Link to={'/'}>
                            {info.cell.getValue()}
                        </Link>;
            }
        }
        
        return null;
    }

    return (
        <div className={"tab-pane show" + (props.defaultActive ? " active" : "")}
            id={props.tabId} >
            <div className="row">
                <PageTable
                    dataList={generateDataList()}
                    columnList={generateColumnList()}
                    fetchData={fetchData}
                    pageSize={10}
                    pageRangeDisplayed={5}
                    totalCount={totalCount}
                    renderCell={renderContentCell}/>
                <ApplyUserInfoPopupView
                    visible={infoVisible}
                    applyInfo={selectApplyInfo}
                    onConfirmPressed={onInfoConfirmPressed}/>
            </div>
        </div>
    );
}

export default ManageCounselView;