//
//  editProfileView.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 17..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
    useEffect,
} from 'react';

// common
import ConstantValue from 'constants/value';

// util
import ListUtil from 'util/listUtil';
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';
import { sessionUpdate } from 'redux/actions/sessionAction';

// network
import NetworkUser from 'network/networkUser';

// info
import UserInfo, {
    USERINFO_CONTAINER,
} from 'info/user/userInfo';

// component
import ImagePicker from 'components/image/imagePicker';
import { Form, Button } from 'react-bootstrap';

// package
import { useNavigate } from 'react-router';


/**
 * @protocol EditProfileView
 * @date 2022/11/17
 * @brief 프로필 수정 화면
 */
function EditProfileView(props) {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    var user = useSelector(state => state.session.user);
    const isLogin = useSelector(state => state.session.isLogin);

    if( !user ) {
		user = new UserInfo();
	}

    useEffect(() => {
		// 로그인 상태 체크
        if( !isLogin || !user.memberId ) {
            dispatch(popupShowDefault(t('alert_not_login'), () => {
                navigate('/');
            }));
        }
	}, [dispatch, navigate, t, user.memberNo, user.memberId, isLogin]);

    var phoneNumber = DataUtil.generatePhoneNumber(user.contact);

    const [email, setEmail] = useState(user.email ? user.email : '');
    const [gender, setGender] = useState(user.gender ? user.gender : '');
    const [nickname, setNickname] = useState(user.nickname ? user.nickname : '');
    const [phone1, setPhone1] = useState(phoneNumber ? phoneNumber[0] : '');
    const [phone2, setPhone2] = useState(phoneNumber ? phoneNumber[1] : '');
    const [phone3, setPhone3] = useState(phoneNumber ? phoneNumber[2] : '');
    const [job, setJob] = useState(user.currentJobCode ? user.currentJobCode : '');
    const [mbti, setMBTI] = useState(user.mbtiCode ? user.mbtiCode : '');
    const [company, setCompany] = useState(user.company ? user.company : '');
    const [deskImage, setDeskImage] = useState(null);
    const [introduce, setIntroduce] = useState(user.onelineIntro ? user.onelineIntro : '');

    const onEmailChanged = ({ target: { value } }) => setEmail(value);
    const onGenderPressed = ({ target: { value}}) => setGender(value);
    const onNicknameChanged = ({ target: { value}}) => setNickname(value);
    const onPhone1Changed = ({ target: { value}}) => setPhone1(value);
    const onPhone2Changed = ({ target: { value}}) => setPhone2(value);
    const onPhone3Changed = ({ target: { value}}) => setPhone3(value);
    const onJobPressed = ({ target: { value}}) => setJob(value);
    const onMBTIPressed = ({ target: { value }}) => setMBTI(value);
    const onCompanyChanged = ({ target: { value}}) => setCompany(value);
    const onDeskImageChanged = (image) => setDeskImage(image);
    const onIntroduceChanged = ({ target: { value}}) => setIntroduce(value);

    const onSubmitPressed = (event) => {
        event.preventDefault();

        var newUserInfo = new UserInfo();
        newUserInfo.memberId = user.memberId;
        newUserInfo.email = email;
        newUserInfo.gender = gender;
        newUserInfo.nickname = nickname;
        newUserInfo.contact = phone1 + phone2 + phone3;
        newUserInfo.currentJobCode = job;
        newUserInfo.mbtiCode = mbti;
        newUserInfo.company = company;
        newUserInfo.onelineIntro = introduce;
        newUserInfo.deskImgData = deskImage;

        NetworkUser.updateMemberInfo((res) => {
            // 세션 정보 설정
            dispatch(sessionUpdate(res[USERINFO_CONTAINER]));
            dispatch(popupShowDefault(t('alert_complete_modify')));
        }, (res) => {
            dispatch(popupShowDefault(t('alert_network_error')));
        }, newUserInfo);
    };

    return (
        <div className={"tab-pane fade show" + (props.defaultActive ? " active" : "")}
            id={props.tabId} >
            <div className="container">
                <h4><i className="icofont-lock"></i> {t('edit_profile_title')}</h4>
                <Form className="c-form" onSubmit={onSubmitPressed}>
                    <div className="row merged-10">
                        <div className="col-lg-12">
                            <input type="text"
                                value={email}
                                placeholder={t('input_email')}
                                required
                                maxLength={ConstantValue.INPUT_MAX_LENGTH_EMAIL}
                                onChange={onEmailChanged}/>
                        </div>
                        <div className="col-lg-12">
                            <label>{t('input_phone')}</label>
                            <div className="row" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                                <div style={{width: '20%'}}>
                                    <input type="text"
                                        value={phone1}
                                        pattern="[0-9]+"
                                        placeholder={t('input_phone1')}
                                        required
                                        maxLength={ConstantValue.INPUT_MAX_LENGTH_PHONE1}
                                        onChange={onPhone1Changed}/>
                                </div>
                                <div style={{width: '10%', textAlign: 'center'}}>
                                    <h4>-</h4>
                                </div>
                                <div style={{width: '30%'}}>
                                    <input type="text"
                                        value={phone2}
                                        pattern="[0-9]+"
                                        placeholder={t('input_phone2')}
                                        required
                                        maxLength={ConstantValue.INPUT_MAX_LENGTH_PHONE2}
                                        onChange={onPhone2Changed}/>
                                </div>
                                <div style={{width: '10%', textAlign: 'center'}}>
                                    <h4>-</h4>
                                </div>
                                <div style={{width: '30%'}}>
                                    <input type="text"
                                        value={phone3}
                                        pattern="[0-9]+"
                                        placeholder={t('input_phone3')}
                                        required
                                        maxLength={ConstantValue.INPUT_MAX_LENGTH_PHONE3}
                                        onChange={onPhone3Changed}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="gender">
                                <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    value={ConstantValue.USER_GENDER_MALE}
                                    required
                                    defaultChecked={gender === ConstantValue.USER_GENDER_MALE}
                                    onClick={onGenderPressed}/>
                                <label htmlFor="male">{t('input_male')}</label>
                                <input
                                    type="radio"
                                    id="female"
                                    name="gender"
                                    value={ConstantValue.USER_GENDER_FEMALE}
                                    required
                                    defaultChecked={gender === ConstantValue.USER_GENDER_FEMALE}
                                    onClick={onGenderPressed}/>
                                <label htmlFor="female">{t('input_female')}</label>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <input type="text"
                                value={nickname}
                                placeholder={t('input_nickname')}
                                required
                                maxLength={ConstantValue.INPUT_MAX_LENGTH_NICKNAME}
                                onChange={onNicknameChanged}/>
                        </div>
                        <div className="col-lg-6">
                            <select aria-required
                                defaultValue={user.mbtiCode}
                                onChange={onMBTIPressed}>
                                {ListUtil.generateMBTIList()}
                            </select>
                        </div>
                        <div className="col-lg-6">
                            <input type="text"
                                value={company}
                                placeholder={t('input_company') + '(' + t('word_option') + ')'}
                                maxLength={ConstantValue.INPUT_MAX_LENGTH_COMPANY}
                                onChange={onCompanyChanged}/>
                        </div>
                        <div className="col-lg-6">
                            <select required
                                defaultValue={user.currentJobCode}
                                onChange={onJobPressed}>
                                {ListUtil.generateJobList(job)}
                            </select>
                        </div>
                        <div className="col-lg-12">
                            <input type="text"
                                value={introduce}
                                placeholder={t('input_introduce') + '(' + t('word_option') + ')'}
                                maxLength={ConstantValue.INPUT_MAX_LENGTH_INTRODUCE}
                                onChange={onIntroduceChanged}/>
                        </div>
                        <div className="col-lg-12">
                            <label>{t('input_deskimage') + '(' + t('word_option') + ')'}</label>
                            <ImagePicker
                                defaultImageUrl={user.deskImgUrl}
                                onImageChanged={onDeskImageChanged}/>
                        </div>
                        <div className="col-lg-12">
                            <Button className="main-btn" type="submit">
                                <i className="icofont-key"></i> {t('button_modify')}
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default EditProfileView;