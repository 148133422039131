//
//  userInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 9..
//  Copyright © 2022년 Melephant. All rights reserved.
//


// common
import { CommonKey } from 'constants/key';

// util
import DataUtil from 'util/dataUtil';

// network
import { listParseFromDictionary } from 'info/listParse';

// info
import TendencyInfo, {
    TENDENCYINFO_LIST,
} from 'info/user/tendencyInfo';

export const USERINFO_LIST                  = 'member_list';
export const USERINFO_CONTAINER             = 'member_info';
export const USERINFO_MEMBER_ID             = 'member_id';
export const USERINFO_SNS_TYPE              = 'sns_type';
export const USERINFO_NAME                  = 'name';
export const USERINFO_EMAIL                 = 'email';
export const USERINFO_GENDER                = 'gender';
export const USERINFO_NICKNAME              = 'nickname';
export const USERINFO_CONTACT               = 'contact';
export const USERINFO_JOBMAIL_AUTH_STATE    = 'jobmail_auth_status';
export const USERINFO_JOB_AUTH_STATE        = 'job_auth_status';
export const USERINFO_CAREER                = 'career';
export const USERINFO_CURRICULUM            = 'curriculum';
export const USERINFO_MARKETINGOK           = 'marketingok';
export const USERINFO_COMPANY               = 'company';
export const USERINFO_BUSINESS_NO           = 'business_no';
export const USERINFO_EMP_PERIOD            = 'emp_period';
export const USERINFO_JOB_EMAIL             = 'job_email';
export const USERINFO_APPLYDATE             = 'applydate';
export const USERINFO_DESK_IMG_URL          = 'deskimage_url';
export const USERINFO_ONELINE_INTRO         = 'oneline_intro';
export const USERINFO_CURRENT_JOB_CODE      = 'current_job_code';
export const USERINFO_MEMBER_NO             = 'member_no';
export const USERINFO_MBTI_CODE             = 'mbti_code';
export const USERINFO_BANK_NAME             = 'bank_name';
export const USERINFO_BANK_ACCOUNT          = 'bank_account';
export const USERINFO_BANK_CODE             = 'bank_code';


/**
 * @protocol UserInfo
 * @date 2022/11/09
 * @brief 사용자 정보
 */
export default class UserInfo {
    constructor() {
        this.memberId = '';
        this.snsType = '';
        this.name = '';
        this.email = '';
        this.gender = '';
        this.nickname = '';
        this.contact = '';
        this.jobMailAuthState = '';
        this.jobAuthState = '';
        this.career = '';
        this.curriculum = '';
        this.marketingOk = false;
        this.company = '';
        this.businessNo = '';
        this.empPeriod = '';
        this.jobEmail = '';
        this.applyDate = null;
        this.deskImgUrl = '';
        this.onelineIntro ='';
        this.currentJobCode = '';
        this.memberNo = '';
        this.mbtiCode = '';
        this.bankName = '';
        this.bankAccount = '';
        this.bankCode = '';

        this.tendencyList = [];
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(USERINFO_MEMBER_ID) ) {
            this.memberId = dict[USERINFO_MEMBER_ID];
        }

        if( dict.hasOwnProperty(USERINFO_SNS_TYPE) ) {
            this.snsType = dict[USERINFO_SNS_TYPE];
        }

        if( dict.hasOwnProperty(USERINFO_NAME) ) {
            this.name = dict[USERINFO_NAME];
        }

        if( dict.hasOwnProperty(USERINFO_EMAIL) ) {
            this.email = dict[USERINFO_EMAIL];
        }

        if( dict.hasOwnProperty(USERINFO_GENDER) ) {
            this.gender = dict[USERINFO_GENDER];
        }

        if( dict.hasOwnProperty(USERINFO_NICKNAME) ) {
            this.nickname = dict[USERINFO_NICKNAME];
        }

        if( dict.hasOwnProperty(USERINFO_CONTACT) ) {
            this.contact = dict[USERINFO_CONTACT];
        }

        if( dict.hasOwnProperty(USERINFO_JOBMAIL_AUTH_STATE) ) {
            this.jobMailAuthState = dict[USERINFO_JOBMAIL_AUTH_STATE];
        }

        if( dict.hasOwnProperty(USERINFO_JOB_AUTH_STATE) ) {
            this.jobAuthState = dict[USERINFO_JOB_AUTH_STATE];
        }

        if( dict.hasOwnProperty(USERINFO_CAREER) ) {
            this.career = dict[USERINFO_CAREER];
        }

        if( dict.hasOwnProperty(USERINFO_CURRICULUM) ) {
            this.curriculum = dict[USERINFO_CURRICULUM];
        }

        if( dict.hasOwnProperty(USERINFO_MARKETINGOK) ) {
            this.marketingOk = dict[USERINFO_MARKETINGOK];
        }

        if( dict.hasOwnProperty(USERINFO_COMPANY) ) {
            this.company = dict[USERINFO_COMPANY];
        }

        if( dict.hasOwnProperty(USERINFO_BUSINESS_NO) ) {
            this.businessNo = dict[USERINFO_BUSINESS_NO];
        }

        if( dict.hasOwnProperty(USERINFO_EMP_PERIOD) ) {
            this.empPeriod = dict[USERINFO_EMP_PERIOD];
        }

        if( dict.hasOwnProperty(USERINFO_JOB_EMAIL) ) {
            this.jobEmail = dict[USERINFO_JOB_EMAIL];
        }

        if( dict.hasOwnProperty(USERINFO_APPLYDATE) ) {
            this.applyDate = dict[USERINFO_APPLYDATE];
        }

        if( dict.hasOwnProperty(USERINFO_DESK_IMG_URL) ) {
            this.deskImgUrl = dict[USERINFO_DESK_IMG_URL];
        }

        if( dict.hasOwnProperty(USERINFO_ONELINE_INTRO) ) {
            this.onelineIntro = dict[USERINFO_ONELINE_INTRO];
        }

        if( dict.hasOwnProperty(USERINFO_CURRENT_JOB_CODE) ) {
            this.currentJobCode = dict[USERINFO_CURRENT_JOB_CODE];
        }

        if( dict.hasOwnProperty(USERINFO_MEMBER_NO) ) {
            this.memberNo = dict[USERINFO_MEMBER_NO];
        }

        if( dict.hasOwnProperty(USERINFO_MBTI_CODE) ) {
            this.mbtiCode = dict[USERINFO_MBTI_CODE];
        }

        if( dict.hasOwnProperty(USERINFO_BANK_NAME) ) {
            this.bankName = dict[USERINFO_BANK_NAME];
        }

        if( dict.hasOwnProperty(USERINFO_BANK_ACCOUNT) ) {
            this.bankAccount = dict[USERINFO_BANK_ACCOUNT];
        }

        if( dict.hasOwnProperty(USERINFO_BANK_CODE) ) {
            this.bankCode = dict[USERINFO_BANK_CODE];
        }

        if( dict.hasOwnProperty(TENDENCYINFO_LIST) ) {
            this.tendencyList = listParseFromDictionary(TendencyInfo, dict[TENDENCYINFO_LIST], '');
        }
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {json} json 데이터
     */
    dictionaryForAllKey() {
        var json = {
            [USERINFO_MEMBER_ID]: this.memberId,
            [USERINFO_SNS_TYPE]: this.snsType,
            [USERINFO_NAME]: this.name,
            [USERINFO_EMAIL]: this.email,
            [USERINFO_GENDER]: this.gender,
            [USERINFO_NICKNAME]: this.nickname,
            [USERINFO_CONTACT]: this.contact,
            [USERINFO_JOBMAIL_AUTH_STATE]: this.jobMailAuthState,
            [USERINFO_JOB_AUTH_STATE]: this.jobAuthState,
            [USERINFO_CAREER]: this.career,
            [USERINFO_CURRICULUM]: this.curriculum,
            [USERINFO_MARKETINGOK]: this.marketingOk,
            [USERINFO_COMPANY]: this.company,
            [USERINFO_BUSINESS_NO]: this.businessNo,
            [USERINFO_EMP_PERIOD]: this.empPeriod,
            [USERINFO_JOB_EMAIL]: this.jobEmail,
            [USERINFO_APPLYDATE]: this.applyDate,
            [USERINFO_DESK_IMG_URL]: this.deskImgUrl,
            [USERINFO_ONELINE_INTRO]: this.onelineIntro,
            [USERINFO_CURRENT_JOB_CODE]: this.currentJobCode,
            [USERINFO_MEMBER_NO]: this.memberNo,
            [USERINFO_MBTI_CODE]: this.mbtiCode,
        };
        return  JSON.stringify(json);
    }

    /**
     * 로그아웃 키에 대한 dictionary 반환
     * @returns {dict} dictionary 데이터
     */
    dictionaryForLogout() {
        var dict = {
            [USERINFO_MEMBER_ID]: this.memberId,
        };
        return dict;
    }

    /**
     * 사수 기본정보 수정 키에 대한 dictionary 반환
     * @returns {dict} dictionary 데이터
     */
    dictionaryForUpdateMentorBasic() {
        var dict = {
            [USERINFO_MEMBER_ID]: this.memberId,
            [USERINFO_EMP_PERIOD]: this.empPeriod,
            [USERINFO_CURRICULUM]: this.curriculum,
            [USERINFO_CAREER]: this.career,
        };
        return dict;
    }

    /**
     * 사수 정산정보 수정 키에 대한 dictionary 반환
     * @returns {dict} dictionary 데이터
     */
    dictionaryForUpdateMentorAdjustment() {
        var dict = {
            [USERINFO_MEMBER_ID]: this.memberId,
            [USERINFO_BANK_NAME]: this.bankName,
            [USERINFO_BANK_ACCOUNT]: this.bankAccount,
            [USERINFO_BANK_CODE]: this.bankCode,
        };
        return dict;
    }
    
    /**
     * 회원가입 관련 키에 대한 FormData 반환
     * @returns {FormData} formData 데이터
     */
    formDataForSignUp() {
        var formData = new FormData();
        
        formData.append([USERINFO_MEMBER_ID], this.memberId);
        formData.append([USERINFO_SNS_TYPE], this.snsType);
        formData.append([USERINFO_EMAIL], this.email ? this.email : '');
        formData.append([USERINFO_GENDER], this.gender ? this.gender : '');
        formData.append([USERINFO_NICKNAME], this.nickname ? this.nickname : '');
        formData.append([USERINFO_CONTACT], this.contact ? this.contact : '');
        formData.append([USERINFO_COMPANY], this.company ? this.company : '');
        formData.append([USERINFO_ONELINE_INTRO], this.onelineIntro ? this.onelineIntro : '');
        formData.append([USERINFO_CURRENT_JOB_CODE], this.currentJobCode ? this.currentJobCode : '');
        formData.append([USERINFO_MBTI_CODE], this.mbtiCode ? this.mbtiCode : CommonKey.USERINFO_MBTI_DEFAIULT);

        if( this.deskImgData ) {
            formData.append([USERINFO_DESK_IMG_URL], this.deskImgData);
        }
        return formData;
    }

    /**
     * 수정 관련 키에 대한 FormData 반환
     * @returns {FormData} formData 데이터
     */
    formDataForUpdate() {
        var formData = new FormData();
        
        formData.append([USERINFO_MEMBER_ID], this.memberId);
        formData.append([USERINFO_EMAIL], this.email ? this.email : '');
        formData.append([USERINFO_GENDER], this.gender ? this.gender : '');
        formData.append([USERINFO_NICKNAME], this.nickname ? this.nickname : '');
        formData.append([USERINFO_CONTACT], this.contact ? this.contact : '');
        formData.append([USERINFO_COMPANY], this.company ? this.company : '');
        formData.append([USERINFO_ONELINE_INTRO], this.onelineIntro ? this.onelineIntro : '');
        formData.append([USERINFO_CURRENT_JOB_CODE], this.currentJobCode ? this.currentJobCode : '');
        formData.append([USERINFO_MBTI_CODE], this.mbtiCode ? this.mbtiCode : CommonKey.USERINFO_MBTI_DEFAIULT);
        
        if( this.deskImgData === '' ) {
            formData.append([USERINFO_DESK_IMG_URL], this.deskImgData);
        }

        if( this.deskImgData ) {
            formData.append([USERINFO_DESK_IMG_URL], this.deskImgData);
        }

        return formData;
    }

    /**
     * 수정 관련 키에 대한 FormData 반환
     * @returns {FormData} formData 데이터
     */
    formDataForApply() {
        var formData = new FormData();
        
        formData.append([USERINFO_MEMBER_ID], this.memberId);
        formData.append([USERINFO_JOB_EMAIL], this.jobEmail ? this.jobEmail : '');
        formData.append([USERINFO_GENDER], this.gender ? this.gender : '');
        formData.append([USERINFO_NICKNAME], this.nickname ? this.nickname : '');
        formData.append([USERINFO_CONTACT], this.contact ? this.contact : '');
        formData.append([USERINFO_COMPANY], this.company ? this.company : '');
        formData.append([USERINFO_ONELINE_INTRO], this.onelineIntro ? this.onelineIntro : '');
        formData.append([USERINFO_CURRENT_JOB_CODE], this.currentJobCode ? this.currentJobCode : '');
        formData.append([USERINFO_MBTI_CODE], this.mbtiCode ? this.mbtiCode : CommonKey.USERINFO_MBTI_DEFAIULT);
        
        formData.append([USERINFO_EMP_PERIOD], this.empPeriod ? this.empPeriod : '');
        formData.append([USERINFO_CURRICULUM], this.curriculum ? this.curriculum : '');
        formData.append([USERINFO_CAREER], this.career ? this.career : '');

        if( this.deskImgData === '' ) {
            formData.append([USERINFO_DESK_IMG_URL], this.deskImgData);
        }

        if( this.deskImgData ) {
            formData.append([USERINFO_DESK_IMG_URL], this.deskImgData);
        }
        
        return formData;
    }

    /**
     * 사수인지 체크
     * @returns {Boolean} 사수인지 여부
     */
    checkMentor() {
        if( DataUtil.validateNumber(this.memberNo) ) {
            return true;
        }
        return false;
    }
}
