//
//  solutionHeader.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 13..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React from 'react';

// common
import ConstantPath from 'constants/path';

// string
import { useTranslation } from 'react-i18next';

// style
import styles from 'css/component/solutionHeader.module.css';

// package
import { Nav} from 'react-bootstrap';


/**
 * @protocol SolutionHeader
 * @date 2023/01/13
 * @brief 사수해법 상단 화면
 */
export default function SolutionHeader(props) {
    const { t } = useTranslation();
  
    return (
        <div className={props.responsive ? styles.responsive : styles.header}>
            <div className={styles.topbar}>
                <ul>
                    <li>
                        <Nav activeKey={ConstantPath.PATH_SOLUTION}>
                        <Nav.Link className={props.location === ConstantPath.PATH_SOLUTION ? styles.link_active : styles.link}
                                href={ConstantPath.PATH_SOLUTION}>
                                {t('menu_solution_project')}
                            </Nav.Link>
                            <Nav.Link className={styles.divide}>|</Nav.Link>
                            <Nav.Link className={props.location === ConstantPath.PATH_SOLUTION_COUNSEL ? styles.link_active : styles.link}
                                href={ConstantPath.PATH_SOLUTION_COUNSEL}>
                                {t('menu_solution_counsel')}
                            </Nav.Link>
                            <Nav.Link className={styles.divide}>|</Nav.Link>
                            <Nav.Link className={props.location === ConstantPath.PATH_SOLUTION_BLOG ? styles.link_active : styles.link}
                                href={ConstantPath.PATH_SOLUTION_BLOG}>
                                {t('menu_solution_blog')}
                            </Nav.Link>
                            <Nav.Link className={styles.divide}>|</Nav.Link>
                            <Nav.Link className={props.location === ConstantPath.PATH_SOLUTION_PROJECT ? styles.link_active : styles.link}
                                href={ConstantPath.PATH_SOLUTION_PROJECT}>
                                {t('menu_solution_home')}
                            </Nav.Link>
                            <Nav.Link className={styles.divide}>|</Nav.Link>
                            <Nav.Link className={props.location === ConstantPath.PATH_SOLUTION_APPLY ? styles.link_active : styles.link}
                                href={ConstantPath.PATH_SOLUTION_APPLY}>
                                {t('menu_solution_apply')}
                            </Nav.Link>
                        </Nav>
                    </li>
                </ul>
            </div>
        </div>
    );
}
