//
//  replaceKey.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 30..
//  Copyright © 2022년 Melephant. All rights reserved.
//


/**
 * @protocol ReplaceKey
 * @date 2022/11/30
 * @brief 문구 변환 키값
 */
 export const ReplaceKey = {
    REPLACE_PROJECT_NAME:   '[[REPLACE_PROJECT_NAME]]',
    REPLACE_EMAIL:          '[[REPLACE_EMAIL]]',
    REPLACE_PHONE:          '[[REPLACE_PHONE]]',
    REPLACE_WEEKS:          '[[REPLACE_WEEKS]]',
    REPLACE_NAME:           '[[REPLACE_NAME]]',
    REPLACE_DATE:           '[[REPLACE_DATE]]',
};
