//
//  reviewCell.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 29..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
} from 'react';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// style
import styles from 'css/component/reviewCell.module.css';


/**
 * @protocol ReviewCell
 * @date 2022/11/29
 * @brief 리뷰 셀
 */
function ReviewCell(props) {
    const { t } = useTranslation();

    return (
        props.disableList ?
        <div className={styles.container} style={props.style}>
            <div className={styles.header}>
                <div className={styles.user}>
                    <img src={props.data.reviewer && props.data.reviewer.deskImgUrl ? props.data.reviewer.deskImgUrl : 'assets/images/image/img_user.jpg'} alt=""/>
                    <h4 className={styles.nickname}>{props.data.reviewer.nickname}</h4>
                    <h6 className={styles.date}>{DataUtil.convertDateTimeToString(props.data.regdate)}</h6>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.review}>
                    <span>{props.data.review}</span>
                </div>
                {
                    props.showDelete && props.memberId === props.data.reviewerId ?
                    <button
                        id={props.data.reviewId}
                        className="button primary circle"
                        onClick={props.onDeletePressed}>
                        {t('button_delete')}
                    </button> :
                    null
                }
            </div>
        </div> :
        <li>
            <div className={styles.container} style={props.style}>
                <div className={styles.header}>
                    <div className={styles.user}>
                        <img src={props.data.reviewer && props.data.reviewer.deskImgUrl ? props.data.reviewer.deskImgUrl : 'assets/images/image/img_user.jpg'} alt=""/>
                        <h4 className={styles.nickname}>{props.data.reviewer.nickname}</h4>
                        <h6 className={styles.date}>{DataUtil.convertDateTimeToString(props.data.regdate)}</h6>
                    </div>
                    
                </div>
                <div className={styles.footer}>
                    <div className={styles.review}>
                        <span>{props.data.review}</span>
                    </div>
                    {
                        props.showDelete && props.memberId === props.data.reviewerId ?
                        <button
                            id={props.data.reviewId}
                            className="button primary circle"
                            onClick={props.onDeletePressed}>
                            {t('button_delete')}
                        </button> :
                        null
                    }
                </div>
            </div>
        </li>
    );
}

export default ReviewCell;