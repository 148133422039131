//
//  termsScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 10. 25..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
} from 'react';

// common
import { MenuList } from 'constants/list';
import { TermsMenuKey } from 'constants/key';

// util
import DataUtil from 'util/dataUtil';

// component
import Layout from 'components/layout/layout';
import SideTab from 'components/tab/sideTab';
import PrivacyTerms from 'components/terms/privacyTerms';
import ServiceTerms from 'components/terms/serviceTerms';


/**
 * @protocol TermsScreen
 * @date 2022/10/25
 * @brief 약관 화면
 */
function TermsScreen(props) {
    var activeIndex = 0;
    const termsList = MenuList.TermsMenuList();

    if( DataUtil.validateString(props.active) ) {
        var index = termsList.findIndex(element => element.id === props.active);
        activeIndex = index >= 0 ? index : 0;
    }

    const [defaultTab] = useState(termsList[activeIndex].id);

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}>
            <div className="gap" style={{textAlign: 'left'}}>
                <div className="container">
                    <SideTab
                        defaultTab={defaultTab}
                        menuList={termsList}/>
                    <div className="col-lg-12">
                        <div className="tab-content" id="components-nav">
                            <ServiceTerms tabId={TermsMenuKey.TERM_MENU_SERVICE}
                                defaultActive={defaultTab === TermsMenuKey.TERM_MENU_SERVICE ? true : false}/>
                            <PrivacyTerms tabId={TermsMenuKey.TERM_MENU_PRIVACY}
                                defaultActive={defaultTab === TermsMenuKey.TERM_MENU_PRIVACY ? true : false}/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
        
    );
}

export default TermsScreen;