//
//  m2mAnswerInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 15..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// info
import UserInfo from "info/user/userInfo";

export const M2MANSWERINFO_LIST             = 'm2m_answer_list';
export const M2MANSWERINFO_CONTAINER        = 'm2m_answer_info';
export const M2MANSWERINFO_ID               = 'project_m2m_answer_idx';
export const M2MANSWERINFO_PROJECT_M2M_ID   = 'project_m2m_idx';
export const M2MANSWERINFO_MEMBER_ID        = 'member_id';
export const M2MANSWERINFO_ANSWER           = 'answer';
export const M2MANSWERINFO_REGDATE          = 'regdate';


/**
 * @protocol M2mAnswerInfo
 * @date 2022/12/15
 * @brief 1:1 문의 답변 정보
 */
export default class M2mAnswerInfo {
    constructor() {
        this.m2mAnswerId = 0;
        this.m2mId = '';
        this.memberId = '';
        this.answer = '';
        this.regdate = null;

        this.userInfo = new UserInfo();
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(M2MANSWERINFO_ID) ) {
            this.m2mAnswerId = dict[M2MANSWERINFO_ID];
        }

        if( dict.hasOwnProperty(M2MANSWERINFO_PROJECT_M2M_ID) ) {
            this.m2mId = dict[M2MANSWERINFO_PROJECT_M2M_ID];
        }

        if( dict.hasOwnProperty(M2MANSWERINFO_MEMBER_ID) ) {
            this.memberId = dict[M2MANSWERINFO_MEMBER_ID];
        }

        if( dict.hasOwnProperty(M2MANSWERINFO_ANSWER) ) {
            this.answer = dict[M2MANSWERINFO_ANSWER];
        }

        if( dict.hasOwnProperty(M2MANSWERINFO_REGDATE) ) {
            this.regdate = new Date(dict[M2MANSWERINFO_REGDATE]);
        }

        this.userInfo.parseFromDictionary(dict);
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [M2MANSWERINFO_ID]: this.m2mAnswerId,
            [M2MANSWERINFO_PROJECT_M2M_ID]: this.m2mId,
            [M2MANSWERINFO_MEMBER_ID]: this.memberId,
            [M2MANSWERINFO_ANSWER]: this.answer,
            [M2MANSWERINFO_REGDATE]: this.regdate,
        };
        return dict;
    }

    /**
     * 생성 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForPost() {
        var dict = {
            [M2MANSWERINFO_PROJECT_M2M_ID]: this.m2mId,
            [M2MANSWERINFO_MEMBER_ID]: this.memberId,
            [M2MANSWERINFO_ANSWER]: this.answer,
        };
        return dict;
    }
}
