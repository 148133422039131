//
//  sideTab.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 17..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
} from 'react';


/**
 * @protocol SideTab
 * @date 2022/11/17
 * @brief 사이드 메뉴
 */
function SideTab(props) {
    const renderTypeMenu = () => {
        const itemList = [];
        const menuList = props.menuList;
        for( var i = 0; i < menuList.length; i++ ) {
            itemList.push(  <li className="nav-item"
                                key={"side_tab_menu_" + String(i)}>
                                <a className={menuList[i].id === props.defaultTab ? "active" : ""}
                                    href={menuList[i].url ? menuList[i].url :'#' + menuList[i].id}
                                    data-toggle={menuList[i].url ? "" : "tab"}>
                                    {menuList[i].title}
                                </a>
                            </li>);
        }
        return itemList;
    }

    return (
        <div className="col-lg-12">
            <nav className="responsive-tab style-4 mb-4">
                <ul className="nav nav-tabs uk-list">
                {renderTypeMenu()}
                </ul>
            </nav>
        </div>
    );
}

export default SideTab;