//
//  path.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 4..
//  Copyright © 2022년 Melephant. All rights reserved.
//


/**
 * @protocol ConstantPath
 * @date 2022/11/04
 * @brief 주소 값
 */
const ConstantPath = {
    PATH_LOGIN:             '/login',
    PATH_SIGNUP_ADDITIONAL: '/signup/additional',
    PATH_OAUTH_KAKAO:       'oauth/kakao',

    PATH_TERMS_SERVICE:     '/terms/service',
    PATH_TERMS_PRIVACY:     '/terms/privacy',
    
    PATH_WORRY:             '/worry',
    PATH_WORRY_DISPOSITION: '/worry/disposition',

    PATH_SOLUTION:          '/',
    PATH_SOLUTION_MY:       '/solution/my',
    PATH_SOLUTION_RECRUIT:  '/solution/recruit',
    PATH_SOLUTION_QNA:      '/solution/qna',
    PATH_SOLUTION_QNA_DETAIL:   '/solution/qna/detail',
    PATH_SOLUTION_MANAGE:   '/solution/manage',
    PATH_SOLUTION_SALES:    '/solution/sales',
    PATH_SOLUTION_COUNSEL:  '/solution/counsel',
    PATH_SOLUTION_PROJECT:  '/solution/project',
    PATH_SOLUTION_BLOG:     '/solution/blog',
    PATH_SOLUTION_BLOG_DETAIL:  '/solution/blog/:blog_id',
    PATH_SOLUTION_APPLY:    '/solution/apply',
    PATH_SOLUTION_REQUEST:  '/solution/request',

    PATH_PROJECT:           '/project/:project_id/:ordinal',
    PATH_PROJECT_INTRO:     '/project/intro/:project_id',
    PATH_PROJECT_MANAGE:    '/project/manage',
    PATH_PROJECT_ATTENDANCE:'/project/manage/attendance',
    PATH_PROJECT_CONFIRM:   '/project/confirm',
    PATH_PROJECT_INVOICE:   '/project/invoice',
    PATH_COUNSEL_INTRO:     '/counsel/intro/:project_id',
    PATH_COUNSEL_CONFIRM:   '/counsel/confirm',
    PATH_COUNSEL_INVOICE:   '/counsel/invoice',
    PATH_PROJECT_NOTICE:    '/project/:project_id/:ordinal/notice',
    PATH_PROJECT_NOTICE_DETAIL: '/project/:project_id/:ordinal/notice/:notice_id',
    PATH_NOTICE:            '/notice',
    PATH_PROJECT_REVIEW:    '/review/project/:project_id',
    PATH_MENTOR_REVIEW:     '/review/mentor/:mentor_id',

    PATH_HELP:              '/help',
    PATH_HELP_FAQ:          '/help/faq',
    PATH_HELP_M2M:          '/help/qna',
    PATH_HELP_M2M_DETAIL:   '/help/qna/detail',
    PATH_HELP_NOTICE:       '/help/notice',
    PATH_HELP_NOTICE_DETAIL:'/help/notice/detail',

    PATH_NFT:               '/nft',

    PATH_EDIT_PROFILE:      '/edit/profile',
    PATH_EDIT_MENTOR:       '/edit/mentor',

    PATH_COMPANY:           '/company',

    PATH_PAYMENT_COUNSEL:           '/payment/counsel',
    PATH_PAYMENT_COUNSEL_SUCCESS:   '/payment/counsel/success',
    PATH_PAYMENT_COUNSEL_FAIL:      '/payment/counsel/fail',
    PATH_PAYMENT_PROJECT:           '/payment/project',
    PATH_PAYMENT_PROJECT_SUCCESS:   '/payment/project/success',
    PATH_PAYMENT_PROJECT_FAIL:      '/payment/project/fail',
};

export default ConstantPath;
