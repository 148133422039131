//
//  projectPaymentFailScreen.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 31..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React from 'react';

// common
import ConstantPath from 'constants/path';

// string
import { useTranslation } from 'react-i18next';

// redux
import { useDispatch } from 'react-redux';
import { dataPayment } from 'redux/actions/dataAction';
import { popupShowDefault } from 'redux/actions/popupAction';

// package
import { useNavigate } from 'react-router';


/**
 * @protocol ProjectPaymentFailScreen
 * @date 2023/01/31
 * @brief 팀플 결제 취소 화면
 */
function ProjectPaymentFailScreen(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
	const navigate = useNavigate();

    dispatch(popupShowDefault(t('alert_network_error'), () => {
        dispatch(dataPayment(null));
        navigate(ConstantPath.PATH_SOLUTION_REQUEST);
    }));
    return (
        <div/>
    );
}

export default ProjectPaymentFailScreen;