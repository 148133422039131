//
//  noticeRegisterPopupView.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 12..
//  Copyright © 2022년 Melephant. All rights reserved.
//


import React, {
    useState,
    useEffect,
} from 'react';

// common
import ConstantValue from 'constants/value';

// string
import { useTranslation } from 'react-i18next';

// package
import { Button, Modal } from 'react-bootstrap';


/**
 * @protocol NoticeRegisterPopupView
 * @date 2022/12/12
 * @brief 공지사항 작성 화면 객체
 */
export default function NoticeRegisterPopupView(props) {
    const { t } = useTranslation();

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    useEffect(() => {
    }, []);

    const onTitleChanged = ({ target: { value}}) => setTitle(value);
    const onContentChanged = ({ target: { value}}) => setContent(value);

    /**
     * 초기화
     */
    const initialize = () => {
        setTitle('');
        setContent('');
    }

    /**
     * 취소 버튼 클릭시 호출
     */
    const onCancelPressed = () => {
        if( props.onCancelPressed ) {
            props.onCancelPressed();
        }
        
        // 재사용 위해 초기화
        initialize();
    };

    /**
     * 취소 버튼 클릭시 호출
     */
    const onNextPressed = () => {
        if( props.onConfirmPressed ) {
            props.onConfirmPressed(title, content);
        }

        // 재사용 위해 초기화
        initialize();
    };

    return  <Modal
                show={props.visible}
                onHide={onCancelPressed}
                backdrop="static"
                size='lg'
                keyboard={false}>
                <Modal.Header>
                    {t('project_notice_register_title')}
                </Modal.Header>
                <Modal.Body>
                    <div className="row col-xs-4">
                        <div className="col-lg-12">
                            <input className="uk-input"
                                type="text"
                                value={title}
                                placeholder={t('input_title')}
                                maxLength={ConstantValue.INPUT_MAX_LENGTH_NOTICE_TITLE}
                                onChange={onTitleChanged}/>
                        </div>
                        <div className="col-lg-12" style={{marginTop: '20px'}}>
                            <textarea placeholder={t('input_content')}
                                rows="4"
                                className="uk-textarea"
                                required
                                value={content}
                                maxLength={ConstantValue.INPUT_MAX_LENGTH_NOTICE_CONTENT}
                                onChange={onContentChanged}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="canceled button outline-primary" variant="secondary"
                        onClick={onCancelPressed}>
                        {t('button_cancel')}
                    </Button>
                    <Button className="primary button"
                        disabled={title.length > 0 && content.length > 0 ? false : true}
                        onClick={onNextPressed}>
                        {t('button_register')}
                    </Button>
                </Modal.Footer>
            </Modal>;
}
