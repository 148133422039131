//
//  emptyCell.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 29..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
} from 'react';

// string
import { useTranslation } from 'react-i18next';


/**
 * @protocol EmptyCell
 * @date 2022/11/29
 * @brief 항목 없음 셀
 */
function EmptyCell(props) {
    const { t } = useTranslation();

    return (
        props.disableList ?
        <div>
            <p style={{fontWeight: '500'}}>{t('notexist_message')}</p>
        </div> :
        <li>
            <div>
                <p style={{fontWeight: '500'}}>{t('notexist_message')}</p>
            </div>
        </li>
    );
}

export default EmptyCell;