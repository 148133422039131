//
//  helpFaqListView.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 20..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
} from 'react';

// common
import { FaqList } from 'constants/list';

// util
import DataUtil from 'util/dataUtil';

// package
import { Link, useLocation } from 'react-router-dom';


/**
 * @protocol HelpFaqListView
 * @date 2022/12/20
 * @brief 자주찾는 질문 리스트 화면
 */
function HelpFaqListView(props) {
    const location = useLocation();
    
    const faqList = FaqList.FaqList(props.tabId);

    useEffect(() => {
    }, []);

    const renderContent = () => {
        var itemList = [];

        if( DataUtil.validateArray(faqList) ) {
            var i = 0;
            faqList.forEach(element => {
                if( DataUtil.validateString(element.title) ) {
                    itemList.push(  <div className="card"
                                        key={"card" + props.tabId + String(i)}>
                                        <div className="card-header" id={"heading" + String(i)}>
                                            <h5 className="mb-0">
                                            <button className="btn btn-link" data-toggle="collapse" data-target={"#collapse" + props.tabId + String(i)} aria-expanded="true" aria-controls={"#collapse" + props.tabId + String(i)}>
                                                {element.title}
                                            </button>
                                            </h5>
                                        </div>
                                        <div id={"collapse" + props.tabId + String(i)} className="collapse" aria-labelledby={"heading" + props.tabId + String(i)} data-parent="#accordion">
                                            <div className="card-body">
                                            <p style={{whiteSpace: 'pre-wrap'}}>{element.description}</p>
                                            {
                                                DataUtil.validateString(element.link) ?
                                                <>
                                                    <br/>
                                                    <Link to={element.url}
                                                        state={element.state}
                                                        onClick={() => {
                                                            if( element.url === location.pathname ) {
                                                                window.location.reload(false);
                                                            }
                                                        }}
                                                        style={{color: '#F26625'}}>
                                                        {element.link}
                                                    </Link>
                                                </>:
                                                null
                                            }
                                            </div>
                                        </div>
                                    </div>);
                    i++;
                }
            });
        }
        return itemList;
    }
    return (
        <div className={"tab-pane show" + (props.defaultActive ? " active" : "")}
            id={props.tabId} >
            <h4 className="main-title">{props.tabName}</h4>
            <div className="question-collaps">
                <div id="accordion">
                    {renderContent()}
                </div>
            </div>
        </div>
    );
}

export default HelpFaqListView;