//
//  applyUserInfoPopupView.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 20..
//  Copyright © 2022년 Melephant. All rights reserved.
//


import React, {
    useState,
    useEffect,
} from 'react';

// common
import { CommonList } from 'constants/list';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// package
import { Button, Modal } from 'react-bootstrap';


/**
 * @protocol ApplyUserInfoPopupView
 * @date 2023/01/20
 * @brief 상담 신청자 정보 화면 객체
 */
export default function ApplyUserInfoPopupView(props) {
    const { t } = useTranslation();

    const [content, setContent] = useState('');

    const mbitList = CommonList.MBTIList();
    const jobList = CommonList.JobList();

    useEffect(() => {
    }, []);

    /**
     * 초기화
     */
    const initialize = () => {
        setContent('');
    }

    /**
     * mbti 문구 반환
     * @returns {String} MBTI 문구
     */
    const getMbtiStr = () => {
        if( props.applyInfo && props.applyInfo.user ) {
            var index = mbitList.findIndex(element => element.id === props.applyInfo.user.mbtiCode);

            if( index >= 0 ) {
                return mbitList[index].title;
            }
        }
        return '';
    }

    /**
     * 현재 직무 문구 반환
     * @returns {String} 현재 직무 문구
     */
    const getCurrentJobCodeStr = () => {
        if( props.applyInfo && props.applyInfo.user ) {
            var index = jobList.findIndex(element => element.id === props.applyInfo.user.currentJobCode);

            if( index >= 0 ) {
                return jobList[index].title;
            }
        }
        return '';
    }

    /**
     * 취소 버튼 클릭시 호출
     */
    const onCancelPressed = () => {
        if( props.onCancelPressed ) {
            props.onCancelPressed();
        }
        
        // 재사용 위해 초기화
        initialize();
    };

    /**
     * 취소 버튼 클릭시 호출
     */
    const onConfirmPressed = () => {
        if( props.onConfirmPressed ) {
            props.onConfirmPressed(content);
        }

        // 재사용 위해 초기화
        initialize();
    };
    
    return <Modal
                show={props.visible}
                onHide={onCancelPressed}
                backdrop="static"
                size='lg'
                keyboard={false}>
                <Modal.Header>
                    {t('manage_solution_apply_title')}
                </Modal.Header>
                <Modal.Body>
                    <div className="row col-xs-4">
                        <div className="prod-stat" style={{paddingLeft: '15px', paddingRight: '15px'}}>
                            <ul>
                                {
                                    props.applyInfo ?
                                    <>
                                        <li><span>{t('manage_solution_name')}:</span> {props.applyInfo.memberName}</li>
                                        <li><span>{t('manage_solution_contact')}:</span> {props.applyInfo.contact}</li>
                                        <li><span>{t('manage_solution_firstdate')}:</span> {DataUtil.convertDateTimeToString(props.applyInfo.firstdate)}</li>
                                        <li><span>{t('manage_solution_seconddate')}:</span> {DataUtil.convertDateTimeToString(props.applyInfo.seconddate)}</li>
                                        <li><span>{t('manage_solution_thirddate')}:</span> {DataUtil.convertDateTimeToString(props.applyInfo.thirddate)}</li>
                                        <li><br/></li>
                                        {
                                            props.applyInfo.user ?
                                            <>
                                                <li><span>{t('manage_solution_email')}:</span> {props.applyInfo.user.email}</li>
                                                <li><span>{t('manage_solution_nickname')}:</span> {props.applyInfo.user.nickname}</li>
                                                <li><span>{t('manage_solution_gender')}:</span> {props.applyInfo.user.gender === 'M' ? t('input_male') : t('input_female')}</li>
                                                <li><span>{t('manage_solution_mbti')}:</span> {getMbtiStr()}</li>
                                                <li><span>{t('manage_solution_cjc')}:</span> {getCurrentJobCodeStr()}</li>
                                                <li><span>{t('manage_solution_onelineintro')}:</span> {props.applyInfo.user.onelineIntro}</li>
                                            </> :
                                            null
                                        }
                                    </> :
                                    null
                                }
                                
                                {/* <li><span>{t('counsel_intro_intro')}:</span> {project.mentor.onelineIntro}</li>
                                <li><span>{t('counsel_intro_current_job')}:</span> {currentJob}</li>
                                <li><span>{t('counsel_intro_company')}:</span> {project.mentor.company}</li>
                                <li><span>{t('counsel_intro_tos')}:</span> {tos}</li>
                                <li><span>{t('counsel_intro_career')}:</span> {project.mentor.career}</li>
                                <li><br/></li>
                                <li><span>{t('counsel_intro_tendency')}:</span> {renderTendencyCode()}</li>
                                <li><span>{t('counsel_intro_mbti')}:</span> {project.mentor.mbtiCode}</li> */}
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="primary button"
                        onClick={onConfirmPressed}>
                        {t('button_confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>;
}
