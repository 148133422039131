//
//  menuList.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 8..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// common
import { CommonList, LIST_TOTAL } from 'constants/list';
import {
    SolutionMenuKey,
    MySolutionMenuKey,
    RecruitSolutionMenuKey,
    M2mSolutionMenuKey,
    ProfileMenuKey,
    UserMenuKey,
    TermsMenuKey,
    HelpMenuKey,
    HelpFaqMenuKey,
    HelpM2mMenuKey,
    RequestSolutionMenuKey,
    ManageSolutionMenuKey,
    SalesSolutionMenuKey,
} from 'constants/key';
import ConstantPath from 'constants/path';

// string
import { useTranslation } from 'react-i18next';


/**
 * @protocol SolutionMenuList
 * @date 2022/11/08
 * @brief 사수해법 메뉴 목록 반환
 */
export function SolutionMenuList() {
    const { t } = useTranslation();
    return [{
        id: SolutionMenuKey.SOLUTION_MENU_RECRUIT,
        title: t('solution_menu_recruit'),
        url: ConstantPath.PATH_SOLUTION_MY,
        margin: false,
    }];
}

/**
 * @protocol SolutionUserMenuList
 * @date 2022/11/08
 * @brief 사수해법 일반 사용자용 메뉴 목록 반환
 */
export function SolutionUserMenuList() {
    const { t } = useTranslation();
    return [{
        id: SolutionMenuKey.SOLUTION_MENU_MY,
        title: t('solution_menu_my'),
        url: ConstantPath.PATH_SOLUTION_MY,
        margin: false,
    },{
        id: SolutionMenuKey.SOLUTION_MENU_RECRUIT,
        title: t('solution_menu_recruit'),
        url: ConstantPath.PATH_SOLUTION_RECRUIT,
        margin: false,
    },{
        id: SolutionMenuKey.SOLUTION_MENU_QNA,
        title: t('solution_menu_qna'),
        url: ConstantPath.PATH_SOLUTION_QNA,
        margin: true,
    }];
}

/**
 * @protocol SolutionMentorMenuList
 * @date 2022/11/08
 * @brief 사수해법 사수용 메뉴 목록 반환
 */
export function SolutionMentorMenuList() {
    const { t } = useTranslation();
    return [{
        id: SolutionMenuKey.SOLUTION_MENU_MY,
        title: t('solution_menu_my'),
        url: ConstantPath.PATH_SOLUTION_MY,
        margin: false,
    },{
        id: SolutionMenuKey.SOLUTION_MENU_RECRUIT,
        title: t('solution_menu_recruit'),
        url: ConstantPath.PATH_SOLUTION_RECRUIT,
        margin: false,
    },{
        id: SolutionMenuKey.SOLUTION_MENU_QNA,
        title: t('solution_menu_qna'),
        url: ConstantPath.PATH_SOLUTION_QNA,
        margin: true,
    },{
        id: SolutionMenuKey.SOLUTION_MENU_MANAGE,
        title: t('solution_menu_manage'),
        url: ConstantPath.PATH_SOLUTION_MANAGE,
        margin: false,
    },{
        id: SolutionMenuKey.SOLUTION_MENU_SALES,
        title: t('solution_menu_sales'),
        url: ConstantPath.PATH_SOLUTION_SALES,
        margin: false,
    }];
}

/**
 * @protocol RecruitSolutionMenuList
 * @date 2022/11/08
 * @brief 모집중 사수해법 메뉴 목록 반환
 */
export function MySolutionMenuList() {
    const { t } = useTranslation();
    return [{
        id: MySolutionMenuKey.MY_SOLUTION_TYPE_COUNCEL,
        title: t('my_solution_menu_counsel'),
    },{
        id: MySolutionMenuKey.MY_SOLUTION_TYPE_PROJECT,
        title: t('my_solution_menu_project'),
    }];
}

/**
 * @protocol RecruitSolutionMenuList
 * @date 2022/11/08
 * @brief 모집중 사수해법 메뉴 목록 반환
 */
export function RecruitSolutionMenuList() {
    const { t } = useTranslation();
    return [{
        id: RecruitSolutionMenuKey.RECRUIT_SOLUTION_TYPE_COUNCEL,
        title: t('recruit_solution_menu_counsel'),
    },{
        id: RecruitSolutionMenuKey.RECRUIT_SOLUTION_TYPE_PROJECT,
        title: t('recruit_solution_menu_project'),
    }];
}

/**
 * @protocol RequestSolutionMenuList
 * @date 2023/01/17
 * @brief 신청한 사수해법 메뉴 목록 반환
 */
export function RequestSolutionMenuList() {
    const { t } = useTranslation();
    return [{
        id: RequestSolutionMenuKey.REQUEST_SOLUTION_MENU_COUNCEL,
        title: t('request_solution_menu_counsel'),
    },{
        id: RequestSolutionMenuKey.REQUEST_SOLUTION_MENU_PROJECT,
        title: t('request_solution_menu_project'),
    }];
}

/**
 * @protocol ManageSolutionMenuList
 * @date 2023/01/17
 * @brief 담당 사수해법 메뉴 목록 반환
 */
export function ManageSolutionMenuList() {
    const { t } = useTranslation();
    return [{
        id: ManageSolutionMenuKey.MANAGE_SOLUTION_MENU_COUNCEL,
        title: t('manage_solution_menu_counsel'),
    },{
        id: ManageSolutionMenuKey.MANAGE_SOLUTION_MENU_PROJECT,
        title: t('manage_solution_menu_project'),
    }];
}

/**
 * @protocol RecruitCounselMenuList
 * @date 2022/11/08
 * @brief 모집중 사수해법 메뉴 목록 반환
 */
export function RecruitCounselMenuList() {
    var itemList = [];
    const categoryList = CommonList.CounselCategoryList();
    const { t } = useTranslation();
    itemList.push({
        id: LIST_TOTAL,
        title: t('word_total'),
    });
    for( var i = 0; i < categoryList.length; i++ ) {
        itemList.push({
            id: categoryList[i].id,
            title: categoryList[i].title,
        });
    }
    return itemList;
}

/**
 * @protocol M2mSolutionUserMenuList
 * @date 2022/12/15
 * @brief 1:1 문의 사수해법 사용자용 메뉴 목록 반환
 */
export function M2mSolutionUserMenuList() {
    const { t } = useTranslation();
    return [{
        id: M2mSolutionMenuKey.M2M_SOLUTION_TYPE_SEND,
        title: t('m2m_solution_menu_send'),
    }];
}

/**
 * @protocol M2mSolutionMentorMenuList
 * @date 2022/12/15
 * @brief 1:1 문의 사수해법 사수용 메뉴 목록 반환
 */
export function M2mSolutionMentorMenuList() {
    const { t } = useTranslation();
    return [{
        id: M2mSolutionMenuKey.M2M_SOLUTION_TYPE_RECEIVE,
        title: t('m2m_solution_menu_receive'),
    },{
        id: M2mSolutionMenuKey.M2M_SOLUTION_TYPE_SEND,
        title: t('m2m_solution_menu_send'),
    }];
}

/**
 * @protocol ProfileUserMenuList
 * @date 2022/11/17
 * @brief 일반 사용자용 프로필 메뉴 목록 반환
 */
export function ProfileUserMenuList() {
    const { t } = useTranslation();
    return [{
        id: ProfileMenuKey.PROFILE_MENU_EDIT_PROFILE,
        title: t('profile_menu_edit_profile'),
        margin: false,
    }];
}

/**
 * @protocol ProfileMentorMenuList
 * @date 2022/11/17
 * @brief 사수용 프로필 메뉴 목록 반환
 */
export function ProfileMentorMenuList() {
    const { t } = useTranslation();
    return [{
        id: ProfileMenuKey.PROFILE_MENU_EDIT_PROFILE,
        title: t('profile_menu_edit_profile'),
        margin: false,
    }, {
        id: ProfileMenuKey.PROFILE_MENU_EDIT_MENTOR,
        title: t('profile_menu_edit_mentor'),
        margin: false,
    }];
}

/**
 * @protocol UserMenuList
 * @date 2023/01/16
 * @brief 일반 사용자용 메뉴 목록 반환
 */
export function UserMenuList() {
    const { t } = useTranslation();
    return [{
        id: UserMenuKey.USER_MENU_SOLUTION_REQUEST,
        title: t('menu_solution_request'),
        icon: 'assets/images/icon/icon_edit_profile.svg',
        link: ConstantPath.PATH_SOLUTION_REQUEST,
    }, {
        id: UserMenuKey.USER_MENU_SOLUTION_M2M,
        title: t('menu_solution_m2m'),
        icon: 'assets/images/icon/icon_edit_profile.svg',
        link: ConstantPath.PATH_SOLUTION_QNA,
    }, {
        id: UserMenuKey.USER_MENU_EDIT_PROFILE,
        title: t('menu_edit_profile'),
        icon: 'assets/images/icon/icon_edit_profile.svg',
        link: ConstantPath.PATH_EDIT_PROFILE,
    }];
}

/**
 * @protocol MentorMenuList
 * @date 2023/01/16
 * @brief 사수용 메뉴 목록 반환
 */
export function MentorMenuList() {
    const { t } = useTranslation();
    return [{
        id: UserMenuKey.USER_MENU_SOLUTION_REQUEST,
        title: t('menu_solution_request'),
        icon: 'assets/images/icon/icon_edit_profile.svg',
        link: ConstantPath.PATH_SOLUTION_REQUEST,
    }, {
        id: UserMenuKey.USER_MENU_SOLUTION_M2M,
        title: t('menu_solution_m2m'),
        icon: 'assets/images/icon/icon_edit_profile.svg',
        link: ConstantPath.PATH_SOLUTION_QNA,
    }, {
        id: UserMenuKey.USER_MENU_SOLUTION_MANANGE,
        title: t('menu_solution_manage'),
        icon: 'assets/images/icon/icon_edit_profile.svg',
        link: ConstantPath.PATH_SOLUTION_MANAGE,
    }, {
        id: UserMenuKey.USER_MENU_SOLUTION_SALES,
        title: t('menu_solution_sales'),
        icon: 'assets/images/icon/icon_edit_profile.svg',
        link: ConstantPath.PATH_SOLUTION_SALES,
    }, {
        id: UserMenuKey.USER_MENU_EDIT_PROFILE,
        title: t('menu_edit_profile'),
        icon: 'assets/images/icon/icon_edit_profile.svg',
        link: ConstantPath.PATH_EDIT_PROFILE,
    }, {
        id: UserMenuKey.USER_MENU_EDIT_MENTOR,
        title: t('menu_edit_mentor'),
        icon: 'assets/images/icon/icon_edit_mentor.svg',
        link: ConstantPath.PATH_EDIT_MENTOR,
    }];
}

/**
 * @protocol TermsList
 * @date 2022/10/25
 * @brief 약관 목록 반환
 */
export function TermsMenuList() {
    const { t } = useTranslation();
    return [{
        id: TermsMenuKey.TERM_MENU_SERVICE,
        title: t('terms_service'),
        margin: false,
    },{
        id: TermsMenuKey.TERM_MENU_PRIVACY,
        title: t('terms_privacy'),
        margin: false,
    }];
}

/**
 * @protocol HelpMenuList
 * @date 2022/12/19
 * @brief 고객센터 메뉴 목록 반환
 */
export function HelpMenuList() {
    const { t } = useTranslation();
    return [{
        id: HelpMenuKey.HELP_MENU_FAQ,
        title: t('help_menu_faq'),
        url: ConstantPath.PATH_HELP_FAQ,
        margin: false,
    }, {
        id: HelpMenuKey.HELP_MENU_M2M,
        title: t('help_menu_m2m'),
        url: ConstantPath.PATH_HELP_M2M,
        margin: false,
    }, {
        id: HelpMenuKey.HELP_MENU_NOTICE,
        title: t('help_menu_notice'),
        url: ConstantPath.PATH_HELP_NOTICE,
        margin: false,
    }];
}

/**
 * @protocol HelpFAQMenuList
 * @date 2022/12/20
 * @brief 고객센터 자주찾는 질문 메뉴 목록 반환
 */
export function HelpFAQMenuList() {
    const { t } = useTranslation();
    return [{
        id: HelpFaqMenuKey.HELP_FAQ_MENU_SERVICE,
        title: t('help_faq_category_service'),
    }, {
        id: HelpFaqMenuKey.HELP_FAQ_MENU_USAGE_COUNSEL,
        title: t('help_faq_category_usage_counsel'),
    }, {
        id: HelpFaqMenuKey.HELP_FAQ_MENU_APPLY_MENTOR,
        title: t('help_faq_category_apply_mentor'),
    }, {
        id: HelpFaqMenuKey.HELP_FAQ_MENU_PAYMENT_AND_REFUND,
        title: t('help_faq_category_payment_and_refund'),
    }];
}

/**
 * @protocol HelpM2mMenuList
 * @date 2022/12/20
 * @brief 고객센터 1:1 문의 메뉴 목록 반환
 */
export function HelpM2mMenuList() {
    const { t } = useTranslation();
    return [{
        id: HelpM2mMenuKey.HELP_M2M_MENU_INQUIRY,
        title: t('help_m2m_menu_inquiry'),
    }, {
        id: HelpM2mMenuKey.HELP_M2M_MENU_HISTORY,
        title: t('help_m2m_menu_history'),
    }];
}

/**
 * @protocol SalesSolutionMenuList
 * @date 2023/02/01
 * @brief 사수해법 매출 메뉴 목록 반환
 */
export function SalesSolutionMenuList() {
    const { t } = useTranslation();
    return [{
        id: SalesSolutionMenuKey.SALES_SOLUTION_MENU_COUNCEL,
        title: t('sales_solution_menu_counsel'),
    },{
        id: SalesSolutionMenuKey.SALES_SOLUTION_MENU_PROJECT,
        title: t('sales_solution_menu_project'),
    }];
}