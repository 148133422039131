//
//  solutionScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 8..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
    useEffect,
} from 'react';

// common
import ConstantPath from 'constants/path';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkSolution from 'network/networkSolution';

// info
import { PROJECTINFO_LIST } from 'info/solution/projectInfo';

// component
import Layout from 'components/layout/layout';
import ProjectCell from 'components/cell/projectCell';
import CounselCell from 'components/cell/counselCell';
import MainReviewCarousel from 'components/carousel/mainReviewCarousel';

// package
import { useNavigate, useLocation } from "react-router-dom";


/**
 * @protocol SolutionScreen
 * @date 2022/11/08
 * @brief 나의 사수해법 화면
 */
function SolutionScreen(props) {
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    const location = useLocation();

    const [projectList, setProjectList] = useState(null);

    useEffect(() => {
        if( projectList === null ) {
            NetworkSolution.getMainProjectList((res) => {
                setProjectList(res[PROJECTINFO_LIST]);
            }, (res) => {
            });
        }
    }, [projectList]);

    /**
     * 프로젝트 목록 ui 렌더링
     * @returns {Array} 프로젝트 ui 목록
     */
    const renderProjectList = () => {
        var itemList = [];
        if( DataUtil.validateArray(projectList) ) {
            for( var i = 0; i < projectList.length; i++ ) {
                if( projectList[i].type === 'TEAM' ) {
                    itemList.push(  <div key={"project" + String(i)}
                                        className="col-lg-4 col-md-6 col-sm-6">
                                        <ProjectCell data={projectList[i]}
                                            onCellPressed={(id) => {
                                                var path = ConstantPath.PATH_PROJECT_INTRO.split("/");
                                                var strippedPath = path.slice(0, path.length-1).join("/");
                                                navigate(strippedPath + '/' + id);
                                        }}/>
                                    </div>)
                }
                else if( projectList[i].type === 'COUN' ) {
                    itemList.push(  <div key={"project" + String(i)}
                                        className="col-lg-4 col-md-6 col-sm-6">
                                        <CounselCell data={projectList[i]}
                                            onCellPressed={(id) => {
                                                var path = ConstantPath.PATH_COUNSEL_INTRO.split("/");
                                                var strippedPath = path.slice(0, path.length-1).join("/");
                                                navigate(strippedPath + '/' + id);
                                        }}/>
                                    </div>)
                }
            }
        }
        
        return itemList;
    }
    
    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}
            visibleSolutionHeader={true}
            location={location.pathname}>
			<div className="gap" style={{textAlign: 'left'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="main-wraper">
                                <div className="row">
                                    <div className="col-lg-4 col-md-12 col-sm-12">
                                        <div className="group-box" style={{marginTop: '10px', marginBottom: '10px'}}>
                                            <a href={ConstantPath.PATH_SOLUTION_COUNSEL}>
                                                <img alt="" src="assets/images/image/img_service_01.png"/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12">
                                    <div className="group-box" style={{marginTop: '10px', marginBottom: '10px'}}>
                                            <a href={ConstantPath.PATH_SOLUTION_PROJECT}>
                                                <img alt="" src="assets/images/image/img_service_02.png"/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12">
                                    <div className="group-box" style={{marginTop: '10px', marginBottom: '10px'}}>
                                            <a href={ConstantPath.PATH_SOLUTION_BLOG}>
                                                <img alt="" src="assets/images/image/img_service_03.png"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    DataUtil.validateArray(projectList) ?
                    <section>
                        <div className="gap" style={{textAlign: 'left'}}>
                            <div className="container">
                                <div className="row remove-ext30">
                                    <div className="col-lg-12">
                                        <div className="title">
                                            <h1>{t('main_project_title')}</h1>
                                        </div>
                                    </div>
                                    {renderProjectList()}
                                </div>
                            </div>
                        </div>
                    </section> :
                    null
                }
                <section>
                    <div className="gap no-top" style={{textAlign: 'left'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="newsletter-sec">
                                        <figure><img src="assets/images/image/img_logo.png" alt="" style={{maxWidth: '100px'}}/></figure>
                                        <div className="leter-meta">
                                            <span><h5>{t('main_help_title')}</h5></span>
                                            <h3>{t('main_help_description')}</h3>
                                        </div>
                                        <div className="form">
                                            <a href={ConstantPath.PATH_HELP} title="" className="main-btn" data-ripple="">{t('button_help')}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="gap no-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="title">
                                        <h1>{t('main_review_title')}</h1>
                                        <h5>{t('main_review_description')}</h5>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <MainReviewCarousel/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
}

export default SolutionScreen;