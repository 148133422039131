//
//  projectPaymentInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 30..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// info
import ProjectInfo, {
    PROJECTINFO_CONTAINER
} from "info/solution/projectInfo";
import OrdinalInfo, {
    ORDINALINFO_CONTAINER
} from "info/solution/ordinalInfo";

export const PROJECTPAYMENTINFO_CONTAINER           = 'project_payment_info';
export const PROJECTPAYMENTINFO_PROJECT_PAYMENT_ID  = 'project_payment_idx';
export const PROJECTPAYMENTINFO_PROJECT_ID          = 'project_id';
export const PROJECTPAYMENTINFO_ORDINAL             = 'ordinal';
export const PROJECTPAYMENTINFO_PAYMENT_ID          = 'payment_idx';
export const PROJECTPAYMENTINFO_PAYMENT_STATUS      = 'payment_status';
export const PROJECTPAYMENTINFO_MEMBER_ID           = 'member_id';
export const PROJECTPAYMENTINFO_MEMBER_NAME         = 'member_name';
export const PROJECTPAYMENTINFO_CONTACT             = 'contact';
export const PROJECTPAYMENTINFO_REGDATE             = 'regdate';


/**
 * @protocol ProjectPaymentInfo
 * @date 2022/11/30
 * @brief 결제 정보
 */
export default class ProjectPaymentInfo {
    constructor() {
        this.projectPaymentId = '';
        this.projectId = '';
        this.ordinal = '';
        this.paymentId = '';
        this.paymentStatus = '';
        this.memberId = '';
        this.memberName = '';
        this.contact = '';
        this.regdate = null;

        this.projectInfo = new ProjectInfo();
        this.ordinalInfo = new OrdinalInfo();
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(PROJECTPAYMENTINFO_PROJECT_PAYMENT_ID) ) {
            this.projectPaymentId = dict[PROJECTPAYMENTINFO_PROJECT_PAYMENT_ID];
        }

        if( dict.hasOwnProperty(PROJECTPAYMENTINFO_PROJECT_ID) ) {
            this.projectId = dict[PROJECTPAYMENTINFO_PROJECT_ID];
        }

        if( dict.hasOwnProperty(PROJECTPAYMENTINFO_ORDINAL) ) {
            this.ordinal = dict[PROJECTPAYMENTINFO_ORDINAL];
        }

        if( dict.hasOwnProperty(PROJECTPAYMENTINFO_PAYMENT_ID) ) {
            this.paymentId = dict[PROJECTPAYMENTINFO_PAYMENT_ID];
        }

        if( dict.hasOwnProperty(PROJECTPAYMENTINFO_PAYMENT_STATUS) ) {
            this.paymentStatus = dict[PROJECTPAYMENTINFO_PAYMENT_STATUS];
        }

        if( dict.hasOwnProperty(PROJECTPAYMENTINFO_MEMBER_ID) ) {
            this.memberId = dict[PROJECTPAYMENTINFO_MEMBER_ID];
        }

        if( dict.hasOwnProperty(PROJECTPAYMENTINFO_MEMBER_NAME) ) {
            this.memberName = dict[PROJECTPAYMENTINFO_MEMBER_NAME];
        }

        if( dict.hasOwnProperty(PROJECTPAYMENTINFO_CONTACT) ) {
            this.contact = dict[PROJECTPAYMENTINFO_CONTACT];
        }

        if( dict.hasOwnProperty(PROJECTPAYMENTINFO_REGDATE) ) {
            this.regdate = new Date(dict[PROJECTPAYMENTINFO_REGDATE]);
        }

        if( dict.hasOwnProperty(PROJECTINFO_CONTAINER) ) {
            this.projectInfo.parseFromDictionary(dict[PROJECTINFO_CONTAINER]);
        }

        if( dict.hasOwnProperty(ORDINALINFO_CONTAINER) ) {
            this.ordinalInfo.parseFromDictionary(dict[ORDINALINFO_CONTAINER]);
        }
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [PROJECTPAYMENTINFO_PROJECT_PAYMENT_ID]: this.projectPaymentId,
            [PROJECTPAYMENTINFO_PROJECT_ID]: this.projectId,
            [PROJECTPAYMENTINFO_ORDINAL]: this.ordinal,
            [PROJECTPAYMENTINFO_PAYMENT_ID]: this.paymentId,
            [PROJECTPAYMENTINFO_PAYMENT_STATUS]: this.paymentStatus,
            [PROJECTPAYMENTINFO_MEMBER_ID]: this.memberId,
            [PROJECTPAYMENTINFO_MEMBER_NAME]: this.memberName,
            [PROJECTPAYMENTINFO_CONTACT]: this.contact,
            [PROJECTPAYMENTINFO_REGDATE]: this.regdate,
        };
        return dict;
    }

    /**
     * 결제 요청시에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForPayment() {
        var dict = {
            [PROJECTPAYMENTINFO_PROJECT_ID]: this.projectId,
            [PROJECTPAYMENTINFO_ORDINAL]: this.ordinal,
            [PROJECTPAYMENTINFO_PAYMENT_ID]: this.paymentId,
            [PROJECTPAYMENTINFO_MEMBER_ID]: this.memberId,
            [PROJECTPAYMENTINFO_MEMBER_NAME]: this.memberName,
            [PROJECTPAYMENTINFO_CONTACT]: this.contact,
        };
        return dict;
    }
}
