//
//  popupManager.js
//  datatown
//
//  Created by Mumakil on 2022. 8. 16..
//  Copyright © 2022년 Melephant. All rights reserved.
//


// util
import DataUtil from 'util/dataUtil';


/**
 * @protocol PopupManager
 * @date 2022/08/16
 * @brief 팝업 데이터 관리 매니저
 */
 export default class PopupManager {
    /**
     * 싱글톤 객체 반환
     */
    static getInstance() {
        if( PopupManager.instance ) {
            return PopupManager.instance;
        }

        PopupManager.instance = this;

        this.popupList = [];

        return this;
    }

    /**
     * 팝업 queue 항목 추가
     * @param {json} info 팝업 정보
     */
    static enqueuePopupInfo(info) {
        this.popupList.push(info);
    }

    /**
     * 팝업 queue 항목 삭제
     */
    static dequeuePopupInfo() {
        this.popupList.shift();
    }

    /**
     * 현재 표시될 팝업 반환
     * @returns {json} 우선 표시할 팝업 정보
     */
    static getCurrentPopupInfo() {
        if( DataUtil.validateArray(this.popupList) ) {
            return this.popupList[0];
        }
        return null;
    }

    /**
     * 팝업 queue 데이터 존재여부 반환
     * @returns {boolean} 팝업 정보 존재 여부
     */
    static checkPopupEmpty() {
        if( DataUtil.validateArray(this.popupList) ) {
            return false;
        }
        return true;
    }
}
