//
//  popupReducer.js
//  datatown
//
//  Created by Mumakil on 2022. 8. 16..
//  Copyright © 2022년 Melephant. All rights reserved.
//


// common
import ConstantValue from 'constants/value';

// redux
import * as PopupAction from 'redux/actions/popupAction';

// manager
import PopupManager from 'manager/popupManager';


// 팝업 관련 초기 상태
export const INITIAL_STATE = {
    current: null,
};

const reducer = (state = INITIAL_STATE, action) => {
    // 팝업 표시 시에는 정보 포함, 팝업 해제 시에는 정보 제거
    switch( action.type ) {
        case PopupAction.ACTION_POPUP_SHOW: {
            PopupManager.getInstance().enqueuePopupInfo(action.payload);
            return {
                current: action.payload,
            };
        }
        case PopupAction.ACTION_POPUP_HIDE: {
            // 애니메이션 종료 후, 데이터 제거
            setTimeout(() => {
                PopupManager.getInstance().dequeuePopupInfo();
            }, ConstantValue.ANIMATION_TIME_POPUP);
            
            return {
                current: null,
            };
        }
        case PopupAction.ACTION_POPUP_SHOW_AGAIN: {
            return {
                current: action.payload,
            };
        }
        default:
            return state;
    }
};

export default reducer;
