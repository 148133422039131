//
//  dayTimetableInput.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 18..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
} from 'react';

// string
import { useTranslation } from 'react-i18next';

// style
import styles from 'css/component/dayTimetableInput.module.css';
import ReactDatePicker from 'react-datepicker';


/**
 * @protocol DayTimetableInput
 * @date 2022/11/18
 * @brief 일간 타임테이블 수정 화면
 */
function DayTimetableInput(props) {
    const { t } = useTranslation();

    const [startTime, setStartTime] = useState(null);
    const [finTime, setFinTime] = useState(null);

    /**
     * 체크 클릭시 호출
     */
    const onCheckPressed = () => {
        if( props.onCheckPressed ) {
            props.onCheckPressed(props.data.dayId, !props.data.isSelect);
        }
	};

    /**
     * 시작시간 변경시 호출
     * @param {*} param0 시작시간 값
     */
    const onStartChanged = (date) => {
        setStartTime(date);
        
        if( props.onStartChanged ) {
            props.onStartChanged(props.data.dayId, String(date.getHours()), String(date.getMinutes()));
        }
    }

    /**
     * 종료시간 변경시 호출
     * @param {*} param0 종료시간 값
     */
    const onFinChanged = (date) => {
        setFinTime(date);

        if( props.onFinChanged ) {
            props.onFinChanged(props.data.dayId, String(date.getHours()), String(date.getMinutes()));
        }
    }

    return (
        <div className={styles.container}>
            <input type="checkbox"
                checked={props.data.isSelect}
                onChange={onCheckPressed}/>
            <label>{props.data.dayStr}</label>
            <div>
                <ReactDatePicker
                    selected={startTime}
                    onChange={(date) => onStartChanged(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    autoComplete='off'
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="time"
                    placeholderText={t('input_starttime')}
                    dateFormat="hh:mm aa"/>
            </div>
            <label> - </label>
            <div>
                <ReactDatePicker
                    selected={finTime}
                    onChange={(date) => onFinChanged(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    autoComplete='off'
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="time"
                    placeholderText={t('input_fintime')}
                    dateFormat="hh:mm aa"/>
            </div>
        </div>
    );
}

export default DayTimetableInput;