//
//  tendencyInfo.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 23..
//  Copyright © 2022년 Melephant. All rights reserved.
//


export const TENDENCYINFO_LIST              = 'tendency_info_list';
export const TENDENCYINFO_CONTAINER         = 'tendency_info';
export const TENDENCYINFO_ID                = 'member_tendency_idx';
export const TENDENCYINFO_MEMBER_ID         = 'member_id';
export const TENDENCYINFO_MEMBER_TYPE       = 'member_type';
export const TENDENCYINFO_TENDENCY_CODE     = 'tendency_code';


/**
 * @protocol TendencyInfo
 * @date 2022/11/23
 * @brief 성향 정보
 */
export default class TendencyInfo {
    constructor() {
        this.tendencyId = 0;
        this.memberId = 0;
        this.memberType = '';
        this.tendencyCode = '';
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(TENDENCYINFO_ID) ) {
            this.tendencyId = Number(dict[TENDENCYINFO_ID]);
        }

        if( dict.hasOwnProperty(TENDENCYINFO_MEMBER_ID) ) {
            this.memberId = Number(dict[TENDENCYINFO_MEMBER_ID]);
        }

        if( dict.hasOwnProperty(TENDENCYINFO_MEMBER_TYPE) ) {
            this.memberType = dict[TENDENCYINFO_MEMBER_TYPE];
        }

        if( dict.hasOwnProperty(TENDENCYINFO_TENDENCY_CODE) ) {
            this.tendencyCode = dict[TENDENCYINFO_TENDENCY_CODE];
        }
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [TENDENCYINFO_ID]: this.tendencyId,
            [TENDENCYINFO_MEMBER_ID]: this.memberId,
            [TENDENCYINFO_MEMBER_TYPE]: this.memberType,
            [TENDENCYINFO_TENDENCY_CODE]: this.tendencyCode,
        };
        return dict;
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForApply() {
        var dict = {
            [TENDENCYINFO_TENDENCY_CODE]: this.tendencyCode,
        };
        return dict;
    }
}
