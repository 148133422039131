//
//  counselApplyInfo.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 19..
//  Copyright © 2022년 Melephant. All rights reserved.
//


// info
import UserInfo from "info/user/userInfo";

import {
    PAYMENTINFO_MEMBER_ID,
    PAYMENTINFO_ORDER_NAME,
    PAYMENTINFO_PRICE,
} from "info/payment/paymentInfo";

export const COUNSELAPPLYINFO_LIST              = 'counsel_apply_list';
export const COUNSELAPPLYINFO_CONTAINER         = 'counsel_apply_info';
export const COUNSELAPPLYINFO_APPLY_ID          = 'project_counsel_apply_idx';
export const COUNSELAPPLYINFO_PROJECT_ID        = 'project_id';
export const COUNSELAPPLYINFO_APPLY_MEMBER_ID   = 'apply_member_id';
export const COUNSELAPPLYINFO_APPLY_STATUS      = 'apply_status';
export const COUNSELAPPLYINFO_PAYMENT_ID        = 'payment_idx';
export const COUNSELAPPLYINFO_MEMBER_NAME       = 'member_name';
export const COUNSELAPPLYINFO_CONTACT           = 'contact';
export const COUNSELAPPLYINFO_FIRSTDATE         = 'firstdate';
export const COUNSELAPPLYINFO_SECONDDATE        = 'seconddate';
export const COUNSELAPPLYINFO_THIRDDATE         = 'thirddate';
export const COUNSELAPPLYINFO_APPROVEDATE       = 'approvedate';
export const COUNSELAPPLYINFO_REGDATE           = 'regdate';
export const COUNSELAPPLYINFO_CONFIRMDATE       = 'confirmdate';
export const COUNSELAPPLYINFO_MENTOR_NICKNAME   = 'nickname';
export const COUNSELAPPLYINFO_PROJECT_NAME      = 'project_name';
export const COUNSELAPPLYINFO_PRICE             = 'price';

/**
 * @protocol CounselApplyInfo
 * @date 2023/01/19
 * @brief 상담 신청 정보
 */
export default class CounselApplyInfo {
    constructor() {
        this.applyId = '';
        this.projectId = '';
        this.applyMemberId = '';
        this.applyStatus = '';
        this.paymentId = '';
        this.memberName = '';
        this.contact = '';
        this.firstdate = null;
        this.seconddate = null;
        this.thirddate = null;
        this.approvedate = null;
        this.regdate = null;
        this.confirmdate = null;
        this.mentorNickname = '';
        this.projectName = '';
        this.price = '';

        this.user = new UserInfo();
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(COUNSELAPPLYINFO_APPLY_ID) ) {
            this.applyId = dict[COUNSELAPPLYINFO_APPLY_ID];
        }

        if( dict.hasOwnProperty(COUNSELAPPLYINFO_PROJECT_ID) ) {
            this.projectId = dict[COUNSELAPPLYINFO_PROJECT_ID];
        }

        if( dict.hasOwnProperty(COUNSELAPPLYINFO_APPLY_MEMBER_ID) ) {
            this.applyMemberId = dict[COUNSELAPPLYINFO_APPLY_MEMBER_ID];
        }

        if( dict.hasOwnProperty(COUNSELAPPLYINFO_APPLY_STATUS) ) {
            this.applyStatus = dict[COUNSELAPPLYINFO_APPLY_STATUS];
        }

        if( dict.hasOwnProperty(COUNSELAPPLYINFO_PAYMENT_ID) ) {
            this.paymentId = dict[COUNSELAPPLYINFO_PAYMENT_ID];
        }

        if( dict.hasOwnProperty(COUNSELAPPLYINFO_MEMBER_NAME) ) {
            this.memberName = dict[COUNSELAPPLYINFO_MEMBER_NAME];
        }

        if( dict.hasOwnProperty(COUNSELAPPLYINFO_CONTACT) ) {
            this.contact = dict[COUNSELAPPLYINFO_CONTACT];
        }

        if( dict.hasOwnProperty(COUNSELAPPLYINFO_FIRSTDATE) ) {
            this.firstdate = new Date(dict[COUNSELAPPLYINFO_FIRSTDATE]);
        }

        if( dict.hasOwnProperty(COUNSELAPPLYINFO_SECONDDATE) ) {
            this.seconddate = new Date(dict[COUNSELAPPLYINFO_SECONDDATE]);
        }

        if( dict.hasOwnProperty(COUNSELAPPLYINFO_THIRDDATE) ) {
            this.thirddate = new Date(dict[COUNSELAPPLYINFO_THIRDDATE]);
        }

        if( dict.hasOwnProperty(COUNSELAPPLYINFO_APPROVEDATE) ) {
            if( dict[COUNSELAPPLYINFO_APPROVEDATE] ) {
                this.approvedate = new Date(dict[COUNSELAPPLYINFO_APPROVEDATE]);
            }
            else {
                this.approvedate = null;
            }
        }

        if( dict.hasOwnProperty(COUNSELAPPLYINFO_REGDATE) ) {
            this.regdate = new Date(dict[COUNSELAPPLYINFO_REGDATE]);
        }

        if( dict.hasOwnProperty(COUNSELAPPLYINFO_CONFIRMDATE) ) {
            this.confirmdate = new Date(dict[COUNSELAPPLYINFO_CONFIRMDATE]);
        }

        if( dict.hasOwnProperty(COUNSELAPPLYINFO_MENTOR_NICKNAME) ) {
            this.mentorNickname = dict[COUNSELAPPLYINFO_MENTOR_NICKNAME];
        }

        if( dict.hasOwnProperty(COUNSELAPPLYINFO_PROJECT_NAME) ) {
            this.projectName = dict[COUNSELAPPLYINFO_PROJECT_NAME];
        }

        if( dict.hasOwnProperty(COUNSELAPPLYINFO_PRICE) ) {
            this.price = dict[COUNSELAPPLYINFO_PRICE];
        }

        this.user.parseFromDictionary(dict);
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [COUNSELAPPLYINFO_APPLY_ID]: this.applyId,
            [COUNSELAPPLYINFO_PROJECT_ID]: this.projectId,
            [COUNSELAPPLYINFO_APPLY_MEMBER_ID]: this.applyMemberId,
            [COUNSELAPPLYINFO_APPLY_STATUS]: this.applyStatus,
            [COUNSELAPPLYINFO_MEMBER_NAME]: this.memberName,
            [COUNSELAPPLYINFO_CONTACT]: this.contact,
            [COUNSELAPPLYINFO_FIRSTDATE]: this.firstdate,
            [COUNSELAPPLYINFO_SECONDDATE]: this.seconddate,
            [COUNSELAPPLYINFO_THIRDDATE]: this.thirddate,
            [COUNSELAPPLYINFO_APPROVEDATE]: this.approvedate,
            [COUNSELAPPLYINFO_REGDATE]: this.regdate,
            [COUNSELAPPLYINFO_CONFIRMDATE]: this.confirmdate,
        };
        return dict;
    }

    /**
     * 신청시에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForApply() {
        var dict = {
            [COUNSELAPPLYINFO_PROJECT_ID]: this.projectId,
            [COUNSELAPPLYINFO_APPLY_MEMBER_ID]: this.applyMemberId,
            [COUNSELAPPLYINFO_MEMBER_NAME]: this.memberName,
            [COUNSELAPPLYINFO_CONTACT]: this.contact,
        };
        if( this.firstdate ) {
            dict[COUNSELAPPLYINFO_FIRSTDATE] = this.firstdate;
        }
        if( this.seconddate ) {
            dict[COUNSELAPPLYINFO_SECONDDATE] = this.seconddate;
        }
        if( this.thirddate ) {
            dict[COUNSELAPPLYINFO_THIRDDATE] = this.thirddate;
        }
        return dict;
    }

    /**
     * 결제시에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForPayment() {
        var dict = {
            [COUNSELAPPLYINFO_PAYMENT_ID]: this.paymentId,
            [COUNSELAPPLYINFO_APPLY_ID]: this.applyId,
            [PAYMENTINFO_MEMBER_ID]: this.applyMemberId,
            [PAYMENTINFO_ORDER_NAME]: this.projectName,
            [PAYMENTINFO_PRICE]: this.price,
        };
        return dict;
    }
}
