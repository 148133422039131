//
//  additionalScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 10. 26..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
} from 'react';

// common
import ConstantValue from 'constants/value';
import ConstantPath from 'constants/path';

// util
import ListUtil from 'util/listUtil';
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkUser from 'network/networkUser';

// info
import UserInfo, {
    USERINFO_CONTAINER,
} from 'info/user/userInfo';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';
import { sessionLogin } from 'redux/actions/sessionAction';

// component
import ImagePicker from 'components/image/imagePicker';

// package
import { Image, Form, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";


/**
 * @protocol AdditionalScreen
 * @date 2022/10/26
 * @brief 회원 추가정보 입력화면
 */
function AdditionalScreen() {
    const { t } = useTranslation();

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const redirect = useSelector(state => state.data.redirect);

    var prevUserInfo;
    
    // 이전 설정 데이터 없으면 초기화면으로 이동
    if( location.state === null || location.state[USERINFO_CONTAINER] === null ) {
        dispatch(popupShowDefault(t('alert_network_error'), () => {
            navigate(ConstantPath.PATH_LOGIN);
        }));
        prevUserInfo = new UserInfo();
    }
    else {
        prevUserInfo = location.state[USERINFO_CONTAINER];
    }

    const [email, setEmail] = useState(prevUserInfo.email);
    const [gender, setGender] = useState(prevUserInfo.gender);
    const [nickname, setNickname] = useState("");
    const [phone1, setPhone1] = useState("");
    const [phone2, setPhone2] = useState("");
    const [phone3, setPhone3] = useState("");
    const [job, setJob] = useState("");
    const [mbti, setMBTI] = useState("");
    const [company, setCompany] = useState("");
    const [deskImage, setDeskImage] = useState("");
    const [introduce, setIntroduce] = useState("");

    const onEmailChanged = ({ target: { value } }) => setEmail(value);
    const onGenderPressed = ({ target: { value}}) => setGender(value);
    const onNicknameChanged = ({ target: { value}}) => setNickname(value);
    const onPhone1Changed = ({ target: { value}}) => setPhone1(value);
    const onPhone2Changed = ({ target: { value}}) => setPhone2(value);
    const onPhone3Changed = ({ target: { value}}) => setPhone3(value);
    const onJobPressed = ({ target: { value}}) => setJob(value);
    const onMBTIPressed = ({ target: { value }}) => setMBTI(value);
    const onCompanyChanged = ({ target: { value}}) => setCompany(value);
    const onDeskImageChanged = (image) => setDeskImage(image);
    const onIntroduceChanged = ({ target: { value}}) => setIntroduce(value);

    const onSubmitPressed = (event) => {
        event.preventDefault();
        
        var prevUserInfo = location.state[USERINFO_CONTAINER];

        var newUserInfo = new UserInfo();
        newUserInfo.memberId = prevUserInfo.memberId;
        newUserInfo.snsType = prevUserInfo.snsType;
        newUserInfo.email = email;
        newUserInfo.gender = gender;
        newUserInfo.nickname = nickname;
        newUserInfo.contact = phone1 + phone2 + phone3;
        newUserInfo.currentJobCode = job;
        newUserInfo.mbtiCode = mbti;
        newUserInfo.company = company;
        newUserInfo.onelineIntro = introduce;
        newUserInfo.deskImgData = deskImage;

        NetworkUser.requestSignUp((res) => {
            dispatch(popupShowDefault(t('alert_complete_signup'), () => {
                // 세션 정보 설정
                dispatch(sessionLogin(res[USERINFO_CONTAINER]));
                
                if( DataUtil.validateString(redirect) ) {
                    navigate(redirect);
                }
                else {
                    navigate('/');
                }
            }));
        }, (res) => {
            dispatch(popupShowDefault(t('alert_network_error')));
        }, newUserInfo);
    };
    
    return (
        <div style={{textAlign: 'left'}}>
            <div className="authtication blackish high-opacity">
                <div className="verticle-center">
                    <div className="welcome-note">
                        <div className="logo">
                            <Image src="assets/images/image/img_logo.png" alt=""/>
                            <span style={{color: '#fff'}}>{t('common_service_slogan')}</span>
                        </div>
                        <h1>{t('addtional_guide_title')}</h1>
                        <p style={{whiteSpace: 'pre-wrap'}}>{t('addtional_guide_description')}</p>
                    </div>
                <div className="bg-image" style={{backgroundImage: "url(assets/images/resources/login-bg.jpg)"}}></div> 
                </div>
            </div>
            <div className="auth-login">
                <div className="verticle-center">
                    <div className="signup-form">
                        <h4><i className="icofont-lock"></i> {t('addtional_title')}</h4>
                        <Form className="c-form" onSubmit={onSubmitPressed}>
                            <div className="row merged-10">
                                <div className="col-lg-12">
                                    <input type="text"
                                        value={email}
                                        placeholder={t('input_email')}
                                        required
                                        maxLength={ConstantValue.INPUT_MAX_LENGTH_EMAIL}
                                        onChange={onEmailChanged}/>
                                </div>
                                <div className="gender mb-2">
                                    <label>{t('input_phone')}</label>
                                    <div className="row">
                                        <div className="col-lg-2">
                                            <input type="text"
                                                value={phone1}
                                                pattern="[0-9]+"
                                                placeholder={t('input_phone1')}
                                                required
                                                maxLength={ConstantValue.INPUT_MAX_LENGTH_PHONE1}
                                                onChange={onPhone1Changed}/>
                                        </div>
                                        <div className="col-lg-0.5">
                                            <h4>-</h4>
                                        </div>
                                        <div className="col-lg-3">
                                            <input type="text"
                                                value={phone2}
                                                pattern="[0-9]+"
                                                placeholder={t('input_phone2')}
                                                required
                                                maxLength={ConstantValue.INPUT_MAX_LENGTH_PHONE2}
                                                onChange={onPhone2Changed}/>
                                        </div>
                                        <div className="col-lg-0.5">
                                            <h4>-</h4>
                                        </div>
                                        <div className="col-lg-3">
                                            <input type="text"
                                                value={phone3}
                                                pattern="[0-9]+"
                                                placeholder={t('input_phone3')}
                                                required
                                                maxLength={ConstantValue.INPUT_MAX_LENGTH_PHONE3}
                                                onChange={onPhone3Changed}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="gender">
                                        <input
                                            type="radio"
                                            id="male"
                                            name="gender"
                                            value={ConstantValue.USER_GENDER_MALE}
                                            required
                                            defaultChecked={gender === ConstantValue.USER_GENDER_MALE}
                                            onClick={onGenderPressed}/>
                                        <label htmlFor="male">{t('input_male')}</label>
                                        <input
                                            type="radio"
                                            id="female"
                                            name="gender"
                                            value={ConstantValue.USER_GENDER_FEMALE}
                                            required
                                            defaultChecked={gender === ConstantValue.USER_GENDER_FEMALE}
                                            onClick={onGenderPressed}/>
                                        <label htmlFor="female">{t('input_female')}</label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <input type="text"
                                        value={nickname}
                                        placeholder={t('input_nickname')}
                                        required
                                        maxLength={ConstantValue.INPUT_MAX_LENGTH_NICKNAME}
                                        onChange={onNicknameChanged}/>
                                </div>
                                <div className="col-lg-6">
                                    <select required
                                        onChange={onMBTIPressed}>
                                        {ListUtil.generateMBTIList()}
                                    </select>
                                </div>
                                <div className="col-lg-6">
                                    <input type="text"
                                        value={company}
                                        placeholder={t('input_company') + '(' + t('word_option') + ')'}
                                        maxLength={ConstantValue.INPUT_MAX_LENGTH_COMPANY}
                                        onChange={onCompanyChanged}/>
                                </div>
                                <div className="col-lg-6">
                                    <select required
                                        onChange={onJobPressed}>
                                        {ListUtil.generateJobList()}
                                    </select>
                                </div>
                                <div className="col-lg-12">
                                    <input type="text"
                                        value={introduce}
                                        placeholder={t('input_introduce') + '(' + t('word_option') + ')'}
                                        maxLength={ConstantValue.INPUT_MAX_LENGTH_INTRODUCE}
                                        onChange={onIntroduceChanged}/>
                                </div>
                                <div className="gender mb-2">
                                    <label>{t('input_deskimage') + '(' + t('word_option') + ')'}</label>
                                    <ImagePicker
                                        onImageChanged={onDeskImageChanged}/>
                                </div>
                                <div className="col-lg-12">
                                    <Button className="main-btn" type="submit">
                                        <i className="icofont-key"></i> {t('button_signup')}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdditionalScreen;