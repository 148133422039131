//
//  faqList.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 20..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// common
import { CommonKey, HelpFaqMenuKey, ApplyTabKey } from 'constants/key';
import ConstantPath from 'constants/path';

// string
import { useTranslation } from 'react-i18next';


/**
 * @protocol FaqList
 * @date 2022/12/19
 * @brief 자주 찾는 질문 목록 반환
 */
export function FaqList(id) {
    const { t } = useTranslation();

    switch( id ) {
        case HelpFaqMenuKey.HELP_FAQ_MENU_SERVICE: {
            return [{
                title: t('help_faq_category1_list1_title'),
                description: t('help_faq_category1_list1_description'),
                link: t('help_faq_category1_list1_link'),
                url: '#',
            }, {
                title: t('help_faq_category1_list2_title'),
                description: t('help_faq_category1_list2_description'),
                link: t('help_faq_category1_list2_link'),
                url: '#',
            }, {
                title: t('help_faq_category1_list3_title'),
                description: t('help_faq_category1_list3_description'),
                link: t('help_faq_category1_list3_link'),
                url: ConstantPath.PATH_WORRY,
            }, {
                title: t('help_faq_category1_list4_title'),
                description: t('help_faq_category1_list4_description'),
                link: t('help_faq_category1_list4_link'),
                url: '#',
            }, {
                title: t('help_faq_category1_list5_title'),
                description: t('help_faq_category1_list5_description'),
                link: t('help_faq_category1_list5_link'),
                url: '#',
            }];
        }
        case HelpFaqMenuKey.HELP_FAQ_MENU_USAGE_COUNSEL: {
            return [{
                title: t('help_faq_category2_list1_title'),
                description: t('help_faq_category2_list1_description'),
                link: t('help_faq_category2_list1_link'),
                url: ConstantPath.PATH_SOLUTION_COUNSEL,
            }, {
                title: t('help_faq_category2_list2_title'),
                description: t('help_faq_category2_list2_description'),
                link: t('help_faq_category2_list2_link'),
                url: ConstantPath.PATH_SOLUTION_PROJECT,
            }, {
                title: t('help_faq_category2_list3_title'),
                description: t('help_faq_category2_list3_description'),
                link: t('help_faq_category2_list3_link'),
                url: ConstantPath.PATH_SOLUTION_PROJECT,
            }, {
                // title: t('help_faq_category2_list4_title'),
                // description: t('help_faq_category2_list4_description'),
                // link: t('help_faq_category2_list4_link'),
                // url: ConstantPath.PATH_NFT,
            }, {
                title: t('help_faq_category2_list5_title'),
                description: t('help_faq_category2_list5_description'),
                link: t('help_faq_category2_list5_link'),
                url: '#',
            }];
        }
        case HelpFaqMenuKey.HELP_FAQ_MENU_APPLY_MENTOR: {
            return [{
                title: t('help_faq_category3_list1_title'),
                description: t('help_faq_category3_list1_description'),
                link: t('help_faq_category3_list1_link'),
                url: ConstantPath.PATH_SOLUTION_APPLY,
                state: {
                    [CommonKey.TAB_ID]: ApplyTabKey.APPLY_TAB_EXAMPLE,
                }
            }, {
                title: t('help_faq_category3_list2_title'),
                description: t('help_faq_category3_list2_description'),
                link: t('help_faq_category3_list2_link'),
                url: '#',
            }, {
                title: t('help_faq_category3_list3_title'),
                description: t('help_faq_category3_list3_description'),
                link: t('help_faq_category3_list3_link'),
                url: '#',
            }, {
                title: t('help_faq_category3_list4_title'),
                description: t('help_faq_category3_list4_description'),
                link: t('help_faq_category3_list4_link'),
                url: '#',
            }, {
                title: t('help_faq_category3_list5_title'),
                description: t('help_faq_category3_list5_description'),
                link: t('help_faq_category3_list5_link'),
                url: '#',
            }];
        }
        case HelpFaqMenuKey.HELP_FAQ_MENU_PAYMENT_AND_REFUND: {
            return [{
                title: t('help_faq_category4_list1_title'),
                description: t('help_faq_category4_list1_description'),
                link: t('help_faq_category4_list1_link'),
                url: '#',
            }, {
                title: t('help_faq_category4_list2_title'),
                description: t('help_faq_category4_list2_description'),
                link: t('help_faq_category4_list2_link'),
                url: '#',
            }, {
                title: t('help_faq_category4_list3_title'),
                description: t('help_faq_category4_list3_description'),
                link: t('help_faq_category4_list3_link'),
                url: '#',
            }, {
                title: t('help_faq_category4_list4_title'),
                description: t('help_faq_category4_list4_description'),
                link: t('help_faq_category4_list4_link'),
                url: '#',
            }, {
                title: t('help_faq_category4_list5_title'),
                description: t('help_faq_category4_list5_description'),
                link: t('help_faq_category4_list5_link'),
                url: '#',
            }];
        }
        default: {
            return [];
        }
    }
}