//
//  commonList.js
//  datatown
//
//  Created by Mumakil on 2022. 10. 24..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// common
import {
    CommonKey,
    OrdinalStatusKey,
    HelpM2mSubjectKey,
    ApplyCounselStatusKey,
} from 'constants/key';

// string
import { useTranslation } from 'react-i18next';


/**
 * @protocol JobList
 * @date 2022/10/26
 * @brief 직무 목록 반환
 */
export function JobList() {
    const { t } = useTranslation();
    return [{
        id: 'default',
        title: t('input_currentjob'),
    },{
        id: 'CJA',
        title: t('job_01'),
    },{
        id: 'CJB',
        title: t('job_02'),
    },{
        id: 'CJC',
        title: t('job_03'),
    },{
        id: 'CJD',
        title: t('job_04'),
    },{
        id: 'CJE',
        title: t('job_05'),
    },{
        id: 'CJF',
        title: t('job_06'),
    },{
        id: 'CJG',
        title: t('job_07'),
    },{
        id: 'CJH',
        title: t('job_08'),
    },{
        id: 'CJI',
        title: t('job_09'),
    },{
        id: 'CJJ',
        title: t('job_10'),
    },{
        id: 'CJK',
        title: t('job_11'),
    },{
        id: 'CJL',
        title: t('job_12'),
    },{
        id: 'CJM',
        title: t('job_13'),
    },{
        id: 'CJN',
        title: t('job_14'),
    },{
        id: 'CJO',
        title: t('job_15'),
    },{
        id: 'CJP',
        title: t('job_16'),
    },{
        id: 'CJQ',
        title: t('job_17'),
    },{
        id: 'CJR',
        title: t('job_18'),
    },{
        id: 'CJS',
        title: t('job_19'),
    },{
        id: 'CJT',
        title: t('job_20'),
    },{
        id: 'CJU',
        title: t('job_21'),
    },{
        id: 'CJV',
        title: t('job_22'),
    }];
}

/**
 * @protocol TermOfServiceList
 * @date 2022/10/26
 * @brief 재직기간 목록 반환
 */
export function TermOfServiceList() {
    const { t } = useTranslation();
    return [{
        id: 'default',
        title: t('input_termofservice'),
    },{
        id: 'TWA',
        title: t('term_of_office_01'),
    },{
        id: 'TWB',
        title: t('term_of_office_02'),
    },{
        id: 'TWC',
        title: t('term_of_office_03'),
    },{
        id: 'TWD',
        title: t('term_of_office_04'),
    },{
        id: 'TWE',
        title: t('term_of_office_05'),
    },{
        id: 'TWF',
        title: t('term_of_office_06'),
    }];
}

/**
 * @protocol ActionTypeList
 * @date 2022/10/28
 * @brief 활동형태 목록 반환
 */
export function ActionTypeList() {
    const { t } = useTranslation();
    return [{
        id: 'default',
        title: t('input_actiontype'),
    },{
        id: 'AA',
        title: t('actiontype_01'),
    },{
        id: 'AB',
        title: t('actiontype_02'),
    }];
}

/**
 * @protocol MBTIList
 * @date 2022/11/07
 * @brief MBTI 목록 반환
 */
export function MBTIList() {
    const { t } = useTranslation();
    return [{
        id: 'default',
        title: 'MBTI',
    },{
        id: 'ISTJ',
        title: 'ISTJ',
    },{
        id: 'ISTP',
        title: 'ISTP',
    },{
        id: 'ISFJ',
        title: 'ISFJ',
    },{
        id: 'ISFP',
        title: 'ISFP',
    },{
        id: 'INTJ',
        title: 'INTJ',
    },{
        id: 'INTP',
        title: 'INTP',
    },{
        id: 'INFJ',
        title: 'INFJ',
    },{
        id: 'INFP',
        title: 'INFP',
    },{
        id: 'ESTJ',
        title: 'ESTJ',
    },{
        id: 'ESTP',
        title: 'ESTP',
    },{
        id: 'ESFJ',
        title: 'ESFJ',
    },{
        id: 'ESEP',
        title: 'ESEP',
    },{
        id: 'ENTJ',
        title: 'ENTJ',
    },{
        id: 'ENTP',
        title: 'ENTP',
    },{
        id: 'ENFJ',
        title: 'ENFJ',
    },{
        id: 'ENFP',
        title: 'ENFP',
    },{
        id: CommonKey.USERINFO_MBTI_DEFAIULT,
        title: t('word_unknown'),
    }];
}

/**
 * @protocol CounselCategoryList
 * @date 2022/11/08
 * @brief 상담 카테고리 목록 반환
 */
export function CounselCategoryList() {
    const { t } = useTranslation();
    return [{
        id: 'counsel_category_01',
        title: t('counsel_category_01'),
    },{
        id: 'counsel_category_02',
        title: t('counsel_category_02'),
    },{
        id: 'counsel_category_03',
        title: t('counsel_category_03'),
    },{
        id: 'counsel_category_04',
        title: t('counsel_category_04'),
    },{
        id: 'counsel_category_05',
        title: t('counsel_category_05'),
    },{
        id: 'counsel_category_06',
        title: t('counsel_category_06'),
    },{
        id: 'counsel_category_07',
        title: t('counsel_category_07'),
    },{
        id: 'counsel_category_08',
        title: t('counsel_category_08'),
    }];
}

/**
 * @protocol DispositionList
 * @date 2022/11/18
 * @brief 사수성향 목록 반환
 */
export function DispositionList() {
    const { t } = useTranslation();
    return [{
        id: 'BTA',
        title: t('disposition_01'),
    }, {
        id: 'BTB',
        title: t('disposition_02'),
    }, {
        id: 'BTC',
        title: t('disposition_03'),
    }, {
        id: 'BTD',
        title: t('disposition_04'),
    }, {
        id: 'BTE',
        title: t('disposition_05'),
    }, {
        id: 'BTF',
        title: t('disposition_06'),
    }, {
        id: 'BTG',
        title: t('disposition_07'),
    }, {
        id: 'BTH',
        title: t('disposition_08'),
    }];
}

/**
 * @protocol WeekList
 * @date 2022/11/18
 * @brief 요일 목록 반환
 */
export function WeekList() {
    const { t } = useTranslation();
    return [{
        id: 'SUN',
        weekofday: 0,
        title: t('week_01'),
    }, {
        id: 'MON',
        weekofday: 1,
        title: t('week_02'),
    }, {
        id: 'TUE',
        weekofday: 2,
        title: t('week_03'),
    }, {
        id: 'WED',
        weekofday: 3,
        title: t('week_04'),
    }, {
        id: 'THU',
        weekofday: 4,
        title: t('week_05'),
    }, {
        id: 'FRI',
        weekofday: 5,
        title: t('week_06'),
    }, {
        id: 'SAT',
        weekofday: 6,
        title: t('week_07'),
    }];
}

/**
 * @protocol BankList
 * @date 2022/11/24
 * @brief 은행 목록 반환
 */
export function BankList() {
    const { t } = useTranslation();
    return [{
        id: 'default',
        title: t('input_bank_code'),
    },{
        id: 'BAA',
        title: t('bank_01'),
    }, {
        id: 'BAB',
        title: t('bank_02'),
    }, {
        id: 'BAC',
        title: t('bank_03'),
    }, {
        id: 'BAD',
        title: t('bank_04'),
    }, {
        id: 'BAE',
        title: t('bank_05'),
    }, {
        id: 'BAF',
        title: t('bank_06'),
    }, {
        id: 'BAG',
        title: t('bank_07'),
    }, {
        id: 'BAH',
        title: t('bank_08'),
    }, {
        id: 'BAI',
        title: t('bank_09'),
    }, {
        id: 'BAJ',
        title: t('bank_10'),
    }, {
        id: 'BAK',
        title: t('bank_11'),
    }, {
        id: 'BAL',
        title: t('bank_12'),
    }, {
        id: 'BAM',
        title: t('bank_13'),
    }, {
        id: 'BAN',
        title: t('bank_14'),
    }, {
        id: 'BAO',
        title: t('bank_15'),
    }, {
        id: 'BAP',
        title: t('bank_16'),
    }, {
        id: 'BAQ',
        title: t('bank_17'),
    }, {
        id: 'BAR',
        title: t('bank_18'),
    }, {
        id: 'BAS',
        title: t('bank_19'),
    }];
}

/**
 * @protocol OrdinalStatusList
 * @date 2022/11/28
 * @brief 기수 진행단계 목록 반환
 */
export function OrdinalStatusList() {
    const { t } = useTranslation();
    return [{
        id: OrdinalStatusKey.ORDINAL_STATUS_REQUEST,
        title: t('ordinal_status_1'),
    },{
        id: OrdinalStatusKey.ORDINAL_STATUS_APPROVAL,
        title: t('ordinal_status_2'),
    },{
        id: OrdinalStatusKey.ORDINAL_STATUS_RECRUITMENT,
        title: t('ordinal_status_3'),
    },{
        id: OrdinalStatusKey.ORDINAL_STATUS_START,
        title: t('ordinal_status_4'),
    },{
        id: OrdinalStatusKey.ORDINAL_STATUS_CLOSE,
        title: t('ordinal_status_5'),
    }];
}

/**
 * @protocol M2mSubjectList
 * @date 2022/12/20
 * @brief 1:1 문의 주제 목록 반환
 */
export function M2mSubjectList() {
    const { t } = useTranslation();
    return [{
        id: 'default',
        title: t('input_m2m_subject'),
    },{
        id: HelpM2mSubjectKey.HELP_M2M_SUBJECT_USAGE_COUNSEL,
        title: t('help_m2m_subject_usage_counsel'),
    },{
        id: HelpM2mSubjectKey.HELP_M2M_SUBJECT_USAGE_PROJECT,
        title: t('help_m2m_subject_usage_project'),
    },{
        id: HelpM2mSubjectKey.HELP_M2M_SUBJECT_APPLY_MENTOR,
        title: t('help_m2m_subject_apply_mentor'),
    },{
        id: HelpM2mSubjectKey.HELP_M2M_SUBJECT_PAYMENT,
        title: t('help_m2m_subject_payment'),
    },{
        id: HelpM2mSubjectKey.HELP_M2M_SUBJECT_ACCOUNT,
        title: t('help_m2m_subject_account'),
    },{
        id: HelpM2mSubjectKey.HELP_M2M_SUBJECT_REFUND,
        title: t('help_m2m_subject_refund'),
    },{
        id: HelpM2mSubjectKey.HELP_M2M_SUBJECT_ERROR,
        title: t('help_m2m_subject_error'),
    },{
        id: HelpM2mSubjectKey.HELP_M2M_SUBJECT_PROPOSAL,
        title: t('help_m2m_subject_proposal'),
    }];
}

/**
 * @protocol ApplyCounselStatusList
 * @date 2023/01/20
 * @brief 상담 진행단계 목록 반환
 */
export function ApplyCounselStatusList() {
    const { t } = useTranslation();
    return [{
        id: ApplyCounselStatusKey.APPLY_COUNSEL_STATUS_REQUEST,
        title: t('apply_counsel_status_1'),
    },{
        id: ApplyCounselStatusKey.APPLY_COUNSEL_STATUS_ACCEPT,
        title: t('apply_counsel_status_2'),
    },{
        id: ApplyCounselStatusKey.APPLY_COUNSEL_STATUS_PAYMENT,
        title: t('apply_counsel_status_3'),
    },{
        id: ApplyCounselStatusKey.APPLY_COUNSEL_STATUS_REJECT,
        title: t('apply_counsel_status_4'),
    }];
}