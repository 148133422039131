//
//  networkCounsel.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 21..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// network
import CommonNetwork from 'network/common/commonNetwork';

// info
import { listParseFromDictionary } from 'info/listParse';
import ResponseInfo, {
    RESPONSEINFO_CONTAINER,
} from 'info/common/responseInfo';
import CategoryInfo, {
    CATEGORYINFO_LIST,
} from 'info/counsel/categoryInfo';

// package
import axios from 'axios';
axios.defaults.withCredentials = true;

const NETWORK_PATH_GET_CATEGORY_LIST    = '/get_category_list';
const NETWORK_PATH_APPLY_COUNSEL        = '/apply_counsel';

const NETWORK_PARAM_CATEGORY_TYPE   = 'category_type';
const NETWORK_PARAM_MEMBER_ID       = 'member_id';
const NETWORK_PARAM_CATEGORY_LIST   = 'category_list';
const NETWORK_PARAM_TENDENCY_CODE   = 'tendency_code';
const NETWORK_PARAM_MBTI_CODE       = 'mbti_code';


/**
 * @protocol NetworkCounsel
 * @date 2022/11/21
 * @brief 상담 관련 통신
 */
export default class NetworkCounsel {
    /**
     * 인증 코드 확인
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} email 수신 이메일
     * @param {String} authCode 인증 코드
     */
    static getCategoryList = async (resSuccess, resFail, categoryType) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_CATEGORY_LIST])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_CATEGORY_TYPE]: categoryType,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var categoryList = listParseFromDictionary(CategoryInfo, responseInfo.data, '');
                resSuccess({
                    [RESPONSEINFO_CONTAINER]: responseInfo,
                    [CATEGORYINFO_LIST]: categoryList,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 상담 신청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} memberId 사용자 id
     * @param {Array} categoryList 상담 카테고리 목록
     * @param {String} tendencyCode 성향 코드
     * @param {String} mbtiCode mbti 코드
     */
    static requestApplyCounsel = async (resSuccess, resFail, memberId, categoryList, tendencyCode, mbtiCode) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_APPLY_COUNSEL]);

        try {
            await axios.post(url, {
                [NETWORK_PARAM_MEMBER_ID]: memberId,
                [NETWORK_PARAM_CATEGORY_LIST]: categoryList,
                [NETWORK_PARAM_TENDENCY_CODE]: tendencyCode,
                [NETWORK_PARAM_MBTI_CODE]: mbtiCode,
            }).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }
}
