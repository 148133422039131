//
//  companyScreen.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 13..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useRef,
    useEffect,
    useState,
} from 'react';

// common
import { MainList } from 'constants/list';
import ConstantPath from 'constants/path';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkSolution from 'network/networkSolution';

// info
import { PROJECTINFO_LIST } from 'info/solution/projectInfo';

// component
import Layout from 'components/layout/layout';
import MainReviewCarousel from 'components/carousel/mainReviewCarousel';
import ProjectCell from 'components/cell/projectCell';
import CounselCell from 'components/cell/counselCell';

// package
import Typed from 'typed.js';
import { useCountUp } from 'react-countup';
import { useNavigate } from "react-router-dom";


/**
 * @protocol CompanyScreen
 * @date 2023/01/13
 * @brief 회사소개 화면
 */
function CompanyScreen() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const el = useRef(null);
    const typed = useRef(null);

    const [projectList, setProjectList] = useState(null);

    useEffect(() => {
        const options = {
            strings: [
                t('counsel_category_01'),
                t('counsel_category_02'),
                t('counsel_category_03'),
                t('counsel_category_04'),
                t('counsel_category_05'),
                t('counsel_category_06'),
                t('counsel_category_07'),
                t('counsel_category_08'),
            ],
            typeSpeed: 50,
            backSpeed: 50,
            loop: true,
            loopCount: Infinity,
        };
        
        typed.current = new Typed(el.current, options);
        
        if( projectList === null ) {
            NetworkSolution.getMainProjectList((res) => {
                setProjectList(res[PROJECTINFO_LIST]);
            }, (res) => {
            });
        }
        return () => {
            typed.current.destroy();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * 카운팅 ui hook
     * @param {String} refId ref id
     * @param {Number} value 목표값
     * @returns {*} countup ui
     */
    const CountUpHook = (refId, value) => {
        useCountUp({
            ref: refId,
            start: 0,
            end: value,
            separator: ',',
            decimal: ',',
        });
        return <span className="counter" id={refId} />;
    };

    /**
     * 서비스 identity 목록 반환
     * @returns {Array} identity 목록
     */
    const renderIdentityList = () => {
        var itemList = [];
        var contentList = MainList.IdentityList();
        for( var i = 0; i < contentList.length; i++ ) {
            itemList.push(  <div key={"identity" + String(i)}
                                className="col-lg-4 col-md-6">
                                <div className="info-sec">
                                    <i className={contentList[i].icon}></i>
                                    <div>
                                        <h6>{contentList[i].title}</h6>
                                        <p><b>{contentList[i].description}</b></p>
                                    </div>
                                </div>
                            </div>);
        }
        return itemList;
    }

    /**
     * 서비스 관련 카운팅 목록 반환
     * @returns {Array} identity 목록
     */
    const renderCounterList = () => {
        var itemList = [];
        var contentList = MainList.CounterList();
        for( var i = 0; i < contentList.length; i++ ) {
            itemList.push(  <div key={"counter" + String(i)}
                                className="col-lg-3 col-md-3 col-sm-6">
                                <div className="funfact-counter">
                                    <i className={contentList[i].icon}></i>
                                    {CountUpHook("counter" + String(i), contentList[i].value)}
                                    <em><h6>{contentList[i].title}</h6></em>
                                </div>
                            </div>);
        }
        return itemList;
    }

    /**
     * 서비스 고민 태그 목록 반환
     * @param {tagList} 태그 정보 목록
     * @returns {Array} 고민 목록
     */
    
    const renderWorryTagList = (tagList) => {
        var itemList = [];
        for( var i = 0; i < tagList.length; i++ ) {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            itemList.push(<a key={"worry_tag_" + String(i)}>{tagList[i]}</a>);
        }
        return itemList;
    }
    
    /**
     * 서비스 고민 목록 반환
     * @returns {Array} 고민 목록
     */
    const renderWorryList = () => {
        var itemList = [];
        var contentList = MainList.WorryList();

        for( var i = 0; i < contentList.length; i++ ) {
            itemList.push(  <section key={"worry" + String(i)}>
                                <div className="gap" style={{textAlign: 'left'}}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-5">
                                                <figure className="side-image">
                                                    <img src={contentList[i].imageUrl} alt=""/>
                                                </figure>
                                            </div>
                                            <div className="col-lg-7 col-md-7">
                                                <div className="verticle-center">
                                                    <div className="measure right">
                                                        <h2 style={{whiteSpace: 'pre-wrap'}}><b>
                                                            {contentList[i].title}
                                                        </b></h2>
                                                        <br/>
                                                        <p><b>
                                                            {renderWorryTagList(contentList[i].tagList)}
                                                        </b></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>);
        }
        return itemList;
    }

    /**
     * 서비스 문구 목록 반환
     * @returns {Array} 서비스 문구 목록
     */
    const renderPhraseList = () => {
        var itemList = [];
        var phraseList = MainList.WelcomePhraseList();
        for( var i = 0; i < phraseList.length; i++ ) {
            itemList.push(  <div key={"phrase" + String(i)}>
                                &#10004;   {phraseList[i]}<br/>
                            </div>);
        }
        return itemList;
    }

    const renderProjectList = () => {
        var itemList = [];
        if( DataUtil.validateArray(projectList) ) {
            for( var i = 0; i < projectList.length; i++ ) {
                if( projectList[i].type === 'TEAM' ) {
                    itemList.push(  <div key={"project" + String(i)}
                                        className="col-lg-4 col-md-6 col-sm-6">
                                        <ProjectCell data={projectList[i]}
                                            onCellPressed={(id) => {
                                                var path = ConstantPath.PATH_PROJECT_INTRO.split("/");
                                                var strippedPath = path.slice(0, path.length-1).join("/");
                                                navigate(strippedPath + '/' + id);
                                        }}/>
                                    </div>)
                }
                else if( projectList[i].type === 'COUN' ) {
                    itemList.push(  <div key={"project" + String(i)}
                                        className="col-lg-4 col-md-6 col-sm-6">
                                        <CounselCell data={projectList[i]}
                                            onCellPressed={(id) => {
                                                var path = ConstantPath.PATH_COUNSEL_INTRO.split("/");
                                                var strippedPath = path.slice(0, path.length-1).join("/");
                                                navigate(strippedPath + '/' + id);
                                        }}/>
                                    </div>)
                }
            }
        }
        
        return itemList;
    }

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}>
            <div>
                <section>
                    <div className="gap overlap nogap mate-black low-opacity">
                        <div className="bg-image"
                            style={{backgroundImage: "url(assets/images/image/img_bg_banner.jpg)"}}/>
                        <div className="feature-meta">
                            <h1>{t('main_banner_title')}
                                <span>{t('common_service')}</span>
                            </h1>
                            <h3><span ref={el}/></h3>
                            <a href={ConstantPath.PATH_WORRY} title="" className="main-btn" data-ripple="">{t('button_find_mentor')}</a>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="gap no-bottom grey-bg nogap" style={{textAlign: 'left'}}>
                        <div className="container">
                            <div className="row">
                                {renderIdentityList()}
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="gap no-bottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="banner-full">
                                        <div className="row">
                                            {renderCounterList()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {renderWorryList()}
                <section>
                    <div className="gap mate-black low-opacity">
                        <div className="bg-image"
                            style={{backgroundImage: "url(assets/images/image/img_bg_phrase.jpg)"}}
                            data-velocity=".2"/>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="welcome-parallax">
                                        <i><img src="assets/images/icon/icon_tv.png" alt=""/></i>
                                        <h2><b>{t('main_welcome_title')}</b></h2>
                                        <span><b>
                                            {renderPhraseList()}
                                        </b></span>
                                        <a href={ConstantPath.PATH_WORRY} title="" className="main-btn" data-ripple="">
                                            {t('button_matching')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    DataUtil.validateArray(projectList) ?
                    <section>
                        <div className="gap" style={{textAlign: 'left'}}>
                            <div className="container">
                                <div className="row remove-ext30">
                                    <div className="col-lg-12">
                                        <div className="title">
                                            <h1>{t('main_project_title')}</h1>
                                        </div>
                                    </div>
                                    {renderProjectList()}
                                </div>
                            </div>
                        </div>
                    </section> :
                    null
                }
                <section>
                    <div className="gap no-top" style={{textAlign: 'left'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="newsletter-sec">
                                        <figure><img src="assets/images/image/img_logo.png" alt="" style={{maxWidth: '100px'}}/></figure>
                                        <div className="leter-meta">
                                            <span><h5>{t('main_help_title')}</h5></span>
                                            <h3>{t('main_help_description')}</h3>
                                        </div>
                                        <div className="form">
                                            <a href={ConstantPath.PATH_HELP} title="" className="main-btn" data-ripple="">{t('button_help')}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="gap no-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="title">
                                        <h1>{t('main_review_title')}</h1>
                                        <h5>{t('main_review_description')}</h5>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <MainReviewCarousel/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
}

export default CompanyScreen;