//
//  sessionReducer.js
//  datatown
//
//  Created by dasein55 on 2022. 7. 27..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import * as SessionAction from 'redux/actions/sessionAction';

// util
import DataUtil from 'util/dataUtil';


// 세션 관련 초기 상태
export const INITIAL_STATE = {
    isLogin: false,     // 로그인 여부
    isMentor: false,    // 사수 여부
	user: null,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch( action.type ) {
        case SessionAction.ACTION_SESSION_LOGIN: {
            return {
                ...state, user: action.payload, isLogin: true, isMentor: DataUtil.validateString(action.payload.memberNo) ? true : false,
            };
        }
        case SessionAction.ACTION_SESSION_LOGOUT: {
            return {
                ...state, user: action.payload, isLogin: false, isMentor: false,
            };
        }
        case SessionAction.ACTION_SESSION_UPDATE: {
            return {
                ...state, user: action.payload, isMentor: DataUtil.validateString(action.payload.memberNo) ? true : false,
            };
        }
        default:
            return state;
    }
};

export default reducer;
