//
//  sessionAction.js
//  datatown
//
//  Created by dasein55 on 2022. 11. 10..
//  Copyright © 2022년 Melephant. All rights reserved.
//

export const ACTION_SESSION_LOGIN   = 'action_session_login';
export const ACTION_SESSION_LOGOUT  = 'action_session_logout';
export const ACTION_SESSION_UPDATE  = 'action_session_update';

export const sessionLogin = userInfo => ({
    type: ACTION_SESSION_LOGIN,
    payload: userInfo,
});

export const sessionLogout = () => ({
    type: ACTION_SESSION_LOGOUT,
    payload: null,
});

export const sessionUpdate = userInfo => ({
    type: ACTION_SESSION_UPDATE,
    payload: userInfo,
});
