//
//  blogDetailScreen.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 18..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
    useEffect,
} from 'react';

// common
import ConstantPath from 'constants/path';
// util
import DataUtil from 'util/dataUtil';

// network
import NetworkBlog from 'network/networkBlog';

// info
import { BLOGINFO_CONTAINER } from 'info/blog/blogInfo';

// component
import Layout from 'components/layout/layout';

// package
import { useParams } from "react-router-dom";


/**
 * @protocol BlogDetailScreen
 * @date 2023/01/18
 * @brief 블로그 상세 화면
 */
function BlogDetailScreen(props) {
    var params = useParams();

    const [blog, setBlog] = useState(null);
    
    useEffect(() => {
        if( blog === null ) {
            NetworkBlog.getBlogInfo((res) => {
                var blogInfo = res[BLOGINFO_CONTAINER];
                
                if( blogInfo ) {
                    setBlog(blogInfo);
                }
            }, (res) => {
            }, params.blog_id);
        }
    }, [blog, params.blog_id]);

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}
            visibleSolutionHeader={true}
            location={ConstantPath.PATH_SOLUTION_BLOG}>
			<div className="gap" style={{textAlign: 'left'}}>
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-1 col-lg-10">
                            {
                                blog ?
                                <div className="blog-detail">
                                    <div className="blog-title">
                                        <h2>{blog.subject}</h2>
                                    </div>
                                    <div className="blog-details-meta">
                                        <ul>
                                            <li>
                                                <i className="">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        className="feather feather-calendar">
                                                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                                        <line x1="16" y1="2" x2="16" y2="6"></line>
                                                        <line x1="8" y1="2" x2="8" y2="6"></line>
                                                        <line x1="3" y1="10" x2="21" y2="10"></line>
                                                    </svg>
                                                </i>
                                                {DataUtil.convertDateTimeToString(blog.regdate)}
                                            </li>
                                        </ul>
                                        <div dangerouslySetInnerHTML={{
                                                __html: blog.contents
                                            }}/>
                                    </div>
                                </div> :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default BlogDetailScreen;