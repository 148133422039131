//
//  mainReviewCarousel.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 2..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React from 'react';

// common
import { MainList } from 'constants/list';

// package
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


/**
 * @protocol MainReviewCarousel
 * @date 2022/11/02
 * @brief 메인화면 후기 관련 캐러셀
 */
function MainReviewCarousel() {
    var itemList = [];
    
    const reviewList = MainList.ReviewList();
    for( var i = 0; i < reviewList.length; i++ ) {
        itemList.push(  <div
							className="review-item"
                            key={"review" + String(i)}>
                            {/* <figure><img src={reviewList[i].imageUrl} alt=""/></figure> */}
							<figure><img alt="" src="assets/images/image/commentator.jpg"/></figure>
                            <h6>{reviewList[i].nickname}</h6>
                            <span>{reviewList[i].company}</span>
                            <p><b>
                                {reviewList[i].review}
                            </b></p>
                        </div>);
    }

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "20px",
        slidesToShow: 3,
        speed: 500,
        dots: true,
        responsive: [{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				infinite: true,
				dots: true
			}
		},{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2
			}
		},{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}]
    };

    return	<div
				style={{ marginBottom: '50px' }}>
				<Slider {...settings}>
					{itemList}
				</Slider>
			</div>;
}

export default MainReviewCarousel;
