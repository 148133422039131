//
//  blogInfo.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 18..
//  Copyright © 2022년 Melephant. All rights reserved.
//

export const BLOGINFO_LIST      = 'blog_list';
export const BLOGINFO_CONTAINER = 'blog_info';
export const BLOGINFO_BLOG_ID   = 'blog_idx';
export const BLOGINFO_SUBJECT   = 'subject';
export const BLOGINFO_CONTENTS  = 'contents';
export const BLOGINFO_IMAGE_URL = 'image_url';
export const BLOGINFO_REGDATE   = 'regdate';


/**
 * @protocol BlogInfo
 * @date 2023/01/18
 * @brief 블로그 정보
 */
export default class BlogInfo {
    constructor() {
        this.blogId = '';
        this.subject = '';
        this.contents = '';
        this.imageUrl = '';
        this.regdate = null;
    }

    /**
     * dictionary 객체에서 데이터 값을 가져와서 변수에 넣음
     * @param {dictionary} dict 정보 객체
     */
    parseFromDictionary(dict) {
        if( dict.hasOwnProperty(BLOGINFO_BLOG_ID) ) {
            this.blogId = dict[BLOGINFO_BLOG_ID];
        }

        if( dict.hasOwnProperty(BLOGINFO_SUBJECT) ) {
            this.subject = dict[BLOGINFO_SUBJECT];
        }

        if( dict.hasOwnProperty(BLOGINFO_CONTENTS) ) {
            this.contents = dict[BLOGINFO_CONTENTS];
        }

        if( dict.hasOwnProperty(BLOGINFO_IMAGE_URL) ) {
            this.imageUrl = dict[BLOGINFO_IMAGE_URL];
        }

        if( dict.hasOwnProperty(BLOGINFO_REGDATE) ) {
            this.regdate = new Date(dict[BLOGINFO_REGDATE]);
        }
    }

    /**
     * 모든 키에 대한 dictionary 반환
     * @returns {dictionary} 데이터
     */
    dictionaryForAllKey() {
        var dict = {
            [BLOGINFO_BLOG_ID]: this.blogId,
            [BLOGINFO_SUBJECT]: this.subject,
            [BLOGINFO_CONTENTS]: this.contents,
            [BLOGINFO_IMAGE_URL]: this.imageUrl,
            [BLOGINFO_REGDATE]: this.regdate,
        };
        return dict;
    }
}
