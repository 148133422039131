//
//  OAuthKakaoScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 9..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
} from 'react';

// common
import ConstantPath from 'constants/path';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkUser from 'network/networkUser';

// info
import { USERINFO_CONTAINER } from 'info/user/userInfo';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';
import { sessionLogin } from 'redux/actions/sessionAction';

// component
import Layout from 'components/layout/layout';

// package
import { useNavigate } from 'react-router';


/**
 * @protocol OAuthKakaoScreen
 * @date 2022/11/09
 * @brief 카카오 인증 화면
 */
const OAuthKakaoScreen = () => {
    // 카카오 인증 코드 획득
    const code = new URL(window.location.href).searchParams.get("code");

    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const redirect = useSelector(state => state.data.redirect);

    useEffect(() => {
        // 카카오 로그인 요청
        NetworkUser.requestKakaoLogin((res) => {
            // 세션 정보 설정
            dispatch(sessionLogin(res[USERINFO_CONTAINER]));
            
            if( DataUtil.validateString(redirect) ) {
                navigate(redirect);
            }
            else {
                navigate('/');
            }
        }, (res) => {
            var user = res[USERINFO_CONTAINER];
            
            // 사용자 정보가 있으면, 가입되지 않은 카카오 계정 정보가 설정되어 있음
            if( user ) {
                navigate(ConstantPath.PATH_SIGNUP_ADDITIONAL, {
                    state: {
                        [USERINFO_CONTAINER]: user,
                    }
                });
            }
            // 사용자 정보 없으면, 카카오 인증 과정 오류 발생
            else {
                dispatch(popupShowDefault(t('alert_network_error'), () => {
                    navigate(-1);
                }));
            }
        }, code);
    }, [code, dispatch, navigate, t, redirect]);
    
    return (
        <Layout
            visibleHeader={false}
            visibleFooter={false}
            visibleLoader={true}>
        </Layout>
    );
};

export default OAuthKakaoScreen;
