//
//  projectIntroScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 28..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
} from 'react';

// common
import { CommonList } from 'constants/list';
import { CommonKey, TableKey } from 'constants/key';
import ConstantPath from 'constants/path';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkSolution from 'network/networkSolution';

// info
import { PROJECTINFO_CONTAINER } from 'info/solution/projectInfo';
import { ORDINALINFO_ORDINAL } from 'info/solution/ordinalInfo';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefaultCancel } from 'redux/actions/popupAction';

// component
import Layout from 'components/layout/layout';
import DefaultTable from 'components/table/defaultTable';
import ReviewCell from 'components/cell/reviewCell';
import EmptyCell from 'components/cell/emptyCell';
import ProjectRequestBar from 'components/bar/projectRequestBar';
import ProjectImageView from 'components/image/projectImageView';

// package
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";


/**
 * @protocol ProjectIntroScreen
 * @date 2022/11/28
 * @brief 팀프로젝트 소개 화면
 */
function ProjectIntroScreen(props) {
    var params = useParams();
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    
    const isLogin = useSelector(state => state.session.isLogin);

    const [project, setProject] = useState(null);
    const [currentJob, setCurrentJob] = useState(null);
    const [tos, setTos] = useState(null);
    const [requestOrdinal, setRequestOrdinal] = useState(null);

    const jobList = CommonList.JobList();
    const tosList = CommonList.TermOfServiceList();
    const ordinalStatusList = CommonList.OrdinalStatusList();
    const dispositionList = CommonList.DispositionList();

    useEffect(() => {
        if( project === null ) {
            NetworkSolution.getProjectInfo((res) => {
                var projectInfo = res[PROJECTINFO_CONTAINER];
                if( projectInfo ) {
                    setProject(projectInfo);
                    var jobCodeIndex = jobList.findIndex(element => element.id === projectInfo.mentor.currentJobCode);
                    if( jobCodeIndex >= 0 ) {
                        setCurrentJob(jobList[jobCodeIndex].title);
                    }
                    var tosIndex = tosList.findIndex(element => element.id === projectInfo.mentor.empPeriod);
                    if( tosIndex >= 0 ) {
                        setTos(tosList[tosIndex].title);
                    }
                }
            }, (res) => {
            }, params.project_id);
        }
    }, [project, params.project_id, jobList, tosList]);

    const onOrdinalChanged = ({ target: { value}}) => setRequestOrdinal(value);
    const onRequestPressed = (event) => {
        event.preventDefault();

        // 로그인 체크 후, 화면 전환 처리
        if( isLogin ) {
            navigate(ConstantPath.PATH_PROJECT_CONFIRM, {
                state: {
                    [PROJECTINFO_CONTAINER]: project,
                    [ORDINALINFO_ORDINAL]: requestOrdinal,
                }
            });
        }
        else {
            dispatch(popupShowDefaultCancel(t('alert_not_login'), () => {
                setTimeout(() => {
                    navigate(ConstantPath.PATH_LOGIN, {
                        state: { [CommonKey.REDIRECT]: location.pathname }
                    });
                }, 100);
            }), null);
        }

        
    }

    /**
     * 기수 column 목록 생성
     * @returns {Array} 기수 column 목록
     */
    const generateOrdinaryColumnList = () => {
        return [{
            id: TableKey.TABLE_ORDINARY,
            name: t('word_ordinary'),
            isClickable: false,
        }, {
            id: TableKey.TABLE_MENTOR,
            name: t('word_mentor'),
            isClickable: false,
        }, {
            id: TableKey.TABLE_PERIOD,
            name: t('word_plan'),
            isClickable: false,
        }, {
            id: TableKey.TABLE_STATUS,
            name: t('word_progress'),
            isClickable: false,
        }];
    }

    /**
     * 기수 데이터 목록 생성
     * @returns {Array} 기수 데이터 목록
     */
    const generateOrdinaryDataList = () => {
        var itemList = [];
        if( DataUtil.validateArray(project.ordinalList) ) {
            for( let i = 0; i < project.ordinalList.length; i++ ) {
                var ordinalStatusIndex = ordinalStatusList.findIndex(element => element.id === project.ordinalList[i].ordinalStatus);
                
                itemList.push({
                    [TableKey.TABLE_ORDINARY]: project.ordinalList[i].ordinal + t('word_ordinary_short'),
                    [TableKey.TABLE_MENTOR]: project.mentor.nickname,
                    [TableKey.TABLE_PERIOD]: DataUtil.convertDateToString(project.ordinalList[i].startdate) +
                    '~' + DataUtil.convertDateToString(project.ordinalList[i].enddate),
                    [TableKey.TABLE_STATUS]: ordinalStatusList[ordinalStatusIndex].title,
                });
            }
        }
        return itemList;
    }

    /**
     * 스케쥴 목록 ui 렌더링
     * @returns {Array} 스케쥴 목록
     */
    const renderSchedule = () => {
        var itemList = [];

        if( DataUtil.validateArray(project.scheduleList) ) {
            for( var i = 0; i < project.scheduleList.length; i++ ) {
                itemList.push(  <div className="card"
                                    key={"schedule" + String(i)}>
                                    <div className="card-header" id="headingOne">
                                        <h5 className="mb-0">
                                            <p>{project.scheduleList[i].weeks + t('word_schedule_week') + ' - ' +
                                            project.scheduleList[i].schedule}</p>
                                        </h5>
                                    </div>
                                </div>);
            }
        }
        return itemList;
    }

    /**
     * 리뷰 목록 ui 렌더링
     * @returns {Array} 리뷰 목록
     */
    const renderReview = () => {
        var itemList = [];

        if( DataUtil.validateArray(project.reviewList) ) {
            for( var i = 0; i < project.reviewList.length; i++ ) {
                itemList.push(  <ReviewCell
                                    key={"review" + String(i)}
                                    data={project.reviewList[i]}/>);
            }
        }
        else {
            itemList.push(<EmptyCell key={'review_empty'}/>);
        }
        return itemList;
    }

    /**
     * 사수 성향 ui 렌더링
     * @returns {Array} 사수 성향 목록
     */
    const renderTendencyCode = () => {
        var itemList = [];

        if( DataUtil.validateArray(project.mentor.tendencyList) ) {
            var i = 0;
            project.mentor.tendencyList.forEach(element => {
                var index = dispositionList.findIndex(item => item.id === element.tendencyCode);
                
                if( index >= 0 ){
                    itemList.push(<h6 key={"tendency" + String(i)}>{dispositionList[index].title}</h6>);
                }
                i++;
            })
        }

        return itemList;
    }
    
    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}
            visibleSolutionHeader={true}
            existBottomBar={true}
            location={ConstantPath.PATH_SOLUTION}>
			<div className="gap" style={{textAlign: 'left'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                project ?
                                <div id="page-contents" className="row merged20">
                                    <div className="col-lg-12">
                                        <div className="main-wraper">
                                            <div className="row">
                                                <div className="col-lg-7 col-md-7">
                                                    <div className="course-details">
                                                        <h4>{t('project_intro_category') + ' ' + project.name + ' - ' + project.weeks + t('word_weeks')}</h4>
                                                        <span className="course-price">
                                                        {
                                                            Number(project.price) === 0 ?
                                                            t('word_free') :
                                                            t('word_currency') + DataUtil.numberWithCommas(Number(project.price))
                                                        }
                                                        </span>
                                                        <p style={{whiteSpace: 'pre-wrap'}}>{project.intro}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-5">
                                                    <div className="course-video">
                                                        <ProjectImageView
                                                            maxWidth='500px'
                                                            imageUrl={project.gateImgUrl}/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="course-description">
                                                        <h4 className="main-title">{t('project_intro_goal')}</h4>
                                                        <p style={{whiteSpace: 'pre-wrap'}}>{project.goal}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="course-description">
                                                        <h4 className="main-title">{t('project_intro_description')}</h4>
                                                        <p style={{whiteSpace: 'pre-wrap'}}>{project.description}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-5">
                                                    <div className="course-description">
                                                        <h4 className="main-title">{t('project_intro_mentor')}
                                                            <a className="view-all" href="#!" onClick={(e) => {
                                                                e.preventDefault();
                                                                var path = ConstantPath.PATH_MENTOR_REVIEW.split("/");
                                                                var strippedPath = path.slice(0, path.length-1).join("/");
                                                                navigate(strippedPath + '/' + project.mentor.memberId);
                                                            }}>{t('project_intro_mentor_review')}</a>
                                                        </h4>
                                                    </div>
                                                    <div className="create-by">
                                                        <figure><img src={project.mentor.deskImgUrl} alt=""/></figure>
                                                        <div>
                                                            <span>{project.mentor.nickname}</span>
                                                        </div>
                                                    </div>
                                                    <div className="course-description">
                                                        {/* <p>{currentJob}</p>
                                                        <p>{project.mentor.company}</p>
                                                        <p>{tos}</p>
                                                        <p>{project.mentor.career}</p> */}
                                                        <div className="prod-stat">
                                                            <ul>
                                                                <li><span>{t('project_intro_intro')}:</span> {project.mentor.onelineIntro}</li>
                                                                <li><span>{t('project_intro_current_job')}:</span> {currentJob}</li>
                                                                <li><span>{t('project_intro_company')}:</span> {project.mentor.company}</li>
                                                                <li><span>{t('project_intro_tos')}:</span> {tos}</li>
                                                                <li><span>{t('project_intro_career')}:</span> {project.mentor.career}</li>
                                                                <li><br/></li>
                                                                <li><span>{t('project_intro_tendency')}:</span> {renderTendencyCode()}</li>
                                                                <li><span>{t('project_intro_mbti')}:</span> {project.mentor.mbtiCode}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-7 col-md-7">
                                                    <div className="course-description">
                                                        <h4 className="main-title">{t('project_intro_schedule')}</h4>
                                                        <div className="question-collaps">
                                                            <div id="accordion">
                                                                {renderSchedule()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-5">
                                                    <div className="course-description">
                                                        <h4 className="main-title">{t('project_intro_ordinal')}</h4>
                                                        <DefaultTable
                                                            dataList={generateOrdinaryDataList()}
                                                            columnList={generateOrdinaryColumnList()}/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-7 col-md-7">
                                                    <div className="course-description">
                                                        <h4 className="main-title">{t('project_intro_proejct_review')}
                                                            <a className="view-all" href="#!" onClick={(e) => {
                                                                e.preventDefault();
                                                                var path = ConstantPath.PATH_PROJECT_REVIEW.split("/");
                                                                var strippedPath = path.slice(0, path.length-1).join("/");
                                                                navigate(strippedPath + '/' + project.projectId);
                                                            }}>{t('button_more')}</a>
                                                        </h4>
                                                        <div className="notification-box" style={{marginBottom: '20px'}}>
                                                            <ul>
                                                                {renderReview()}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ProjectRequestBar
                                        data={project}
                                        onOrdinalChanged={onOrdinalChanged}
                                        onRequestPressed={onRequestPressed}/>
                                </div> :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ProjectIntroScreen;