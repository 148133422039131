//
//  layout.js
//  datatown
//
//  Created by Mumakil on 2022. 10. 14..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
} from 'react';

// component
import Header from 'components/layout/header';
import Footer from 'components/layout/footer';
import PageLoader from 'components/indicator/pageLoader';


/**
 * @protocol Layout
 * @date 2022/10/14
 * @brief 화면 레이아웃
 */
export default function Layout(props) {
    const [createKakao, setCreateKakao] = useState(false);
    
    useEffect(() => {
        if( !createKakao ) {
            const script = document.createElement('script');
            script.async = true;

            try {
                if( window.Kakao ) {
                    const kakao = window.Kakao;
        
                    if( !kakao.isInitialized() ) {
                        kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
                    }
                }

                window.Kakao.Channel.createChatButton({
                    container: '#kakao-talk-channel-chat-button',
                    channelPublicId: process.env.REACT_APP_KAKAO_CHANNEL_ID,
                });
                
                setCreateKakao(true);
            } catch (error) {
                console.log(error);
            }
        }
        
    }, [createKakao, props.visibleKakao]);

    return (
        <div>
            {props.visibleLoader ? <PageLoader/> : null}
            {props.visibleHeader ?
            <Header visibleSolutionHeader={props.visibleSolutionHeader}
                solutionLocation={props.location}/> :
            null}
            
            <div className={props.visibleHeader ? (props.visibleSolutionHeader ? "main-solution-body" : "main-body") : ""}>
                {props.children}
                <div id="kakao-talk-channel-chat-button"
                    data-channel-public-id={process.env.REACT_APP_KAKAO_CHANNEL_ID}
                    data-title="consult"
                    data-size="small"
                    data-color="yellow"
                    data-shape="pc"
                    data-support-multiple-densities="true"
                    style={{
                        position: 'fixed',
                        right: '10px',
                        bottom: (props.existBottomBar ? '120px' : '50px'),
                        zIndex: '9999999'
                    }}/>
            </div>

            {props.visibleFooter ? <Footer/> : null}
        </div>
    );
}
