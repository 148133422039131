//
//  tableKey.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 28..
//  Copyright © 2022년 Melephant. All rights reserved.
//


/**
 * @protocol TableKey
 * @date 2022/11/28
 * @brief 테이블 키값
 */
export const TableKey = {
    TABLE_ORDINARY:     'table_ordinary',
    TABLE_MENTOR:       'table_mentor',
    TABLE_PERIOD:       'table_period',
    TABLE_STATUS:       'table_status',
    TABLE_NAME:         'table_name',
    TABLE_CONTACT:      'table_contact',
    TABLE_ETC:          'table_etc',
    TABLE_WEEKS:        'table_weeks',
    TABLE_SCHEDULE:     'table_schedule',
    TABLE_SCHEDULE_STATUS:  'table_schedule_status',
    TABLE_ATTENDANCE:   'table_attendance',
    TABLE_CONTENT:      'table_content',
    TABLE_SENDER:       'table_sender',
    TABLE_SEND_DATE:    'table_send_date',
    TABLE_ANSWER_COUNT: 'table_answer_count',
    TABLE_PRICE:        'table_price',
    TABLE_ORDINAL:      'table_ordinal',
    TABLE_REGDATE:      'table_regdate',
    TABLE_CONFIRMDATE:  'table_confirmdate',
    TABLE_SUBJECT:      'table_subject',
    TABLE_ANSWER_EXIST: 'table_answer_exist',
    TABLE_CATEGORY:     'table_category',
    TABLE_APPROVED_DATE:'table_approved_date',
    TABLE_CANCEL:       'table_cancel',
    TABLE_CANCEL_DATE:  'table_cancel_date',
    TABLE_CANCEL_REASON:'table_cancel_reason',
};
