//
//  popupView.js
//  datatown
//
//  Created by Mumakil on 2022. 8. 16..
//  Copyright © 2022년 Melephant. All rights reserved.
//


import React, {
    useState,
    useEffect,
} from 'react';

// common
import ConstantValue from 'constants/value';
import { PopupTypeKey } from 'constants/key';

// util
import DataUtil from 'util/dataUtil';

// package
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';


/**
 * @protocol PopupView
 * @date 2022/08/16
 * @brief 팝업 화면 객체
 */
export default function PopupView(props) {
    const [visible, setVisible] = useState(false);
    const [animate, setAnimate] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        // 화면 표시되지 않고, 데이터가 있을 때는 표시 전환
        if( !visible && !animate && props.info !== null ) {
            setVisible(true);
            if( props.info.type === PopupTypeKey.POPUP_TYPE_TOAST ) {
                setTimeout(() => {
                    // 부모에게 닫힘 알림
                    if( props.onPopupClosed ) {
                        props.onPopupClosed();
                    }

                    // 표시 해제 처리
                    setVisible(false);
                }, ConstantValue.ANIMATION_TIME_TOAST_POPUP);
            }
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props, props.info]);

    /**
     * 확인 버튼 클릭시 호출
     */
    const onConfirmPressed = () => {
        setAnimate(true);

        // callback 호출
        if( props.info.confirmCallback ) {
            props.info.confirmCallback();
        }

        // 부모에게 닫힘 알림
        if( props.onPopupClosed ) {
            props.onPopupClosed();
        }

        // 표시 해제 처리
        setVisible(false);

        // 애니메이션 종료 설정
        setTimeout(() => {
            setAnimate(false);
        }, ConstantValue.ANIMATION_TIME_POPUP);
    };

    /**
     * 취소 버튼 클릭시 호출
     */
    const onCancelPressed = () => {
        setAnimate(true);
        
        // callback 호출
        if( props.info.cancelCallback ) {
            props.info.cancelCallback();
        }

        // 부모에게 닫힘 알림
        if( props.onPopupClosed ) {
            props.onPopupClosed();
        }

        // 표시 해제 처리
        setVisible(false);
        
        // 애니메이션 종료 설정
        setTimeout(() => {
            setAnimate(false);
        }, ConstantValue.ANIMATION_TIME_POPUP);
    };

    // 팝업 정보가 있으면 화면 표시, 없으면 표시 안함
    if( props.info ) {
        return (
            <Modal
                show={visible}
                onHide={onCancelPressed}
                backdrop="static"
                keyboard={false}
                style={{zIndex: 99999999}}>
                {
                    DataUtil.validateString(props.info.title) ?
                    <Modal.Header>
                        <Modal.Title>{props.info.title}</Modal.Title>
                    </Modal.Header> :
                    null
                }
                <Modal.Body style={{whiteSpace: 'pre-wrap'}}>
                    {props.info.message}
                </Modal.Body>
                <Modal.Footer>
                {
                    props.info.type === PopupTypeKey.POPUP_TYPE_CANCEL ?
                    <Button className="canceled button outline-primary" variant="secondary" onClick={onCancelPressed}>
                        {
                            DataUtil.validateString(props.info.cancel) ?
                            props.info.cancel :
                            t('button_cancel')
                        }
                    </Button> :
                    null
                }
                <Button className="primary button" onClick={onConfirmPressed}>
                    {
                        DataUtil.validateString(props.info.confirm) ?
                        props.info.confirm :
                        t('button_confirm')
                    }
                </Button>
                </Modal.Footer>
            </Modal>
        );
    }
	else {
        return null;
    }
}
