//
//  applyScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 10. 24..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
	useState,
	useEffect,
} from 'react';

// common
import ConstantValue from 'constants/value';
import ConstantPath from 'constants/path';
import {
	CommonKey,
	ReplaceKey,
	ApplyTabKey,
	HelpFaqMenuKey,
	ApplyStatusKey,
} from 'constants/key';
import {
	CommonList,
	ApplyList,
	FaqList,
} from 'constants/list';

// util
import ListUtil from 'util/listUtil';
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkAuth from 'network/networkAuth';
import NetworkUser from 'network/networkUser';
import NetworkStatus from 'network/common/networkStatus';

// info
import { RESPONSEINFO_CONTAINER } from 'info/common/responseInfo';
import CounselTimeInfo from 'info/counsel/counselTimeInfo';
import TendencyInfo from 'info/user/tendencyInfo';
import UserInfo, {
    USERINFO_CONTAINER,
} from 'info/user/userInfo';
import { APPLYINFO_CONTAINER } from 'info/user/applyInfo';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault, popupShowDefaultCancel } from 'redux/actions/popupAction';
import { sessionUpdate } from 'redux/actions/sessionAction';

// component
import Layout from 'components/layout/layout';
import ImagePicker from 'components/image/imagePicker';
import WeekTimetableInput from 'components/input/weekTimetableInput';
import CategoryInput from 'components/input/categoryInput';

// package
import { Form, Button } from 'react-bootstrap';
import { useLocation, useNavigate, Link } from 'react-router-dom';


/**
 * 이메일 인증 상태
 */
const AUTH_EMAIL_STATE = {
	AUTH_EMAIL_STATE_UNAUTH:	'auth_email_state_unauth',		// 미인증
	AUTH_EMAIL_STATE_SEND:		'auth_email_state_send',		// 인증 메일 발송
	AUTH_EMAIL_STATE_COMPLETE:	'auth_email_state_complete',	// 인증 코드 확인
};


/**
 * @protocol ApplyScreen
 * @date 2022/10/24
 * @brief 사수 신청 화면
 */
function ApplyScreen() {
    const { t } = useTranslation();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const location = useLocation();

	var user = useSelector(state => state.session.user);
	const isLogin = useSelector(state => state.session.isLogin);
	
	if( !user ) {
		user = new UserInfo();
	}

	const dispositionList = CommonList.DispositionList();
	const weekList = CommonList.WeekList();
	const mentorContentList = ApplyList.MentorContentList();
	const mentorTagList = ApplyList.MentorTagList();
	const exampleContentList = ApplyList.ExampleContentList();
	const faqList = FaqList.FaqList(HelpFaqMenuKey.HELP_FAQ_MENU_APPLY_MENTOR);
	const mbtiList = ListUtil.generateMBTIList();
	const jobList = ListUtil.generateJobList();
	const tosList = ListUtil.generateTermOfServiceList();
	const actionTypeList = ListUtil.generateActionTypeList();
	const defaultTabId = location.state && location.state[CommonKey.TAB_ID] ? location.state[CommonKey.TAB_ID] : ApplyTabKey.APPLY_TAB_MENTOR;

    // 상담 시간 초기 데이터 설정
    var timeInfoList = [];
    for( var i = 0; i < weekList.length; i++ ) {
        var info = new CounselTimeInfo();
        info.isSelect = false;
		info.weekofday = weekList[i].weekofday;
		info.dayId = weekList[i].id;
		info.dayStr = weekList[i].title;
        timeInfoList.push(info);
    }
    
	var phoneNumber = DataUtil.generatePhoneNumber(user.contact);
	// var bizNumber = DataUtil.generateBizNumber(user.businessNo);

	const isMentor = useSelector(state => state.session.isMentor);

	// 기본정보
	const [authState, setAuthState] = useState(user.jobMailAuthState ? AUTH_EMAIL_STATE.AUTH_EMAIL_STATE_COMPLETE : AUTH_EMAIL_STATE.AUTH_EMAIL_STATE_UNAUTH);
	const [email, setEmail] = useState(user.jobEmail ? user.jobEmail : '');
	const [authCode, setAuthCode] = useState('');
	const [gender, setGender] = useState(user.gender);
    const [nickname, setNickname] = useState(user.nickname);
    const [phone1, setPhone1] = useState(phoneNumber ? phoneNumber[0] : "");
    const [phone2, setPhone2] = useState(phoneNumber ? phoneNumber[1] : "");
    const [phone3, setPhone3] = useState(phoneNumber ? phoneNumber[2] : "");
    const [job, setJob] = useState(user.currentJobCode);
    const [mbti, setMBTI] = useState(user.mbtiCode);
    const [company, setCompany] = useState(user.company);
    const [deskImage, setDeskImage] = useState(null);
    const [introduce, setIntroduce] = useState(user.onelineIntro);

	// 사수정보
	const [termOfService, setTermOfService] = useState(user.empPeriod);
    const [career, setCareer] = useState(user.career);
	const [actionType, setActionType] = useState(user.curriculum);
	// const [bizNumber1, setBizNumber1] = useState(bizNumber ? bizNumber[0] : "");
    // const [bizNumber2, setBizNumber2] = useState(bizNumber ? bizNumber[1] : "");
    // const [bizNumber3, setBizNumber3] = useState(bizNumber ? bizNumber[2] : "");
	const [dispositionDataList, setDispositionDataList] = useState(null);
	const [timeDataList, setTimeDataList] = useState(timeInfoList);
	const [categoryList, setCategoryList] = useState([]);
	
	useEffect(() => {
        if( dispositionDataList === null ) {
            var infoList = [];
            for( var i = 0; i < dispositionList.length; i++ ) {
                var info = new TendencyInfo();
                info.id = dispositionList[i].id;
				info.tendencyCode = dispositionList[i].id;
                info.name = dispositionList[i].title;
                info.isSelect = false;
                infoList.push(info);
            }
            setDispositionDataList(infoList);
        }
    }, [dispositionDataList, dispositionList]);

	/**
	 * 로그인 상태 체크
	 * @returns {Boolean} 로그인 여부
	 */
	const checkLogin = () => {
		if( !isLogin || !user.memberId ) {
			dispatch(popupShowDefaultCancel(t('alert_not_login'), () => {
				setTimeout(() => {
					navigate(ConstantPath.PATH_LOGIN, {
						state: { [CommonKey.REDIRECT]: location.pathname }
					});
				}, 100);
			}), null);
			return false;
		}
		return true;
	}

	const onEmailChanged = ({ target: { value } }) => {
		// 이메일 변경시 인증 초기화
		setEmail(value);
		setAuthState(AUTH_EMAIL_STATE.AUTH_EMAIL_STATE_UNAUTH);
	};
	const onAuthCodeChanged = ({ target: { value } }) => setAuthCode(value);
	const onSendPressed = () => {
		if( DataUtil.validateString(email) ) {
			NetworkAuth.requestSendEmail(() => {
				dispatch(popupShowDefault(t('alert_send_email'), () => {
					setAuthState(AUTH_EMAIL_STATE.AUTH_EMAIL_STATE_SEND);
				}));
			}, (res) => {
				dispatch(popupShowDefault(t('alert_network_error')));
			}, email);
		}
		else {
			dispatch(popupShowDefault(t('alert_not_entered_email')));
		}
	};
	const onAuthPressed = () => {
		if( DataUtil.validateString(authCode) ) {
			NetworkAuth.requestAuthCode(() => {
				dispatch(popupShowDefault(t('alert_complete_auth_code')));
				setAuthState(AUTH_EMAIL_STATE.AUTH_EMAIL_STATE_COMPLETE);
			}, (res) => {
				var response = res[RESPONSEINFO_CONTAINER];
				
				// 인증 실패시 에러 처리
				if( response.code === NetworkStatus.NOT_MATCHED ) {
					dispatch(popupShowDefault(t('alert_invalid_auth_code')));
				}
				else if( response.code === NetworkStatus.EXPIRED ) {
					dispatch(popupShowDefault(t('alert_expired_auth_code')));
					setAuthCode("");
					setAuthState(AUTH_EMAIL_STATE.AUTH_EMAIL_STATE_UNAUTH);
				}
				else {
					dispatch(popupShowDefault(t('alert_network_error')));
				}
			}, email, authCode);
		}
		else {
			dispatch(popupShowDefault(t('alert_not_entered_auth_code')));
		}
	};
    const onGenderPressed = ({ target: { value}}) => setGender(value);
    const onNicknameChanged = ({ target: { value}}) => setNickname(value);
	const onPhone1Changed = ({ target: { value}}) => setPhone1(value);
    const onPhone2Changed = ({ target: { value}}) => setPhone2(value);
    const onPhone3Changed = ({ target: { value}}) => setPhone3(value);
    const onJobPressed = ({ target: { value}}) => setJob(value);
	const onMBTIPressed = ({ target: { value }}) => setMBTI(value);
    const onCompanyChanged = ({ target: { value}}) => setCompany(value);
    const onDeskImageChanged = (image) => setDeskImage(image);
    const onIntroduceChanged = ({ target: { value}}) => setIntroduce(value);

	const onTermsOfServicePressed = ({ target: { value}}) => setTermOfService(value);
	const onCareerChanged = ({ target: { value}}) => setCareer(value);
	const onActionTypePressed = ({ target: { value}}) => setActionType(value);
	// const onBizNumber1Changed = ({ target: { value}}) => setBizNumber1(value);
    // const onBizNumber2Changed = ({ target: { value}}) => setBizNumber2(value);
    // const onBizNumber3Changed = ({ target: { value}}) => setBizNumber3(value);

	/**
	 * form 입력 상태 변경시 호출
	 * @param {*} event 입력 이벤트
	 * @returns 
	 */
	const onFormChanged = (event) => {
		// 로그인 상태 체크
		if( !checkLogin() ) {
			return;
		}
	}

	/**
	 * form 제출 클릭시 호출
	 * @param {*} event 클릭 이벤트
	 * @returns 
	 */
    const onSubmitPressed = (event) => {
        event.preventDefault();

		// 로그인 상태 체크
		if( !isLogin || !user.memberId ) {
			dispatch(popupShowDefaultCancel(t('alert_not_login'), () => {
				setTimeout(() => {
					navigate(ConstantPath.PATH_LOGIN, {
						state: { [CommonKey.REDIRECT]: location.pathname }
					});
				}, 100);
			}), null);
			return;
		}
		else {
			// 로그인 이상없으면 이미 신청한 것이 있는지 체크
			NetworkUser.checkApplyMentor((res) => {
				var applyInfo = res[APPLYINFO_CONTAINER];
				// 이전 신청 내역이 있을때 안내 팝업 표시 및 진행 종료
				if( applyInfo !== null ) {
					switch( applyInfo.applyStatus ) {
						case ApplyStatusKey.APPLY_STATUS_REQUEST:
						case ApplyStatusKey.APPLY_STATUS_ACCEPT: {
							dispatch(popupShowDefault(t('alert_already_apply_mentor')));
							return;
						}
						case ApplyStatusKey.APPLY_STATUS_APPROVAL: {
							dispatch(popupShowDefault(t('alert_already_mentor')));
							return;
						}
						case ApplyStatusKey.APPLY_STATUS_REJECT: {
							dispatch(popupShowDefault(t('alert_reject_mentor').replace(ReplaceKey.REPLACE_PHONE, ConstantValue.DATATOWN_PHONE)
							.replace(ReplaceKey.REPLACE_EMAIL, ConstantValue.DATATOWN_EMAIL)));
							return;
						}
						default: {
							break;
						}
					}
				}
				
				// 이전 신청 내역이 없을때, 신청 진행
				var applyUser = new UserInfo();
				applyUser.memberId = user.memberId;
				applyUser.jobEmail = email;
				applyUser.gender = gender;
				applyUser.nickname = nickname;
				applyUser.contact = phone1 + phone2 + phone3;
				applyUser.currentJobCode = job;
				applyUser.mbtiCode = mbti;
				applyUser.company = company;
				applyUser.onelineIntro = introduce;
				applyUser.deskImgData = deskImage;
				applyUser.empPeriod = termOfService;
				applyUser.curriculum = actionType;
				applyUser.career = career;
		
				// 데스크 사진 필수 체크
				// 데스크 사진 첨부된 것 없고, 이전에 올린 url도 없을때
				// 데스크 사진 삭제했을 때
				if( (!deskImage && !DataUtil.validateString(user.deskImgUrl)) || deskImage === '' ) {
					dispatch(popupShowDefault(t('alert_not_entered_apply_deskimage')));
					return;
				}
				
				// 상담 가능 카테고리 체크
				if( !categoryList || categoryList.length === 0 ) {
					dispatch(popupShowDefault(t('alert_not_entered_apply_category')));
					return;
				}
				
				// 상담 시간 데이터 체크
				var validateInputTimetable = true;
				var selectTimetableList = [];
				timeDataList.forEach(element => {
					if( !element.validateInput() ) {
						validateInputTimetable = false;
					}
					if( element.isSelect ) {
						selectTimetableList.push(element);
					}
				});
		
				// 상담 시간 입력 체크
				if( !validateInputTimetable ) {
					dispatch(popupShowDefault(t('alert_invalid_timetable')));
					return;
				}
		
				// 상담 시간 입력 개수 체크
				if( selectTimetableList.length === 0 ) {
					dispatch(popupShowDefault(t('alert_not_entered_apply_timetable')));
					return;
				}
		
				// 사수성향 입력 체크
				var selectDispositionList = [];
				dispositionDataList.forEach(element => {
					if( element.isSelect ) {
						selectDispositionList.push(element);
					}
				});
		
				if( selectDispositionList.length === 0 ) {
					dispatch(popupShowDefault(t('alert_not_entered_apply_disposition')));
					return;
				}
				
				// 사수 신청
				NetworkUser.applyMemberInfo((res) => {
					// 세션 정보 설정
					dispatch(sessionUpdate(res[USERINFO_CONTAINER]));
					dispatch(popupShowDefault(t('alert_complete_apply_mentor')));
				}, (res) => {
					dispatch(popupShowDefault(t('alert_network_error')));
				}, applyUser, categoryList[0], selectTimetableList, selectDispositionList);
			}, (res) => {
				var response = res[RESPONSEINFO_CONTAINER];
				
				if( response.code === NetworkStatus.ALREADY_EXIST ) {
					dispatch(popupShowDefault(t('alert_already_apply_mentor')));
				}
				else {
					dispatch(popupShowDefault(t('alert_network_error')));
				}
			}, user.memberId);
		}
    };

	/**
     * 항목 클릭시 호출
     * @param {*} event 이벤트
     */
	const onDispositionDataPressed = (event) => {
		// 로그인 상태 체크하여 입력 방지
		// 로그인 팝업은 form 변경시에 표시됨
		if( !isLogin || !user.memberId ) {
			return;
		}

        var index = dispositionDataList.findIndex(element => element.id === event.target.id);
		
        if( index >= 0 ) {
            var updateDataList = dispositionDataList.splice(0, dispositionDataList.length);
            if( !event.target.checked ) {
                updateDataList[index].isSelect = false;
                setDispositionDataList(updateDataList);
            }
            else {
                var count = updateDataList.filter(element => element.isSelect === true).length;
                if( count < ConstantValue.CATEGORY_MENTOR_DISPOSITION_LIMIT ) {
                    updateDataList[index].isSelect = true;
                    setDispositionDataList(updateDataList);
                }
                else {
                    setDispositionDataList(updateDataList);
                }
            }
        }
    };

	/**
	 * 타임테이블 체크 클릭시 호출
	 * @param {String} id 타임테이블 id
	 * @param {Boolean} value 체크 여부
	 */
	const onTimetableCheckPressed = (id, value) => {
		if( !checkLogin() ) {
			return;
		}

		var index = timeDataList.findIndex(element => element.dayId === id);
		var updateDataList = timeDataList.splice(0, timeDataList.length);
		updateDataList[index].isSelect = value;
		setTimeDataList(updateDataList);
    };

	/**
	 * 타임테이블 시작시간 변경시 호출
	 * @param {String} id 타임테이블 id
	 * @param {String} hour 시간
	 * @param {String} min 분
	 */
	const onTimetableStartChanged = (id, hour, min) => {
		if( !checkLogin() ) {
			return;
		}

		var index = timeDataList.findIndex(element => element.dayId === id);
		var updateDataList = timeDataList.splice(0, timeDataList.length);
		updateDataList[index].startHour = hour;
		updateDataList[index].startMin = min;
		setTimeDataList(updateDataList);
	}

	/**
	 * 타임테이블 종료시간 변경시 호출
	 * @param {String} id 타임테이블 id
	 * @param {String} hour 시간
	 * @param {String} min 분
	 */
	const onTimetableFinChanged = (id, hour, min) => {
		if( !checkLogin() ) {
			return;
		}

		var index = timeDataList.findIndex(element => element.dayId === id);
		var updateDataList = timeDataList.splice(0, timeDataList.length);
		updateDataList[index].finHour = hour;
		updateDataList[index].finMin = min;
		setTimeDataList(updateDataList);
	}

	/**
	 * 상담 카테고리 추가시 호출
	 * @param {Array} list 상담 카테고리 목록
	 */
	const insertCategoryList = (list) => {
		if( !checkLogin() ) {
			return;
		}

		var updateCategoryList = categoryList.splice(0, categoryList.length);
		updateCategoryList.push(list);
		setCategoryList(updateCategoryList);
	}

	/**
	 * 상담 카테고리 삭제 클릭시 호출
	 * @param {Number} index 카테고리 데이터 인덱스
	 */
	const deleteCategoryList = (index) => {
		if( !checkLogin() ) {
			return;
		}
		
		var updateCategoryList = categoryList.splice(0, categoryList.length);
		updateCategoryList.splice(index, 1);
		setCategoryList(updateCategoryList);
	}

	/**
     * 카테고리 목록 반환
     * @returns {Array} 카테고리 목록
     */
	const renderCategoryList = () => {
        if( DataUtil.validateArray(dispositionDataList) ) {
            var itemList = [];
            
            for( var i = 0; i < dispositionDataList.length; i++ ) {
                itemList.push(
                    <div key={"keyword" + String(i)}
                        className="col-lg-3 col-md-3 col-sm-4">
                        <div className="group-box" style={{marginBottom: '0px'}}>
                            <input type="checkbox"
                                id={dispositionDataList[i].id}
                                checked={dispositionDataList[i].isSelect ? true : false}
                                onChange={onDispositionDataPressed}/>
                            <label htmlFor={dispositionDataList[i].id}>{dispositionDataList[i].name}</label> 
                        </div>
                    </div>
                );
            }
            return itemList;
        }
        return null;
    }

	/**
     * 사수 메뉴 컨텐츠 목록 반환
     * @returns {Array} 컨텐츠 목록
     */
	const renderMentorContentList = () => {
        var itemList = [];

        for( var i = 0; i < mentorContentList.length; i++ ) {
            var content = mentorContentList[i];
            
			if( i !== 0 ) {
				itemList.push(<br key={"br_mentor" + String(i) + "1"}/>);
                itemList.push(<br key={"br_mentor" + String(i) + "2"}/>);
			}

			if( DataUtil.validateString(content.image) ) {
				itemList.push(<figure key={"figure_mentor" + String(i)}><img src={content.image} alt=""/></figure>);
			}

            if( DataUtil.validateString(content.title) ) {
                itemList.push(<h4 key={"h4_mentor" + String(i)}>{content.title}</h4>);
				itemList.push(<br key={"br_mentor" + String(i) + "3"}/>);
            }

			if( DataUtil.validateArray(content.list) ) {
				for( var j = 0; j < content.list.length; j++ ) {
					if( DataUtil.validateString(content.list[j].title) ) {
						itemList.push(<b key={"b_mentor" + String(i) + String(j)}>{content.list[j].title}</b>);
						itemList.push(<br key={"br_mentor" + String(i) + String(j) + "1"}/>);
					}

					if( DataUtil.validateString(content.list[j].description) ) {
						itemList.push(<p style={{whiteSpace: 'pre-wrap'}} key={"p_mentor" + String(i) + String(j)}>{content.list[j].description}</p>);
					}
				}
			}
        }
        return itemList;
    }

	/**
     * 태그 목록 반환
     * @returns {Array} 태그 목록
     */
	const renderTagList = () => {
		var itemList = [];

		if( DataUtil.validateArray(mentorTagList) ) {
			for( var i = 0; i < mentorTagList.length; i++ ) {
				if( DataUtil.validateString(mentorTagList[i].title) ) {
					itemList.push(	<li key={'tag' + String(i)}>
										<a href="#!" title="" data-ripple="" onClick={(e) => {
											e.preventDefault();
										}}>
											{mentorTagList[i].title}
										</a>
									</li>);
				}
			}
		}
		return itemList;
	}

	/**
     * 사례 메뉴 컨텐츠 목록 반환
     * @returns {Array} 컨텐츠 목록
     */
	const renderExampleContentList = () => {
        var itemList = [];

        for( var i = 0; i < exampleContentList.length; i++ ) {
            var content = exampleContentList[i];
            
			if( DataUtil.validateString(content.title) ) {
				itemList.push(	<div className="post-detail"
									key={'example' + String(i)}>
									{
										i !== 0 ?
										<br/> :
										null
									}
									{
										DataUtil.validateString(content.image) ?
										<figure><img src={content.image} alt=""/></figure> :
										null
									}
									<h4>{content.title}</h4>
									{
										DataUtil.validateString(content.description) ?
										<p style={{whiteSpace: 'pre-wrap'}}><b>{content.description}</b></p> :
										null
									}
								</div>);
			}
        }
        return itemList;
    }

	/**
     * 질문 메뉴 컨텐츠 목록 반환
     * @returns {Array} 컨텐츠 목록
     */
	const renderFaqContentList = () => {
        var itemList = [];

		var i = 0;
		faqList.forEach(element => {
			if( DataUtil.validateString(element.title) && DataUtil.validateString(element.description) ) {
				itemList.push(	<div className="card"
									key={"faq_card" + String(i)}>
									<div className="card-header" id={"heading" + String(i)}>
										<h5 className="mb-0">
										<button className="btn btn-link"
											data-toggle="collapse"
											data-target={"#collapse" + String(i)}
											aria-expanded="true"
											aria-controls={"#collapse" + String(i)}>
											{element.title}
										</button>
										</h5>
									</div>
									<div id={"collapse" + String(i)}
										className="collapse"
										aria-labelledby={"heading" + String(i)}
										data-parent="#accordion">
										<div className="card-body">
										<p style={{whiteSpace: 'pre-wrap'}}>{element.description}</p>
										{
											DataUtil.validateString(element.link) ?
											<>
												<br/>
												<Link to={element.url}
													state={element.state}
													onClick={() => {
														if( element.url === location.pathname ) {
															window.location.reload(false);
														}
													}}
													style={{color: '#F26625'}}>
													{element.link}
												</Link>
											</>:
											null
										}
										</div>
									</div>
								</div>);
				i++;
			}
        });
        return itemList;
    }

    return (
		<Layout
            visibleHeader={true}
            visibleFooter={true}
			visibleSolutionHeader={true}
			location={location.pathname}>
			<div className="gap no-gap" style={{textAlign: 'left'}}>
                <div className="top-area mate-black low-opacity">
                    <div className="bg-image" style={{backgroundImage: 'url(assets/images/image/img_bg_top.jpg)'}}></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="post-subject">
                                    <a className="tagz" href="#!" title="" onClick={e => e.preventDefault()}>{t('apply_recruit_mentor')}</a>
                                    <h4>{t('apply_title')}</h4>
                                    <ul className="nav nav-tabs post-detail-btn">
                                        <li className="nav-item">
                                            <a className={defaultTabId === ApplyTabKey.APPLY_TAB_MENTOR ? "active" : ""}
												href={"#" + ApplyTabKey.APPLY_TAB_MENTOR}
												data-toggle="tab">
                                                {t('apply_section_mentor')}
                                            </a>
                                        </li>
                                        <li className="nav-item">
											<a className={defaultTabId === ApplyTabKey.APPLY_TAB_EXAMPLE ? "active" : ""}
												href={"#" + ApplyTabKey.APPLY_TAB_EXAMPLE}
												data-toggle="tab">
                                                {t('apply_section_example')}
                                            </a>
                                        </li>
										<li className="nav-item">
											<a className={defaultTabId === ApplyTabKey.APPLY_TAB_FAQ ? "active" : ""}
												href={"#" + ApplyTabKey.APPLY_TAB_FAQ}
												data-toggle="tab">
                                                {t('apply_section_faq')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="gap" style={{textAlign: 'left'}}>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div id="page-contents" className="row merged20">
									<div className="col-lg-8">
										<div className="tab-content">
											<div className={"tab-pane fade" + (defaultTabId === ApplyTabKey.APPLY_TAB_MENTOR ? " active show" : "")}
												id={ApplyTabKey.APPLY_TAB_MENTOR}>
												<div className="main-wraper">
													<div className="post-detail">
														<figure><img src="assets/images/image/img_mentor_01.png" alt=""/></figure>
														<h4>{t('apply_mentor_title')}</h4>
														<p style={{whiteSpace: 'pre-wrap'}}>{t('apply_mentor_description')}</p>
														<div className="blog-details-meta">
															{renderMentorContentList()}
														</div>
													</div>
												</div>
											</div>
											<div className={"tab-pane fade" + (defaultTabId === ApplyTabKey.APPLY_TAB_EXAMPLE ? " active show" : "")}
												id={ApplyTabKey.APPLY_TAB_EXAMPLE}>
												<div className="main-wraper">
													<div className="post-detail">
														{renderExampleContentList()}
													</div>
												</div>
											</div>
											<div className={"tab-pane fade" + (defaultTabId === ApplyTabKey.APPLY_TAB_FAQ ? " active show" : "")}
												id={ApplyTabKey.APPLY_TAB_FAQ}>
												<div className="main-wraper">
													<h4 className="main-title">{t('apply_faq_title')}</h4>
													<p>
														<i className="icofont-question-circle"></i> {t('apply_faq_description')}
													</p>
													<div className="question-collaps">
														<div id="accordion">
															{renderFaqContentList()}
														</div>
													</div>
												</div>
											</div>
										</div>
										<h5 className="tags-title">{t('apply_mentor_tag_title')}</h5>
										<ul className="tags">
											{renderTagList()}
										</ul>
										{
											!isMentor ?
											<div className="main-wraper">
												<div className="verticle-center">
													<div className="signup-form">
														<h4><i className="icofont-lock"></i> {t('apply_title')}</h4>
														<h6 className="mb-4" style={{whiteSpace: 'pre-wrap'}}>{t('apply_guide')}</h6>
														<Form className="c-form" onSubmit={onSubmitPressed} onChange={onFormChanged}>
															<h4>
																<b>{t('apply_input_basic')}</b>
															</h4>
															<div className="row merged-10">
																<div className="col-lg-9">
																	<input className="uk-input"
																		style={{backgroundColor: '#fff'}}
																		type="text"
																		value={email}
																		placeholder={t('input_email_company')}
																		required
																		maxLength={ConstantValue.INPUT_MAX_LENGTH_EMAIL}
																		onChange={onEmailChanged}/>
																</div>
																<div className='col-lg-3'>
																	<Button className="button primary circle"
																		style={{height: '70%', width: '150px'}}
																		onClick={onSendPressed}>
																		{t('button_auth_send')}
																	</Button>
																</div>
																{
																	authState === AUTH_EMAIL_STATE.AUTH_EMAIL_STATE_UNAUTH ?
																	null :
																	<>
																		<div className="col-lg-9">
																			<input className="uk-input"
																				style={{backgroundColor: '#fff'}}
																				type="text"
																				value={authCode}
																				placeholder={t('input_authcode')}
																				maxLength={ConstantValue.INPUT_MAX_LENGTH_EMAIL}
																				onChange={onAuthCodeChanged}/>
																		</div>
																		<div className='col-lg-3'>
																			{
																				authState === AUTH_EMAIL_STATE.AUTH_EMAIL_STATE_SEND ?
																				<Button className="button primary circle"
																					style={{height: '70%', width: '150px'}}
																					onClick={onAuthPressed}>
																					{t('button_auth')}
																				</Button> :
																				<Button className="button primary circle"
																					style={{height: '70%', width: '150px'}}>
																					{t('button_auth_complete')}
																				</Button>
																			}
																		</div>
																	</>
																}
																<div className="gender mb-2">
																	<label>{t('input_phone')}</label>
																	<div className="row">
																		<div className="col-lg-2">
																			<input type="text"
																				style={{backgroundColor: '#fff'}}
																				value={phone1}
																				pattern="[0-9]+"
																				placeholder={t('input_phone1')}
																				required
																				maxLength={ConstantValue.INPUT_MAX_LENGTH_PHONE1}
																				onChange={onPhone1Changed}/>
																		</div>
																		<div className="col-lg-0.5">
																			<h4>-</h4>
																		</div>
																		<div className="col-lg-3">
																			<input type="text"
																				style={{backgroundColor: '#fff'}}
																				value={phone2}
																				pattern="[0-9]+"
																				placeholder={t('input_phone2')}
																				required
																				maxLength={ConstantValue.INPUT_MAX_LENGTH_PHONE2}
																				onChange={onPhone2Changed}/>
																		</div>
																		<div className="col-lg-0.5">
																			<h4>-</h4>
																		</div>
																		<div className="col-lg-3">
																			<input type="text"
																				style={{backgroundColor: '#fff'}}
																				value={phone3}
																				pattern="[0-9]+"
																				placeholder={t('input_phone3')}
																				required
																				maxLength={ConstantValue.INPUT_MAX_LENGTH_PHONE3}
																				onChange={onPhone3Changed}/>
																		</div>
																	</div>
																</div>
																<div className="col-lg-12">
																	<div className="gender">
																		<input
																			type="radio"
																			id="male"
																			name="gender"
																			value={ConstantValue.USER_GENDER_MALE}
																			required
																			defaultChecked={gender === ConstantValue.USER_GENDER_MALE}
																			onClick={onGenderPressed}/>
																		<label htmlFor="male">{t('input_male')}</label>
																		<input
																			type="radio"
																			id="female"
																			name="gender"
																			value={ConstantValue.USER_GENDER_FEMALE}
																			required
																			defaultChecked={gender === ConstantValue.USER_GENDER_FEMALE}
																			onClick={onGenderPressed}/>
																		<label htmlFor="female">{t('input_female')}</label>
																	</div>
																</div>
																<div className="col-lg-6">
																	<input type="text"
																		style={{backgroundColor: '#fff'}}
																		value={nickname}
																		placeholder={t('input_nickname')}
																		required
																		maxLength={ConstantValue.INPUT_MAX_LENGTH_NICKNAME}
																		onChange={onNicknameChanged}/>
																</div>
																<div className="col-lg-6">
																	<select aria-required
																		style={{backgroundColor: '#fff'}}
																		defaultValue={user.mbtiCode}
																		required
																		onChange={onMBTIPressed}>
																		{mbtiList}
																	</select>
																</div>
																<div className="col-lg-6">
																	<input type="text"
																		style={{backgroundColor: '#fff'}}
																		value={company}
																		placeholder={t('input_company')}
																		required
																		maxLength={ConstantValue.INPUT_MAX_LENGTH_COMPANY}
																		onChange={onCompanyChanged}/>
																</div>
																<div className="col-lg-6">
																	<select required
																		style={{backgroundColor: '#fff'}}
																		defaultValue={user.currentJobCode}
																		onChange={onJobPressed}>
																		{jobList}
																	</select>
																</div>
																<div className="col-lg-12">
																	<input type="text"
																		style={{backgroundColor: '#fff'}}
																		value={introduce}
																		placeholder={t('input_introduce')}
																		required
																		maxLength={ConstantValue.INPUT_MAX_LENGTH_INTRODUCE}
																		onChange={onIntroduceChanged}/>
																</div>
																<div className="gender mb-2">
																	<label>{t('input_deskimage')}</label>
																	<ImagePicker
																		defaultImageUrl={user.deskImgUrl}
																		onImageChanged={onDeskImageChanged}/>
																</div>
															</div>
															<h4>
																<b>{t('apply_input_member')}</b>
															</h4>
															<div className="row merged-10">
																<div className="col-lg-6">
																	<select aria-required
																		style={{backgroundColor: '#fff'}}
																		defaultValue={user.empPeriod}
																		required
																		onChange={onTermsOfServicePressed}>
																		{tosList}
																	</select>
																</div>
																<div className="col-lg-6">
																	<select aria-required
																		style={{backgroundColor: '#fff'}}
																		defaultValue={user.curriculum}
																		required
																		onChange={onActionTypePressed}>
																		{actionTypeList}
																	</select>
																</div>
																<div className="col-lg-12">
																	<textarea placeholder={t('input_career')}
																		style={{backgroundColor: '#fff'}}
																		rows="4"
																		defaultValue={user.career}
																		className="uk-textarea"
																		required
																		onChange={onCareerChanged}></textarea>
																</div>
																<div className="col-lg-12">
																	<label>{t('input_category')}</label>
																	<div className="">
																		<CategoryInput
																			data={categoryList}
																			insertData={insertCategoryList}
																			deleteData={deleteCategoryList}/>
																	</div>
																</div>
																<div className="gender mb-2">
																	<label>{t('input_disposition')}</label>
																	<div className="row col-xs-6">
																		{renderCategoryList()}
																	</div>
																</div>
																<div className="gender mb-6">
																	<label>{t('input_timetable')}</label>
																	<div className="row col-xs-12">
																		<WeekTimetableInput
																			data={timeDataList}
																			onCheckPressed={onTimetableCheckPressed}
																			onStartChanged={onTimetableStartChanged}
																			onFinChanged={onTimetableFinChanged}/>
																	</div>
																</div>
															</div>
															<div className="col-lg-12">
																<Button className="main-btn" type="submit">
																	<i className="icofont-key"></i> {t('button_sumbit')}
																</Button>
															</div>
														</Form>
													</div>
												</div>
											</div> :
											null
										}
									</div>
									<div className="col-lg-4">
										<aside className="sidebar static right">
											<div className="widget">
												<h4 className="widget-title">{t('apply_widget_mentor_title')}</h4>
												<div className="ask-question">
													<i className="icofont-question-circle"></i>
													<h6>{t('apply_widget_mentor_description')}</h6>
													<a href={ConstantPath.PATH_SOLUTION_APPLY} title="">{t('button_help_mentor')}</a>
												</div>
											</div>
											<div className="widget">
												<h4 className="widget-title">{t('apply_widget_webinar_title')}</h4>
												<div className="rec-events bg-purple">
													<i className="icofont-gift"></i>
													<h6><a title="" href="/">{t('apply_widget_webinar_content1')}</a></h6>
													<img alt="" src="assets/images/image/img_clock.png"/>
												</div>
												<div className="rec-events bg-blue">
													<i className="icofont-microphone"></i>
													<h6><a title="" href="/">{t('apply_widget_webinar_content2')}</a></h6>
													<img alt="" src="assets/images/image/img_clock.png"/>
												</div>
											</div>
										</aside>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
		
    )
}

export default ApplyScreen;