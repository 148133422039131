//
//  counselInvoiceScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 30..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
    useRef,
} from 'react';

// common
import ConstantValue from 'constants/value';
import ConstantPath from 'constants/path';
import { ReplaceKey } from 'constants/key';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkPayment from 'network/networkPayment';

// info
import {
    PAYMENTINFO_CONTAINER,
    PAYMENTINFO_ORDER_ID,
} from 'info/payment/paymentInfo';
import { PROJECTINFO_CONTAINER } from 'info/solution/projectInfo';
import { COUNSELAPPLYINFO_CONTAINER } from 'info/solution/counselApplyInfo';

// redux
import { useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';

// component
import Layout from 'components/layout/layout';

// package
import { useLocation, useNavigate } from "react-router-dom";
import ReactToPrint from 'react-to-print';


/**
 * @protocol CounselInvoiceScreen
 * @date 2022/11/30
 * @brief 팀프로젝트 결제 영수증 화면
 */
function CounselInvoiceScreen(props) {
    const { t } = useTranslation();

    const printRef = useRef();
    
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const orderId = location.state[PAYMENTINFO_ORDER_ID];
    
    const [payment, setPayment] = useState(null);
    const [project, setProject] = useState(null);
    const [apply, setApply] = useState(null);


    useEffect(() => {
        if( payment === null && DataUtil.validateString(orderId) ) {
            NetworkPayment.getCounselPaymentInfo((res) => {
                setPayment(res[PAYMENTINFO_CONTAINER]);
                setProject(res[PROJECTINFO_CONTAINER]);
                setApply(res[COUNSELAPPLYINFO_CONTAINER]);
            }, (res) => {
                dispatch(popupShowDefault(t('alert_network_error')));
            }, orderId);
        }
    }, [dispatch, navigate, t, payment, orderId]);

    const onPrintPressed = () => {
        let printContent = printRef.current;
        let windowObj = window.open(
            '',
            'Print',
            'width=1350, height=800, toolbars=no, scrollbars=no, status=no, resizable=no'
        );
        windowObj.document.writeln(printContent.innerHTML);
        windowObj.document.close();
        windowObj.focus();
        windowObj.print();
        windowObj.close();
    }

    return (
        <Layout
            visibleHeader={false}
            visibleFooter={false}>
            <div className="theme-layout gray-bg"
                ref={printRef}>
                {
                    payment ?
                    <div className="container" style={{textAlign: 'left'}}>
                        <div className="row justify-content-md-center">
                            <div className="col-lg-8">
                                <div className="invoice">
                                    <div className="invoice-head">
                                        <figure><img src="images/logo2.png" alt=""/></figure>
                                        <h6>{t('counsel_invoice_title')}</h6>
                                    </div>
                                    <div className="invoice-meta">
                                        <div className="create-date">
                                            <div className="print-share">
                                                <ReactToPrint
                                                    trigger={() =>  <button className="print_btn"
                                                                        onClick={onPrintPressed}>
                                                                        <i className="icofont-print"></i> {t('button_print')}
                                                                    </button>}
                                                    content={() => printRef.current}/>
                                            </div>
                                            <ul>
                                                <li>
                                                    <span>{t('counsel_invoice_date') + ' :'}</span>
                                                    {' ' + DataUtil.convertDateTimeToString(payment.approveddate)}
                                                </li>
                                                <li>
                                                    <span>{t('counsel_invoice_payment_id') + ' :'}</span>
                                                    {' ' + payment.paymentId}
                                                </li>
                                                <li>
                                                    <span>{t('counsel_invoice_participant') + ' :'}</span>
                                                    {' ' + apply.memberName}
                                                </li>
                                                <li>
                                                    <span>{t('counsel_invoice_contact') + ' :'}</span>
                                                    {' ' + apply.contact}
                                                </li>
                                                <li>
                                                    <span>{t('counsel_invoice_category') + ' :'}</span>
                                                    {' ' + project.categoryInfo.name}
                                                </li>
                                                <li>
                                                    <span>{t('counsel_invoice_mentor') + ' :'}</span>
                                                    {' ' + project.mentor.nickname}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="invoice-tofrom">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <p style={{fontWeight: '500', whiteSpace: 'pre-wrap'}}>
                                                        {t('counsel_invoice_guide')
                                                        .replace(ReplaceKey.REPLACE_PHONE, ConstantValue.DATATOWN_PHONE)
                                                        .replace(ReplaceKey.REPLACE_EMAIL, ConstantValue.DATATOWN_EMAIL)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="invoice-item" style={{textAlign: 'center'}}>
                                        <table className="item-tble table table-responsive-sm">
                                            <thead>
                                                <tr>
                                                    <th>{t('project_invoice_column_id')}</th>
                                                    <th>{t('project_invoice_column_item')}</th>
                                                    <th>{t('project_invoice_column_price')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{project.projectId}</td>
                                                    <td>{payment.orderName}</td>
                                                    <td>{t('word_currency') + DataUtil.numberWithCommas(Number(payment.price))}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2"></td>
                                                    <td>
                                                        <div className="user-total">														
                                                            <div className="totalinv2">
                                                                <span>
                                                                    {t('word_currency') + DataUtil.numberWithCommas(Number(payment.price))}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>												
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="invoice-footer">
                                        <a className="main-btn" style={{color: '#fff'}} href={ConstantPath.PATH_SOLUTION_REQUEST}>{t('button_solution')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    null
                }
                
            </div>
        </Layout>
    );
}

export default CounselInvoiceScreen;