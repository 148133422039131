//
//  index.js
//  datatown
//
//  Created by Mumakil on 2022. 8. 11..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React from 'react';
import './index.css';
import App from './App';

// redux
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import PersistReducer from 'redux/reducers/persistReducer';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

// package
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CookiesProvider } from 'react-cookie';

// string
import 'string/i18n';

// controller
import PopupController from 'controller/popupController';

const store = configureStore({
	reducer: PersistReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false,
	}),
});

const persistor = persistStore(store);
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	// <React.StrictMode>
		<BrowserRouter>
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					<CookiesProvider>
						<PersistGate loading={null} persistor={persistor}>
							<App/>
							<PopupController/>
						</PersistGate>
					</CookiesProvider>
				</QueryClientProvider>
			</Provider>
		</BrowserRouter>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
