//
//  networkNotice.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 2..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// common
import { CommonKey } from 'constants/key';

// network
import CommonNetwork from 'network/common/commonNetwork';
import { listParseFromDictionary } from 'info/listParse';

// info
import ResponseInfo, {
    RESPONSEINFO_CONTAINER,
} from 'info/common/responseInfo';
import NoticeInfo, {
    NOTICEINFO_LIST,
    NOTICEINFO_CONTAINER,
} from 'info/solution/noticeInfo';
import ServiceNoticeInfo, {
    SERVICENOTICEINFO_LIST,
    SERVICENOTICEINFO_CONTAINER,
} from 'info/service/serviceNoticeInfo';
import { PROJECTINFO_NAME } from 'info/solution/projectInfo';
import { USERINFO_MEMBER_ID } from 'info/user/userInfo';

// package
import axios from 'axios';
axios.defaults.withCredentials = true;

const NETWORK_PATH_GET_PROJECT_NOTICE_LIST  = '/get_project_notice_list';
const NETWORK_PATH_GET_SERVICE_NOTICE_LIST  = '/get_service_notice_list';
const NETWORK_PATH_GET_PROJECT_NOTICE_INFO  = '/get_project_notice_info';
const NETWORK_PATH_GET_SERVICE_NOTICE_INFO  = '/get_service_notice_info';
const NETWORK_PATH_POST_PROJECT_NOTICE      = '/post_project_notice';
const NETWORK_PATH_DELETE_PROJECT_NOTICE    = '/delete_project_notice';

const NETWORK_PARAM_PROJECT_ID          = 'project_id';
const NETWORK_PARAM_ORDINAL             = 'ordinal';
const NETWORK_PARAM_PROJECT_NOTICE_ID   = 'project_notice_idx';
const NETWORK_PARAM_NOTICE_ID           = 'notice_idx';
const NETWORK_PARAM_PAGE_NUM            = 'page_num';
const NETWORK_PARAM_PAGE_SIZE           = 'page_size';


/**
 * @protocol NetworkNotice
 * @date 2022/12/02
 * @brief 공지사항 관련 통신
 */
export default class NetworkNotice {
    /**
     * 공지사항 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} projectId 팀프로젝트 id
     * @param {String} ordinal 기수
     * @param {Number} pageNum 페이지 번호
     * @param {Number} pageSize 페이지 크기
     */
    static getProjectNoticeList = async (resSuccess, resFail, projectId, ordinal, pageNum, pageSize) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_PROJECT_NOTICE_LIST])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_PROJECT_ID]: projectId,
            [NETWORK_PARAM_ORDINAL]: ordinal,
            [NETWORK_PARAM_PAGE_NUM]: pageNum,
            [NETWORK_PARAM_PAGE_SIZE]: pageSize,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var noticeList = [];
                var projectName = '';
                var memberId = '';
                var totalCount = 0;

                if( responseInfo.data.hasOwnProperty(NOTICEINFO_LIST) ) {
                    noticeList = listParseFromDictionary(NoticeInfo, responseInfo.data[NOTICEINFO_LIST], '');
                }

                if( responseInfo.data.hasOwnProperty(PROJECTINFO_NAME) ) {
                    projectName = responseInfo.data[PROJECTINFO_NAME];
                }

                if( responseInfo.data.hasOwnProperty(USERINFO_MEMBER_ID) ) {
                    memberId = responseInfo.data[USERINFO_MEMBER_ID];
                }

                if( responseInfo.data.hasOwnProperty(CommonKey.PAGE_TOTAL_COUNT) ) {
                    totalCount = responseInfo.data[CommonKey.PAGE_TOTAL_COUNT];
                }

                resSuccess({
                    [NOTICEINFO_LIST]: noticeList,
                    [PROJECTINFO_NAME]: projectName,
                    [USERINFO_MEMBER_ID]: memberId,
                    [CommonKey.PAGE_TOTAL_COUNT]: totalCount,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 공지사항 목록 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {Number} pageNum 페이지 번호
     * @param {Number} pageSize 페이지 크기
     */
    static getServiceNoticeList = async (resSuccess, resFail, pageNum, pageSize) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_SERVICE_NOTICE_LIST])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_PAGE_NUM]: pageNum,
            [NETWORK_PARAM_PAGE_SIZE]: pageSize,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);

                var noticeList = [];
                var totalCount = 0;

                if( responseInfo.data.hasOwnProperty(SERVICENOTICEINFO_LIST) ) {
                    noticeList = listParseFromDictionary(ServiceNoticeInfo, responseInfo.data[SERVICENOTICEINFO_LIST], '');
                }

                if( responseInfo.data.hasOwnProperty(CommonKey.PAGE_TOTAL_COUNT) ) {
                    totalCount = responseInfo.data[CommonKey.PAGE_TOTAL_COUNT];
                }

                resSuccess({
                    [SERVICENOTICEINFO_LIST]: noticeList,
                    [CommonKey.PAGE_TOTAL_COUNT]: totalCount,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 공지사항 정보 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} noticeId 공지사항 id
     */
    static getProjectNoticeInfo = async (resSuccess, resFail, noticeId) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_PROJECT_NOTICE_INFO])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_PROJECT_NOTICE_ID]: noticeId,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                var noticeInfo = new NoticeInfo();
                noticeInfo.parseFromDictionary(responseInfo.data);

                resSuccess({
                    [NOTICEINFO_CONTAINER]: noticeInfo,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 공지사항 정보 조회
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {String} noticeId 공지사항 id
     */
    static getServiceNoticeInfo = async (resSuccess, resFail, noticeId) => {
        var url = CommonNetwork.generateAPIPath([NETWORK_PATH_GET_SERVICE_NOTICE_INFO])
        + CommonNetwork.generateQuery({
            [NETWORK_PARAM_NOTICE_ID]: noticeId,
        });

        try {
            axios.get(url, {}).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                
                var noticeInfo = new ServiceNoticeInfo();
                noticeInfo.parseFromDictionary(responseInfo.data);

                resSuccess({
                    [SERVICENOTICEINFO_CONTAINER]: noticeInfo,
                });
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 공지사항 등록 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {NoticeInfo} noticeInfo 공지사항 정보
     */
    static requestPostNoticeInfo = async (resSuccess, resFail, noticeInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_POST_PROJECT_NOTICE]);

        try {
            await axios.post(url, noticeInfo.dictionaryForRegister()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }

    /**
     * 공지사항 삭제 요청
     * @param {Function} resSuccess 성공시 코드
     * @param {Function} resFail 실패시 코드
     * @param {NoticeInfo} noticeInfo 공지사항 정보
     */
    static requestDeleteNoticeInfo = async (resSuccess, resFail, noticeInfo) => {
        const url = CommonNetwork.generateAPIPath([NETWORK_PATH_DELETE_PROJECT_NOTICE]);

        try {
            await axios.post(url, noticeInfo.dictionaryForDelete()).then((res) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(res.data);
                resSuccess({[RESPONSEINFO_CONTAINER]: responseInfo});
            }).catch((err) => {
                var responseInfo = new ResponseInfo();
                responseInfo.parseFromDictionary(err.response.data);
                resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
            });
        } catch (error) {
            var responseInfo = new ResponseInfo();
            responseInfo.parseFromDictionary(error);
            resFail({[RESPONSEINFO_CONTAINER]: responseInfo});
        }
    }
}
