//
//  commonNetwork.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 9..
//  Copyright © 2022년 Melephant. All rights reserved.
//


export const NETWORK_METHOD_PUT    = 'PUT';
export const NETWORK_METHOD_POST   = 'POST';
export const NETWORK_METHOD_GET    = 'GET';
export const NETWORK_METHOD_DELETE = 'DELETE';


/**
 * @protocol CommonNetwork
 * @date 2022/08/11
 * @brief 서버 통신 공통 객체
 */
export default class CommonNetwork {
    /**
     * 기본 url 반환
     * @returns {string} 서버 url
     */
    static getUrl() {
        return process.env.REACT_APP_SERVER_URL;
    }

    /**
     * url + path 반환
     * @param {array} array path 목록
     */
    static generateAPIPath(array) {
        var url = this.getUrl();

        for( var index in array ) {
            url += array[index];
        }

        return url;
    }

    /**
     * 쿼리 반환
     * @param {dictionary} dict 쿼리 목록
     */
    static generateQuery(dict) {
        var query = '';

        for(var key in dict) {
            if(dict.hasOwnProperty(key)) {
                if( query.length === 0 ) {
                    query += '?';
                }
                else {
                    query += '&';
                }

                query += key + '=' + dict[key];
            }
        }

        return query;
    }

    /**
     * 기본 헤더 반환
     */
    static getHeader() {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
    }

    /**
     * 폼 데이터 헤더 반환
     */
    static getFormDataHeader() {
        return {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        };
    }
}
