//
//  listUtil.js
//  datatown
//
//  Created by Mumakil on 2022. 10. 28..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// common
import { CommonList } from 'constants/list';


/**
 * @protocol ListUtil
 * @date 2022/10/25
 * @brief 목록 생성 도구
 */
export default class ListUtil {
    /**
     * 직군 관련 목록 생성
     * @returns {Array} 목록
     */
    static generateJobList() {
        var itemList = [];
        const jobList = CommonList.JobList();
        
        for( var i = 0; i < jobList.length; i++ ) {
            itemList.push(  <option key={'cj' + String(i)}
                                value={i === 0 ? "" : jobList[i].id}>
                                {jobList[i].title}
                            </option>);
        }
        return itemList;
    }

    /**
     * 재직기간 관련 목록 생성
     * @returns {Array} 목록
     */
    static generateTermOfServiceList() {
        var itemList = [];
        const termOfServiceList = CommonList.TermOfServiceList();
        
        for( var i = 0; i < termOfServiceList.length; i++ ) {
            itemList.push(  <option key={'tos' + String(i)}
                                value={i === 0 ? "" : termOfServiceList[i].id}>
                                {termOfServiceList[i].title}
                            </option>);
        }
        return itemList;
    }

    /**
     * 활동타입 관련 목록 생성
     * @returns {Array} 목록
     */
    static generateActionTypeList() {
        var itemList = [];
        const actionTypeList = CommonList.ActionTypeList();
        
        for( var i = 0; i < actionTypeList.length; i++ ) {
            itemList.push(  <option key={'at' + String(i)}
                                value={i === 0 ? "" : actionTypeList[i].id}>
                                {actionTypeList[i].title}
                            </option>);
        }
        return itemList;
    }

    /**
     * 직군 관련 목록 생성
     * @returns {Array} 목록
     */
    static generateMBTIList() {
        var itemList = [];
        const mbtiList = CommonList.MBTIList();
        
        for( var i = 0; i < mbtiList.length; i++ ) {
            itemList.push(  <option key={'mbti' + String(i)}
                                value={i === 0 ? "" : mbtiList[i].id}>
                                {mbtiList[i].title}
                            </option>);
        }
        return itemList;
    }

    /**
     * 성향 관련 목록 생성
     * @returns {Array} 목록
     */
    static generateDispositionList() {
        var itemList = [];
        const dispositionList = CommonList.DispositionList();
        
        for( var i = 0; i < dispositionList.length; i++ ) {
            itemList.push(  <option key={'disposition' + String(i)}
                                value={i === 0 ? "" : dispositionList[i].id}>
                                {dispositionList[i].title}
                            </option>);
        }
        return itemList;
    }

    /**
     * 은행 관련 목록 생성
     * @returns {Array} 목록
     */
    static generateBankList() {
        var itemList = [];
        const bankList = CommonList.BankList();
        
        for( var i = 0; i < bankList.length; i++ ) {
            itemList.push(  <option key={'bank' + String(i)}
                                value={i === 0 ? "" : bankList[i].id}>
                                {bankList[i].title}
                            </option>);
        }
        return itemList;
    }

    /**
     * 1:1 문의 주제 관련 목록 생성
     * @returns {Array} 목록
     */
    static generateM2mSubjectList() {
        var itemList = [];
        const subjectList = CommonList.M2mSubjectList();
        
        for( var i = 0; i < subjectList.length; i++ ) {
            itemList.push(  <option key={'subject' + String(i)}
                                value={i === 0 ? "" : subjectList[i].title}>
                                {subjectList[i].title}
                            </option>);
        }
        return itemList;
    }
}
