//
//  blogListScreen.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 18..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
} from 'react';

// common
import { CommonKey } from 'constants/key';
import ConstantValue from 'constants/value';
import ConstantPath from 'constants/path';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkBlog from 'network/networkBlog';

// info
import { BLOGINFO_LIST } from 'info/blog/blogInfo';

// redux
import { useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';

// component
import Layout from 'components/layout/layout';
import PageList from 'components/list/pageList';
import BlogCell from 'components/cell/blogCell';

// package
import { useNavigate, useLocation } from "react-router-dom";


/**
 * @protocol BlogListScreen
 * @date 2023/01/18
 * @brief 블로그 목록 화면
 */
function BlogListScreen(props) {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [dataList, setDataList] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    
    // 데이터 요청 처리
    const fetchData = async (pageNum, pageSize) => {
        NetworkBlog.getBlogList((res) => {
            setDataList(res[BLOGINFO_LIST]);
            setTotalCount(res[CommonKey.PAGE_TOTAL_COUNT]);
        }, (res) => {
            dispatch(popupShowDefault(t('alert_network_error')));
        }, pageNum, pageSize);
    };

    /**
     * 프로젝트 셀 ui 렌더링
     * @param {NoticeInfo} info 공지사항 정보
     * @returns {NoticeCell} 공지사항 셀
     */
    const renderContent = (info) => {
        return  <div className="col-lg-12"
                    key={"cell" + String(info.blogId)}>
                    <BlogCell key={String(info.blogId)}
                        data={info}
                        onCellPressed={(id) => {
                            var path = ConstantPath.PATH_SOLUTION_BLOG_DETAIL.split("/");
                            var strippedPath = path.slice(0, path.length-1).join("/");
                            navigate(strippedPath + '/' + id);
                        }}/>
                </div>;
    }

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}
            visibleSolutionHeader={true}
            location={location.pathname}>
			<div className="gap" style={{textAlign: 'left'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div id="page-contents" className="row merged20">
                                <div className="col-lg-8">
                                    <div className="main-wraper">
                                        <div className="main-title">{t('blog_list_title')}</div>
                                        <PageList
                                            dataList={dataList}
                                            fetchData={fetchData}
                                            pageSize={ConstantValue.BLOG_PAGE_SIZE}
                                            pageRangeDisplayed={5}
                                            totalCount={totalCount}
                                            renderCell={renderContent}/>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <aside className="sidebar static right">
                                        <div className="widget">
                                            <h4 className="widget-title">{t('nft_widget_mentor_title')}</h4>
                                            <div className="ask-question">
                                                <i className="icofont-question-circle"></i>
                                                <h6>{t('nft_widget_mentor_description')}</h6>
                                                <a href={ConstantPath.PATH_SOLUTION_APPLY} title="">{t('button_help_mentor')}</a>
                                            </div>
                                        </div>
                                        <div className="widget">
                                            <h4 className="widget-title">{t('nft_widget_webinar_title')}</h4>
                                            <div className="rec-events bg-purple">
                                                <i className="icofont-gift"></i>
                                                <h6><a title="" href="/">{t('nft_widget_webinar_content1')}</a></h6>
                                                <img alt="" src="assets/images/image/img_clock.png"/>
                                            </div>
                                            <div className="rec-events bg-blue">
                                                <i className="icofont-microphone"></i>
                                                <h6><a title="" href="/">{t('nft_widget_webinar_content2')}</a></h6>
                                                <img alt="" src="assets/images/image/img_clock.png"/>
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default BlogListScreen;