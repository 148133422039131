//
//  solutionM2mScreen.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 16..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
    useEffect,
} from 'react';

// common
import { M2mSolutionMenuKey } from 'constants/key';
import { MenuList } from 'constants/list';

// util
import DataUtil from 'util/dataUtil';

// redux
import { useSelector } from 'react-redux';

// component
import Layout from 'components/layout/layout';
import M2mReceiveView from 'components/subview/m2mReceiveView';
import M2mSendView from 'components/subview/m2mSendView';


/**
 * @protocol SolutionM2mScreen
 * @date 2023/01/16
 * @brief 사수해법 1:1 문의 화면
 */
function SolutionM2mScreen(props) {
    const isLogin = useSelector(state => state.session.isLogin);
    const isMentor = useSelector(state => state.session.isMentor);

    const userMenuList = MenuList.M2mSolutionUserMenuList();
    const mentorMenuList = MenuList.M2mSolutionMentorMenuList();
    const [defaultTab, setDefaultTab] = useState(null);
    const [menuList, setMenuList] = useState(null);

    useEffect(() => {
        if( isLogin ) {
            if( isMentor ) {
                setDefaultTab(M2mSolutionMenuKey.M2M_SOLUTION_TYPE_RECEIVE);
                setMenuList(mentorMenuList);
            }
            else {
                setDefaultTab(M2mSolutionMenuKey.M2M_SOLUTION_TYPE_SEND);
                setMenuList(userMenuList);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin, isMentor]);
    
    /**
     * 사이드 메뉴 목록 반환
     * @returns {Array} 사이드 메뉴 목록
     */
    const renderTypeMenu = () => {
        const itemList = [];
        
        for( var i = 0; i < menuList.length; i++ ) {
            itemList.push(  <li className="nav-item"
                                key={"m2m_solution_menu" + String(i)}>
                                <a className={menuList[i].id === defaultTab ? "active" : ""}
                                    href={'#' + menuList[i].id}
                                    data-toggle="tab">
                                    {menuList[i].title}
                                </a>
                            </li>);
        }
        return itemList;
    }

    /**
     * 메뉴별 컨텐츠 ui 렌더링
     * @returns {Array} 메뉴별 컨텐츠 목록
     */
    const renderContent = () => {
        var itemList = [];

        for( var i = 0; i < menuList.length; i++ ) {
            switch( menuList[i].id ) {
                case M2mSolutionMenuKey.M2M_SOLUTION_TYPE_RECEIVE: {
                    itemList.push(
                        <M2mReceiveView tabId={menuList[i].id}
                            key={menuList[i].id}
                            defaultActive={defaultTab === menuList[i].id ? true : false}/>);
                    break;
                }
                case M2mSolutionMenuKey.M2M_SOLUTION_TYPE_SEND: {
                    itemList.push(
                        <M2mSendView tabId={menuList[i].id}
                            key={menuList[i].id}
                            defaultActive={defaultTab === menuList[i].id ? true : false}/>);
                    break;
                }
                default: {
                    break;
                }
            }
        }
        return itemList;
    }

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}>
			<div className="gap" style={{textAlign: 'left'}}>
                <div className={"tab-pane fade show active"}>
                    <div className="container">
                        {
                            DataUtil.validateArray(menuList) && DataUtil.validateString(defaultTab) ?
                            <div className="row">
                                <div className="col-lg-12">
                                    <div id="page-contents" className="row merged20">
                                        <div className="col-lg-12">
                                            <div className="main-wraper">
                                                <ul className="nav nav-tabs pix-filter">
                                                    {renderTypeMenu()}
                                                </ul>
                                                <div className="tab-content" id="components-nav">
                                                    {renderContent()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            null
                        }
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default SolutionM2mScreen;