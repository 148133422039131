//
//  projectDetailScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 11. 30..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
    useState,
} from 'react';

// common
import { CommonList } from 'constants/list';
import { CommonKey, OrdinalScheduleStatusKey } from 'constants/key';
import ConstantPath from 'constants/path';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkSolution from 'network/networkSolution';
import NetworkM2m from 'network/networkM2m';

// info
import { PROJECTINFO_CONTAINER } from 'info/solution/projectInfo';
import M2mInfo from 'info/solution/m2mInfo';
import {
    ORDINALINFO_PROJECT_ID,
    ORDINALINFO_ORDINAL,
} from 'info/solution/ordinalInfo';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { popupShowDefault, popupShowDefaultCancel } from 'redux/actions/popupAction';

// component
import Layout from 'components/layout/layout';
import NoticeCell from 'components/cell/noticeCell';
import EmptyCell from 'components/cell/emptyCell';
import M2mPopupView from 'components/modal/m2mPopupView';
import ProjectImageView from 'components/image/projectImageView';

// package
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";


/**
 * @protocol ProjectDetailScreen
 * @date 2022/11/30
 * @brief 팀프로젝트 소개 화면
 */
function ProjectDetailScreen(props) {
    var params = useParams();
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    
    const isLogin = useSelector(state => state.session.isLogin);
    const user = useSelector(state => state.session.user);

    const [m2mVisible, setM2mVisible] = useState(false);
    const [project, setProject] = useState(null);

    const jobList = CommonList.JobList();
    const tosList = CommonList.TermOfServiceList();
    const ordinalStatusList = CommonList.OrdinalStatusList();

    useEffect(() => {
        if( project === null ) {
            NetworkSolution.getOrdinalInfo((res) => {
                var projectInfo = res[PROJECTINFO_CONTAINER];
                
                if( projectInfo ) {
                    setProject(projectInfo);
                }
            }, (res) => {
            }, params.project_id, params.ordinal);
        }
    }, [project, params.project_id, params.ordinal, jobList, tosList]);

    /**
     * 기수 상태 문구 반환
     * @returns {String} 기수 상태 문구
     */
    const getOrdinalStatus = () => {
        if( project.ordinalInfo && project.ordinalInfo.ordinalStatus ) {
            var ordinalStatusIndex = ordinalStatusList.findIndex(element => element.id === project.ordinalInfo.ordinalStatus);
            if( ordinalStatusIndex >= 0 ) {
                return ordinalStatusList[ordinalStatusIndex].title;
            }
        }
        return '';
    }

    /**
     * 진도율 반환
     * @returns {Number} 진도율
     */
    const generateProgressRate = () => {
        var count = 0;
        var totalCount = project.ordinalInfo.scheduleList.length;

        // 등록된 스케쥴이 없으면 0 처리
        if( totalCount === 0 ) {
            return 0;
        }

        // 현재 진행 완료된 스케쥴 개수 / 전체 스케쥴 개수
        for( var i = 0; i < project.ordinalInfo.scheduleList.length; i++ ) {
            if( project.ordinalInfo.scheduleList[i].scheduleStatus === OrdinalScheduleStatusKey.ORDINAL_SCHEDULE_STATUS_AFTER ) {
                count += 1;
            }
        }
        return Math.floor(count / totalCount * 100);
    }

    /**
     * 출석율 반환
     * @returns {Number} 출석율
     */
    const generateAttendanceRate = () => {
        var scheduleCount = 0;
        var memberCount = project.ordinalInfo.memberList.length;
        var scheduleList = project.ordinalInfo.scheduleList;
        var attendanceList = project.ordinalInfo.attendanceList;
        
        if( !DataUtil.validateArray(scheduleList) || !DataUtil.validateArray(attendanceList) ) {
            return 0;
        }

        // 진행완료된 스케쥴 개수 계산
        for( var i = 0; i < scheduleList.length; i++ ) {
            if( scheduleList[i].scheduleStatus === OrdinalScheduleStatusKey.ORDINAL_SCHEDULE_STATUS_AFTER ) {
                scheduleCount += 1;
            }
        }

        // 총 출석 개수는 진행완료된 스케쥴 개수 * 멤버 수
        var totalCount = scheduleCount * memberCount;
        var attendanceCount = 0;
        
        // 출석 정보의 스케쥴이 진행완료된 것에 한해서 출석 개수에 합산
        attendanceList.forEach(element => {
            var scheduleIndex = scheduleList.findIndex(item => String(item.weeks) === String(element.weeks));
            
            if( scheduleIndex >= 0 ){
                if( scheduleList[scheduleIndex].scheduleStatus === OrdinalScheduleStatusKey.ORDINAL_SCHEDULE_STATUS_AFTER ) {
                    attendanceCount += 1;
                }
            }
        });

        return Math.floor(attendanceCount / totalCount * 100);
    }

    /**
     * 취소 버튼 클릭시 호출
     */
    const onM2mCancelPressed = () => {
        setM2mVisible(false);
    }

    /**
     * 작성 버튼 클릭시 호출
     * @param {String} content 1:1 문의 내용
     */
    const onM2mConfirmPressed = (content) => {
        setM2mVisible(false);
        
        var m2mInfo = new M2mInfo();
        m2mInfo.projectId = project.projectId;
        m2mInfo.ordinal = project.ordinalInfo.ordinal;
        m2mInfo.memberId = user.memberId;
        m2mInfo.contents = content;

        NetworkM2m.requestPostProjectM2m((res) => {
            dispatch(popupShowDefault(t('alert_complete_post_m2m')));
        }, (res) => {
            dispatch(popupShowDefault(t('alert_fail_post_m2m')));   
        }, m2mInfo);
    }

    /**
     * 스케쥴 목록 ui 렌더링
     * @returns {Array} 스케쥴 목록
     */
    const renderSchedule = () => {
        var itemList = [];

        if( DataUtil.validateArray(project.ordinalInfo.scheduleList) ) {
            for( var i = 0; i < project.ordinalInfo.scheduleList.length; i++ ) {
                itemList.push(  <div className="card"
                                    key={"schedule" + String(i)}>
                                    <div className="card-header" id="headingOne">
                                        <h5 className="mb-0">
                                            <p>{project.ordinalInfo.scheduleList[i].weeks + t('word_schedule_week') + ' - ' +
                                            project.ordinalInfo.scheduleList[i].schedule}</p>
                                        </h5>
                                    </div>
                                </div>);
            }
        }
        return itemList;
    }

    /**
     * 공지사항 ui 목록 렌더링
     * @returns {Array} 공지사항 ui 목록
     */
    const renderNotice = () => {
        var itemList = [];

        if( DataUtil.validateArray(project.ordinalInfo.noticeList) ) {
            for( var i = 0; i < project.ordinalInfo.noticeList.length; i++ ) {
                itemList.push(  <NoticeCell key={'notice' + String(i)}
                                    data={project.ordinalInfo.noticeList[i]}
                                    onViewPressed={(id) => {
                                        navigate(location.pathname + ConstantPath.PATH_NOTICE + '/' + id);
                                    }}/>);
            }
        }
        else {
            itemList.push(<EmptyCell key={'review_empty'} disableList={true}/>);
        }
        return itemList;
    }

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}
            visibleSolutionHeader={true}
            location={ConstantPath.PATH_SOLUTION_PROJECT}>
			<div className="gap" style={{textAlign: 'left'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                project ?
                                <div id="page-contents" className="row merged20">
                                    <div className="col-lg-12">
                                        <div className="main-wraper">
                                            <div className="row">
                                                <div className="col-lg-7 col-md-7">
                                                    <div className="course-details">
                                                        <h4>
                                                            {t('project_intro_category') + ' ' + project.name + ' - ' + project.weeks + t('word_weeks')}
                                                        </h4>
                                                        <span className="course-price">{getOrdinalStatus()}</span>
                                                        <p style={{whiteSpace: 'pre-wrap'}}>{project.intro}</p>
                                                        <div className="create-by" style={{marginBottom: '30px'}}>
                                                            <figure><img src={project.mentor.deskImgUrl} alt=""/></figure>
                                                            <div>
                                                                <span>{project.mentor.nickname}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-5">
                                                    <div className="course-video" style={{marginBottom: '0px'}}>
                                                        <ProjectImageView
                                                            maxWidth='500px'
                                                            imageUrl={project.gateImgUrl}/>
                                                    </div>
                                                    <div className="row"
                                                        style={{marginBottom: '30px', justifyContent: 'center'}}>
                                                        {
                                                            user && user.memberId === project.mentor.memberId ?
                                                            <button className='button primary circle'
                                                                style={{marginRight: '20px'}}
                                                                onClick={() => {
                                                                    navigate(ConstantPath.PATH_PROJECT_MANAGE, {
                                                                        state: {
                                                                            [ORDINALINFO_PROJECT_ID]: project.ordinalInfo.projectId,
                                                                            [ORDINALINFO_ORDINAL]: project.ordinalInfo.ordinal,
                                                                        }
                                                                    });
                                                                }}>
                                                                {t('button_manage_ordinal')}
                                                            </button> :
                                                            null
                                                        }
                                                        <button className='button primary circle'
                                                            style={{marginRight: '20px'}}
                                                            onClick={() => {
                                                                if( isLogin ) {
                                                                    setM2mVisible(true);
                                                                }
                                                                else {
                                                                    dispatch(popupShowDefaultCancel(t('alert_not_login'), () => {
                                                                        setTimeout(() => {
                                                                            navigate(ConstantPath.PATH_LOGIN, {
                                                                                state: { [CommonKey.REDIRECT]: location.pathname }
                                                                            });
                                                                        }, 100);
                                                                    }), null);
                                                                }
                                                            }}>
                                                            {t('button_m2m')}
                                                        </button>
                                                        <button className='button primary circle'
                                                            style={{marginRight: '20px'}}
                                                            onClick={() => {
                                                                var path = ConstantPath.PATH_PROJECT_REVIEW.split("/");
                                                                var strippedPath = path.slice(0, path.length-1).join("/");
                                                                navigate(strippedPath + '/' + project.projectId);
                                                            }}>
                                                            {t('button_project_review')}
                                                        </button>
                                                        <button className='button primary circle'
                                                            onClick={() => {
                                                                var path = ConstantPath.PATH_MENTOR_REVIEW.split("/");
                                                                var strippedPath = path.slice(0, path.length-1).join("/");
                                                                navigate(strippedPath + '/' + project.mentor.memberId);
                                                            }}>
                                                            {t('button_mentor_review')}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="course-description">
                                                        <h4 className="main-title">{t('project_detail_goal')}</h4>
                                                        <p style={{whiteSpace: 'pre-wrap'}}>{project.goal}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="course-description">
                                                        <h4 className="main-title">{t('project_detail_description')}</h4>
                                                        <p style={{whiteSpace: 'pre-wrap'}}>{project.description}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-5">
                                                    <div className="course-description">
                                                        <h4 className="main-title">{t('project_detail_section_project')}</h4>
                                                        <div className="create-date">
                                                            <ul>
                                                                <li>
                                                                    <span>{t('project_invoice_date') + ' :'}</span>
                                                                    {' ' + DataUtil.convertDateToString(project.ordinalInfo.startdate) +
                                                                    '~' + DataUtil.convertDateToString(project.ordinalInfo.enddate)}
                                                                </li>
                                                                <li>
                                                                    <span>{t('project_detail_progress') + ' :'}</span>
                                                                    {' ' + String(generateProgressRate()) + '%'}
                                                                </li>
                                                                <li>
                                                                    <span>{t('project_detail_attendance') + ' :'}</span>
                                                                    {' ' + String(generateAttendanceRate()) + '%'}
                                                                </li>
                                                                <li>
                                                                    <a href={project.ordinalInfo.gatertownUrl} target="_blank" rel="noreferrer">
                                                                        {t('project_detail_gater')}
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href={project.ordinalInfo.openchatUrl} target="_blank" rel="noreferrer">
                                                                        {t('project_detail_openchat')}
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-7 col-md-7">
                                                    <div className="course-description">
                                                        <h4 className="main-title">{t('project_detail_section_schedule')}</h4>
                                                        <div className="question-collaps">
                                                            <div id="accordion">
                                                                {renderSchedule()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="main-wraper">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="course-description" style={{marginTop: '0px'}}>
                                                        <h4 className="main-title">{t('project_detail_section_notice')}
                                                            <a className="view-all" href={location.pathname + ConstantPath.PATH_NOTICE}>{t('button_more')}</a>
                                                        </h4>
                                                        <div className="question-collaps">
                                                            <div id="accordion">
                                                                {renderNotice()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <M2mPopupView
                                        visible={m2mVisible}
                                        userInfo={project.mentor}
                                        onCancelPressed={onM2mCancelPressed}
                                        onConfirmPressed={onM2mConfirmPressed}/>
                                </div> :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
            
        </Layout>
    );
}

export default ProjectDetailScreen;